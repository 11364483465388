import "./HomePage.style.scss";
import { homePageLogic } from "./HomePage.logic";
import { HeaderNews } from "../../_components/Header";
import ButtonNews from "../../_components/Button/ButtonNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import Slogan from "../../_components/Slogan/Slogan";
import { TypeHomePage } from "../../_constants/homepageNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { renderListDetail } from "../../_base/DetailListNews";
import { handleCheckEven } from "../../_base/Validate";
import AdvisementForm from "../../_components/AdvisementForm/AdvisementForm";
import { userConstants } from "../../_constants";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import LazyLoad from "react-lazyload";

const HomePage = (props) => {
  let {
    dataBanner1,
    dataBanner2,
    dataContent,
    handleClickNavigate,
    handleNavigateCurriculum,
    handleNavigateCurriculumMobile,
  } = homePageLogic(props);

  const renderContentList = (item) => {
    return (
      <div
        key={item.id}
        className={`item_content_student flex-align pos_rel ${
          handleCheckEven(item.id) ? "item_content_even" : "item_content_odd"
        }`}
        style={{
          borderBottom: handleCheckEven(item.id) && item.id != 9 && "none",
          paddingLeft: handleCheckEven(item.id)
            ? "calc(5.4vw + 0.5rem)"
            : "1.8vw",
          paddingRight: !handleCheckEven(item.id)
            ? "calc(5.4vw + 0.5rem)"
            : "1.8vw",
        }}
      >
        <div
          className={` flex-align col-100 ${
            handleCheckEven(item.id) ? "item_detail_even" : "item_detail_odd"
          }`}
        >
          <div className="color-red font_news_extra_bold number_content_student">
            {item.id}
          </div>
          <div
            className="fz-16-875 fz-11-mobile"
            style={{
              textAlign: handleCheckEven(item.id) ? "right" : "left",
              marginLeft: !handleCheckEven(item.id) && "calc(2vw + 0.5rem)",
              marginRight: handleCheckEven(item.id) && "calc(2vw + 0.5rem)",
            }}
          >
            {item.id != 6 ? (
              item.content
            ) : (
              <span className="fz-16-875 fz-11-mobile">
                {item.content}
                <span className="color-primary-green font_news_bold fz-18 fz-14-mobile">
                  {item?.sideText}
                </span>
                {item?.tailText}
              </span>
            )}
          </div>

          {item.id == dataContent.length && (
            <img
              src="/assets/images/homeNews/icon/ico_proactive.png"
              alt="Icon Proactive"
              className="icon_proactive"
            />
          )}
        </div>
      </div>
    );
  };

  const renderContentList2 = (item) => {
    return (
      <div
        key={item.id}
        className={`item_content_student flex-align pos_rel ${
          handleCheckEven(item.id) ? "item_content_even" : "item_content_odd"
        }`}
        style={{
          border: "none",
          paddingLeft: handleCheckEven(item.id)
            ? "calc(5.4vw + 0.5rem)"
            : "1.8vw",
          paddingRight: !handleCheckEven(item.id)
            ? "calc(5.4vw + 0.5rem)"
            : "1.8vw",
        }}
      >
        <div
          className={` flex-align col-100 ${
            handleCheckEven(item.id) ? "item_detail_even" : "item_detail_odd"
          }`}
        >
          <div className="color-red font_news_extra_bold number_content_student">
            {item.id}
          </div>
          <div
            className="fz-16-875 fz-11-mobile"
            style={{
              textAlign: handleCheckEven(item.id) ? "right" : "left",
              marginLeft: !handleCheckEven(item.id) && "calc(2vw + 0.5rem)",
              marginRight: handleCheckEven(item.id) && "calc(2vw + 0.5rem)",
            }}
          >
            {item.id != 6 ? (
              item.content
            ) : (
              <span className="fz-16-875 fz-11-mobile">
                {item.content}
                <span className="color-primary-green font_news_bold fz-18 fz-14-mobile">
                  {item?.sideText}
                </span>
                {item?.tailText}
              </span>
            )}
          </div>

          {item.id == dataContent.length && (
            <img
              src="/assets/images/homeNews/icon/ico_proactive.png"
              alt="Icon Proactive"
              className="icon_proactive"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="homepage bg-white commondPage">
      <HeaderNews type="" />

      <div>
        <LazyLoad once className="slider ">
          <LazyLoad
            className="slider_bg display_none_mobile"
            style={{
              background: `url(
              /assets/images/homeNews/slider/slider_bg.png
            ) no-repeat top center /cover`,
              display: "flex",
              justifyContent: "space-between",
              backgroundAttachment: "scroll",
            }}
          >
            <div className="title_container">
              <div className="title_name font_news_bold ">SUNDAY ENGLISH</div>

              <div className="title_detail_1 font_news_extra_bold line_height_normal">
                GIẢI PHÁP NÂNG CAO
              </div>
              <div className="title_detail_2 ">
                NĂNG LỰC TIẾNG ANH HỌC SINH THCS
              </div>
              {/* <ButtonNews
              width="55%"
              height={"4rem"}
              onClick={() => handleNavigateCurriculum()}
              backgroundColor={"#FDCD04"}
            >
              <div className="btn_text font_news_bold">HỌC THỬ MIỄN PHÍ</div>
            </ButtonNews> */}
            </div>
            <LazyLoad once className="slider_1 pos_rel">
              <div className="slider_1_img_wrapper">
                <img
                  src="/assets/images/homeNews/slider/student/slider_1.png"
                  alt="Slider 1"
                  className="slider_1_img contain_image"
                />
              </div>
              <LazyLoad once className="pos_abs img_side_container">
                <img
                  src="/assets/images/homeNews/slider/student/img_side_home.png"
                  alt="Slider 1"
                  className=" contain_image img_side_1"
                />
                <LazyLoad once className="pos_abs img_side_2_container">
                  {/* <img
                    src="/assets/images/homeNews/slider/video_slider_parent.png"
                    alt="Slider 1"
                    className=" contain_image img_side_2"
                  /> */}
                  <div
                    className="col-100 flex-center video_slide_2"
                    style={{
                      height: "100%",
                    }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      controls={false}
                      playsInline
                      src="/assets/videos/student_video.mp4"
                      // alt="Video Slider Teacher"
                      className=" img_side_2 box-shadow"
                      poster="/assets/images/homeNews/slider/student_poster.jpg"
                    />
                  </div>
                  <img
                    src="/assets/images/homeNews/slider/student/img_circle_slider.png"
                    alt="Slider 1"
                    className=" contain_image img_side_circle"
                  />
                </LazyLoad>
              </LazyLoad>
            </LazyLoad>
          </LazyLoad>
          <LazyLoad
            className="slider_bg_mobile hide display_flex_mobile"
            style={{
              background: `url(
              /assets/images/homeNews/slider/slider_bg_mobile.png
            ) no-repeat top center /cover`,
              // paddingTop: "50%",
              display: "flex",
              justifyContent: "space-between",
              backgroundAttachment: "scroll",
            }}
          >
            <div className="title_container">
              <div className="title_name font_news_bold ">HỌC TRỰC TUYẾN</div>

              <div className="title_detail_1 line_height_normal font_news_extra_bold">
                GIẢI PHÁP NÂNG CAO
              </div>

              <div className="title_detail_2">
                NĂNG LỰC TIẾNG ANH HỌC SINH THCS
              </div>
              {/* <ButtonNews
              width="45%"
              height={"4rem"}
              onClick={() => handleNavigateCurriculumMobile()}
              backgroundColor={"#FDCD04"}
            >
              <div className="btn_text font_news_bold">HỌC THỬ MIỄN PHÍ</div>
            </ButtonNews> */}
            </div>
            <LazyLoad className="slider_1 pos_rel">
              <div className="slider_1_img_wrapper">
                <img
                  src="/assets/images/homeNews/slider/student/slider_1.png"
                  alt="Slider 1"
                  className="slider_1_img contain_image"
                />
              </div>
              <LazyLoad once className="pos_abs img_side_container">
                <img
                  src="/assets/images/homeNews/slider/student/img_side_home.png"
                  alt="Slider 1"
                  className=" contain_image img_side_1"
                />
                <LazyLoad once className="pos_abs img_side_2_container">
                  {/* <img
                    src="/assets/images/homeNews/slider/video_slider_parent.png"
                    alt="Slider 1"
                    className=" contain_image img_side_2"
                  /> */}
                  <video
                    autoPlay
                    muted
                    loop
                    controls={false}
                    playsInline
                    src="/assets/videos/student_video.mp4"
                    // alt="Video Slider Teacher"
                    poster="/assets/images/homeNews/slider/student_poster.jpg"
                    className=" img_side_2 box-shadow"
                  />
                  <img
                    src="/assets/images/homeNews/slider/student/img_circle_slider.png"
                    alt="Slider 1"
                    className=" contain_image img_side_circle"
                  />
                </LazyLoad>
              </LazyLoad>
            </LazyLoad>
          </LazyLoad>
        </LazyLoad>
      </div>

      <div className="slider_student flex-center-column pd-top-2-pc">
        <div className="display_block_mobile hide col-50-mobile border-bottom-2-mobile"></div>
        <div className="flex-center-column pd-top-2">
          <div className="font_news_bold title_1_student  text-align-center">
            ỨNG DỤNG HỌC THÔNG MINH
          </div>
          <div className="text-align-center title_2_student font_news_extra_bold color-red ">
            GIÚP HỌC SINH BỨT PHÁ ĐIỂM 9, 10
          </div>
        </div>

        <LazyLoad once className="col-100 pd-top-3-pc">
          <img
            src="/assets/images/homeNews/slider/slider_student.png"
            alt="Slider Student Img"
            className="cover_image img_slide_student display_none_mobile"
          />
          <img
            src="/assets/images/homeNews/slider/slider_student_mobile.png"
            alt="Slider Student Img"
            className="cover_image img_slide_student hide display_block_mobile"
          />
        </LazyLoad>
      </div>

      <div className="content_main flex-center-column">
        <div className="content_bg_1">
          <div className="content_title flex-center-column">
            {/* <div className="content_title_1 flex-center-column">
              <div className="flex-align">
                <div
                  style={{
                    fontSize: "1.3rem",
                  }}
                  className="fz-11-mobile"
                >
                  <span
                    className="fz-11-mobile color-light-black font_news_bold"
                    style={{
                      fontSize: "1.2rem",
                      marginRight: "0.4rem",
                    }}
                  >
                    TRỊ MẤT GỐC TIẾNG ANH
                  </span>
                  chỉ sau
                </div>
              </div>

              <div
                className="font_news_bold color-red fz-22-mobile"
                style={{
                  fontSize: "3.2rem",
                }}
              >
                6 - 9 THÁNG
              </div>
            </div>

            <ButtonNews
              width="12rem"
              height={"3.5rem"}
              onClick={() => handleClickNavigate(TypeHomePage.BENEFIT_STUDENT)}
              border={"1px solid #00cc83"}
            >
              <div className=" btn_text font_news_bold">XEM THÊM</div>
            </ButtonNews> */}

            <LazyLoad once className="col-100">
              <div className="col-100 flex-center-column pos_rel content_student_container">
                {/* <div className="list_content_student flex-column">
                  {dataContent.map((item) => renderContentList(item))}
                </div> */}

                <div
                  className="list_content_student flex-column display_none_mobile"
                  style={{
                    background: `url(
              /assets/images/homeNews/content/bg_border_student.png
            ) no-repeat top center /contain`,
                    backgroundAttachment: "scroll",
                  }}
                >
                  {dataContent.map((item) => renderContentList2(item))}
                </div>

                <div className="list_content_student flex-center-column hide display_flex_mobile">
                  <img
                    src="/assets/images/homeNews/content/img_detail_student_mobile.png"
                    alt="Img Detail Student"
                    className="contain_image col-100 img_detail_student_mobile"
                  />
                </div>

                <div className="display_none_mobile list_icon_bg">
                  <div className="img_left_top_student pos_abs">
                    <img
                      src="/assets/images/homeNews/content/icon/ico_left_trial.png"
                      alt="Icon"
                      className="contain_image"
                    />
                  </div>

                  <div className="img_left_center pos_abs">
                    <img
                      src="/assets/images/homeNews/content/student/ico_left_student.png"
                      alt="Icon"
                      className="contain_image"
                    />
                  </div>

                  <div className="img_right_top pos_abs">
                    <img
                      src="/assets/images/homeNews/content/student/ico_right_student.png"
                      alt="Icon"
                      className="contain_image"
                    />
                  </div>

                  <div className="img_right_bottom pos_abs">
                    <img
                      src="/assets/images/homeNews/content/icon/ico_top_teacher.png"
                      alt="Icon"
                      className="contain_image"
                    />
                  </div>
                </div>

                {/* <div className="flex-jus-evenly pd-top-2-pc trial_container">
                <img
                  src="/assets/images/homeNews/content/student/box_trial.png"
                  alt="Img Trial"
                  className="contain_image img_box_trial"
                />

                <div
                  className="text-align-center color-red text_underline font_news_bold fz-22 fz-18-mobile pointer_cursor"
                  onClick={() => handleNavigateCurriculum()}
                >
                  HỌC THỬ MIỄN PHÍ
                </div>
              </div> */}
              </div>
            </LazyLoad>

            <div className="col-100 content_banner_teacher">
              <LazyLoad once className="col-100">
                <div className="flex-center-column content_banner pos_rel">
                  <img
                    src="/assets/images/homeNews/content/banner_1.png"
                    alt="Banner 1"
                    className="img_banner display_none_mobile"
                  />
                  <img
                    src="/assets/images/homeNews/content/banner_1_mobile.png"
                    alt="Banner 1"
                    className="img_banner hide display_block_mobile"
                  />

                  <div className="display_block_mobile hide col-50-mobile border-bottom-2-mobile"></div>
                  <div className="flex-center-column col-90-mobile">
                    <div
                      className="fz-21-5-mobile text-align-center font_news_extra_bold font_news_black_mobile color-red pd-top-1"
                      style={{
                        fontSize: "3.7rem",
                      }}
                    >
                      CÔNG CỤ ĐẮC LỰC
                    </div>

                    <div className="text-align-center fz-25 fz-1-mobile color-light-black font_news_bold">
                      GIÚP THẦY CÔ DẠY TIẾNG ANH HIỆU QUẢ
                    </div>

                    <div className="list_detail_banner">
                      {dataBanner1.map((item) => renderListDetail(item, 1))}
                    </div>
                    <ButtonNews
                      width="12rem"
                      height={"3.5rem"}
                      onClick={() =>
                        handleClickNavigate(TypeHeaderNewsItem.TEACHER)
                      }
                      border={"1px solid #00cc83"}
                      backgroundColor={"transparent"}
                    >
                      <div className="btn_text font_news_bold">XEM THÊM</div>
                    </ButtonNews>
                  </div>

                  <div className="display_none_mobile">
                    <div className="icon_left_teacher_container ">
                      <img
                        src="/assets/images/homeNews/content/icon/ico_left_teacher.png"
                        alt="Icon Text Left"
                        className="contain_image"
                      />
                    </div>

                    <div className="icon_dots_container ">
                      <img
                        src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                        alt="Icon Text Center"
                        className="contain_image"
                      />
                    </div>

                    <div className="icon_right_teacher_container ">
                      <img
                        src="/assets/images/homeNews/content/icon/ico_right_teacher.png"
                        alt="Icon Text Right"
                        className="contain_image"
                      />
                    </div>

                    <div className="icon_top_teacher_container ">
                      <img
                        src="/assets/images/homeNews/content/icon/ico_top_teacher.png"
                        alt="Icon Text Right"
                        className="contain_image"
                      />
                    </div>

                    <div className="icon_half_teacher_container ">
                      <img
                        src="/assets/images/homeNews/content/icon/ico_half_circle.png"
                        alt="Icon Text Right"
                        className="contain_image"
                      />
                    </div>
                  </div>
                </div>
              </LazyLoad>
            </div>

            <LazyLoad once className="col-100">
              <div className="flex-center-column mg-top-2-mobile content_banner col-100">
                <img
                  src="/assets/images/homeNews/content/banner_2.png"
                  alt="Banner 2"
                  className="img_banner display_none_mobile"
                />
                <img
                  src="/assets/images/homeNews/content/banner_2_mobile.png"
                  alt="Banner 1"
                  className="img_banner hide display_block_mobile"
                />

                <div className="display_none_mobile flex-center-column">
                  <div className="flex-align-end flex-center-column-mobile ">
                    <div className="fz-25 flex-center-column-mobile text-align-center fz-11-mobile font_news_bold color-light-black">
                      BA MẸ KHÔNG GIỎI TIẾNG ANH
                      {/* <span className="col-100-mobile text-align-center-mobile">
                    <div className="display_inline_block_mobile hide col-20-mobile border-bottom-1-mobile"></div>{" "}
                    vẫn{" "}
                    <span
                      className=" fz-18-mobile color-primary-green font_news_bold"
                      style={{
                        fontSize: "2.4rem",
                      }}
                    >
                      TỰ TIN{" "}
                    </span>
                    <div className="display_inline_block_mobile hide col-20-mobile border-bottom-1-mobile"></div>
                  </span> */}
                    </div>
                  </div>

                  {/* <div className="fz-11-mobile flex-align-end">
                <div
                  style={{
                    fontSize: "1.3rem",
                  }}
                >
                  <span
                    className="fz-22-mobile font_news_bold color-red"
                    style={{
                      fontSize: "2.3rem",
                      marginTop: "2rem",
                      marginRight: "0.5rem",
                    }}
                  >
                    ĐỒNG HÀNH
                  </span>
                  cùng con
                </div>
              </div> */}

                  <div className="fz-11-mobile flex-align-end ">
                    <span
                      className="fz-24-mobile text-align-center font_news_extra_bold color-red text-align-center"
                      style={{
                        marginRight: "0.5rem",
                        fontSize: "2.9rem",
                      }}
                    >
                      VẪN TỰ TIN ĐỒNG HÀNH CÙNG CON
                    </span>
                  </div>
                </div>

                <div className="flex-center-column col-100 hide display_flex_mobile">
                  <div className="flex-align-end flex-center-column-mobile col-100">
                    <div
                      className="col-100 fz-12 text-align-center flex-center-column-mobile font_news_bold color-light-black"
                      style={{
                        fontSize: "1.25rem",
                      }}
                    >
                      BA MẸ KHÔNG GIỎI TIẾNG ANH
                      <span className="col-100-mobile text-align-center-mobile">
                        <div className="display_inline_block_mobile hide col-20-mobile border-bottom-2-black-mobile"></div>{" "}
                        <span
                          className="text-align-center color-primary-green font_news_bold"
                          style={{
                            fontSize: "1.75rem",
                          }}
                        >
                          VẪN TỰ TIN
                        </span>
                        <div className="display_inline_block_mobile hide col-20-mobile border-bottom-2-black-mobile"></div>
                      </span>
                    </div>
                  </div>

                  <div className="fz-11-mobile flex-align-end ">
                    <span
                      className="text-align-center font_news_black color-red"
                      style={{
                        fontSize: "1.75rem",
                        marginTop: "0.3rem",
                      }}
                    >
                      ĐỒNG HÀNH CÙNG CON
                    </span>
                  </div>
                </div>
                <div className="col-100 flex-center-column pos_rel col-90-mobile">
                  {/* <div className="list_detail_banner list_detail_parent ">
                  {dataBanner2.data1.map((item) => renderListDetail(item))}
                </div>
                <div className="list_detail_banner  ">
                  {dataBanner2.data2.map((item) => renderListDetail(item))}
                </div> */}
                  <div className="list_detail_banner list_detail_parent">
                    {dataBanner2.map((item) => renderListDetail(item, 1))}
                  </div>
                  <div className="icon_left_container display_none_mobile">
                    <img
                      src="/assets/images/homeNews/content/icon/ico_text_left.png"
                      alt="Icon Text Left"
                      className="contain_image icon_text_left"
                    />
                  </div>
                  <div className="icon_center_container">
                    <img
                      src="/assets/images/homeNews/content/icon/ico_bonus_center.png"
                      alt="Icon Text Center"
                      className="contain_image icon_bonus_center"
                    />
                  </div>
                  <div className="icon_right_container display_none_mobile">
                    <img
                      src="/assets/images/homeNews/content/icon/ico_text_right.png"
                      alt="Icon Text Right"
                      className="contain_image icon_text_right"
                    />
                  </div>
                </div>
                <ButtonNews
                  width="12rem"
                  height={"3.5rem"}
                  onClick={() => handleClickNavigate(TypeHeaderNewsItem.PARENT)}
                  border={"1px solid #00cc83"}
                >
                  <div className="btn_text fz-1-mobile font_news_bold">
                    XEM THÊM
                  </div>
                </ButtonNews>
              </div>
            </LazyLoad>
          </div>
        </div>

        <Slogan />

        <LazyLoad once className="col-100">
          <div className="hide display_flex_mobile col-100 flex-center pd-bottom-15">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="94%"
              height="2"
              viewBox="0 0 344 2"
              fill="none"
            >
              <path
                d="M0 1H343.5"
                stroke="black"
                strokeWidth="0.6"
                strokeDasharray="4 4"
              />
            </svg>
          </div>
        </LazyLoad>

        <div id="advisement_home" className="flex-center-column col-100">
          <AdvisementForm
            placeholder="Họ và tên học sinh"
            type={userConstants.ROLE_STUDENT}
          />
        </div>
      </div>

      <FooterNews />

      <ScrollFixed />
    </div>
  );
};

export default HomePage;
