import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function EmptyClassSlider() {
  const authentication = useSelector((state) => state.authentication);
  return (
    <div className="slider-custom-2 uk-slider box-no-class">
      <div className="uk-position-relative">
        <div className="uk-slider-container uk-light">
          <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
            <li>
              <div className="box-shadow box-white-content text-center slider-item-content flex-m">
                <div className="img-thumb">
                  <img
                    src="assets/images/img_no_class.png"
                    alt="img_no_class"
                  />
                </div>
                <div className="flex-1 slider-item-desc">
                  <div className="center-horizontal">
                    <p className="text-light">
                      Bạn chưa có lớp học{" "}
                      <span className="font-weight-bold">online</span> nào.
                      <br />
                      Để theo dõi báo cáo học tập, hãy tạo lớp!
                    </p>
                    <span className="form-sunE-button rel btn-img">
                      <img
                        src="assets/images/icon/ico_add.png"
                        alt="ico_add"
                        className="ico_add_custom"
                      />
                      <Link
                        to={
                          "/" + authentication.role + "/class/add?page=homepage"
                        }
                      >
                        <button className="btn-line-blue create_class_new">
                          Tạo lớp mới
                        </button>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="uk-visible@s">
          <a
            className="uk-position-center-left-out uk-position-small"
            href="!#"
            uk-slider-item="previous"
          >
            <img
              src="assets/images/icon/ico_left_white.png"
              alt="ico_left_white"
            />
          </a>
          <a
            className="uk-position-center-right-out uk-position-small"
            href="!#"
            uk-slider-item="next"
          >
            <img
              src="assets/images/icon/ico_right_white.png"
              alt="ico_right_white"
            />
          </a>
        </div>
        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" />
      </div>
    </div>
  );
}

export { EmptyClassSlider };
