import React, { useEffect, useRef, useState } from "react";
import { isJsonString } from "../../../_helpers/utils";
import { AudioV2 } from "../../../_components/Audio";

export default function ResultQuestionPronun8(props) {
  const {dataQuestion, skill, questionType, answer, isReview} = props;


  const audio = dataQuestion[0]?.list_option[0]?.content_question ? JSON.parse(dataQuestion[0]?.list_option[0]?.content_question)?.content_question_audio : null;

  let listData = [];
  const temp = [];
  
  let finalAnswer = JSON.parse(answer)?.map(item => {
    const user_choice = isJsonString(item?.user_choice) ? JSON.parse(item.user_choice) : item.user_choice
    return user_choice
    
  })?.flat();
  const item = dataQuestion[0]?.list_option?.[0]
  const listLetter = item?.match_option_text?.map(text => text?.split(', '))?.flat();
  const listResultRemaining = listLetter?.filter(letter => !finalAnswer?.includes(letter));

  listData = listLetter?.map((letter, index) => {
    const user_choice = isReview ? letter : finalAnswer?.[index] || '';
    const isRightAns = !temp.includes(user_choice) && listLetter?.includes(user_choice);
    const data = {
      words: isRightAns ? user_choice : listResultRemaining?.shift(),
      score: isRightAns ? 1 : 0,
      user_choice,
    }
    temp.push(user_choice)
    return data
  })

  const renderItem = (item, index) => {
    
    return (
      <div>
        <div style={{flexDirection: 'row', display: 'flex'}}>
          <p style={{flexDirection: 'row', display: 'flex', gap: 4}}>
              {`${index + 1}.`}
              <p style={{color: item?.score > 0 ? '#62B440' : 'red'}}>{isReview ? item?.words : item?.user_choice}</p>
          </p>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems:'center'
        }}>
          {!item?.score && <div 
            style={{
              marginLeft: 16,
              marginRight: 6,
              width: 24,
              height: 24,
            }}
          >
            {!item?.score ? <img 
              src="/assets/images/ico_hover_answer.png"
              style={{
                width: 24,
                height: 24,
              }}
            /> : null}
          </div>}
          <div style={{marginLeft: 6, flex: 1}}>
            {!item?.score && <p style={{
              color: '#62B440',
            }}>
                {item?.words}
            </p>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {audio && <div className="questions title-question title_question_width" style={{display: 'block', margin: '8px 0 32px'}}>
        <AudioV2 link={audio} />
      </div>
      }
      <div style={{flex: 1, marginTop: 16, display: 'flex', flexDirection: 'column', gap: 32}}>
        {listData?.map((item,index) => {
          return (
            <div key={index}>
              {renderItem(item, index)}
            </div>
          )
        })}
      </div>
    </div>
  )
}