import "./ForgotPwNews.style.scss";
import { HeaderNews } from "../../../_components/Header";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import FooterAuth from "../../../_components/FooterNews/FooterAuth";
import ModalAuth from "../../../_components/Modal/ModalAuth/ModalAuth";
import InputText from "../../../_components/Auth/InputText";
import ButtonNews from "../../../_components/Button/ButtonNews";
import { forgotPwLogic } from "./ForgotPwNews.logic";

const ForgotPwNews = () => {
  let {
    email,
    emailError,
    onBlurField,
    disabledBtnSend,
    setEmailError,
    changeEmail,
    handleSendRequired,
    handleCancel,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    textBtnSend,
  } = forgotPwLogic();

  const renderAuthIcon = (type) => {
    return (
      <div className="flex-center">
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Email"
          className="contain_image icon img_label_input"
        />
      </div>
    );
  };

  return (
    <div className="loginNew loginAbsolute commondPage">
      <HeaderNews type={TypeHeaderNewsItem.LOGIN} />

      <div
        className="content flex-column"
        style={{
          background: `url(
              /assets/images/authNews/bg_login.png
            ) no-repeat top center /cover`,
        }}
      >
        <div className="content_1 flex-center-column">
          <ModalAuth
            title="Quên mật khẩu"
            desc1="Quay lại"
            desc2="Đăng nhập"
            linkAuth={`/${TypeHeaderNewsItem.LOGIN}`}
          >
            <div className="form_input_login">
              <InputText
                value={email}
                setValue={changeEmail}
                type="text"
                name="email"
                placeholder="Địa chỉ Email"
                required={true}
                renderLabelIcon={() => renderAuthIcon("email")}
                errorText={emailError}
                onBlur={() => {
                  onBlurField("email");
                }}
                setErrorText={setEmailError}
                errorAbsolute={true}
              ></InputText>
              {/* <InputText
                value={phone}
                setValue={changePhone}
                type="text"
                name="phone"
                placeholder="Số điện thoại"
                required={true}
                renderLabelIcon={() => renderAuthIcon("phone")}
                errorText={phoneWarning}
                setErrorText={setPhoneWarning}
                onBlur={() => {
                  onBlurField("phone");
                }}
              ></InputText> */}

              <div className="flex-center-column msg_success hide">
                <img
                  // loading="lazy"
                  src="/assets/images/icon/ico_tick_success.png"
                  alt="Icon Success"
                  className="contain_image image_success hide"
                />
                <div className="dot_typing"></div>
                <div className="fz-11-25 text-align-center fz-09-mobile mg-top-15">
                  Đường dẫn đặt lại mật khẩu đã được gửi đến địa chỉ email{" "}
                  <span className="fz-11-25 mg-left-0-5 fz-09-mobile font_news_bold">
                    {email}
                  </span>
                  . Bạn vui lòng kiểm tra hộp thư và làm theo hướng dẫn nhé!
                </div>
              </div>

              <div className="flex-jus-between btn_auth_container">
                <ButtonNews
                  width="15rem"
                  height="3.5rem"
                  onClick={() => handleSendRequired()}
                  border={"1px solid #00cc83"}
                  backgroundColor={"#00cc83"}
                  boxShadow={"2px 2px 5px white, 0 0 1px black"}
                  maxWidth={"45%"}
                  maxHeight={"7vw"}
                  disabled={disabledBtnSend}
                >
                  <div className="fz-12 fz-08-mobile color-white font_news_bold">
                    {textBtnSend}
                  </div>
                </ButtonNews>

                <ButtonNews
                  width="15rem"
                  height="3.5rem"
                  onClick={() => handleCancel()}
                  border={"1px solid #00cc83"}
                  backgroundColor={"transparent"}
                  maxWidth={"45%"}
                  maxHeight={"7vw"}
                >
                  <div className="fz-12 fz-08-mobile color-primary-green font_news_bold">
                    HỦY
                  </div>
                </ButtonNews>
              </div>

              {/* <div className="flex-center-column connect_social fz-08-mobile">
                <div>Hoặc kết nối với</div>

                <div
                  onClick={() => console.log("123")}
                  className="pointer_cursor box_auth_social flex-center"
                >
                  <img
                    src="/assets/images/authNews/ico_gg.png"
                    alt="Icon Google"
                    className="contain_image icon_auth_social"
                  />
                </div>
              </div> */}
            </div>
          </ModalAuth>
        </div>
        <FooterAuth />
      </div>
    </div>
  );
};

export default ForgotPwNews;
