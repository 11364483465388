import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { apiCaller } from "../../../../_helpers"

export const EntranceScoreLogic = () => {
  const [listEntranceScore, setListEntranceScore] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {id} = useParams()

  const getData = () => {
    setIsLoading(true)
    const url = '/api_class/entrance_test' + '?class_id=' + id
    apiCaller(url, 'get', {}, null, true).then((res) => {
      setListEntranceScore(res?.data || [])
    }).catch(err => {
      alert(err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    listEntranceScore,
    isLoading
  }
}