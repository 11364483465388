import React, { useEffect, useState } from "react";
import { teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SelectAsDiv } from "../../../_components/Select";
import { ShowMapGrid, ChangView } from "../../../_components/Admin/Curriculum";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { isEmpty } from "lodash";
import SelectCurriculum from "../../../_components/Select/SelectCurriculum/SelectCurriculum";

function ViewDetailCurriculum() {
  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const [listCuriculum, setListCurriculum] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");
  const typeAccess = params.get("access");
  const subType = params.get("subType");
  const curriculums = useSelector((state) => state.curriculums);

  useEffect(() => {
    if (type === "personal") {
      teacherActions.getCurriculumV2("personal").then((res) => {
        setListCurriculum(res || []);
      });
    } else {
      teacherActions.getCurriculumV2("sunday").then((res) => {
        setListCurriculum(res || []);
      });
    }
    getData(id, subType)
    return () => {
      setListCurriculum([]);
    };
  }, []);

  const getData = (id, sub_type) => {
    setLoading(true);
    if(!!sub_type) {
      teacherService.getDetailCurriculumPreparationCourse(id).then((res) => {
        if (res) {
          const listData = res?.data?.filter(item => item?.status != 0)
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum: {
              ...res,
              data_lesson: {
                data: [...listData],
                unit_name: listData?.map(item => ({
                  list_skill: [],
                  ...item
                }))
              },
            },
          });
        }
        setLoading(false);
      });
    } else {
      teacherService.getDetailCurriculumById(id).then((res) => {
        if (res) {
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum: res,
          });
        }
        setLoading(false);
      });
      // dispatch(teacherActions.getDetailCurriculumById(id));
    }
  }

  const dispatch = useDispatch();

  function onChangeSelect(key, sub_type) {
    history.push(
      "/" +
        authentication.role +
        "/curriculum/view/detail/" +
        key + '?' +
        (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
        (!!sub_type ? `&subType=${sub_type}` : '')
    );
    getData(key, sub_type)
  }
  let curriculumSelected = listCuriculum.find((item) => item.id === id) || {};

  return (
    <div className='sunE-right-container'>
      <div className='sunE-main-title flex-m'>
        <div className='flex-1 input-gr bar-select custom-select-no-bg'>
          <SelectCurriculum
            textDefault={curriculumSelected.name}
            data={listCuriculum}
            onChangeSelect={(key, sub_type) => onChangeSelect(key, sub_type)}
            className='home_curriculum'
            position='left'
            typeName='type'
            typeCurriculum={curriculumSelected?.type}
          />
        </div>
        <ChangView />
        <div
          className='form-sunE-button rel'
          onClick={() =>
            history.push(
              "/" +
                authentication.role +
                "/curriculum" +
                (type ? "/view/" + type : "/view/sunday") +
                (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            )
          }
        >
          <button className='btn-line-blue'>Quay lại</button>
        </div>
      </div>
      {!isLoading && curriculumSelected.name && (
        <ShowMapGrid view={curriculums.view} curriculums={curriculums} />
      )}
    </div>
  );
}

export { ViewDetailCurriculum };
