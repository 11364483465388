import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { useParams } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import $ from "jquery";
import { configConstants } from "../../../_constants";

function ViewClassRank() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const dataRanking = useSelector((state) => state.classes.class_ranking);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [dataRanking]);

  const topOne = dataRanking.data[0];
  const topCompleted = dataRanking.data[1];
  const topChangeRank = dataRanking.data[2];
  const topSpeed = dataRanking.data[3];
  const topExpired = dataRanking.data[4];

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getClassRanking(id));
  }, []);

  return (
    <div className="sunE-container flex flex bg-main-color">
      <div className="sunE-right-container vinhdanh">
        {<Header title="Vinh danh" isBack />}
        {!isLoading &&
          (!isEmpty(dataRanking?.data) ? (
            <div className="uk-container vinhdanh-content">
              <div className="uk-grid">
                <div className=" uk-width-1-2">
                  <div className="vinhdanh-item box-shadow">
                    <div className="flex-m rel">
                      <div className="flex-1 text-center rel">
                        <div className="avt-vd">
                          <img
                            src={
                              topOne
                                ? `${configConstants.BASE_URL}${topOne.avatar}`
                                : "/assets/images/hoso/default_avt.png"
                            }
                            alt="ico_user_border"
                          />
                        </div>
                        <p className="name-vd bg-red">
                          {topOne ? topOne.fullname : ""}
                        </p>
                      </div>
                      <img
                        src="/assets/images/thanhtich/badge-vodich1.png"
                        alt="vodich1"
                        className="vinhdanh-bg"
                      />
                      <p className="vd-info text-red">
                        Điểm TBC:{" "}
                        {parseFloat(
                          Number.parseFloat(topOne?.avg_score).toFixed(1)
                        )}{" "}
                        điểm
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" uk-width-1-2">
                  <div className="vinhdanh-item box-shadow">
                    <div className="flex-m rel">
                      <div className="flex-1 text-center rel">
                        <div className="avt-vd">
                          <img
                            src={
                              topCompleted
                                ? configConstants.BASE_URL + topCompleted.avatar
                                : "/assets/images/hoso/default_avt.png"
                            }
                            alt="ico_user_border"
                          />
                        </div>
                        <p className="name-vd bg-blue">
                          {topCompleted ? topCompleted.fullname : ""}
                        </p>
                      </div>
                      <img
                        src="/assets/images/thanhtich/badge-vodich2.png"
                        alt="vodich2"
                        className="vinhdanh-bg"
                      />
                      {topCompleted && (
                        <p className="vd-info text-blue">
                          Đã hoàn thành {topCompleted.number_completed} bài tập
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" uk-width-1-2">
                  <div className="vinhdanh-item box-shadow">
                    <div className="flex-m rel">
                      <div className="flex-1 text-center rel">
                        <div className="avt-vd">
                          <img
                            src={
                              topChangeRank
                                ? configConstants.BASE_URL + topChangeRank.avatar
                                : "/assets/images/hoso/default_avt.png"
                            }
                            alt="ico_user_border"
                          />
                        </div>
                        <p className="name-vd bg-green">
                          {topChangeRank ? topChangeRank.fullname : ""}
                        </p>
                      </div>
                      <img
                        src="/assets/images/thanhtich/badge-vodich4.png"
                        alt="vodich4"
                        className="vinhdanh-bg"
                      />
                      <p className="vd-info text-green">
                        Leo lên{" "}
                        {topChangeRank ? topChangeRank.number_rank_change : "0"}{" "}
                        bậc
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" uk-width-1-2">
                  <div className="vinhdanh-item box-shadow">
                    <div className="flex-m rel">
                      <div className="flex-1 text-center rel">
                        <div className="avt-vd">
                          <img
                            src={
                              topSpeed
                                ? configConstants.BASE_URL + topSpeed.avatar
                                : "/assets/images/hoso/default_avt.png"
                            }
                            alt="ico_user_border"
                          />
                        </div>
                        <p className="name-vd bg-green-hi">
                          {topSpeed ? topSpeed.fullname : ""}
                        </p>
                      </div>
                      <img
                        src="/assets/images/thanhtich/badge-vodich3.png"
                        alt="vodich3"
                        className="vinhdanh-bg"
                      />
                      <p className="vd-info text-green-hi">
                        Hoàn thành bài tập trong{" "}
                        {(Number(topSpeed?.duration) / 60).toFixed() <= 1
                          ? 1
                          : (Number(topSpeed?.duration) / 60).toFixed()}{" "}
                        phút
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className=" uk-width-1-2">
                            <div className="vinhdanh-item box-shadow">
                                <div className="flex-m rel">
                                    <div className="flex-1 text-center rel">
                                        <div className="avt-vd">
                                            <img src={topExpired ? topExpired.avatar : "/assets/images/hoso/default_avt.png"} alt="ico_user_border" />
                                        </div>
                                        <p className="name-vd bg-black">{topExpired ? topExpired.fullname : ''}</p>
                                    </div>
                                    <img src="/assets/images/thanhtich/badge-vodich5.png" alt="vodich5" className="vinhdanh-bg" />
                                    {topExpired && <p className="vd-info text-black">Để quá hạn {topExpired ? topExpired.total_expired : '0'} bài tập</p>}
                                </div>
                            </div>
                        </div> */}
              </div>
            </div>
          ) : (
            <div className="__text_empty_curiclum_personal">
              Kết quả vinh danh của lớp đang được cập nhật!
            </div>
          ))}
      </div>
    </div>
  );
}

export { ViewClassRank };
