import "./SkillsAssign.style.scss";
import { skillsAssignLogic } from "./SkillsAssign.logic";

const SkillsAssign = (props) => {
  let { dataSkills, typeModal } = props;
console.log(dataSkills)
  let {
    handleChangeLevel,
    dataSkillsSide,
    handleChangeCountExercise,
    handleChecked,
  } = skillsAssignLogic(props);

  // Render List Skills
  const renderListSkills = (data) => {
    return (
      <div key={data?.skill} className={`skill_assign_box  box-shadow ${!!dataSkills?.is_lost_root ? 'skill_assign_box_lost_root' : ''}`}>
        <div className="flex-jus-between pd-bottom-0-5">
          <div className="color-title-assign fz-12 color-white">
            {data?.skill.capitalize()}
          </div>
          <span className="chk-custom-gr wh20 check-select-student">
            <input
              type="checkbox"
              name="selection_assign"
              defaultValue="selection_assign"
              id={data?.skill}
              onChange={(e) => handleChecked(e, data)}
              checked={data.sum > 0}
            />
            <label htmlFor={data.skill}></label>
          </span>
        </div>

        <div
          className={`skill_assign_level ${
            !["pronunciation", "vocabulary"].includes(
              data?.skill?.toLowerCase()
            )
              ? " flex-jus-between"
              : "flex-center"
          }  color-white fz-09 font_myriad_bold mg-bottom-0-9 text-align-center`}
          style={{
            backgroundColor:
              data?.level == "easy"
                ? "#84C241"
                : data?.level == "medium"
                ? "#F8AF36"
                : "  #EB5757",
          }}
        >
          {!["pronunciation", "vocabulary"].includes(
            data?.skill?.toLowerCase()
          ) && (
            <div
              className="btn_prev_assign btn_level pointer_cursor"
              onClick={(e) => handleChangeLevel(data, "prev")}
            >
              <img
                src="/assets/images/icon/ico_left_weight.png"
                alt="img icon left"
                className="contain_image"
              />
            </div>
          )}

          {data?.level.capitalize()}

          {!["pronunciation", "vocabulary"].includes(
            data?.skill?.toLowerCase()
          ) && (
            <div
              className="btn_next_assign btn_level pointer_cursor"
              onClick={(e) => handleChangeLevel(data, "next")}
            >
              <img
                src="/assets/images/icon/ico_right_weight.png"
                alt="img icon right"
                className="contain_image"
              />
            </div>
          )}
        </div>

        <div className="col-100 flex-jus-between btn_count_exercises">
          <button
            className={
              "btn flex-center pointer_cursor btn_prev_assign" +
              (data.sum <= 0 ? " btn-disable" : "")
            }
            // className={
            //   "btn flex-center pointer_cursor btn_prev_assign" +
            //   (cantChangeUnit == "prev" ? " btn-disable" : "")
            // }
            onClick={() => handleChangeCountExercise(data, "prev")}
            disabled={data.sum <= 0}
          >
            <img
              src="/assets/images/icon/ico_left_weight.png"
              alt="img icon left"
              className="contain_image"
            />
          </button>

          <div className="text-align-center fz-1">{data?.sum} bài</div>

          <button
            className={
              "btn flex-center pointer_cursor btn_next_assign" +
              (data.sum >= 20 ? " btn-disable" : "")
            }
            // className={
            //   "btn flex-center pointer_cursor btn_prev_assign" +
            //   (cantChangeUnit == "prev" ? " btn-disable" : "")
            // }
            onClick={() => handleChangeCountExercise(data, "next")}
            disabled={data.sum >= 20}
          >
            <img
              src="/assets/images/icon/ico_right_weight.png"
              alt="img icon left"
              className="contain_image"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`skills_assign ${
        typeModal == "center"
          ? " flex-center-column  skills_assigns_proposal scrollbar-custom-2"
          : " flex-column-start"
      } flex-align-start col-100`}
    >
      <div
        className={`${
          typeModal == "center" && " flex-center"
        } flex-align col-100 pd-bottom-17-5`}
        style={{
          flex: 1,
        }}
      >
        {dataSkillsSide?.slice(0, 3)?.map((data) => renderListSkills(data))}
      </div>
      <div
        className={`flex-align col-100 ${
          typeModal == "center" && "flex-center"
        } `}
      >
        {dataSkillsSide?.slice(3)?.map((data) => renderListSkills(data))}
      </div>

      {!dataSkills?.is_lost_root && <div
        style={{
          paddingTop: typeModal == "center" ? "3%" : "6%",
        }}
      >
        <div className="before_asterisk font_style_italic fz-11-25 line_height_normal pd-bottom-0-5">
          Bài tập Easy phù hợp với trình độ Junior
        </div>
        <div className="before_asterisk font_style_italic fz-11-25 line_height_normal pd-bottom-0-5">
          Bài tập Medium phù hợp với trình độ Middle
        </div>
        <div className="before_asterisk font_style_italic fz-11-25 line_height_normal ">
          Bài tập Hard phù hợp với trình độ Senior
        </div>
      </div>}
    </div>
  );
};

export default SkillsAssign;
