import { LaptopWindowsRounded } from "@material-ui/icons";
import { useState } from "react";

export const faqsLogic = (props) => {
  // Variable category selected
  const [categorySelected, setCategorySelected] = useState(
    localStorage.getItem("categoryFAQ")
      ? JSON.parse(localStorage.getItem("categoryFAQ"))
      : {
          id: 1,
          type: "start",
          title: "Bắt đầu",
        }
  );

  // Title Selected Question
  let [titleSelected, setTitleSelected] = useState("");

  // MOBILE
  const [openSelectMobile, setOpenSelectMobile] = useState(false);

  // Handle Open Select
  const handleOpenSelect = () => {
    setOpenSelectMobile(!openSelectMobile);
  };

  // List Categories
  const dataCategories = [
    {
      id: 1,
      type: "start",
      title: "Bắt đầu",
    },
    {
      id: 2,
      type: "commond_issues",
      title: "Các vấn đề thông dụng",
    },
    {
      id: 3,
      type: "efficient_learning",
      title: "Cách học hiệu quả",
    },
    {
      id: 4,
      type: "main_feature",
      title: "Các tính năng chính",
    },
    {
      id: 5,
      type: "contact",
      title: "Liên hệ",
    },
  ];

  // Handle Change Category
  const handleChangeCategory = (category) => {
    localStorage.setItem("categoryFAQ", JSON.stringify(category));
    setCategorySelected(category);
  };

  //  Data Question
  const dataQuestion = {
    start: [
      {
        id: 1,
        title: "1. Sunday English là gì?",
        data: {
          content_1:
            "Sunday English là ứng dụng phần mềm học Tiếng Anh hàng đầu dành cho học sinh THCS, được thiết kế theo tiêu chuẩn quốc tế, bám sát chương trình SGK Bộ GD&ĐT. Sunday English bao gồm 8 bộ giáo trình học liệu Tiếng Anh cho các khối 6, 7, 8, 9, phát triển toàn diện 4 kỹ năng Nghe – Nói - Đọc - Viết. Sunday English là công cụ thực hành Tiếng Anh hiệu quả, bổ trợ cho phương pháp học truyền thống trên lớp. Giúp học sinh tiến bộ nhanh, hiểu bài sâu hơn, nhớ Từ vựng và Ngữ pháp lâu hơn với sự hỗ trợ của AI và các phương pháp học thuật tiên tiến.",
        },
      },
      {
        id: 2,
        title: "2. Sunday Teacher là gì?",
        data: {
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Sunday Teacher là công cụ giảng dạy Tiếng Anh THCS hiệu quả của Thầy cô, kết nối với tài khoản học Tiếng Anh Sunday English của học sinh.",
            },
            {
              id: 2,
              content_1:
                "- Ứng dụng hỗ trợ Thầy cô soạn bài, giao bài, chấm chữa bài, quản lý thời gian, mức độ chuyên cần và kết quả học tập của học sinh nhằm mang lại kết quả đào tạo cao nhất.",
            },
          ],
        },
      },
      {
        id: 3,
        title: "3. Sunday Parent là gì?",
        data: {
          content_1:
            "Sunday Parent là công cụ kèm con học giỏi Tiếng Anh của Ba mẹ, sử dụng miễn phí, kết nối với tài khoản học Tiếng Anh Sunday English của con. Sunday Parent giúp Ba mẹ kèm con học Tiếng Anh ngay cả khi Ba mẹ không thạo Tiếng Anh với các ưu điểm:",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Dễ dàng – Chỉ cần nhập lên hệ thống thời gian mong muốn con học tập",
            },
            {
              id: 2,
              content_1:
                "- Chính xác – Tự động giao bài phù hợp với học lực của con theo thời gian mong muốn",
            },
            {
              id: 3,
              content_1:
                "- Nhanh chóng – Nhận được báo cáo tức thì về tình hình làm bài tập của con",
            },
          ],
        },
      },
      {
        id: 4,
        title: "4. Sunday English có bản dùng thử không?",
        data: {
          content_1:
            "Có, bạn sẽ được truy cập và trải nghiệm miễn phí các tính năng của Sunday English trong 1 bài học đầu tiên sau khi đăng ký tài khoản Sunday English.",
        },
      },
      {
        id: 5,
        title: "5. Đăng ký tài khoản",
        data: {
          content_1: "Bạn vui lòng nhấn vào đường dẫn này để xem ",
          content_1_link: "[hướng dẫn]",
          href: "/account_activation",
          content_1_side: "đăng ký tài khoản",
        },
      },
      {
        id: 6,
        title: "6. Cách kích hoạt mã gói sau khi mua",
        data: {
          content_1:
            "Sau khi thanh toán thành công và nhận được mã kích hoạt qua email, bạn vui lòng thực hiện các bước sau:",
          content_list_1: [
            {
              id: 1,
              content_bold_black: "- Bước 1: ",
              content_1: "Cài đặt app: Tải ứng dụng trên Appstore/Google Play.",
              image_download: true,
            },
            {
              id: 2,
              content_bold_black: "- Bước 2: ",
              content_1: "Đăng ký/Đăng nhập tài khoản.",
            },
            {
              id: 3,
              content_bold_black: "- Bước 3: ",
              content_1: "Kích hoạt khóa học:",
              content_list_child: [
                {
                  id: 1,
                  content: `+ Chọn “Kích hoạt tài khoản” tại mục “Thêm (ooo)",`,
                },
                {
                  id: 2,
                  content:
                    "+ Nhập mã kích hoạt được gửi đến email đăng ký khi mua hàng,",
                },
                {
                  id: 3,
                  content: `+ Nhấn nút “Kích hoạt” để hoàn tất. Sau khi kích hoạt thành công, bạn sẽ có toàn quyền truy cập vào ứng dụng.`,
                },
              ],
            },
          ],
        },
      },
      {
        id: 7,
        title: "7. Ứng dụng Sunday English có thể cài đặt trên mấy thiết bị?",
        data: {
          content_1: "Bạn được quyền cài đặt ứng dụng Sunday English trên",
          content_1_bold: "2 thiết bị.",
          content_1_side:
            "Nếu muốn cài đặt ứng dụng sang 1 thiết bị mới sau khi đã cài đủ 2 thiết bị, bạn cần vào mục Quản lý thiết bị để xoá 1 thiết bị đi và cài ứng dụng sang thiết bị mới.",
        },
      },
      {
        id: 8,
        title:
          "8. Tại một thời điểm tôi có thể sử dụng Sunday English đồng thời trên mấy thiết bị?",
        data: {
          content_1: "Tại một thời điểm bạn có thể sử dụng Sunday English trên",
          content_1_bold: "1 thiết bị.",
        },
      },
      {
        id: 9,
        title: "9. Tại sao mã kích hoạt gói học trả phí không thể sử dụng lại?",
        data: {
          content_1:
            "Mỗi mã kích hoạt chỉ có giá trị sử dụng 1 lần, do đó bạn sẽ không thể kích hoạt một mã đã được kích hoạt trước đó.",
        },
      },
      {
        id: 10,
        title: "10. Sunday English có chính sách hoàn tiền không?",
        data: {
          content_1:
            "Khi khách hàng mua một khóa học Tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua. Sunday English không có chính sách hoàn trả học phí trong bất kể trường hợp nào.",
        },
      },
      {
        id: 11,
        title:
          "11. Tôi có thể sang nhượng tài khoản trả phí cho người khác được không?",
        data: {
          content_1:
            "Bạn không được cho, tặng, sang nhượng, chuyển giao, hoặc cho thuê mượn những tài khoản đã kích hoạt trên hệ thống Sunday English.",
        },
      },
    ],
    commond_issues: [
      {
        id: 1,
        title: "1. Làm sao để cài lại mật khẩu?",
        data: {
          content_1_bold: "HƯỚNG DẪN CÁCH CÀI LẠI MẬT KHẨU",
          content_list_1: [
            {
              id: 1,
              content_bold_black: "- Bước 1: ",
              content_1:
                "Trên thiết bị di động (điện thoại/tablet/ipad). Mở ứng dụng Sunday English và ấn vào nút “",
              content_green_1: "Quên mật khẩu",
              content_2: "” và điền vào địa chỉ email của bạn.",
            },
            {
              id: 2,
              content_bold_black: "- Bước 2: ",
              content_1:
                "Điền đúng địa chỉ email của tài khoản Sunday English.",
            },
            {
              id: 3,
              content_bold_black: "- Bước 3: ",
              content_1:
                "Bạn sẽ nhận được một đường dẫn để thay đổi lại mật khẩu của bạn. Bạn cần phải Kiểm tra hộp thư chính/hộp thư rác.",
            },
            {
              id: 4,
              content_bold_black: "- Bước 4: ",
              content_1: " Nhấn vào đường dẫn/nút “",
              content_green_1: "TẠI ĐÂY",
              content_2: '" để thay đổi mật khẩu.',
            },
            {
              id: 5,
              content_bold_black: "- Bước 5: ",
              content_1: 'Nhập mật khẩu mới. Nhấn "',
              content_green_1: "Đồng ý",
              content_2: '"',
            },
          ],
          content_side_1: {
            id: 5,
            content_1:
              'Khi mật khẩu được cập nhật bạn sẽ nhìn thấy màn hình thông báo đổi mật khẩu thành công. Nếu bạn thay đổi 1 lần nữa, hệ thống sẽ tiếp tục hiển thị màn hình đổi mật khẩu thành công. Bạn cần phải quay lại ứng dụng => Nhấn nút "',
            content_green_1: "Quên mật khẩu",
            content_2: '" một lần nữa và vào email để nhấn vào',
            content_green_2: "LINK MỚI",
            content_3:
              ". Một đường dẫn chỉ có giá trị thay đổi mật khẩu một lần.",
          },
          content_side_italic:
            "Sau khi đổi mật khẩu thành công, bạn hãy mở ứng dụng Sunday English trên điện thoại/máy tính bảng và thực hiện đăng nhập với địa chỉ email và mật khẩu mới.",
        },
      },
      {
        id: 2,
        title: "2. Sunday English không nhận diện giọng nói của tôi?",
        data: {
          content_1:
            "Thông thường hệ thống có thể không nhận diện được giọng nói của bạn trong một số trường hợp sau:",
          content_list_1: [
            {
              id: 1,
              content_bold_black: "1. ",
              content_1:
                "Luyện tập ở môi trường quá nhiều tiếng ồn → Bạn vui lòng thử lại ở một không gian yên tĩnh hơn.",
            },
            {
              id: 2,
              content_bold_black: "2. ",
              content_1:
                "Để điện thoại/micro ở quá xa miệng, các thiết bị khác nhau sẽ có độ nhạy cảm biến micro khác nhau → Vui lòng đặt điện thoại/micro của thiết bị sát miệng khi phát âm.",
            },
            {
              id: 3,
              content_bold_black: "3. ",
              content_1:
                "Sử dụng tai nghe Bluetooth có thể ảnh hưởng đến việc ghi âm giọng nói của bạn → Vui lòng sử dụng tai nghe có dây hoặc loa điện thoại.",
            },
            {
              id: 4,
              content_bold_black: "4. ",
              content_1:
                "Đọc thầm trong miệng hoặc nói quá nhỏ: hệ thống sẽ không nghe thấy và phân tích → Vui lòng đọc rõ ràng các âm tiết trong từ.",
            },
          ],
        },
      },
      {
        id: 3,
        title: "3. Tôi đã thanh toán nhưng không nhận được mã kích hoạt?",
        data: {
          content_1:
            "Thông thường mã kích hoạt sẽ được gửi đến cho bạn qua email ngay sau khi bạn thanh toán.",
          content_list_1: [
            {
              id: 1,
              content_1:
                '- Nếu không nhận được mã ngay lập tức, bạn hãy bấm nút "Gửi lại mã" ở màn hình thông báo Thanh toán thành công.',
            },
            {
              id: 2,
              content_1:
                "- Bạn vui lòng kiểm tra thường xuyên hộp thư chính/spam của mình trong 24h sau khi thanh toán, nếu sau thời gian cam kết trên mà bạn vẫn chưa nhận được mã kích hoạt của mình, bạn vui lòng chụp hình lịch sử chuyển khoản và gửi về email",
              content_1_email: "support@sundayenglish.com",
              content_2: "để được hỗ trợ ngay.",
            },
          ],
        },
      },
      {
        id: 4,
        title: "4. Có thể kích hoạt cùng lúc hai gói học không?",
        title_2: "Gói học cũ vẫn còn hạn có thể kích hoạt thêm gói mới không?",
        data: {
          content_1:
            "Có, bạn có thể kích hoạt gói khoá học mới trong khi đang sử dụng một gói khác. Thời hạn sử dụng sẽ bằng thời gian tổng của các gói được kích hoạt.",
        },
      },
      {
        id: 5,
        title: "5. Tại sao tôi không mở được Unit trong khi học?",
        data: {
          content_1: "Bạn sẽ không mở được Unit trong một số trường hợp sau:",
          content_list_1: [
            {
              id: 1,
              content_bold_black: "1. ",
              content_1:
                "Tài khoản bạn đang sử dụng là tài khoản Trial, chỉ có quyền học nội dung thuộc Unit 1 của giáo trình. Để học các Unit tiếp theo, hãy nâng cấp tài khoản nhé!",
            },
            {
              id: 2,
              content_bold_black: "2. ",
              content_1:
                "Bạn chưa hoàn thành các bài tập trong Master Unit: Nếu các bài tập bị điểm thấp, bạn sẽ cần cải thiện điểm để tiếp tục học các bài học khác.",
            },
            {
              id: 3,
              content_bold_black: "3. ",
              content_1:
                "Bạn chưa đạt điều kiện để mở Unit mới trong Giáo trình hội nhập. Trong một Unit, các bài tập trong một kỹ năng cần đạt tối thiểu 7 điểm để mở bài Kiểm tra tương ứng với kỹ năng đó. Các bài kiểm tra cần đạt tối thiểu 7 điểm để mở Unit tiếp theo.",
            },
          ],
        },
      },
    ],
    efficient_learning: [
      {
        id: 1,
        title: "1. Học với Sunday English như thế nào để có hiệu quả cao nhất?",
        data: {
          content_side_1: {
            id: 1,
            content_1:
              "Đầu tiên bạn hãy làm bài Kiểm tra trình độ đầu vào để xác định học lực của mình. Bạn hãy lập thời khoá biểu học Tiếng Anh cho mình từ",
            content_green_1: "3-5 buổi/tuần",
            content_2: ". Mỗi lần học",
            content_green_2: "45-60 phút",
            content_3: ". Học liên tục trong vòng",
            content_green_3: "6 tháng",
            content_4:
              "bạn sẽ thấy trình độ Tiếng Anh của mình được cải thiện đáng kể. Hãy chú ý hoàn thành các bài được Gia sư ảo của hệ thống giao cho bạn trong Phần Master Unit. Đây là các bài tập dành riêng cho bạn, theo học lực của bạn, nhằm giúp bạn củng cố kiến thức và phát triển toàn diện năng lực Tiếng Anh của mình một cách hiệu quả nhất.",
          },
        },
      },
      {
        id: 2,
        title: "2. Con tôi bị mất gốc Tiếng Anh có học được không?",
        data: {
          content_1:
            "Có, hiện tại, Sunday English có 8 giáo trình/chương trình đào tạo bao gồm:",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Giáo trình Hội nhập lớp 6, 7, 8, 9 dành cho học sinh mất gốc Tiếng Anh thuộc khối lớp tương ứng.",
            },
            {
              id: 2,
              content_1:
                "- Giáo trình Nâng cao Năng lực lớp 6, 7, 8, 9 dành cho học sinh có học lực Tiếng Anh ở mức Trung bình, Khá hoặc cao hơn và muốn chinh phục điểm 9, 10 thuộc khối lớp tương ứng.",
            },
          ],
          image_right: "learning_2_1",
        },
      },
      {
        id: 3,
        title: "3. Học theo năng lực là như thế nào?",
        data: {
          content_1:
            "Sunday English căn cứ vào kết quả của bài thi đầu vào và kết quả học tập của học sinh trong từng giai đoạn để đánh giá học lực của học sinh theo từng kỹ năng riêng biệt, qua đó hệ thống sẽ tự động điều chỉnh giáo trình đào tạo, đưa ra các bài học bám sát chương trình học tập tại trường nhưng có độ khó phù hợp với học lực của các con. Giúp các con tự tin học, thực hành Tiếng Anh, có nhiều tiến bộ và đạt được kết quả tốt trong các kỳ thi.",
          // image_right: "learning_2",
          content_images: [{id: "learning_3_1", src: "learning_3_1"},{id: "learning_3_2", src: "learning_3_2"}]
        },
      },
    ],
    main_feature: [
      {
        id: 1,
        title: "1. Công cụ cá nhân hóa giáo trình là gì?",
        data: {
          content_list_1: [
            {
              id: 1,
              content_1:
                "Thầy cô được cấp quyền để có thể tự biên soạn các bộ giáo trình của riêng Thầy cô bằng cách tạo mới các bộ giáo trình hoặc biên tập, tuỳ chỉnh trong danh sách 8 bộ giáo trình có sẵn trên hệ thống.",
            },
            {
              id: 2,
              content_1:
                "Thầy cô được cung cấp 3GB dung lượng cho kho tài nguyên của mình bao gồm các tư liệu, bài tập, bài giảng do Thầy cô tạo ra phục vụ cho giáo trình của riêng Thầy cô.",
            },
          ],
        },
      },
      {
        id: 2,
        title: "2. Thầy cô giao bài cho học sinh bằng cách nào?",
        data: {
          content_1: "Sunday Teacher hỗ trợ Thầy cô 3 hình thức giao bài:",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Giao bài theo năng lực: Thầy cô chỉ cần chọn thời lượng làm bài tập cần giao cho học sinh, ví dụ 45 phút, Sunday Teacher sẽ tự động đề xuất những bài tập phù hợp nhất với năng lực từng học sinh theo thời lượng yêu cầu nhằm nâng cao học lực và các kỹ năng còn yếu của học sinh.",
            },
            {
              id: 2,
              content_1:
                "- Giao bài theo yêu cầu: Thầy cô chọn kỹ năng, độ khó, hoặc số lượng bài tập cần giao, sau đó Sunday Teacher sẽ tự động đề xuất bài tập phù hợp cho học sinh.",
            },
            {
              id: 3,
              content_1:
                "- Giao bài tự do: Thầy cô có thể tự chọn các bài tập có sẵn trong 8 bộ giáo trình của Sunday English hoặc trong giáo trình riêng của Thầy cô để giao cho học sinh.",
            },
          ],
          content_images: [
            {
              id: 1,
              src: "feature_2_1",
            },
            {
              id: 2,
              src: "feature_2_2",
            },
            {
              id: 3,
              src: "feature_2_3",
            },
            {
              id: 4,
              src: "feature_2_4",
            },
          ],
        },
      },
      {
        id: 3,
        title:
          "3. Công cụ tự động chấm bài bằng AI hỗ trợ thầy cô như thế nào?",
        data: {
          content_0:
            "Tất cả các dạng bài đều có tính năng tự động chấm, chữa bài với lời giải chi tiết.",
          content_1:
            "Riêng dạng bài tập Writing có tính năng chấm chữa bài bằng công nghệ AI dựa trên 4 tiêu chí:",
          content_list_1: [
            {
              id: 1,
              content_1: "- Task response",
            },
            {
              id: 2,
              content_1: "- Grammar",
            },
            {
              id: 3,
              content_1: "- Vocabulary",
            },
            {
              id: 4,
              content_1: "- Coherence and Cohesion",
            },
          ],
          content_side_1: {
            id: 1,
            content_1:
              "Bài tập Speaking được trang bị công nghệ nhận diện giọng nói, tự động chấm và chỉ ra các lỗi phát âm giúp học sinh cải thiện kỹ năng nói giọng bản ngữ.",
          },
          content_images: [
            {
              id: 1,
              src: "feature_3_1",
            },
            {
              id: 2,
              src: "feature_3_2",
            },
          ],
        },
      },
      {
        id: 4,
        title: "4. Learning Index™ là gì?",
        data: {
          content_1:
            "Learning Index™ là chỉ số hoc tập của Lớp và từng học sinh. Thầy cô có thể xem chỉ số học tập tổng quan của cả lớp bao gồm: Số học sinh Giỏi, Khá, TB, ...",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Thầy cô và ba mẹ có thể xem Chỉ số học tập của con bao gồm: Số bài tập đã hoàn thành và số bài tập quá hạn",
            },
            {
              id: 2,
              content_1: "- Tổng thời gian đã học và số từ vựng đã học",
            },
            {
              id: 3,
              content_1: "- Điểm trung bình bài tập",
            },
            {
              id: 4,
              content_1: "- Điểm trung bình kiểm tra",
            },
            {
              id: 5,
              content_1: "- Điểm trung bình từng kỹ năng",
            },
            {
              id: 6,
              content_1: "- Chỉ ra những tiến bộ và kỹ năng cần cải thiện",
            },
            {
              id: 7,
              content_1: "- Thứ hạng học tập của từng học sinh trong lớp.",
            },
          ],
          content_images: [
            {
              id: 1,
              src: "feature_4_1",
            },
            {
              id: 2,
              src: "feature_4_2",
            },
          ],
        },
      },
      {
        id: 5,
        title:
          "5. Thầy cô có thể tạo phiếu điểm cho lớp học Offline được không?",
        data: {
          content_1:
            "Có, Thầy/Cô có thể tạo phiếu điểm cho các lớp học ofline bằng cách sau:",
          content_side_1: {
            content_1: "Vào mục",
            content_green_1: "Quản lý lớp",
            content_2: "=> Chọn lớp học cần tạo phiếu điểm =>",
            content_green_2: "Phiếu điểm",
            content_3: "=>",
            content_green_3: "Tạo bảng điểm",
            content_4:
              "=> Nhập Tên bài kiểm tra, chọn Học kỳ, chọn Loại bài kiểm tra, chọn Hệ số điểm, chọn Ngày => Nhập Điểm số và nhận xét",
          },
          content_images: [
            {
              id: 1,
              src: "feature_5_1",
            },
            {
              id: 2,
              src: "feature_5_2",
            },
            {
              id: 3,
              src: "feature_5_3",
            },
            {
              id: 4,
              src: "feature_5_4",
            },
          ],
        },
      },
      {
        id: 6,
        title: "6. Ba mẹ giao bài cho con cách nào?",
        data: {
          content_1: "Sunday Parent hỗ trợ Ba mẹ 3 hình thức giao bài:",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Giao bài theo năng lực: Ba mẹ chỉ cần chọn thời lượng làm bài tập cần giao cho con, ví dụ 45 phút, Sunday Parent sẽ tự động đề xuất những bài tập phù hợp nhất với năng lực của con, theo thời lượng yêu cầu nhằm nâng cao học lực và các kỹ năng còn yếu của con.",
            },
            {
              id: 2,
              content_1:
                "- Giao bài theo yêu cầu: Ba mẹ chọn kỹ năng, độ khó, hoặc số lượng bài tập cần giao, sau đó Sunday Parent sẽ tự động đề xuất bài tập phù hợp cho con.",
            },
            {
              id: 3,
              content_1:
                "- Giao bài tự do: Ba mẹ có thể tự chọn các bài tập có sẵn trong 8 bộ giáo trình để giao cho con.",
            },
          ],
        },
      },
      {
        id: 7,
        title:
          "7. Công cụ tự động chấm bài bằng AI hỗ trợ phụ huynh như thế nào?",
        data: {
          content_0:
            "Với Sunday Parent, Ba mẹ bận rộn hoặc không biết Tiếng Anh vẫn có thể kèm con học giỏi Tiếng Anh nhờ tính năng tự động chấm và chữa bài kèm lời giải chi tiết.",
          content_1: "Đặc biệt:",
          content_list_1: [
            {
              id: 1,
              content_1:
                "- Bài tập luyện kỹ năng Viết có tính năng chấm chữa bài bằng công nghệ AI dựa trên 4 tiêu chí:",
              content_list_child: [
                {
                  id: 1,
                  content: "+ Task response (Đáp ứng yêu cầu đề bài)",
                },
                {
                  id: 2,
                  content: "+ Grammar (Ngữ pháp)",
                },
                {
                  id: 3,
                  content: "+ Vocabulary (Từ vựng)",
                },
                {
                  id: 4,
                  content:
                    "+ Coherence and Cohesion (Tính mạch lạc và liên kết)",
                },
              ],
            },
            {
              id: 2,
              content_1:
                "- Bài tập luyện kỹ năng nói được trang bị công nghệ nhận diện giọng nói, tự động chấm và chỉ ra các lỗi phát âm giúp con cải thiện kỹ năng nói giọng bản ngữ.",
            },
          ],
        },
      },
    ],
    contact: [
      {
        id: 1,
        title:
          "1. Trong quá trình sử dụng, nếu gặp vấn đề tôi có thể liên hệ với ai?",
        data: {
          content_1:
            "Nếu bạn có bất cứ câu hỏi, thắc mắc nào, vui lòng liên hệ với chúng tôi qua các hình thức sau:",
          content_list_1: [
            {
              id: 1,
              content_bold_black: "1. ",
              content_1: "Gửi email đến:",
              content_1_email: "support@sundayenglish.com",
              content_2:
                "– thông thường, Sunday English sẽ phản hồi thông tin đến bạn trong vòng 24h (không kể Thứ 7, chủ nhật và ngày lễ)",
            },
            {
              id: 2,
              content_bold_black: "2. ",
              content_1: "Liên hệ số Tổng đài CSKH:",
              content_1_phone: "024 6281 3888",
              content_2: "thời gian từ 08:00 – 17:00 Thứ 2 – Thứ 6.",
            },
          ],
        },
      },
    ],
  };

  // Handle Select Title Question
  const handleSelectQuestion = (title) => {
    title == titleSelected ? setTitleSelected("") : setTitleSelected(title);
    if (title != titleSelected) {
      setTimeout(() => {
        const element = document.getElementById(title?.trim());
        // console.log(element);
        element?.scrollIntoView({
          behavior: "instant",
          block: "start", // or 'center' or 'end'
          inline: "start", // or 'start' or 'end'
        });
      }, 25);

      setTimeout(() => {
        window.scrollBy(0, -75);
      }, 75);
    }
  };

  return {
    categorySelected,
    dataCategories,
    titleSelected,
    setTitleSelected,
    handleChangeCategory,
    handleSelectQuestion,
    dataQuestion,
    openSelectMobile,
    handleOpenSelect,
  };
};
