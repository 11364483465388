import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "./../../../_components/Admin/Header";
import {
  PopUpAddTimeTable,
  PopUpShowTimeTable,
  PopUpResetTimeTable,
} from "./../../../_components/Popup";
import { scheduleActions } from "./../../../_actions";
import { Alert } from "./../../../_components/Alert";
import { scheduleConstants, popupConstants } from "./../../../_constants";
import { SelectDate } from "./../../../_components/Calendar";
import _ from "lodash";
import moment from "moment";
import $ from "jquery";
import { useHistory } from "react-router-dom";

function HomePageScheduleTimeTableStudent() {
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const schedules = useSelector((state) => state.schedules);
  const Popup = useSelector((state) => state.Popup);
  const [widthItem, setWidthItem] = useState(66);
  const refsBox = useRef(null);
  const todayObj = new Date();

  useEffect(() => {
    if (refsBox) {
      setWidthItem(refsBox.current.scrollWidth);
      window.addEventListener("resize", updateSize);
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [refsBox]);

  function updateSize(e) {
    setWidthItem(refsBox.current.scrollWidth);
  }

  // Format Date
  function formatDate(date, i) {
    date.setDate(todayObj.getDate() - todayObj.getDay() + i + 1);
    date = moment(date).format("YYYY-MM-DD");

    return date;
  }

  const start_time_week = formatDate(new Date(todayObj), 0);
  const end_time_week = formatDate(new Date(todayObj), 6);

  useEffect(() => {
    start_time_week &&
      end_time_week &&
      dispatch(scheduleActions.getTimetable(start_time_week, end_time_week));
    document.getElementById("showtime").scrollIntoView();
  }, []);

  let initData = {
    start_time: "08:00",
    end_time: "09:00",
    class_name: "",
    remind_time: 10,
    day_name: "Monday",
    checkRemind: true,
    action: "add",
  };
  let [dataEditTimeTable, setDataEditTimeTable] = useState(initData);
  const alert = useSelector((state) => state.alert);

  function addComponentCreate() {
    return (
      <Fragment>
        <button
          className="form-sunE-button btn-create-taomoi rel btn-line-blue"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => showFormaAdd()}
        >
          <img
            src="/assets/images/teacher/lichlamviec/ico_add.png"
            alt="ico_add"
          />
          <p
            style={{
              fontSize: "1rem",
              color: "#fff",
              fontFamily: "Myriadpro-SemiBold",
              marginLeft: "1.5rem",
            }}
          >
            Tạo mới
          </p>
        </button>
        <div className="form-sunE-button btn-create-datlai rel">
          <img
            src="/assets/images/teacher/lichlamviec/ico_reload.png"
            alt="ico_add"
          />
          <button
            className={
              "btn-line-blue" +
              (_.isEmpty(schedules.timeTable.data) ? " btn-disable" : "")
            }
            onClick={() => setShowPopUpResetTable(true)}
          >
            Đặt lại
          </button>
        </div>
      </Fragment>
    );
  }

  function setShowPopUpResetTable() {
    setDataEditTimeTable(initData);
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFormResetTimeTable: true,
      },
    });
  }

  function showAskEdit(data) {
    data.checkRemind = data.remind_time ? true : false;
    data.action = "edit";
    setDataEditTimeTable(data);
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFormDetailTimeTable: true,
      },
    });
  }

  function showFormaAdd() {
    setDataEditTimeTable(initData);
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFormAddTimeTable: true,
      },
    });
  }

  function closePopUp() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }
  function disableShowAskEditOpenFormEdit() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFormDetailTimeTable: false,
        showFormAddTimeTable: true,
      },
    });
  }

  function destructuringDateTime(dateTime) {
    const [hour, minute] = dateTime?.split(":");

    return {
      hour,
      minute,
    };
  }

  function showItemTimeTable() {
    return (
      !_.isEmpty(schedules.timeTable.data) &&
      schedules.timeTable.data.map((data, i) => {
        let start_time_hours = destructuringDateTime(data.start_time).hour;
        let start_time_minutes = destructuringDateTime(data.start_time).minute;
        let end_time_hours = destructuringDateTime(data.end_time).hour;
        let end_time_minutes = destructuringDateTime(data.end_time).minute;
        let height = (end_time_hours - start_time_hours) * 88;
        let top =
          (parseInt(start_time_hours) + 1) * 88 +
          start_time_minutes * (88 / widthItem);
        if (end_time_hours === start_time_hours) {
          height += (end_time_minutes - start_time_minutes) * (88 / 60);
        } else {
          height += (end_time_minutes - start_time_minutes) * (88 / 60);
        }
        let left = (data.day_number - 1) * (widthItem + 0.5);
        let dayToday = new Date().getDay() + 1;
        return (
          <Fragment key={i} style={{ width: widthItem, padding: 3 }}>
            <div
              className="tkb-item"
              style={{
                top: top + "px",
                left: left + "px",
                height: height + "px",
                width: widthItem - 5,
                background:
                  dayToday === parseInt(data.day_number)
                    ? "#00BBB6"
                    : "#00E2A0",
              }}
              onClick={() => showAskEdit(data)}
              title={data.class_name}
            >
              <div style={{ width: "100%", padding: "0 0.1rem" }}>
                <h2
                  className={height < 30 && "one-line"}
                  style={{ width: "100%" }}
                >
                  {data.class_name}
                </h2>
                {height > 50 && (
                  <p>
                    {start_time_hours}:{start_time_minutes}
                  </p>
                )}
                {height > 70 && (
                  <p>
                    {end_time_hours}:{end_time_minutes}
                  </p>
                )}
              </div>
            </div>
          </Fragment>
        );
      })
    );
  }

  function createElementsHours() {
    var elements = [];
    for (let i = 0; i <= 24; i++) {
      elements.push(
        <div className="sunE-tkb-hour" id={i === 8 ? "showtime" : ""} key={i}>
          <div className="w60 tkb-time">
            <div className="borderBottom50" />
            <p>{i}:00</p>
          </div>
          <div className="w60" />
          <div className="w60" />
          <div className="w60" />
          <div className="w60" />
          <div className="w60" />
          <div className="w60" />
          <div className="w60" />
        </div>
      );
    }
    return elements;
  }

  function updateTimeForTimeTable(time) {
    if (
      moment(time).toDate().getTime() >
      new Date().getTime() - 24 * 60 * 60 * 1000
    ) {
      dispatch(
        scheduleActions.updateTimeForTimeTable({
          valid_to: moment(time).format("yyyy-MM-DD"),
        })
      );
    }
  }

  return (
    <div className="sunE-right-container">
      <Header
        title={"Thời khoá biểu"}
        component={addComponentCreate()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/more/schedule")
        }
      />
      {alert.message &&
        (alert.screen === scheduleConstants.SCREEN_RESET_TIME_TABLE ||
          alert.screen === scheduleConstants.SCREEN_ADD_TIME_TABLE) && (
          <Alert alert={alert} notShowError={true} />
        )}
      {alert.message &&
        alert.screen === scheduleConstants.SCREEN_UPDATE_TIME_TIME_TABLE && (
          <Alert alert={alert} />
        )}
      <div className="flex-m sunE-content thoikhoabieu student">
        <div className="sunE-content-box">
          <div className="tkb-content rel">
            <div className="tkb-day">
              <div className="w60" ref={refsBox} />
              <div className="w60">
                <p>T2</p>
              </div>
              <div className="w60">
                <p>T3</p>
              </div>
              <div className="w60">
                <p>T4</p>
              </div>
              <div className="w60">
                <p>T5</p>
              </div>
              <div className="w60">
                <p>T6</p>
              </div>
              <div className="w60">
                <p>T7</p>
              </div>
              <div className="w60">
                <p>CN</p>
              </div>
            </div>
            <div className="tkb-table scrollbar-custom rel">
              {createElementsHours()}
              {showItemTimeTable()}
            </div>
          </div>
          {schedules.timeTable.valid_to && (
            <div className="flex-m apdung center-flex">
              <div className="flex-m bg-w-h-50">
                <label>Áp dụng đến:</label>
                <div className="valid_to_input rel time-to-khht-cn">
                  <SelectDate
                    id="changeTime"
                    timeIntervals={15}
                    dateFormat={"dd/MM/yyyy"}
                    name="valid_to"
                    selected={new Date(schedules.timeTable.valid_to)}
                    onChange={(time) => updateTimeForTimeTable(time)}
                  />
                  <img
                    src="/assets/images/teacher/lichlamviec/ico_edit.png"
                    alt="ico_edit"
                    className="ico_edit_time_to_khht"
                    onClick={() => $("#changeTime").trigger("click")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {Popup.showFormAddTimeTable && (
        <PopUpAddTimeTable
          dataEditTimeTable={dataEditTimeTable}
          titleButtonDone="Lưu"
          titleButtonCancel="Hủy"
          onClickCancel={() => closePopUp()}
          width={320}
        />
      )}
      {Popup.showFormDetailTimeTable && (
        <PopUpShowTimeTable
          onClickClose={() => {
            closePopUp();
          }}
          onClickDelete={() => closePopUp()}
          onClickEdit={() => {
            disableShowAskEditOpenFormEdit();
          }}
          titleButtonDone="Sửa"
          titleButtonCancel="Xóa"
          dataEditTimeTable={dataEditTimeTable}
        />
      )}
      {!_.isEmpty(schedules.timeTable.data) && Popup.showFormResetTimeTable && (
        <PopUpResetTimeTable onClose={() => closePopUp()} />
      )}
    </div>
  );
}

export { HomePageScheduleTimeTableStudent };
