import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { PopUpFilter } from "../../../_components/Popup";
import {
  configConstants,
  curriculumConstants,
  popupConstants,
} from "../../../_constants";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import LazyLoad from "react-lazyload";
import $ from "jquery";

function HomePageManageHomeWork() {
  const classes = useSelector((state) => state.classes);
  const authentication = useSelector((state) => state.authentication);
  const curriculums = useSelector((state) => state.curriculums);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const paramFilter = useSelector((state) => state.curriculums.filters.param);
  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [offsetFilter, setOffsetFilter] = useState(0);
  const [listHomeWork, setListHomeWork] = useState([]);
  const refsScroll = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const limitDefault = configConstants.DEFAULT_LIMIT;

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      teacherActions.getListHomeWork(
        id,
        "class_mark",
        limitDefault,
        0,
        listHomeWork
      )
    ).then((res) => {
      setListHomeWork(res.data);
      setOffset(offset + limitDefault);
      setLoadMore(res.data?.length === configConstants.DEFAULT_LIMIT);
    });
    return () => {
      dispatch({
        type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM,
      });
    };
  }, []);

  // useEffect(() => {
  //   if (refsScroll && refsScroll.current) {
  //     refsScroll.current?.addEventListener("scroll", handleScroll);
  //     return () =>
  //       refsScroll.current?.removeEventListener("scroll", handleScroll);
  //   }
  // }, [refsScroll, offset, isLoadMore]);

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const fetchData = (offset_Data) => {
    if ((!isLoading && offset != offset_Data) || offset_Data == 0) {
      setLoading(true);
      dispatch(
        teacherActions.getListHomeWork(
          id,
          "class_mark",
          limitDefault,
          offset,
          listHomeWork
        )
      ).then((res) => {
        setLoading(false);
        if (!hasDuplicates(res.data, listHomeWork)) {
          setListHomeWork((prev) => [...prev, ...res.data]);
          setOffset(offset_Data);
          setLoadMore(res.data?.length === configConstants.DEFAULT_LIMIT);
          setOffsetFilter(0);
        }
        if (scrollPosition > 0 && !isLoading) {
          refsScroll.current.scrollTop = scrollPosition;
        }
      });
    }
  };

  const fetchDataFilter = (offset_DataFilter) => {
    if (!isLoading && offsetFilter != offset_DataFilter) {
      setLoading(true);
      dispatch(
        teacherActions.getHistoryExercise(
          id,
          paramFilter,
          "class_mark",
          limitDefault,
          offsetFilter,
          classes.homeworks.data
        )
      ).then((res) => {
        setLoading(false);
        if (!hasDuplicates(res.data, listHomeWork)) {
          setListHomeWork((prev) => [...prev, ...res.data]);
          setLoadMore(res.data?.length === configConstants.DEFAULT_LIMIT);
          setOffsetFilter(offset_DataFilter);
          if (scrollPosition > 0 && !isLoading) {
            refsScroll.current.scrollTop = scrollPosition;
          }
        }
      });
    }
  };

  const handleScroll = (e) => {
    if (
      refsScroll &&
      refsScroll.current?.scrollTop + refsScroll.current?.clientHeight + 5 >=
        refsScroll.current?.scrollHeight &&
      isLoadMore &&
      !isLoading
    ) {
      if (isEmpty(paramFilter.level) && isEmpty(paramFilter.skill)) {
        fetchData(offset + limitDefault);
      } else {
        fetchDataFilter(offsetFilter + configConstants.DEFAULT_LIMIT);
      }
    }
    setScrollPosition(refsScroll.current?.scrollTop);
  };

  function addComponentFilter() {
    return (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
        <img
          src={
            _.isEmpty(paramFilter.level) && _.isEmpty(paramFilter.skill)
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          alt="ico_add"
          className="ml--5"
        />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  const Popup = useSelector((state) => state.Popup);

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  let isNotUseFilter =
    isEmpty(curriculums.filters.param.grade_id) &&
    isEmpty(curriculums.filters.param.level) &&
    isEmpty(curriculums.filters.param.skill) &&
    isEmpty(curriculums.filters.param.type) &&
    isEmpty(curriculums.filters.param.start_time) &&
    isEmpty(curriculums.filters.param.end_time);

  return (
    <div className="sunE-right-container">
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          isHideRangeDate={true}
          filter_exercise={true}
          filter_level={true}
          filter_curriculum={false}
          onClickNo={() => setcloseFilter()}
          width={380}
          filter_files={false}
          type="class_mark"
          setExerciseHistory={setListHomeWork}
          listExerciseHistory={listHomeWork}
          setLoadMore={setLoadMore}
          setOffset={setOffsetFilter}
        />
      )}
      <Header
        title={"Chấm bài"}
        component={addComponentFilter()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id)
        }
      />
      <div className="sunE-container-box chambai flex-column">
        {listHomeWork.length > 0 ? (
          <div
            className="sunE-chambai-content box-shadow scrollbar-custom flex-1"
            // onScroll={(e) => handleScroll(e)}
            ref={refsScroll}
          >
            {listHomeWork.map((data, i) => {
              return (
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/class/view/" +
                    id +
                    "/homework/" +
                    data.exercise_id +
                    "/" +
                    data.library +
                    "/1"
                  }
                  className="sunE-giaotrinh-item flex-m rel pointer_cursor"
                  key={i}
                >
                  <div className="img">
                    <img
                      src={
                        "/assets/images/teacher/giaobai/" +
                        data.skill.toLocaleLowerCase() +
                        ".png"
                      }
                      alt={data.skill?.toLocaleLowerCase()}
                    />
                  </div>
                  <div className="content rel flex-1 flex-column" style={{paddingTop: 2.6}}>
                    <div className="flex-1">
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/homework/" +
                          data.exercise_id +
                          "/" +
                          data.library +
                          "/1"
                        }
                      >
                        <h2 className="title black">
                          {data.level && (
                            <span className={data.level}>
                              {data.level === "normal" ? "medium" : data.level}
                            </span>
                          )}{" "}
                          {data.exercise_topic}
                        </h2>
                      </Link>
                      <div>
                        <h3 className="desc black">{data.exercise_name}</h3>
                        {/* <div className="ico_right_p5"><img src="/assets/images/chambai/ico_right.png" alt="ico_right" /></div> */}
                        <h3 className="desc black flex-1">
                          {data.curriculum_name}
                        </h3>
                        <p>{`${data?.unit ? `Unit ${data.unit} - ` : ''}${data?.unit_name ? data?.unit_name : ''}`}</p>
                      </div>
                    </div>
                    <div className="lb-dc-gr">
                      <span className="lb" style={{marginRight: 16}}>
                        HS làm bài: {data.number_submit}/{data.number_assign}
                      </span>
                      <span className="dc">
                        Đã chấm: {data.number_mark}/{data.number_submit}
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : !isLoading ? (
          isNotUseFilter ? (
            <div
              className="__horizontal_center flex-self-center"
              style={{ width: "90%" }}
            >
              <img src="/assets/images/empty_confirm_excercise.png" alt="" />
              <div className="__text_empty">Chưa có bài nào cần chấm</div>
            </div>
          ) : (
            <div
              className="__text_empty_tutorial_guide"
              style={{ fontWeight: "normal" }}
            >
              Không tìm thấy kết quả phù hợp.
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export { HomePageManageHomeWork };
