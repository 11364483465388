import React from "react";

import "./PlacementTestPage.styles.scss";
import ButtonNews from "../../../../_components/Button/ButtonNews";
import { placementTestPage } from "./PlacementTestPage.logic";
import DoTestExamModal from "../../exam-test/DoTestExam";

export const PlacementTestPage = () => {
  let {
    goToTest,
    infoTesting,
    handleClickPassed,
    handleClickToStartTest,
    showTest,
    onClose,
  } = placementTestPage();
  return (
    <>
      {showTest ? (
        <DoTestExamModal isShow={true} rootlessness={true} onClose={onClose} />
      ) : (
        <div className="off_background_base">
          <div
            className="first_step-container flex-column"
            style={{
              background: `url(
          /assets/images/rootlessnessCourse/bg_first_step.png
        ) no-repeat top center /cover`,
            }}
          >
            <div className="first_step-header">
              <img
                alt="Logo"
                src="/assets/images/rootlessnessCourse/new_logo_se.png"
                className="contain_image logo-sunday"
              />
            </div>
            {goToTest ? (
              <div className="content-header-completed flex-center-column">
                <h1 className="content-header-font-completed ">
                  Bạn đã sẵn sàng!
                </h1>
                <h1 className="content-header-font-completed">
                  Bây giờ hãy hoàn thành tất cả các câu hỏi để xác định trình độ
                  của mình
                </h1>
              </div>
            ) : (
              <>
                <div className="first_step-content flex-center-column">
                  <div className="content-header">
                    <h1 className="content-header-font">Bài kiểm tra đầu vào</h1>
                  </div>
                  <div className="content-description">
                    <p className="content-description-font">
                      Trước khi bắt đầu hành trình, hãy làm bài kiểm tra đầu vào
                      để Sunday English có thể xác định năng lực của bạn và đề
                      xuất giáo trình phù hợp. Bài kiểm tra gồm{" "}
                      {`${infoTesting?.quantityQuestion}`} câu hỏi và bạn có{" "}
                      {`${infoTesting?.time}`} phút để làm bài.
                    </p>
                  </div>
                </div>
                <div className="first_step-footer">
                  <div className="button-action flex-center">
                    <div className="btn_custom_left">
                      <ButtonNews
                        width={"10rem"}
                        maxWidth={"100%"}
                        height={"2.5rem"}
                        onClick={handleClickPassed}
                      >
                        <div className="fz-11 font_btn_custom_left font_myriad_bold line_height_normal">Bỏ qua</div>
                      </ButtonNews>
                    </div>
                    <div className="btn_custom_right">
                      <div className="font_btn_custom">
                        <ButtonNews
                          width={"10rem"}
                          maxWidth={"100%"}
                          height={"2.5rem"}
                          className="btn_custom"
                          onClick={handleClickToStartTest}
                        >
                        <div className="color-white fz-11 font_myriad_bold line_height_normal">Bắt đầu ngay</div>
                        </ButtonNews>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
