import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "./../../../_components/Admin/Header";
import { scheduleActions } from "./../../../_actions";
import { useHistory } from "react-router-dom";
import { CalendaSchedule } from "../../../_components/Calendar";
import {
  EmptyScheduleToday,
  ScheduleToday,
} from "../../../_components/Admin/More/Schedule";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import { scheduleConstants } from "../../../_constants";
import $ from "jquery";

function HomePageScheduleStudent() {
  const history = useHistory();
  const schedules = useSelector((state) => state.schedules);
  const authentication = useSelector((state) => state.authentication);
  const [daySelected, selectDay] = useState(new Date().getDay());
  const dispatch = useDispatch();
  const [isLoadingTimeTable, setLoadingTimeTable] = useState(false);
  const [isLoadingSchedule, setLoadingSchedule] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isValidDateSelected, setValidDateSelected] = useState(true);
  const [dateSelected, setDateSelected] = useState(moment().startOf("day"));

  useEffect(() => {
    if (!!schedules.dateSelectedCalendar) {
      let day = moment(schedules.dateSelectedCalendar).weekday();
      selectDay(day);
      setDateSelected(moment(schedules.dateSelectedCalendar).startOf("day"));
    } else if (!!schedules.selectDate) {
      let day = moment(schedules.selectDate).weekday();
      selectDay(day);
      setDateSelected(moment(schedules.selectDate).startOf("day"));
    }

    // if (schedules.dateSelectedCalendar) {
    //   dispatch(
    //     scheduleActions.getScheduleToday(
    //       authentication.id,
    //       schedules.dateSelectedCalendar
    //     )
    //   );
    // } else {
    //   dispatch(scheduleActions.getScheduleToday(authentication.id));
    // }
  }, []);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [schedules]);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoadingSchedule(isLoading);
  }, [schedules?.today]);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoadingTimeTable(isLoading);
  }, [schedules?.timeTable]);

  function changeDateCalendaSchedule(date) {
    setLoading(true);
    let dateSelect = new Date(
      date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
    );
    setDateSelected(moment(date).startOf("day"));
    setValidDateSelected(
      dateSelect <= new Date(schedules?.timeTable?.valid_to)
    );
    selectDay(dateSelect.getDay());
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time:
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day,
    });
    dispatch(
      scheduleActions.getScheduleToday(
        authentication.id,
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
      )
    );
  }
  useEffect(() => {
    setLoadingTimeTable(true);
    setLoadingSchedule(true);
    dispatch(scheduleActions.getTimetable());
    if (!!schedules.dateSelectedCalendar) {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.dateSelectedCalendar
        )
      );
    } else {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.selectDate
        )
      );
    }
  }, [schedules.delete_schedule]);

  let dataToday = (schedules.today || []).filter(
    (schedule) => schedule.type === "personal"
  );
  
  let componentScheduleToday = isEmpty(dataToday) ? (
    !isLoading && <EmptyScheduleToday />
  ) : (
    <ScheduleToday schedules={dataToday} student type="personal" />
  );
  function addComponentCreate() {
    return (
      <div className="form-sunE-button rel mr-10">
        <Link to={"/" + authentication.role + "/more/schedule/timetable"}>
          <button className="btn-line-blue">Thời khóa biểu</button>
        </Link>
      </div>
    );
  }

  let [logTimeTable, setLogTimeTable] = useState([]);
  useEffect(() => {
    let newData = [];
    !isEmpty(schedules?.timeTable?.data) &&
      schedules?.timeTable?.data?.map((data, i) => {
        const dateData = moment(data?.date).startOf("day");
        if (
          isValidDateSelected &&
          ((daySelected === 0 && parseInt(data.day_number) === 8) ||
            (parseInt(data.day_number) === daySelected + 1 &&
              dateData.isSame(dateSelected)))
        ) {
          newData.push(data);
        }
      });
    setLogTimeTable(newData);
  }, [dateSelected]);
  return (
    <div className="sunE-right-container scrollbar-custom">
      <Header
        title={"Kế hoạch học tập"}
        component={addComponentCreate()}
        isBack
        clickBack={() => history.push("/" + authentication.role + "/more")}
      />
      <div className="sunE-container-box student-khht flex-column">
        <div className="sunE-calendar student">
          <div className="calendar box-shadow">
            <CalendaSchedule
              logTimeTable={logTimeTable}
              changeDateCalendaSchedule={(date) =>
                changeDateCalendaSchedule(date)
              }
              collapse
              showMonth
            />
          </div>
        </div>
        <div className="flex-1 flex-m">
          <div className="flex-1 mr-20">
            <div className="kh-title ">
              <h2 className="line38">Thời khóa biểu học tập</h2>
            </div>
            {!isEmpty(logTimeTable) ? (
              <div
                className="sunE-plan sunE-plan-list khht-tkb"
                style={{
                  paddingLeft: "0.25rem",
                }}
              >
                {logTimeTable.map((data, i) => {
                  return (
                    <div className="plan-item flex-m box-shadow" key={i}>
                      <div className="plan-item-content rel">
                        <div className="flex-m">
                          <div className="flex-1">
                            <p className="check-work">{data.class_name}</p>
                          </div>
                          <div
                            style={{ textAlign: "right" }}
                            className="flex-1"
                          >
                            <span className="tiem">
                              {data.start_time} - {data.end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              !isLoadingTimeTable && (
                <div className="flex-1 sunE-plan">
                  <div className="no-plan text-center">
                    <img src="/assets/images/no_plan.png" alt={"no_plan"} />
                    <h2>BẠN CHƯA TẠO THỜI KHOÁ BIỂU NÀO</h2>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="flex-1 ml-20 set-max-w">
            <div className="kh-title  flex-m">
              <h2 className="flex-1 line38">Kế hoạch cá nhân</h2>
              <Link
                to={
                  "/" +
                  authentication.role +
                  "/more/schedule/add?date=" +
                  schedules.selectDate
                }
              >
                <div className="rel btn-create-khk">
                  <img
                    src="/assets/images/student/ico_add_small.png"
                    alt={"ico_add_small"}
                    className="ico_add_kh"
                  />
                  <img
                    src="/assets/images/student/line.png"
                    alt={"line"}
                    className="ico_line_kh"
                  />
                  <div>
                    <span className="btn-line-blue">Tạo</span>
                  </div>
                </div>
              </Link>
            </div>
            {componentScheduleToday}
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomePageScheduleStudent };
