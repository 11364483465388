import React, { useState } from 'react';
function PopUpAddCommentRollUp(props) {
    let { onClickNo, title, onClickYes, value, isChecked, setIsChecked, isShowCheckBox } = props;
    const [inputs, setInputs] = useState({
        comment: ''
    });

    function validateParam() {
        return inputs.comment ? true : false;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit() {
        if (validateParam()) {
            onClickYes(inputs.comment);
            onClickNo();
        }

    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                {title && <h2 className="text-center popup-title bold title18">{title}</h2> }
                <textarea type="text" name="comment" onChange={handleChange} placeholder="Viết nhận xét..." className="custom-area scrollbar-custom-2" defaultValue={value}></textarea>
                {isShowCheckBox && <div className='d-flex align-item-center'>
                    <input style={{width: '20px', height: '20px', margin: '8px 8px 8px 0'}}  type="checkbox" id="keepReviewPersonal" name="keepReviewPersonal" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                    <label style={{fontSize: '16px'}} for="keepReviewPersonal"> Giữ lại nhận xét riêng đã soạn trước đó</label>
                </div>}
                <div className="flex-m form-sunE-button">
                    <button className="btn-default btn-custom-width mr-10" onClick={onClickNo}>Đóng</button>
                    <button className={'btn-line-blue btn-custom-width' + (!validateParam() ? ' btn-disable' : '')}  onClick={handleSubmit}>Xong</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpAddCommentRollUp };