import React, { useEffect, useState, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { configConstants, userConstants } from "./../../../_constants";
import { teacherActions } from "../../../_actions";
import { EmptyStudent, ListStudent } from "../../../_components/Admin/Teacher";
import { Header } from "../../../_components/Admin/Header";
import { PopUpOnlyOneButton } from "../../../_components/Popup";
import { useHistory } from "react-router-dom";
import { teacherConstants } from "../../../_constants/teachers";
import $ from "jquery";
import { teacherService } from "../../../_services";

function HomePageManageStudent() {
  const history = useHistory();

  const classes = useSelector((state) => state.classes);

  const [isLoading, setLoading] = useState(false);
  const [pagination] = useState({
    limit: 20,
    offset: 0,
  });
  

  const [showPopupNoStudent, setShowPopupNoStudent] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    // dispatch(teacherActions.getDetailClass(id));
    teacherService.getDetailClass(id).then((res) => {
      if (res?.data) {
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: res.data,
        });
      } else {
        dispatch(teacherActions.getDetailClassOffline(id));
      }
    });
    // dispatch(teacherActions.getStudentOfClass(id, pagination.limit, pagination.offset));
    dispatch(teacherActions.getRequestStudent(id));
    setLoading(true);
    teacherService
      .getStudentOfClassV2(id)
      .then((students) => {
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students,
        });
        setLoading(false);
      });
    // return () => {
    //   dispatch({
    //     type: teacherConstants.GET_STUDENT_OFF_CLASS,
    //     students: {
    //       data: [],
    //       base_url: "",
    //     },
    //   });
    // };
  }, []);
  
  const dataDetailClass = classes.detail;
  let componentStudent = isEmpty(classes.students.data) ? (
    !isLoading && <EmptyStudent />
  ) : (
    <ListStudent
      class_detail={classes.detail}
      class_id={id}
      students={classes.students}
    />
  );
  const authentication = useSelector((state) => state.authentication);

  function checkReviewStudent() {
    if (classes.requests.list_request.length > 0) {
      history.push(
        "/" + authentication.role + "/class/view/" + id + "/student/review"
      );
    } else {
      setShowPopupNoStudent(true);
    }
  }
  
  function addComponentReviewAddstudent() {
    return (
      <Fragment>
        {authentication.role === userConstants.ROLE_TEACHER && !!classes?.detail?.type && classes?.detail?.type !== 'offline' && !!classes?.students?.data?.length && (
          <p className="text-view-detail-entrance" onClick={() => history.push( "/" + authentication.role + "/class/view/" + id + "/student/entrance-score")}>Điểm đầu vào</p>
        )}
        {authentication.role === userConstants.ROLE_TEACHER && (
          <div
            className="rel btn-user-share"
            onClick={() => checkReviewStudent()}
          >
            <img
              src="/assets/images/teacher/ico_user_share.png"
              alt="ico_user_share"
            />
            {classes.requests.list_request.length > 0 && (
              <span className="sh-num">
                {classes.requests.list_request.length}
              </span>
            )}
          </div>
        )}
        {authentication.role === userConstants.ROLE_TEACHER && (
          <Link
            to={
              "/" + authentication.role + "/class/view/" + id + "/student/add"
            }
          >
            <div className="rel btn-user-add">
              <img
                src="/assets/images/teacher/ico_user_add.png"
                alt="ico_user_add"
              />
            </div>
          </Link>
        )}
      </Fragment>
    );
  }

  function clickBack() {
    if (authentication.role === userConstants.ROLE_TEACHER) {
      dispatch({
            type: teacherConstants.GET_STUDENT_OFF_CLASS,
            students: {
              data: [],
              base_url: "",
            },
          });
      return history.push("/" + authentication.role + "/class/view/" + id);
    } else {
      return history.go(-1);
    }
  }

  return (
    <div className="sunE-right-container add_student_container ">
      <Header
        title={"Thành viên"}
        component={addComponentReviewAddstudent()}
        isBack
        clickBack={() => clickBack()}
      />
      {showPopupNoStudent && (
        <PopUpOnlyOneButton
          message={"Không có yêu cầu nào vào lớp"}
          onClickNo={() => setShowPopupNoStudent(false)}
        />
      )}
      {componentStudent}
    </div>
  );
}

export { HomePageManageStudent };
