import React, { useEffect, useState } from "react";
import { classesConstants } from "../../../_constants/classes";

export default function SelectCurriculum(props) {
  let {
    textDefault,
    data,
    onChangeSelect,
    keySelected,
    list_style,
    id,
    className,
    hzLine,
    position,
    disabled,
    fix_text_cut_chart,
    styleOption,
    typeCurriculum,
  } = props;

  if (!id) {
    id = "id";
  }
  let wrapperRef = null;
  let keyName = props.keyName ? props.keyName : "name";
  let typeName = props.typeName ? props.typeName : "type";
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", _click_outside);
    return () => {
      document.removeEventListener("mousedown", _click_outside);
    };
  }, []);

  function _click_outside(event) {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShow(false);
    }
  }

  const _set_wrapper_ref_find = (node) => {
    if (!wrapperRef) {
      wrapperRef = node;
    }
  };

  function closeAndSetValue(key, sub_type) {
    setShow(false);
    onChangeSelect(key, sub_type);
  }

  if (keySelected) {
    if (data.find((item) => item[id] === keySelected)) {
      const itemSelected = data.find((item) => item[id] === keySelected);
      textDefault = itemSelected[keyName];
      typeCurriculum = itemSelected[typeName];
    }
  }
  const isSundayFree =
    typeCurriculum === classesConstants.SUNDAY ||
    typeCurriculum === classesConstants.SUNDAY_FREE;
  return (
    <div
      className={`select rel ${className ? className : ""}`}
      ref={(e) => _set_wrapper_ref_find(e)}
    >
      <div
        style={{ zIndex: 1010, pointerEvents: disabled ? "none" : "" }}
        className={`select-styled one-line${show ? " active" : ""}${
          fix_text_cut_chart ? " fix_text_cut_chart" : ""
        }${typeCurriculum ? " pd-left-12-pc" : ""}`}
        onClick={() => setShow(!show)}
        id={keySelected}
      >
        {typeCurriculum && (
          <div
            className={`image-selected image-option ${
              isSundayFree ? "image-option-logo" : ""
            }`}
          >
            <img
              src={
                isSundayFree
                  ? "/assets/images/logo_S.png"
                  : "/assets/images/giaotrinh/ico_gt.png"
              }
              alt={textDefault}
            />
          </div>
        )}
        {textDefault} {hzLine && <span className='line'>|</span>}
      </div>
      <ul
        style={{
          zIndex: 1000,
          ...styleOption,
          display: show ? "block" : "none",
          marginTop: 10,
        }}
        className={"select-options scrollbar-custom " + position}
      >
        {data?.map((item, i) => {
          return (
            <li key={i} onClick={() => closeAndSetValue(item[id], item?.sub_type)}>
              <div
                className={`image-option ${
                  item?.type === classesConstants.SUNDAY ||
                  item?.type_class === classesConstants.SUNDAY_FREE
                    ? "image-option-logo"
                    : ""
                }`}
              >
                <img
                  src={
                    item?.type === classesConstants.SUNDAY ||
                    item?.type_class === classesConstants.SUNDAY_FREE
                      ? "/assets/images/logo_S.png"
                      : "/assets/images/giaotrinh/ico_gt.png"
                  }
                  alt={item[keyName]}
                />
              </div>
              {list_style && <span className='circle'></span>}
              {item[keyName]}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
