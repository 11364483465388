import React, { Fragment } from "react";

function CricleProcess(percent, size = 'small') {
  return (
    <div className={size === 'large' ? '__custom_percent percent center-flex rel' : 'percent center-flex rel'}>
      {(() => {
        if (percent >= 0 && percent < 10) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_0.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 10 && percent < 20) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_10.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 20 && percent < 30) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_20.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 30 && percent < 40) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_30.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 40 && percent < 50) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_40.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 50 && percent < 60) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_50.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 60 && percent < 70) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_60.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 70 && percent < 80) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_70.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 80 && percent < 90) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_80.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        } else if (percent >= 90 && percent < 100) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_90.png"
                alt="process"
              />
              <span>{percent}%</span>
            </Fragment>
          );
        }else if (percent >= 100) {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_check.png"
                alt="process"
              />
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <img className={size === 'large' ? '__custom_size_img' : ''}
                src="/assets/images/process/ico_pro_lock.png"
                alt="process"
              />
            </Fragment>
          );
        }
      })()}
    </div>
  );
}

export { CricleProcess };
