import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { studentService } from "../../../_services";
import DoTestExamModal from "./DoTestExam";
import "./styles.scss";
import ModalReviewExam from "./ModalReviewExam";

const DetailHistoryTest = (props) => {
  const { id } = useParams();
  const [examDetail, setExamDetail] = useState({});
  const [isDoAgain, showDoAgain] = useState(false);
  const [isReviewItem, setIsReviewItem] = useState(false);
  const [userChoiceItemReview, setUserChoiceItemReview] = useState([])
  const [categorySelect, setCategorySelect] = useState([])
  const [questionNumberSelected, setQuestionNumberSelected] = useState(0)
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);

  useEffect(() => {
    getDetailExamTest();
  }, [id]);

  const getDetailExamTest = async () => {
    try {
      const res = await studentService.getDetailExamTestHistoryService(id);
      if (res?.status) {
        setExamDetail(res);
      }
    } catch (e) {}
  };

  // console.log(examDetail);

  const handleCloseDoTest = () => {
    showDoAgain(false);
    history.push("/" + authentication.role + "/exam-test/history");
  };

  const handleReviewItem = (item, index) => {
    const dataQuestion = examDetail?.data_question?.find(question => question?.question_id == item?.question_id)
    if(dataQuestion) {
      const questionNumber = index + 1
      const category = {
        content: dataQuestion?.list_option?.[0].content,
        group_content: dataQuestion?.list_option?.[0].group_content,
        group_id: dataQuestion?.list_option?.[0].group_id,
        group_script:  dataQuestion?.list_option?.[0].group_script,
        group_title: dataQuestion?.list_option?.[0].group_title,
        listQuestions: [
          {
            explain_parse: dataQuestion?.list_option?.[0].explain_parse,
            group_content: dataQuestion?.list_option?.[0].group_content,
            group_content_vi: dataQuestion?.list_option?.[0].group_content_vi,
            hint: dataQuestion?.list_option?.[0].hint,
            list_option: dataQuestion?.list_option,
            option_explain: dataQuestion?.list_option?.[0].option_explain,
            questionNumber,
            question_content: dataQuestion?.list_option?.[0].question_content,
            question_id: dataQuestion?.list_option?.[0].question_id,
            question_type: dataQuestion?.list_option?.[0].question_type,
            skill: dataQuestion?.list_option?.[0].skill,
          }
        ],
        question_type: dataQuestion?.list_option?.[0].question_type,
        skill: dataQuestion?.list_option?.[0].skill,
        jamming_answer_parse: dataQuestion?.list_option?.[0].jamming_answer_parse,
        group_file: dataQuestion?.list_option?.[0]?.group_file,
      }
      if (category?.skill + category?.question_type === "reading3") {
        category.group_script = dataQuestion?.list_option?.[0]?.question_content;
      } else if (
        category?.skill + category?.question_type === "reading2" ||
        category?.skill + category?.question_type === "reading4"
      ) {
        category.match_option_text = dataQuestion?.list_option?.[0]?.match_option_text;
      } else if (category?.skill + category?.question_type === "writing2") {
        let textQuestion = dataQuestion?.list_option?.[0]?.question_content
          .split("/")
          .sort(() => Math.random() - 0.5);
        category.listQuestions = category.listQuestions.map(item => ({
          ...item,
          question_content: textQuestion.join("/")
        }))
      }

      setCategorySelect([category])
      setIsReviewItem(true)
      setUserChoiceItemReview(JSON.parse(item?.user_choice))
      setQuestionNumberSelected(questionNumber)
    }
  }

  return (
    <div id="c-detail-history-test">
      <div className="header-view">
        <div
          className="title-header"
          onClick={() =>
            history.push("/" + authentication.role + "/exam-test/history")
          }
        >
          <img src="/assets/images/icon/ico_left.png" />
          <span>Kết quả làm bài</span>
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="body-view scrollbar-custom">
        <div className="score-info-history">
          <div className="item-score">
            <label>Điểm số:</label>
            <span>{examDetail?.data_history?.score}</span>
          </div>
          <div className="item-score">
            <label>Câu đúng:</label>
            <span>{examDetail?.data_history?.number_true}</span>
          </div>
          <div className="item-score">
            <label>Câu sai:</label>
            <span>{examDetail?.data_history?.number_false}</span>
          </div>
        </div>
        <div className="detail-history">
          {examDetail?.list_question?.map((item, index) => (
            <div className="col-2" key={item.id} onClick={() => handleReviewItem(item, index)}>
              <div
                className={`item-answer ${
                  item.score === "1" ? "true" : "false"
                }`}
              >
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-view">
        <button className="btn-line-blue" onClick={() => showDoAgain(true)}>
          Làm lại
        </button>
      </div>
      {isDoAgain && (
        <DoTestExamModal
          setExamSelected={showDoAgain}
          isShow={isDoAgain}
          onClose={() => handleCloseDoTest()}
          mockTestId={examDetail?.data_history?.exam_id}
          type={"mock_test"}
        />
      )}
      <ModalReviewExam 
        visible={isReviewItem} 
        categories={categorySelect}
        userChoice={userChoiceItemReview}
        questionNumber={questionNumberSelected}
        onClose={() => {
          setUserChoiceItemReview([])
          setIsReviewItem(false)
        }} />
    </div>
  );
};

export default React.memo(DetailHistoryTest);
