import React from "react"
import { isJsonString, validWord } from "../../../_helpers/utils"

export default function ResultQuestionChooseSound({dataQuestion, skill, question_type, answer}) {
  const listData = [];
  const listPronunciation = [];
  dataQuestion[0].list_option?.map(item => {
    if (!listPronunciation.includes(item?.option_text)) {
      listPronunciation.push(item?.option_text)
    }
    item?.match_option_text?.map(option => {
      option?.split(', ')?.map(text => {
        if (!listData.includes(text)) {
          const userChoice = JSON.parse(answer)?.find(ans => {
            const word = isJsonString(ans?.words) ? validWord(JSON.parse(ans?.words)) : validWord(ans?.words)
            return word === validWord(text)
          })
          listData.push({
            text,
            pronunciation: item?.option_text,
            userChoice
          })
        }
      })
    })
  })
  const transListData = shuffle(listData)
  
  const renderItem = (item, index) => {
    return (
      <div style={{
        flex: 1, 
        borderStyle: 'solid',
        borderWidth: 1, 
        borderColor: '#999999', 
        borderRadius: 16, 
        padding: '8px 0', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '70%',
        backgroundColor: '#fff'
        }}
      >
        <p style={{
          color: '#000000',
          fontWeight: 'bold'
        }}>
            {item?.text}
        </p>
        <div style={{width: '100%', display: 'flex' ,flexDirection: 'row', justifyContent: 'space-around', marginTop: 8}}>
          {listPronunciation.map((pro, i) => {
            const userChoice = isJsonString(item?.userChoice?.user_choice) ? JSON.parse(item?.userChoice?.user_choice)?.trim() : item?.userChoice?.user_choice?.trim()
            const isFailAnswer = userChoice === pro && item?.userChoice?.score == 0;
            const isRightHand = item?.pronunciation === pro && item?.userChoice?.score == 0 && userChoice != pro
            return (
                <div style={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: item?.pronunciation === pro ? '#62B440' : '#cdcdcd', 
                  borderRadius: 8, 
                  padding: '2px 0', 
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: item?.pronunciation === pro ? '#62B440' : isFailAnswer ? '#EB5757' : 'transparent',
                  width: 48,
                  position: 'relative'
                }}
                  key={i}
                >
                  {isRightHand && <img 
                    src="/assets/images/ico_hover_answer.png"
                    style={{
                      width: 24,
                      height: 20,  
                      position: 'absolute',
                      left: -30,
                      top: 4,
                    }}
                  />}
                  <p style={{
                    color: (item?.pronunciation === pro || isFailAnswer) ? '#FFFFFF' : '',
                  }}>
                      {pro}
                  </p>
                </div>
            )
          })}
        </div>
      </div>
    )
  }
  
  return (
    <div style={{flex: 1, marginTop: 16, display: 'flex', flexDirection: 'column', gap: 32}}>
      {transListData?.map((item, index) => {
        return (
          <div key={index}>
            {renderItem(item, index)}
          </div>
        )
      })}
    </div>
  )
}

function shuffle(array) {
  const newArr = [...array]
  let currentIndex = newArr.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArr[currentIndex], newArr[randomIndex]] = [
      newArr[randomIndex], newArr[currentIndex]];
  }
  return newArr;
}