import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

function SideBar() {
    const authentication = useSelector(state => state.authentication);

    return (
        <div className="list-menu-custom">
            {/* <NavLink className="btn-gr flex-m" activeClassName="active" to={'/' + authentication.role + "/license/code"} >
                <span className="btn-gr-img ico_ma">
                    <img src="/assets/images/icon/ico_ma.png" className="ico_default" alt={"alt_image"} />
                    <img src="/assets/images/icon/ico_ma_white.png" className="ico_active" alt={"alt_image"} />
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Nhập mã</p>
                </div>
            </NavLink> */}
            <NavLink className="btn-gr flex-m" activeClassName="active" to={'/' + authentication.role + "/more/license/add"}>
                <span className="btn-gr-img ico_buy">
                    <img src="/assets/images/icon/ico_buy.png" className="ico_default" alt={"alt_image"} />
                    <img src="/assets/images/icon/ico_buy_white.png" className="ico_active" alt={"alt_image"} />
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Mua gói mới</p>
                </div>
            </NavLink>
            <NavLink className="btn-gr flex-m" activeClassName="active" to={'/' + authentication.role + "/more/license/history"}>
                <span className="btn-gr-img ico_time">
                    <img src="/assets/images/icon/ico_time.png" className="ico_default" alt={"alt_image"} />
                    <img src="/assets/images/icon/ico_time_white.png" className="ico_active" alt={"alt_image"} />
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Lịch sử giao dịch</p>
                </div>
            </NavLink>
        </div>
    );
}

export { SideBar };