import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { userConstants } from './../../_constants';

import { HeaderCloud } from '../../_components/Header';
import { userActions } from '../../_actions';
import { LinkBack } from '../../_components/Link';
import { Alert } from '../../_components/Alert';
import InputForm from '../../_components/Input';

function ResetPasswordPage() {

    const alert = useSelector(state => state.alert);

    const { token } = useParams();
    const [inputs, setInputs] = useState({
        password: '',
        re_password: '',
        token
    });
    const dispatch = useDispatch();

    const [submitted, setSubmitted] = useState(false);
    const { password, re_password } = inputs;

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (validateParam()) {
            dispatch(userActions.resetPassword(inputs));
        }
    }

    function validateParam() {
        return (password && re_password) ? true : false;
    }

    return (
        <div className="bg-register rel">
            <HeaderCloud />
            <div className="sunE-form-container login-form-container">
                <div className="sunE-custom-form max430">
                    {alert.message && alert.screen === userConstants.SCREEN_RESET_PASSWORD && <Alert alert={alert} />}
                    <form className="sunE-login-app" onSubmit={handleSubmit} noValidate={true}>
                        <InputForm option={'image'} class={submitted && !password ? ' err' : ''} imageLink={'/assets/images/icon/ico_password.png'} alt={'ico_password'} placeholder={'Mật khẩu'} value={password} onChange={handleChange} type={'password'} name={'password'} />
                        <InputForm option={'image'} class={submitted && !re_password ? ' err' : ''} imageLink={'/assets/images/icon/ico_password.png'} alt={'ico_password'} placeholder={'Nhập lại mật khẩu'} value={re_password} onChange={handleChange} type={'password'} name={'re_password'} />
                        <div className="form-sunE-button">
                            <button className={'btn-line-blue' + (!validateParam() ? ' btn-disable' : '')}>Đổi mật khẩu</button>
                        </div>
                    </form>
                </div>
                <div className="sunE-bottom-form text-center">
                    <LinkBack />
                </div>
            </div>
        </div>
    );
}

export { ResetPasswordPage };