import React, { useEffect, Fragment, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import { Header } from "../../_components/Admin/Header";
import moment from "moment";
import { CalendaSchedule } from "../../_components/Calendar";
import { intervalToDuration } from "date-fns";
import { scheduleConstants } from "../../_constants";
import { isEmpty } from "lodash";

function AssessmentLogLearning() {
  const dispatch = useDispatch();
  const date = new Date();
  const authentication = useSelector((state) => state.authentication);
  const learning_by_date = useSelector(
    (state) => state.assessments.learning_by_date
  );
  const [dateSelected, selectDate] = useState({
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  });
  const schedules = useSelector((state) => state.schedules);

  useEffect(() => {
    let dateFormat =
      dateSelected.year +
      "-" +
      (parseInt(dateSelected.month) + 1) +
      "-" +
      dateSelected.day;
    dispatch(
      studentActions.getLogLearningByDate(
        moment(dateFormat).format("YYYY-MM-DD"),
        authentication.id
      )
    );
  }, [dateSelected]);

  const upperFirstCase = useCallback(
    (actionName) => {
      if (!actionName) {
        return "";
      }
      return actionName.charAt(0).toUpperCase() + actionName.slice(1);
    },
    [learning_by_date]
  );

  const convertTime = (seconds) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const hours = duration?.hours ? `${duration?.hours} giờ` : "";
    const minutes = duration?.minutes ? `${duration?.minutes} phút` : "";
    const second = duration?.seconds ? `${duration?.seconds} giây` : "";

    return `${hours} ${minutes} ${second}`;
  };

  // Handle Select Date
  const handleSelectDate = (param) => {
    selectDate(param);
    dispatch({
      type: scheduleConstants.SET_SELECT_DATE,
      time:
        param.year +
        "-" +
        parseInt(parseInt(param.month) + 1) +
        "-" +
        param.day,
    });
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time:
        param.year +
        "-" +
        parseInt(parseInt(param.month) + 1) +
        "-" +
        param.day,
    });
  };

  return (
    <div className="sunE-right-container flex-column scrollbar-custom">
      <Header title={"Nhật ký hoạt động"} isBack />
      <div className="flex-1 flex-m sunE-content thanhtich-container">
        <div className="__calendar_log">
          <CalendaSchedule
            changeDateCalendaSchedule={(param) => handleSelectDate(param)}
            collapse
            showMonth
            hideEvent={false}
            role="student"
            student_id={authentication.id}
          />
        </div>
        <div className="his-hd box-shadow">
          <div className="scrollbar-custom">
            {learning_by_date.data?.map((data, i) => {
              var dateTime = moment(data.time);
              if (
                dateTime.year() === dateSelected.year &&
                dateTime.month() === dateSelected.month &&
                dateTime.date() === dateSelected.day
              ) {
                return (
                  <Fragment key={i}>
                    {data.length > 0 && (
                      <h2
                        className="b-26-black text-center"
                        style={{ marginTop: 50 }}
                      >
                        {moment(data.time).format("DD/MM/YYYY")}
                      </h2>
                    )}
                    {data ? (
                      <div className="d-flex his-item row" key={i}>
                        <p className="col-md-3 hours" style={{ width: "20%" }}>
                          {moment(data.time).format("HH:mm")}
                        </p>
                        <div
                          className="col-md-3 unit"
                          style={{
                            paddingLeft: 20,
                            paddingTop: 1,
                            width: "80%",
                          }}
                        >
                          <p className="unit bold">{data.lesson_name}</p>
                          <p className="unit">{data?.curriculum_name}</p>
                          <p className="unit">
                            {data.unit_name +
                              " - " +
                              upperFirstCase(data.skill)}
                          </p>
                          {/* <span className="bold">{data.skill}</span> */}
                          <p>Thời gian học: {convertTime(data.duration)}</p>

                          <p className="scor font-family-bold">
                            {data.status == 1 || data.score > 0
                              ? `${parseFloat(
                                  parseFloat(data.score).toFixed(1)
                                )} điểm`
                              : null}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="empty-plan-custom">
                        <img src="/assets/images/no_plan.png" alt="" />
                        <span>Chưa có hoạt động gì cho ngày hôm nay.</span>
                      </div>
                    )}
                  </Fragment>
                );
              }
            })}
            {learning_by_date.data?.length == 0 && (
              <div className="empty-plan-custom" style={{ marginTop: 50 }}>
                <img src="/assets/images/no_plan.png" alt="" />
                {moment(schedules.dateSelectedCalendar).format("YYYY-MM-DD") ==
                moment().format("YYYY-MM-DD") ? (
                  <span>Chưa có hoạt động gì cho ngày hôm nay.</span>
                ) : (
                  <span>Chưa có hoạt động gì cho ngày này.</span>
                )}
              </div>
            )}
            {/* {[1,2,3,4,5,6,7,8,9,10,11].map(item => (
                            <div className="flex-m his-item" key={item}>
                                <p className="hours">10:10</p>
                                <div>
                                    <p className="unit">{'abc'}</p>
                                    <p className="time-hs">Thời gian học: 0 phút</p>
                                </div>
                                <p className="scor">8 điểm</p>
                            </div>
                        ))} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export { AssessmentLogLearning };
