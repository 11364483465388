import { audioPlayerConstants } from "../../_constants/audioPlayer";

var initialState = {
  audioPlayer: {}
};

const audioPlayer = (state = initialState, action) => {
  switch (action.type) {
    case audioPlayerConstants.UPDATE_AUDIO_PLAYER:
      return {
        audioPlayer: action.payload
      };
    default:
      return state;
  }
};

export { audioPlayer };
