import { useEffect, useState } from 'react';
import RootCheckBox from '../RootCheckBox';
import './style.css';
import { apiCaller, history } from '../../_helpers';
import InputDate from '../Auth/InputDate';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import { configConstants, teacherConstants } from '../../_constants';
import PopupEditStudent from './PopupEditStudent';
import SubMenu from '../SubMenu';
import { v4 } from 'uuid';

export default function PopupListStudentAddedFail({ open = false, setOpen, onClose, listData = [], onComplete, screen, totalStudentAddedSuccess = 0, totalStudentAdded = 0, onAddStudent}) {
  if(!open) {
    return null;
  }
  
  const [listStudent, setListStudent] = useState([])
  const [studentEditing,setStudentEditing] = useState()
  const [isOpenModalEdit,setIsOpenModalEdit] = useState(false)
  const dispatch = useDispatch()

  const handleEditStudent = (data) => {
    const newListStudent = listStudent?.map(item => {
      if (item?.id === data?.id) {
        return {
          isEdited: true,
          ...data,
          msg_error: undefined,
        }
      }
      return item;
    })
    setListStudent(newListStudent);
    setIsOpenModalEdit(false);
    setStudentEditing(null);
  }

  const handleRemoveStudent = (index) => {
    const newListStudent = listStudent?.filter((_, i) => i !== index);
    setListStudent(newListStudent)
    if (!newListStudent?.length) {
      onClose();
    }
  }

  const handleSubmit = () => {
    setOpen(false);
    onAddStudent(listStudent?.map(item => {
      const newItem = {...item};
      delete item.id;
      delete item.isEdited;
      return newItem;
    }))
  }

  const validateParams = () => {
    return listStudent.every(item => !item?.msg_error)
  }

  useEffect(() => {
    const newList = (listData || [])?.map((item, index) => ({
      id: v4(),
      ...item
    }))
    setListStudent(newList)
  }, [listData?.length])

  const renderIconEdit = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
        <path 
          fill="none" 
          stroke="#00bbb5" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          stroke-width="1.5" 
          d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
        />
      </svg>
    )
  }

  const renderIconDelete = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 256 256">
        <path 
          fill="red" 
          d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16M96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0"
        />
      </svg>
    )
  }

  const renderIconNopeAvatar = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
          <path fill="red" d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"/>
        </g>
      </svg>
    )
  }
  
  return (
    <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open list-student-added-fail-modal" uk-modal="">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" 
        style={{ 
          width: '50%', 
          maxHeight: '80vh',
          paddingLeft: 0, 
          paddingRight: 0, 
          display: 'flex', 
          flexDirection: 'column' 
        }}
      >
        <div className='list-student-added-fail-header'>
          <span>{`Đã thêm thành công ${totalStudentAddedSuccess}/${totalStudentAdded} học sinh vào lớp`}</span>
        </div>
        <div className='list-student-added-fail-body scrollbar-custom'>
          {listStudent?.map((item, index) => {
            return (
              <div className='student-added-fail-item' key={item?.id}>
                <div className='student-added-fail-img'>
                {!item?.isEdited ? renderIconNopeAvatar() : <img
                  src={configConstants.BASE_URL + (item?.avatar ? item.avatar : `assets/img_base/student_${item?.gender ? item.gender : 'male'}_dfa.jpg`)}
                  alt="avatar"
                />}
                </div>
                <div className='student-added-fail-info'>
                  <p style={{fontWeight: 700}}>{item?.name}</p>
                  {item?.email && <p>{item.email}</p>}
                  {item?.phone && <p>{item.phone}</p>}
                  {item?.msg_error?.length && <div style={{display: 'flex', gap: 4}}>
                    <p>Lí do:</p>
                    <div>
                      {item?.msg_error?.map((err, iErr) => (
                        <p key={iErr}>{`${item?.msg_error?.length > 1 ? '+ ' : ''}${err}`}</p>
                      ))}
                    </div>
                  </div>}
                </div>
                <SubMenu
                  style={{marginLeft: 'auto'}} 
                  renderMenu={
                    <div style={{padding: '8px 16px', display: 'flex', flexDirection: 'column',gap: 12}}>
                      <div 
                          onClick={() => {
                            setStudentEditing(item);
                            setIsOpenModalEdit(true);
                          }} 
                          style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}}
                      >
                        <div
                          className="btn-remove-student"
                        >
                          {renderIconEdit()}
                        </div>
                        <p style={{textWrap: 'nowrap'}}>Chỉnh sửa</p>
                      </div>
                      <div 
                        style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}} 
                        onClick={() => handleRemoveStudent(index)}
                      >
                        <div
                          className="btn-remove-student"
                        >
                          {renderIconDelete()}
                        </div>

                        <p style={{textWrap: 'nowrap'}}>Xóa</p>
                      </div>
                    </div>
                  }
                />
                {/* <div style={{}}>
                  <div
                    className="btn-remove-student"
                    onClick={() => {
                      setStudentEditing(item);
                      setIsOpenModalEdit(true);
                    }}
                    style={{marginTop: 0}}
                  >
                    {renderIconEdit()}
                  </div>
                  <div
                    className="btn-remove-student"
                    onClick={() => handleRemoveStudent(index)}
                    style={{marginTop: 8}}
                  >
                    {renderIconDelete()}
                  </div>
                </div> */}
              </div>
            )
          })}
        </div>
        <div className='list-student-added-fail-action'>
          <button className="btn-default mr-20" onClick={onClose}>Huỷ</button>
          <button 
            disabled={!validateParams()} 
            className={"btn-bg-blue btn-default " + (!validateParams() ? 'btn-disable' : '')}
            onClick={handleSubmit}  
          >
            Thêm
          </button>
        </div>
      </div>
      <PopupEditStudent 
        open={isOpenModalEdit} 
        data={studentEditing}
        onClose={() => {
          setIsOpenModalEdit(false);
          setStudentEditing(null)
        }}
        onSubmit={handleEditStudent}
        isEditStudentFail
      />
    </div>
  )
}