import React from 'react';
import PropTypes from 'prop-types';

function InputForm(props) {
    switch (props.option) {
        case 'image':
            return (
                <div className={`form-sunE-input rel ${props.class ? props.class : ''}`}>
                    <img src={props.imageLink ? props.imageLink : ''} alt={props.alt ? props.alt : ''} className={`ico_input ${props.classImage ? props.classImage : ''}`} />
                    <input type={props.type ? props.type : 'text'} name={props.name ? props.name : ''} placeholder={props.placeholder ? props.placeholder : 'text'} onChange={props.onChange ? props.onChange : ''} value={props.value ? props.value : ''}/>
                </div>
            );
        default:
            return (
                <span>
                    <input type={props.type} className={props.class ? props.class : ''} name={props.name ? props.name : ''} placeholder={props.placeholder}/>
                </span>
            );
    }
}

InputForm.propTypes = {
    type: PropTypes.string,
    class: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    imageLink: PropTypes.string,
    option: PropTypes.any,
    placeholder: PropTypes.string,
    alt: PropTypes.string,
    classImage: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default InputForm;