import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FooterSelection } from "../../_constants/footerSelection";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { id } from "date-fns/locale";

export const footerLogic = (props) => {
  const _history = useHistory();
  const [isOpen, setIsOpen] = useState("");

  // DATA COURSES

  // const [dataCourses, setDataCourses] = useState([
  //   {
  //     id: 1,
  //     name: "Mất gốc",
  //     data: [
  //       {
  //         id: 1,
  //         name: "Tiếng Anh lớp 6",
  //         href: `${TypeHeaderNewsItem.COURSES}/#unknown_06`,
  //       },
  //       {
  //         id: 2,
  //         name: "Tiếng Anh lớp 7",
  //         href: `${TypeHeaderNewsItem.COURSES}/#unknown_07`,
  //       },
  //       {
  //         id: 3,
  //         name: "Tiếng Anh lớp 8",
  //         href: `${TypeHeaderNewsItem.COURSES}/#unknown_08`,
  //       },
  //       {
  //         id: 4,
  //         name: "Tiếng Anh lớp 9",
  //         href: `${TypeHeaderNewsItem.COURSES}/#unknown_09`,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Cơ bản",
  //     data: [
  //       {
  //         id: 1,
  //         name: "Tiếng Anh lớp 6",
  //         href: `${TypeHeaderNewsItem.COURSES}/#basic_06`,
  //       },
  //       {
  //         id: 2,
  //         name: "Tiếng Anh lớp 7",
  //         href: `${TypeHeaderNewsItem.COURSES}/#basic_07`,
  //       },
  //       {
  //         id: 3,
  //         name: "Tiếng Anh lớp 8",
  //         href: `${TypeHeaderNewsItem.COURSES}/#basic_08`,
  //       },
  //       {
  //         id: 4,
  //         name: "Tiếng Anh lớp 9",
  //         href: `${TypeHeaderNewsItem.COURSES}/#basic_09`,
  //       },
  //     ],
  //   },
  // ]);

  const [dataFooterMobile] = useState([
    {
      id: 1,
      name: 'Khóa học',
      href: '/courses'
    },
    {
      id: 2,
      name: 'Phụ huynh',
      href: '/parent_news'
    },
    {
      id: 3,
      name: 'Giáo viên',
      href: '/teacher_news'
    },
    {
      id: 4,
      name: 'Luyện thi',
      href: '/mock_test'
    }
  ])

  const [dataCourses, setDataCourses] = useState([
    {
      id: 1,
      name: "Hội nhập",
      href: `${TypeHeaderNewsItem.COURSES}/#integrate`,
      eleId: "#integrate",
    },
    {
      id: 2,
      name: "Nâng cao năng lực",
      href: `${TypeHeaderNewsItem.COURSES}/#advanced`,
      eleId: "#advanced",
    },
  ]);

  const [dataBuyCourses, setDataBuyCourses] = useState([
    {
      id: 1,
      name: "Gói Pro",
      href: `${TypeHeaderNewsItem.FEE}/#list_packages`,
      eleId: "#list_packages",
    },
    {
      id: 2,
      name: "Gói Plus",
      href: `${TypeHeaderNewsItem.FEE}/#list_packages`,
      eleId: "#list_packages",
    },
    {
      id: 3,
      name: "Gói Premium",
      href: `${TypeHeaderNewsItem.FEE}/#list_packages`,
      eleId: "#list_packages",
    },
  ]);

  const [dataBuyCoursesTeacher, setDataBuyCoursesTeacher] = useState([
    {
      id: 1,
      name: "Gói Silver",
      href: `${TypeHeaderNewsItem.TARIFF}/#list_packages`,
      eleId: "#list_packages",
    },
    {
      id: 2,
      name: "Gói Gold",
      href: `${TypeHeaderNewsItem.TARIFF}/#list_packages`,
      eleId: "#list_packages",
    },
    {
      id: 3,
      name: "Gói Diamond",
      href: `${TypeHeaderNewsItem.TARIFF}/#list_packages`,
      eleId: "#list_packages",
    },
  ]);

  // Data Tariff
  const dataTariff = [
    {
      id: 1,
      name: "Dành cho học sinh",
      href: `${TypeHeaderNewsItem.FEE}/#list_packages`,
      eleId: "list_packages",
    },
    {
      id: 2,
      name: "Dành cho giáo viên",
      href: `${TypeHeaderNewsItem.TARIFF}/#list_packages`,
      eleId: "list_packages",
    },
  ];

  // Data Support

  const dataSupport = [
    {
      id: 1,
      name: "Kích hoạt tài khoản",
      href: "account_activation/#",
    },
    {
      id: 2,
      name: "Hỗ trợ khách hàng",
      href: `faq/#faq`,
    },
    {
      id: 3,
      name: "Câu hỏi thường gặp",
      href: `faq/#faq`,
    },
    {
      id: 4,
      name: "Hướng dẫn thanh toán",
      href: `${TypeHeaderNewsItem.FEE}/policy/#policy_payment`,
      typeStore: "store",
      eleId: "policy_payment",
    },
  ];

  // Data Policy
  const dataPolicy = [
    {
      id: 1,
      name: "Điều khoản sử dụng",
      href: `${TypeHeaderNewsItem.POLICY}/#term`,
      eleId: "term",
    },
    {
      id: 2,
      name: "Chính sách bảo vệ và xử lý dữ liệu cá nhân",
      href: `${TypeHeaderNewsItem.POLICY}/#security`,
      eleId: "security",
    },
    {
      id: 3,
      name: "Chính sách về bản quyền và sở hữu trí tuệ",
      href: `${TypeHeaderNewsItem.POLICY}/#license`,
      eleId: "license",
    },
    {
      id: 4,
      name: "Chính sách thanh toán",
      href: `${TypeHeaderNewsItem.POLICY}/#payment`,
      eleId: "payment",
    },
  ];

  const handleNavigateFooter = (href) => {};

  const handleOpenOption = (type) => {
    if (isOpen != type) {
      // console.log(type);
      setIsOpen(type);
    } else {
      setIsOpen("");
    }
  };

  // Data Infor download
  const dataInforDownload = [
    {
      id: 1,
      title: "Tải app dành cho học sinh",
      linkAppStore: "#",
      linkGGPlay: "#",
      type: "student",
      // href:
    },
    {
      id: 2,
      title: "Tải app dành cho giáo viên",
      linkAppStore: "#",
      linkGGPlay: "#",
      type: "teacher",
    },
    {
      id: 3,
      title: "Tải app dành cho phụ huynh",
      linkAppStore: "#",
      linkGGPlay: "#",
      type: "parent",
    },
  ];

  // Handle Click Link App
  const handleOpenLinkApp = (link) => {
    window.open(link, "_blank");
  };

  return {
    dataFooterMobile,
    dataCourses,
    dataSupport,
    dataBuyCourses,
    dataPolicy,
    isOpen,
    dataInforDownload,
    dataBuyCoursesTeacher,
    dataTariff,
    handleNavigateFooter,
    handleOpenOption,
    handleOpenLinkApp,
  };
};
