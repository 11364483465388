import { useEffect, useState } from "react";
import { apiCaller } from "../../../_helpers";
import { configConstants } from "../../../_constants";
import { advisementLogic } from "../AdvisementForm.logic";

export const formTrialLogic = (props) => {
  // Variable Province
  const [provinceList, setProvinceList] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState({
    title: "",
    value: "",
  });
  // Variable District
  const [districtList, setDistrictList] = useState([]);
  const [districtSelected, setDistrictSelected] = useState({
    title: "",
    value: "",
  });

  const [schoolList, setSchoolList] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState({
    title: "",
    value: "",
  });

  // Text error
  const [errProvince, setErrProvince] = useState("");
  const [errDistrict, setErrDistrict] = useState("");
  const [errSchool, setErrSchool] = useState("");

  // Success Trial
  const [isSuccessTrial, setIsSuccessTrial] = useState(false);

  const getProvinceList = () => {
    apiCaller(
      "/api_category/provinces?country_code=vn",
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setProvinceList(res.data);
      }
    });
  };

  useEffect(() => {
    getProvinceList();
  }, []);

  // Fetch district list
  const getDistrictList = (provinceAlias) => {
    apiCaller(
      `/api_category/districts?province_alias=${provinceAlias}`,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setDistrictList(res.data);
      }
    });
  };

  // Fetch school list
  const getSchoolList = (districtAlias) => {
    apiCaller(
      `/api_category/schools?district_alias=${districtAlias}`,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setSchoolList(res.data);
      }
    });
  };

  // Handle Change Province
  const changeProvince = (newProvince) => {
    if (newProvince.value !== provinceSelected.value) {
      setDistrictSelected({
        title: "",
        value: "",
      });
      setSchoolSelected({
        title: "",
        value: "",
        id: "",
      });
      setProvinceSelected(newProvince);
      getDistrictList(newProvince.value);
    }
  };

  // Handle Change District
  const changeDistrict = (newDistrict) => {
    if (newDistrict.value !== districtSelected.value) {
      setSchoolSelected({
        title: "",
        value: "",
        id: "",
      });
      setDistrictSelected(newDistrict);
      getSchoolList(newDistrict.value);
    }
  };

  // Handle Change School
  const changeSchool = (newSchool) => {
    setSchoolSelected(newSchool);
  };

  //   Data policy trial
  const dataPolicyTrial = {
    title: "Chính sách trải nghiệm sản phẩm",
    data: [
      {
        id: 1,
        title: "Quy định",
        content_list: [
          {
            id: 1,
            content:
              "Chương trình trải nghiệm giải pháp dạy và học tiếng Anh trực tuyến bao gồm 2 sản phẩm Sunday Teacher và Sunday English chỉ áp dụng cho các Giáo viên dạy tiếng Anh tại các trường THCS công lập và tư thục thuộc hệ thống giáo dục Việt Nam.",
          },
          {
            id: 2,
            content: "Mỗi giáo viên được đăng ký trải nghiệm MỘT lần duy nhất.",
          },
          {
            id: 3,
            content:
              "Giáo viên cam kết chỉ sử dụng tài khoản Sunday Teacher/Sunday English để phục vụ và tăng hiệu quả việc giảng dạy, giao bài, hướng dẫn học cho học sinh chính khóa và ngoại khóa của mình.",
          },
          {
            id: 4,
            content:
              "Giáo viên cam kết không đăng ký hộ, cho tặng, sang nhượng, chuyển giao hay cho thuê mượn các tài khoản trải nghiệm miễn phí.",
          },
          {
            id: 5,
            content:
              "Giáo viên cam kết đồng ý và tuân thủ điều khoản, chính sách và dịch vụ của Sunday Teacher.",
          },
        ],
      },
      {
        id: 2,
        title: "Thời gian trải nghiệm",
        content_list: [
          {
            id: 1,
            content:
              "Giáo viên được miễn phí trải nghiệm 1 tài khoản giáo viên (Sunday Teacher) và tối đa 50 tài khoản học sinh (Sunday English) trong 1 tháng.",
          },
        ],
      },
    ],
  };

  return {
    provinceList,
    provinceSelected,
    changeProvince,
    errProvince,
    setErrProvince,
    districtList,
    districtSelected,
    errDistrict,
    setErrDistrict,
    changeDistrict,
    schoolList,
    schoolSelected,
    changeSchool,
    errSchool,
    setErrSchool,
    dataPolicyTrial,
    isSuccessTrial,
    setIsSuccessTrial,
  };
};
