import { HashLink } from "react-router-hash-link";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import "./FooterAuth.style.scss";

const FooterAuth = () => {
  return (
    <div className="flex-jus-between flex-center-column-mobile footer_auth_container">
      <div className="flex-algin footer_auth_first col-40">
        <HashLink
          className="mg-right-3per color_hover_green mg-right-0-mobile fz-08-mobile"
          // smooth
          to={`/${TypeHeaderNewsItem.POLICY}`}
        >
          Điều khoản và Điều kiện
        </HashLink>
        <HashLink
          className="color_hover_green fz-08-mobile"
          // smooth
          to={`/#advisement_home`}
        >
          Liên hệ với chúng tôi
        </HashLink>
      </div>

      <div className="flex-center-column text-align-center">
        <div className="fz-22 fz-15-mobile text-align-center font_news_bold">
          SUNDAY ENGLISH
        </div>

        <div className="text-align-center fz-07-mobile">
          Copyright @ 2007-2023 GK Corp. All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default FooterAuth;
