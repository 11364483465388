import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty, isNull } from "lodash";
import { PopUpFilterV2 } from "../../../_components/Popup";
import { curriculumConstants, popupConstants } from "../../../_constants";
import { Wish } from "../../../_components/Admin/Teacher";
import $ from "jquery";
import { teacherService } from "../../../_services";
import LazyLoad from "react-lazyload";
import { history } from "../../../_helpers";

const filterDefault = {
  topic: "",
  skill: [],
  level: [],
  grade_id: [],
  type: [],
  start_time: "",
  end_time: "",
};
const PAGE_SIZE = 10;

function HomePageManageExerciseMore() {
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState(filterDefault);
  const [listData, setListData] = useState([]);
  const [isShowFilter, showFilter] = useState(false);
  const [isLoadMore, setLoadMore] = useState(true);
  const [listOnlineClass, setListOnlineClass] = useState([]);
  const [isLoadingListOnlineClass, setLoadingListOnlineClass] = useState(false)
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");

  const refsScroll = useRef(null);

  const getListOnlineClass = async () => {
    setLoadingListOnlineClass(true)
    let resOnline = await teacherService.getListClassOnlineAsync();
    setLoadingListOnlineClass(false)
    if (resOnline.status) {
      setListOnlineClass(resOnline.data);
    }
  };

  useEffect(() => {
    getListOnlineClass();
  }, []);

  useEffect(() => {
    if(isLoading || isLoadingListOnlineClass) {
      $(".loading").removeClass("hide");
      return;
    }
    $(".loading").addClass("hide");
  }, [isLoading, isLoadingListOnlineClass])

  useEffect(() => {
    getListData(0);
  }, [filter]);

  // useEffect(() => {
  //   if (refsScroll && refsScroll.current) {
  //     refsScroll.current?.addEventListener("scroll", handleScroll);
  //     return () =>
  //       refsScroll.current?.removeEventListener("scroll", handleScroll);
  //   }
  // }, [refsScroll, isLoadMore, isLoading]);

  const handleScroll = (e) => {
    console.log('e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5: ', e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5)
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMore &&
      !isLoading
    ) {
      getListData(listData.length);
    }
  };

  function getListData(offset) {
    setLoading(true);
    let skills = filter.skill;
    if (filter.skill.indexOf("Test") !== -1) {
      skills = skills.filter((skill) => skill !== "Test");
      // skills = [...skills, "test"];
      skills = [...skills, "exam", "mini_test"];
    }
    let paramsFilter = { ...filter, skill: skills };
    let isFilterDate = !isEmpty(filter.start_time) && !isEmpty(filter.end_time);
    teacherService
      .getHistoryExerciseWithoutClass(
        paramsFilter,
        isFilterDate,
        offset,
        PAGE_SIZE
      )
      .then((res) => {
        if (res && res.data) {
          let data = res.data || [];
          if (offset === 0) {
            setListData(data);
          } else {
            setListData([...listData, ...data]);
          }
          setLoadMore(data.length === PAGE_SIZE);
        }
        setLoading(false);
      });
  }

  function onSubmitFilter(params) {
    setFilter(params);
  }

  let isNotUseFilter =
    isEmpty(filter.grade_id) &&
    isEmpty(filter.level) &&
    isEmpty(filter.skill) &&
    isEmpty(filter.type) &&
    isEmpty(filter.start_time) &&
    isEmpty(filter.end_time);

  // Handle Add Filter Component
  function addComponentFilter() {
    return isEmpty(listData) && isNotUseFilter ? (
      <Fragment></Fragment>
    ) : (
      <div
        className='form-sunE-button btn-filter rel'
        onClick={() => showFilter(true)}
      >
        <img
          src={
            isNotUseFilter
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          alt='ico_add'
          className='ml--5'
        />
        {/* <img src="/assets/images/teacher/ico_filter.png" alt="ico_add" /> */}
        <button className='btn-line-blue'>Lọc</button>
      </div>
    );
  }

  // Handle Go Back Page
  const handleBackClick = () => {
    if (fromPage == "morepage") {
      history.push(`/${authentication.role}/more`);
    } else {
      history.go(-1);
    }
  };

  return (
    <div className='sunE-right-container rel'>
      {isShowFilter && (
        <PopUpFilterV2
          isFilterByRangeDate={true}
          isFilterBySkill={true}
          onClickNo={() => showFilter(false)}
          width={450}
          onSubmitFilter={(params) => onSubmitFilter(params)}
          paramFilter={filter}
        />
      )}
      <Header
        title={"Quản lý bài đã giao"}
        component={addComponentFilter()}
        isBack
        clickBack={!isEmpty(fromPage) ? handleBackClick : history.go(-1)}
      />
      <div className='sunE-container-box giaotrinh filter flex-column'>
        {!isLoading || listData?.length > 0 ? (
          <>
            {!isLoadingListOnlineClass && <div
              className='sunE-giaotrinh-resuft-filter box-shadow scrollbar-custom flex-1 ql-bt-dg'
              ref={refsScroll}
              onScroll={handleScroll}
            >
              {listData.map((data, i) => {
                return (
                  <div className='sunE-giaotrinh-item flex-m rel' key={i}>
                    <Link
                      to={
                        "/" +
                        authentication.role +
                        "/more/exercise/" +
                        data.exercise_id +
                        (data.skill === "exam" ? "?skill=exam" : "") +
                        (!isEmpty(fromPage)
                          ? data.skill === "exam"
                            ? `$&page=${fromPage}`
                            : `?page=${fromPage}`
                          : "") +
                        (!isEmpty(data?.curriculum_id)
                          ? `&curriculum_id=${data?.curriculum_id}`
                          : "") +
                        (!isEmpty(data?.unit_id)
                          ? `&unit_id=${data?.unit_id}`
                          : "")
                      }
                      className='img'
                    >
                      <div className='img'>
                        <img
                          src={
                            "/assets/images/teacher/giaobai/" +
                            data.skill?.toLocaleLowerCase() +
                            ".png"
                          }
                          alt={data.skill}
                        />
                      </div>
                    </Link>
                    <Link
                      to={
                        "/" +
                        authentication.role +
                        "/more/exercise/" +
                        data.exercise_id +
                        (data.skill === "exam" ? "?skill=exam" : "") +
                        (!isEmpty(fromPage)
                          ? data.skill === "exam"
                            ? `&page=${fromPage}`
                            : `?page=${fromPage}`
                          : "") +
                        (!isEmpty(data?.curriculum_id)
                          ? `&curriculum_id=${data?.curriculum_id}`
                          : "") +
                        (!isEmpty(data?.unit_id)
                          ? `&unit_id=${data?.unit_id}`
                          : "")
                      }
                      className=' rel flex-1'
                    >
                      <div className='content rel flex-1' style={{paddingTop: 2.6}}>
                        <h2 className='title black'>
                          {data.level && (
                            <span className={data.level}>
                              {data.level === "normal" ? "medium" : data.level}
                            </span>
                          )}{" "}
                          {data.exercise_topic}
                        </h2>
                        <h3 className='desc'>{data.exercise_name}</h3>
                        <p>{data.curriculum_name}</p>
                        <p>{`${data?.unit ? `Unit ${data.unit} - ` : ''}${data?.unit_name ? data.unit_name : ''}`}</p>
                        {data?.number_assign > 0 && (
                          <span className='normal'>{`${data?.number_assign} lớp được giao`}</span>
                        )}
                      </div>
                    </Link>
                    <Wish
                      lesson_id={data.exercise_id}
                      wish={data.is_in_wishlist}
                      lesson_type={data.skill || data.exercise_type}
                      curriculum_id={data.curriculum_id}
                      unit_id={data?.unit_id}
                    />
                  </div>
                );
              })}
            </div>}
          </>
        ) : (
          !isLoading && !isLoadingListOnlineClass &&
          (isNotUseFilter ? (
            listOnlineClass?.length <= 0 ? (
              <div className='text-center' style={{ fontSize: 18 }}>
                <img src='/assets/images/empty_excercise.png' />
                <br />
                Bạn chưa có lớp học online nào.
                <br /> Hãy vào{" "}
                <strong className='__semibold' style={{ fontSize: 18 }}>
                  "Quản lý lớp"
                </strong>{" "}
                để tạo lớp học và giao bài cho học sinh.
              </div>
            ) : (
              <div className='text-center' style={{ fontSize: 18 }}>
                <img src='/assets/images/empty_excercise.png' />
                <br />
                Bạn chưa giao bài nào.
                <br /> Hãy vào{" "}
                <strong className='__semibold' style={{ fontSize: 18 }}>
                  "Quản lý lớp"
                </strong>{" "}
                và giao bài cho học sinh.
              </div>
            )
          ) : (
            <span className='__text_empty_tutorial_guide'>
              Không tìm thấy kết quả phù hợp.
            </span>
          ))
        )}
      </div>
    </div>
  );
}

export { HomePageManageExerciseMore };
