import React, { useState, useEffect } from "react";
import { Header } from "../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { useSelector } from "react-redux";

function AboutPage() {
  const authentication = useSelector((state) => state.authentication);
  const cachedDeviceId = localStorage.getItem("device_id_" + authentication.id);
  const [dataIntro, setDataIntro] = useState("");

  const typeAbout = authentication.role == "student" ? "0" : "1";

  useEffect(() => {
    teacherService.getSettingDetail(cachedDeviceId, typeAbout).then((data) => {
      setDataIntro(data.data_setting.app_info.intro_app);
    });
  }, []);

  const introInfor = document.getElementById("intro_infor");
  if (introInfor && dataIntro) {
    introInfor.innerHTML = dataIntro;
  }

  return (
    <div className="sunE-right-container flex-column p0 scrollbar-custom">
      <Header
        title={
          authentication.role == "student"
            ? "Về Sunday English"
            : "Về Sunday Teacher"
        }
        isBack
      />
      <div class="sunE-container-box-UI setting flex-1">
        <div class="w100">
          <img
            src="/assets/images/setting/bg_gioithieu.png"
            alt="bg_gioithieu"
          />
        </div>
        <div class="gioithieu-info" id="intro_infor"></div>
      </div>
    </div>
  );
}

export { AboutPage };
