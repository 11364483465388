import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../_actions";
import { useParams, Link, useHistory } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { userConstants } from "../../../_constants";
import { scheduleService, teacherService } from "../../../_services";
import { isEmpty } from "lodash";
import moment from "moment";
import { history } from "../../../_helpers";

var intervalIdRefreshData = null;
var TIME_REQUEST = 60 * 1000; // 60s = 1 minutes
function DetailPageMessage() {
  const { id } = useParams();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const tab = params.get("active");
  const page = params.get("page");
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tab);

  // console.log(page)

  // const dataMessage = useSelector(state => state.messages.data_message);
  const [dataMessage, setDataMessage] = useState({});
  useEffect(() => {
    if (activeTab === "personal") {
      teacherService.getInboxInfo(id).then((data) => {
        setDataMessage(data);
      });
    } else {
      teacherService.getSystemNotification(id).then((data) => {
        setDataMessage(data);
      });
    }
  }, [activeTab]);
  const authentication = useSelector((state) => state.authentication);

  const [detailMessage, setDetailMessage] = useState({});

  const totalMsg = useSelector((state) => state.messages.total_msg);

  const [typeMessage, setTypeMessage] = useState(tab);
  const [messIndex, setMessIndex] = useState("");
  const [roomId, setRoomId] = useState("");
  const [activeIndex, setActiveIndex] = useState("0");
  const [numberMsg, setNumberMsg] = useState({
    number_msg_new: "0",
    number_system_new: "0",
  });

  const dataMess =
    !isEmpty(dataMessage) && messIndex >= 0 ? dataMessage.data[messIndex] : "";

  useEffect(() => {
    dispatch(userActions.getPrivateMessages());
  }, []);

  useEffect(() => {
    clearInterval(intervalIdRefreshData);
    intervalIdRefreshData = setInterval(() => {
      refreshDataMessage();
    }, TIME_REQUEST);

    return () => {
      clearInterval(intervalIdRefreshData);
    };
  }, [typeMessage, roomId]);

  function refreshDataMessage() {
    if (roomId > 0) {
      teacherService.getDetailInbox(roomId).then((data) => {
        setDetailMessage(data);
        executeScroll();
      });
    }

    teacherService.getInboxInfo(id).then((data) => {
      if (typeMessage === "personal") {
        setDataMessage(data);
      }
      setNumberMsg({
        number_msg_new: data.number_msg_new,
        number_system_new: data.number_system_new,
      });
    });

    if (typeMessage === "system") {
      teacherService.getSystemNotification(id).then((data) => {
        setDataMessage(data);
      });
    }
  }

  useEffect(() => {
    if (roomId > 0) {
      teacherService.getDetailInbox(roomId).then((data) => {
        setDetailMessage(data);
        executeScroll();
      });
    }
  }, [roomId]);

  useEffect(() => {
    teacherService.getInboxInfo(id).then((data) => {
      setNumberMsg({
        number_msg_new: data.number_msg_new,
        number_system_new: data.number_system_new,
      });
    });
  }, [typeMessage]);

  const [inputs, setInputs] = useState({
    room_id: roomId,
    to_user_id: "",
    subject: "",
    content: "",
    reply_for_id: "",
    class_id: id,
  });

  const { content } = inputs;

  function changeType(type) {
    setActiveIndex();
    dispatch({
      type: userConstants.RESET_CLASS_MESSAGE,
    });
    setTypeMessage(type);
    if (type === "personal") {
      history.push({
        pathname: history.location.pathname,
        search: `?active=personal&page=${page}`,
      });
      setActiveTab("personal");
      setDataMessage({});
      teacherService.getInboxInfo(id).then((data) => {
        setDataMessage(data);
      });
      setMessIndex("");
    } else {
      setActiveTab("system");
      history.push({
        pathname: history.location.pathname,
        search: `?active=system&page=${page}`,
      });
      setDataMessage({});
      teacherService.getSystemNotification(id).then((data) => {
        setDataMessage(data);
      });
      setRoomId("");
    }
    setDetailMessage({});
  }

  // Handle Click Personal
  function handleClickPersonal(
    e,
    room_id,
    latest_msg_id,
    index,
    latest_msg_status
  ) {
    e.preventDefault();
    setRoomId(room_id);
    setActiveIndex(room_id);
    setInputs((inputs) => ({ ...inputs, room_id: room_id }));

    dispatch(
      userActions.getDetailMessage(room_id, latest_msg_id, latest_msg_status)
    );
    teacherService.getInboxInfo(id).then((data) => {
      setDataMessage(data);
      setNumberMsg({
        number_msg_new: data.number_msg_new,
        number_system_new: data.number_system_new,
      });
    });
    if (roomId > 0) {
      setDetailMessage("");
      teacherService.getDetailInbox(room_id).then((data) => {
        setDetailMessage(data);
        executeScroll();
      });
    }
  }

  function handleClickSystem(e, mess_id, message) {
    e.preventDefault();
    setMessIndex(mess_id);
    setActiveIndex(mess_id);
    dispatch(userActions.getSystemNotification(id));
    teacherService.getSystemNotification(id).then((data) => {
      setDataMessage(data);
    });
    scheduleService.markSystemSeen(message.msg_id).then((res) => {
      teacherService.getInboxInfo(id).then((data) => {
        setNumberMsg({
          number_msg_new: data.number_msg_new,
          number_system_new: data.number_system_new,
        });
      });
    });
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (validateParam()) {
      dispatch(userActions.sendMessage(inputs, roomId));
    }
    dispatch(userActions.getDetailMessage(roomId));
    setInputs((inputs) => ({ ...inputs, content: "" }));

    teacherService.getDetailInbox(roomId).then((data) => {
      setDetailMessage(data);
      executeScroll();
      if (typeMessage === "personal") {
        teacherService.getInboxInfo(id).then((data) => {
          setDataMessage(data);
        });
      }
    });
  }

  function validateParam() {
    return content ? true : false;
  }

  function diffTime(startTimeString) {
    const start = moment(startTimeString).toDate().getTime();
    const end = moment().toDate().getTime();
    const timespan = end - start;
    const duration = moment(timespan);
    return millisToMinutesAndSeconds(duration);
  }
  function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    // var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  function handleKeyPress(event) {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      handleSubmit(event);
    }
  }

  const myRef = useRef(null);

  const executeScroll = () => myRef.current?.scrollIntoView();

  const goBackPageListChat = () => {
    let path = "";
    if (page === "homepage") {
      path =
        authentication.role === userConstants.ROLE_TEACHER
          ? "/teacher"
          : "/student";
    } else {
      path =
        authentication.role === userConstants.ROLE_TEACHER
          ? "/teacher/message"
          : "/student/more";
    }

    history.push(path);
  };

  const renderUserNameSendLastMessage = (data) => {
    if (data.last_user_send_id && !data.last_user_send_name) {
      return "Người dùng đã bị xóa: ";
    } else if (data.last_user_send_id === 0) {
      return "Tin nhắn hệ thống";
    } else if (data.last_user_send_id === null) {
      return null;
    } else if (
      data.user_send_id !== authentication.id &&
      data.last_user_send_id !== authentication.id
    ) {
      return data.last_user_send_name + ": ";
    } else if (
      data.user_send_id === authentication.id ||
      data.last_user_send_id === authentication.id
    ) {
      return "Bạn: ";
    }
  };

  return (
    <div className="sunE-container flex flex">
      <div className="sunE-right-container rel">
        <Header
          title="Tin nhắn"
          component={
            <Link
              to={
                "/" +
                authentication.role +
                (authentication.role === userConstants.ROLE_TEACHER
                  ? "/message/" + id + "/create"
                  : "/more/message/create")
              }
              className="mr-10"
            >
              <div className="form-sunE-button btn-create-diemdanh rel">
                <img
                  src="/assets/images/teacher/lichlamviec/ico_add_small.png"
                  alt="ico_add"
                />
                <button className="btn-line-blue">Gửi tin nhắn</button>
              </div>
            </Link>
          }
          isBack
          clickBack={goBackPageListChat}
        />
        <div className="sunE-content tinnhan">
          <div className="flex-m">
            <div className="flex-1">
              <div className="tn-menu-gr flex-m">
                <div
                  className={
                    "tn-menu-item flex-1 cn" +
                    (typeMessage === "personal" ? " active" : "")
                  }
                  onClick={() => changeType("personal")}
                >
                  <div className="box-btn rel">
                    <img
                      src="/assets/images/tinnhan/ico_message.png"
                      alt="ico_message"
                    />
                    {numberMsg.number_msg_new > 0 && isEmpty(totalMsg) && (
                      <label className="reng">{numberMsg.number_msg_new}</label>
                    )}
                    {!isEmpty(totalMsg) && totalMsg.number_msg_new > 0 && (
                      <label className="reng">{totalMsg.number_msg_new}</label>
                    )}
                  </div>
                  <p>CÁ NHÂN</p>
                </div>
                <div
                  className={
                    "tn-menu-item flex-1 ht" +
                    (typeMessage === "system" ? " active" : "")
                  }
                  onClick={() => changeType("system")}
                >
                  <div className="box-btn rel">
                    <img
                      src="/assets/images/tinnhan/ico_ring.png"
                      alt="ico_ring"
                    />
                    {numberMsg.number_system_new > 0 && isEmpty(totalMsg) && (
                      <label className="reng">
                        {numberMsg.number_system_new}
                      </label>
                    )}
                    {!isEmpty(totalMsg) && numberMsg.number_system_new > 0 && (
                      <label className="reng">
                        {numberMsg.number_system_new}
                      </label>
                    )}
                  </div>
                  <p>HỆ THỐNG</p>
                </div>
              </div>
              <div className="list-tinnhan scrollbar-custom">
                {!isEmpty(dataMessage.data) &&
                  dataMessage.data.map((data, i) => {
                    if (typeMessage === "personal") {
                      let className = "";
                      if (
                        activeIndex === (data.id || data.room_id) &&
                        data.seen_latest_msg_status === "1"
                      ) {
                        className = "msg-active msg-seen";
                      } else if (data.seen_latest_msg_status === "1") {
                        className = "msg-seen";
                      } else if (activeIndex === (data.id || data.room_id)) {
                        className = "msg-active msg-seen";
                      }
                      if (!isEmpty(data.room_name)) {
                        return (
                          <div
                            className={
                              "tinnhan-item rel box-shadow pointer_cursor " +
                              className
                            }
                            key={i}
                            onClick={(e) =>
                              handleClickPersonal(
                                e,
                                data.id || data.room_id,
                                data.latest_msg_id,
                                i,
                                data.seen_latest_msg_status
                              )
                            }
                          >
                            <div className="avt">
                              <img
                                src={
                                  dataMessage
                                    ? dataMessage.base_url + data.avatar
                                    : ""
                                }
                                alt="student"
                              />
                            </div>
                            <div className="flex-m" style={{gap: '16px'}}>
                              {(data?.user_role === userConstants.ROLE_PARENT && !!data?.list_child_name?.length) ? (
                                <h2 style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', maxWidth: '280px'}}>
                                  <h2 className="flex-1 one-line" style={{maxWidth: '280px', marginBottom: 0}}>{data?.room_name || ''}</h2>
                                  <p className="flex-1 one-line" style={{fontSize: '13px', fontStyle: 'italic', opacity: 0.8}}>{`Phụ huynh: ` + data?.list_child_name?.join(', ') }</p>
                                </h2>
                              ) : (
                                <h2 className="flex-1 one-line" style={{maxWidth: '280px'}}>{data?.room_name || ''}</h2>
                              )}
                              <div className="d-flex">
                                <span className="date-time" style={{textWrap: 'nowrap'}}>
                                  {data.latest_msg_time
                                    ? moment(data.latest_msg_time).format(
                                        "HH:mm, DD/MM/YYYY"
                                      )
                                    : "Chưa có tin nhắn nào"}
                                </span>
                                <div className="dot-active"></div>
                              </div>
                            </div>
                            {
                              <p className="mes-info">
                                {renderUserNameSendLastMessage(data) +
                                  data.latest_msg_content || ""}
                              </p>
                            }
                          </div>
                        );
                      } else {
                        return false;
                      }
                    } else {
                      let className = "";
                      if (
                        activeIndex === i &&
                        data.seen_latest_msg_status === "1"
                      ) {
                        className = "msg-active msg-seen";
                      } else if (data.seen_latest_msg_status === "1") {
                        className = "msg-seen";
                      } else if (activeIndex === i) {
                        className = "msg-active";
                      }
                      return (
                        <div
                          className={
                            "tinnhan-item rel box-shadow pointer_cursor " +
                            className
                          }
                          key={i}
                          onClick={(e) => handleClickSystem(e, i, data)}
                        >
                          <div className="avt auto center-flex bd-ht">
                            <img
                              src="/assets/images/tinnhan/ico_tb.png"
                              alt="student"
                            />
                          </div>
                          <div className="flex-m">
                            <h2 className="flex-1 one-line">
                              {data.title || ""}
                            </h2>
                            <span className="date-time">
                              {moment(data.send_time).format(
                                "HH:mm, DD/MM/YYYY"
                              ) || ""}
                            </span>
                          </div>
                          <p className="mes-info">{data.msg || ""}.</p>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            {!isEmpty(dataMessage) && dataMessage.data.length === 0 && (
              <div
                className="__horizontal_center flex-self-center"
                style={{ width: "96%", paddingTop: 30 }}
              >
                <img src="/assets/images/empty_messagebox.png" alt="" />
                {typeMessage === "personal" ? (
                  <div className="__text_empty __fontnormal">
                    Bạn chưa có tin nhắn mới nào
                    <br />
                    Ấn{" "}
                    <strong className="__semibold" style={{ color: "#707070" }}>
                      "Gửi tin nhắn"
                    </strong>{" "}
                    để tạo tin nhắn mới.
                  </div>
                ) : (
                  <div className="__text_empty">
                    Bạn chưa có thông báo mới nào.
                  </div>
                )}
              </div>
            )}
            {!isEmpty(detailMessage) && (
              <div className={"flex-1 mes-content"}>
                {!isEmpty(detailMessage) && (
                  <div className="no-mes-bg">
                    <img
                      src="/assets/images/tinnhan/bg_message.png"
                      alt="bg_message"
                    />
                  </div>
                )}
                {!isEmpty(detailMessage) && (
                  <div className="mex-box pad20 flex-column cus-hei">
                    <div className="scrollbar-custom mex-box-height flex-1">
                      {!isEmpty(detailMessage) &&
                        detailMessage.data.map((data, i) => {
                          return (
                            <div
                              className={
                                "mex-info-box rel" +
                                (data.user_send_id === authentication.id
                                  ? ""
                                  : " isMe")
                              }
                              key={i}
                            >
                              <span className="send-name one-line">
                                {data.user_send_id === authentication.id
                                  ? ""
                                  : data.fullname}
                              </span>
                              {data.user_send_id !== authentication.id && (
                                <div className="mes-avt">
                                  <img
                                    src={
                                      data
                                        ? dataMessage.base_url +
                                          (data.user_avatar || data.avatar)
                                        : ""
                                    }
                                    alt="avatar"
                                    className="mes-avt-img"
                                  />
                                </div>
                              )}
                              <p>{data.msg}</p>
                              {diffTime(data.send_time) === 0 && (
                                <span className="time-send">Vừa xong</span>
                              )}
                              {diffTime(data.send_time) > 59 && (
                                <span className="time-send">
                                  {moment(data.send_time).format(
                                    "HH:mm, DD/MM/YYYY"
                                  )}
                                </span>
                              )}
                              {diffTime(data.send_time) > 0 &&
                                diffTime(data.send_time) <= 59 && (
                                  <span className="time-send">
                                    {diffTime(data.send_time) + " phút trước"}
                                  </span>
                                )}
                            </div>
                          );
                        })}
                      <div ref={myRef}></div>
                    </div>
                    <form onSubmit={handleSubmit} noValidate={true}>
                      <div className="flex-1">
                        <div className="sunE-input-gr flex-m search-gr-p">
                          <div className="flex-1">
                            <textarea
                              name="content"
                              onChange={handleChange}
                              placeholder="Soạn nội dung..."
                              type="text"
                              value={content}
                              className="pr45 mes-box-send"
                              autoComplete="off"
                              onKeyPress={handleKeyPress}
                            ></textarea>
                          </div>
                          <div className="box-btn-send center-flex">
                            <button
                              className={
                                "send-mes" +
                                (!validateParam() ? " btn-disable" : "")
                              }
                            >
                              <img
                                src="/assets/images/tinnhan/ico_send_mes.png"
                                alt="send message"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            )}

            {isEmpty(detailMessage) && (
              <div className="flex-1 mes-content">
                <div className="no-mes-bg">
                  <img
                    src="/assets/images/tinnhan/bg_message.png"
                    alt="bg_message"
                  />
                </div>
                {dataMess && (
                  <div className="mex-box">
                    {isEmpty(dataMess.file_link) && (
                      <img
                        src="/assets/images/tinnhan/bg_update.png"
                        alt="bg_update"
                        className="bg_update"
                      />
                    )}
                    {isEmpty(dataMess.file_link) && (
                      <div className="mex-info">
                        <h2 className="bold text-center">{dataMess.title}</h2>
                        <p>{dataMess.msg}</p>
                      </div>
                    )}

                    {dataMess.file_link && (
                      <div className="mex-info">
                        <h2 className="bold text-center">{dataMess.title}</h2>
                        <p>{dataMess.msg}</p>
                        <div className="form-sunE-button btn-view-detail">
                          <button className="btn-line-blue">
                            Xem chi tiết
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { DetailPageMessage };
