import { useEffect, useState } from "react";
import API from "../../_apis/APIConstants";
import APIBase from "../../_base/APIBase";
import { max } from "lodash";
import { configConstants } from "../../_constants";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img
        src='/assets/images/arrow_right_circle.png'
        alt='ico_right_white'
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src='/assets/images/arrow_left_circle.png' alt='ico_left_white' />
    </div>
  );
}

export const feePageLogic = (props) => {
  const [listPackages, setListPackages] = useState([]);
  const [sliderSettings, setSliderSetting] = useState({
    dots: false,
    infinite: false,
    slidesToShow: document.body.offsetWidth >= 1200 ? 3.5 : document.body.offsetWidth >= 900 ? 2.5 : 1.5,
    slidesToScroll: 1,
    speed: 600,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  });

  // Get List Packages
  const getListPackages = async () => {
    try {
      let urlApi = `${API.baseURL}${API.get_packages}`;

      const result = await APIBase.apiBaseCaller("GET", urlApi);
      if (result?.status) {
        const dataPackageAllLife = result.data.package_list.filter(
          (item) => !item.duration
        );

        const dataPackage = result.data.package_list.filter(
          (item) => item.duration
        );
        dataPackage.sort((a, b) => a.duration - b.duration);

        // setListPackages([...dataPackage, ...dataPackageAllLife]);
        setListPackages(result?.data?.package_list)
        // const info
        // setListPackages(
        //   result.data.package_list.sort((a, b) => {
        //     let prevDuration = a.duration;
        //     let nextDuration = b.duration;
        //     if (!prevDuration) {
        //       prevDuration = Number.MAX_VALUE;
        //       console.log(prevDuration);
        //     }
        //     return prevDuration - nextDuration;
        //   })
        // );

        // Scroll To View
        let hash = window.location.hash;
        if (hash) {
          var ids = hash.split("#");
          for (var i = 1; i < ids.length; i++) {
            var element = document.getElementById(ids[i]);
            // console.log(element);
            if (element) {
              element.scrollIntoView({ behavior: "instant" });
            }
          }
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    getListPackages();
    // window.scrollTo({ top: 0 });
    window.addEventListener('resize', function(event){
      if (document.body.offsetWidth >= 1200) {
        setSliderSetting({
          ...sliderSettings,
          slidesToShow: 3.5
        });
      } else if (document.body.offsetWidth >= 900) {
        setSliderSetting({
          ...sliderSettings,
          slidesToShow: 2.5
        });
      } else {
        setSliderSetting({
          ...sliderSettings,
          slidesToShow: 1.5
        });
      }
    });
  }, []);

  // Handle Navigate to payment service
  const handleNavigatePayment = (item) => {
    window.open(
      `${configConstants.AUTH_URL}license/order?package=${item.id}&type=student`
    );
  };

  // Scroll To View
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  //
  const [dataRule4, setDataRule4] = useState({
    nameRule: "CHÍNH SÁCH ĐỔI, TRẢ HÀNG VÀ HOÀN TIỀN",
    rule_id: 4,
    data: [
      {
        id: 1,
        content:
          "- Khi khách hàng đăng ký mua một khóa học tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua và sử dụng. Sunday English chịu trách nhiệm đảm bảo các cam kết dịch vụ đã nêu ra và sẽ làm hết khả năng để khắc phục trong những trường hợp bất khả kháng.",
      },
      {
        id: 2,
        content:
          "- Sunday English không có trách nhiệm hoàn trả học phí mà khách hàng đã đăng ký.",
      },
    ],
  });

  // Data policy Payment
  const dataPolicyPayment = {
    nameRule: "HƯỚNG DẪN THANH TOÁN",

    data1: {
      title:
        "Sau khi chọn gói học muốn mua tại mục “Học phí”, khách hàng tiến hành thanh toán bằng cách chọn một trong các hình thức thanh toán sau:",
      data: [
        {
          id: 1,
          title: "1. Thanh toán khi nhận hàng (COD) ",
          list_1: [
            {
              id: 1,
              content:
                "Khách hàng có thể đặt hàng qua hotline 024 6281 3888 hoặc qua website. Sau đó, nhân viên chăm sóc khách hàng của chúng tôi sẽ gọi điện xác nhận với quý khách về gói sản phẩm và phương thức vận chuyển. ",
            },
            {
              id: 2,
              content:
                "Khi nhận được nhận được hàng từ đơn vị vận chuyển, quý khách có trách nhiệm thanh toán đầy đủ toàn bộ giá trị đơn hàng cho nhân viên giao hàng. ",
            },
          ],
        },
        {
          id: 2,
          title: "2. Chuyển khoản qua tài khoản ngân hàng ",
          list_1: [
            {
              id: 1,
              content:
                "Quý khách vui lòng chuyển khoản theo cú pháp sau: <ĐK>[Họ và tên][Số điện thoại][Gói học cần mua]",
            },
            {
              id: 2,
              content: "Chủ tài khoản: Công ty Cổ phần Công nghệ Giáo dục GK",
            },
            {
              id: 3,
              content: "Số tài khoản:",
            },
            {
              id: 4,
              content: "Ngân hàng: ",
            },
          ],
        },
      ],
    },

    data2: {
      title: "Lưu ý: ",
      data: [
        {
          id: 1,
          content:
            "Sau khi thanh toán thành công, quý khách vui lòng chụp lại màn hình thanh toán và liên hệ tổng đài 024 6281 3888 để được xác nhận thanh toán. ",
        },
        {
          id: 1,
          content:
            "Bộ sản phẩm sẽ được chuyển đến địa chỉ nhận hàng trong thời gian sớm nhất, chậm nhất 5 ngày kể từ thời điểm hoàn tất giao dịch. ",
        },
      ],
    },
  };

  return { listPackages, dataRule4, dataPolicyPayment, handleNavigatePayment, sliderSettings };
};
