import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "./../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { ChartDoughnut } from "./../../../_components/Chart";

function HomePageReport() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const reports = useSelector((state) => state.classes.reports);
  const [isLoading, setLoading] = useState(false);

  const [students, setStudents] = useState({
    data: [],
  });

    const [currentWeek, setCurrentWeek] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));

    useEffect(() => {
        setLoading(true)
        teacherService.getStudentOfClass(id, 999).then(
            (students) => {
                setStudents(students);
                setLoading(false)
            }
        );
        dispatch(teacherActions.getReportClass(id, moment(currentWeek).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment(currentWeek).endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')));
    }, []);

  let total_overview_score =
    reports.overview_score.A +
    reports.overview_score.B +
    reports.overview_score.C +
    reports.overview_score.D;
  let report_data_overview_score = {
    datasets: [
      total_overview_score !== 0
        ? {
            data: [
              reports.overview_score.A,
              reports.overview_score.B,
              reports.overview_score.C,
              reports.overview_score.D,
            ],
            backgroundColor: ["#00AEEF", "#00CC7E", "#FBB040", "#BE1E2D"],
          }
        : {
            data: [0, 0, 0, 0],
            backgroundColor: ["#00AEEF", "#00CC7E", "#FBB040", "#BE1E2D"],
          },
    ],
    labels: [
      "Giỏi: " +
        reports.overview_score.A +
        " (" +
        (total_overview_score !== 0
          ? (reports.overview_score.A / total_overview_score) * 100
          : 0
        ).toFixed(0) +
        "%)",
      "Khá: " +
        reports.overview_score.B +
        " (" +
        (total_overview_score !== 0
          ? (reports.overview_score.B / total_overview_score) * 100
          : 0
        ).toFixed(0) +
        "%)",
      "Trung Bình: " +
        reports.overview_score.C +
        " (" +
        (total_overview_score !== 0
          ? (reports.overview_score.C / total_overview_score) * 100
          : 0
        ).toFixed(0) +
        "%)",
      "Dưới trung bình: " +
        reports.overview_score.D +
        " (" +
        (total_overview_score !== 0
          ? (reports.overview_score.D / total_overview_score) * 100
          : 0
        ).toFixed(0) +
        "%)",
    ],
  };

    let report_data_overview_exercise = {
        datasets: [reports.overview_exercise.total !== 0 ? {
            data: [(reports.overview_exercise.total - reports.overview_exercise.total_not_done), reports.overview_exercise.total_not_done],
            backgroundColor: [
                '#8DC63F',
                '#D0D2D3',
            ]
        } : {
            data: [0,0],
            backgroundColor: [
                '#8DC63F',
                '#D0D2D3',
            ]
        }],
        labels: [
            (reports.overview_exercise.total - reports.overview_exercise.total_not_done) + ' Học sinh hoàn thành (' + (reports.overview_exercise.total !== 0 ? ((reports.overview_exercise.total - reports.overview_exercise.total_not_done) / reports.overview_exercise.total * 100) : 0).toFixed(0) + '%) \n', 
            reports.overview_exercise.total_not_done + ' Học sinh chưa hoàn thành (' + (reports.overview_exercise.total !== 0 ? (reports.overview_exercise.total_not_done / reports.overview_exercise.total * 100) : 0).toFixed(0) + '%)' ]
    };

    function changeWeek(value) {
        let week = moment(currentWeek, "YYYY-MM-DD HH:mm:ss").add('week', value)
        if(moment() >= moment(week).startOf('isoWeek')){
            dispatch(teacherActions.getReportClass(id, moment(week).startOf('isoWeek').format("YYYY-MM-DD HH:mm:ss"), moment(week).endOf('isoWeek').format("YYYY-MM-DD HH:mm:ss")));
            setCurrentWeek(week);
        }
    }

    let week = moment(currentWeek, "YYYY-MM-DD HH:mm:ss").add('week', 1)
    let isDisableNextWeek = (moment() < moment(week).startOf('isoWeek'))

  const cirleReportEmptyOverview = () => (
    <svg
      style={{ marginTop: -20, marginLeft: 169 }}
      className="chart-circle-report-class shadow-chart-circle"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <ellipse
          ry={55}
          rx={55}
          cy={80}
          cx={80}
          strokeWidth={35}
          stroke={"#fff"}
          fill="transparent"
        />
      </g>
    </svg>
  );

  const cirleReportEmptyExcercise = () => (
    <svg
      style={{ marginTop: -30, marginLeft: 169 }}
      className="chart-circle-report-class shadow-chart-circle"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <ellipse
          ry={55}
          rx={55}
          cy={80}
          cx={80}
          strokeWidth={35}
          stroke={"#fff"}
          fill="transparent"
        />
      </g>
    </svg>
  );

    if (isLoading) {
        return null
    }
    
    return (
        <div className="sunE-right-container flex-column scrollbar-custom">
            <Header title={"Báo cáo học tập"} isBack />
            <div className="center-flex">
                <div className="check-date-time-week flex-m">
                    <div className="center-flex">
                        <img src="/assets/images/teacher/ico_left_ca.png" alt="ico_left_ca" onClick={() => changeWeek(-1)}/>
                    </div>
                    <div className="flex-1 text-center">
                        <p>{moment(currentWeek).startOf('isoWeek').format('DD/MM/YYYY')} {">"}{">"} {moment(currentWeek).endOf('isoWeek').format('DD/MM/YYYY')}</p>
                    </div>
                    <div className="center-flex">
                        {!isDisableNextWeek && <img src="/assets/images/teacher/ico_right_ca.png" alt="ico_right_ca" onClick={() => changeWeek(1)}/>}
                    </div>
                </div>
            </div>
            {reports.total_student ?
                <div className="flex-m sunE-content bcht flex-1">
                    <Fragment>
                        <div className="flex-1 list-chart-gr width_chart_report_student flex_center_chart_report_student">
                            <h2 className="bold sunE-title-medium flex_self_chart_report_student">Tổng quan lớp</h2>
                            <p className="text-center tong-hs">Tổng số: <span className="text-title-blue">{reports.total_student} Học sinh</span></p>
                            <h3 className="sunE-title-medium-p flex_self_chart_report_student">Tổng quan học lực</h3>
                            <div className="box-chart rel">
                                <div style={{width: 500, position: 'relative', marginTop: -20}}>
                                    {total_overview_score === 0 && cirleReportEmptyOverview()}
                                    <ChartDoughnut report_data={report_data_overview_score} position='bottom' />
                                </div>
                                <div style={{width: 480, textAlign: 'center'}}>
                                    {/* <div style={{width: 160}}></div> */}
                                    <p className="flex-1">Điểm trung bình: <span className="blue-main f-24 bold">{Number.isInteger(Number.parseFloat(reports.avg)) ? reports.avg : Number.parseFloat(reports.avg).toFixed(2)}</span></p>
                                </div>
                            </div>
                            <h3 className="sunE-title-medium-p flex_self_chart_report_student">Mức độ hoàn thành tất cả bài được giao</h3>
                            <div className="box-chart rel">
                                <div style={{width: 500, position: 'relative', marginLeft: 0}}>
                                    {reports.overview_exercise.total === 0 && cirleReportEmptyExcercise()}
                                    <ChartDoughnut report_data={report_data_overview_exercise} position='bottom' />
                                </div>
                                <div style={{width: 480, textAlign: 'center'}}>
                                    {/* <div style={{width: 160}}></div> */}
                                    <p className="flex-1">Tổng số bài: <span className="blue-main f-24 bold">{reports.total_exercise}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="student-list-container width_report_detail_student">
                            <h2 className="bold sunE-title-medium">Chi tiết học sinh</h2>
                            <div className="box-shadow custom-list-student">
                                <div className="list-student">
                                    {students.data.map((data, i) => {
                                        return (
                                            <Link to={'/' + authentication.role + '/class/view/' + id + '/report/' + data.id + "?week=" + moment(currentWeek).endOf('isoWeek').format('YYYY-MM-DD')} key={i}>
                                                <div className="item-student flex-m">
                                                    <span className="stt">{i + 1}</span>
                                                    <div className="item-student-img">
                                                        <img src={students.base_url + '/' + data.avatar} alt="avatar" />
                                                    </div>
                                                    <div className="flex-1 item-student-name center-flex-hz">
                                                        <p className="two-line">{data.fullname}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
                :

                <Fragment>
                    <div className="sunE-no-class text-center flex-1">
                        <img src="/assets/images/img_no_class_big.png" alt="img_no_class_big" />
                        <p className="text-light">Bạn chưa có học sinh, hãy thêm học sinh để xem báo cáo học tập.</p>
                    </div>
                    <Link to={'/' + authentication.role + '/class/view/' + id + '/student/add'}>
                        <div className="form-sunE-button text-right btn-mr-custom">
                            <button className="btn-line-blue">Thêm học sinh</button>
                        </div>
                    </Link>
                </Fragment>
            }
        </div>
    );
}

export { HomePageReport };
