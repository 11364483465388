import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import './headerTS.style.scss'

function HeaderTS() {
  const authentication = useSelector((state) => state.authentication);
  return (
    <div className="logo header-ts">
      <Link to={`/`}>
        <img 
          src={"/assets/images/logo_TS.png"} 
          alt={"Logo"} 
        />
        <span className="flex-1 d-flex align-items-center">Cô Đặng Thu Hiền</span>
      </Link>
    </div>
  );
}

export { HeaderTS };
