import React, { useEffect, useRef, useState } from "react";
import { isJsonString } from "../../../_helpers/utils";
import { configConstants } from "../../../_constants";
import { AudioV2 } from "../../../_components/Audio";

export default function ResultQuestionSpeaking(props) {
  const {dataQuestion, skill, question_type, indexQuestion, answer, isReview} = props;
  const finalAnswer = isJsonString(answer) ? JSON.parse(answer) : answer;
  const initPlayer = {}
  dataQuestion?.map(ques => {
    ques?.list_option?.map(item => {
      initPlayer[`dialogA-${item.id}`] = false;
      initPlayer[`dialogB-${item.id}`] = false;
    })
  })
  const [player, setPlayer] = useState(initPlayer)

  const stopAllPlayer = () => {
    const newPlayer = {...player}
    Object.keys(player).forEach(key => {
      newPlayer[key] = false
    })
    setPlayer(newPlayer)
  }

  const renderOption = (item, iOption, iQues) => {
    const percentScore = Math.round(finalAnswer?.[iQues]?.score * 10000) / 100
    if((skill == 'speaking' && question_type == 2)) {
      const user_audio = finalAnswer?.[iQues]?.audio_recorder_path;
      return (
        <div>
        {item?.option_text && <DialogPlayer 
          data={JSON.parse(item.option_text)?.[0]} 
          isPlay={player[`dialogA-${item.id}`]} 
          onSetPlayer={(isPlay) => setPlayer(prev => ({...prev, [`dialogA-${item.id}`]: isPlay}))}
          onStopAllPlayer={stopAllPlayer}
          id={`dialogA-${item.id}`}
        />}
        <div style={{marginTop: 4}}>
          {!!item?.match_option_text?.length && 
            <DialogPlayer 
              data={item.match_option_text[0]} 
              audio={user_audio}
              isAns={true} 
              isPlay={player[`dialogB-${item.id}`]}
              onSetPlayer={(isPlay) => setPlayer(prev => ({...prev, [`dialogB-${item.id}`]: isPlay}))}
              onStopAllPlayer={stopAllPlayer}
              id={`dialogB-${item.id}`}
            />
          }
        </div>
        {!isReview && <p style={{
          marginLeft: 80, 
          flex: 1,
          color: percentScore >= 50 ? '#62B440' : 'red'
        }}
        >
          {percentScore + '%'}
        </p>}
      </div>
      )
    }
    return (
      <div>
        <p style={{
          marginLeft: 8, 
          flex: 1,
          textAlign: 'justify',
          fontWeight: 'bold'
        }}
        >
          {item?.match_option_text?.[0]}
        </p>
        {!isReview && <p style={{
          marginLeft: 8, 
          flex: 1,
          color: percentScore >= 50 ? '#62B440' : 'red'
        }}
        >
          {percentScore + '%'}
        </p>}
      </div>
    )
  }

  useEffect(() => {
    // stop all audio when back question tab
    stopAllPlayer()
  }, [indexQuestion])

  return (
    <div style={{flex: 1, marginTop: 16, display: 'flex', flexDirection: 'column', gap: 32}}>
      {dataQuestion?.map((item, index) => {
        const audio = isReview ? 
        item?.list_option[0]?.content_question ? 
        JSON.parse(item?.list_option[0]?.content_question)?.content_question_audio : 
        null : 
        finalAnswer?.[index]?.audio_recorder_path ?
        (configConstants.BASE_URL + finalAnswer?.[index]?.audio_recorder_path) :
        null;
        return (
          <div key={index}>
            <p style={{fontWeight: 700, marginBottom: 4}}>{`Question ${index + 1}.`}</p>
            {audio && skill == 'pronunciation' && question_type == 1 && <div style={{display: 'block', margin: '8px 0 16px', width: '50%'}}>
                  <AudioV2 link={audio} />
              </div>
            }
            {item?.list_option?.map((option, iOption) => {
              return (
                <div>
                  {renderOption(option, iOption, index)}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

const DialogPlayer = ({data, isAns, isPlay, onSetPlayer, onStopAllPlayer, id, audio}) => {
  const AudioRecord = useRef();

  const setPlay = () => {
    const audio = document.getElementById(id)
    audio.currentTime = 0
    onStopAllPlayer()
    onSetPlayer(true)
  }

  const endAudio = () => {
    AudioRecord.current.seek(0);
    onSetPlayer(false)
  };

  useEffect(() => {
    const audio = document.getElementById(id)
    if (isPlay && audio) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlay])

  return (
    <div style={{flexDirection: 'row', display: 'flex'}}>
      <div style={{flexDirection: 'row', alignItems:'center', display: 'flex'}}>
        <div style={{width: 30, height: 30}}>
          {isAns && <img 
              src="/assets/images/ico_hover_answer.png"
              style={{
                width: 24,
                height: 20,  
              }}
          />}
        </div>
        <div style={{cursor: 'pointer'}} onClick={() => setPlay()}>
          <img 
              src="/assets/images/ico_ear.png"
              style={{
                width: 26,
                height: 26,  
                marginLeft: 8
              }}
          />
        </div>
      </div>
      <p style={{
        // fontSize: SmartScreenBase.smFontSize * 50,
        color: isAns ? '#62B440' : '#000000',
        // fontFamily:FontBase.MyriadPro_Regular,
        marginLeft: 8, 
        flex: 1,
        textAlign: 'justify',
        display: 'flex',
        gap: 4
      }}
      >
        <p style={{fontWeight: 'bold'}}>{`${isAns ? 'B' : 'A'}: `}</p>
        {data?.text}
      </p>
      {/* <Video
          source={{ uri: `${API.domain}${data?.audio}` }}
          ref={AudioRecord}
          paused={!isPlay}
          onEnd={endAudio}
      /> */}
      <audio id={id} loop={false} ref={AudioRecord}>
        <source src={!!audio ? `${configConstants.BASE_URL}${audio}` : `${configConstants.BASE_URL}${data?.audio}`} />
      </audio>
    </div>
  )
}