import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "../../_components/Admin/Header";
import { studentService } from "../../_services";
import { configConstants } from "../../_constants";
import LessonBase from "../../_base/LessonBase";
import DoTestExamModal from "./exam-test/DoTestExam";

const TABS = {
  TO_DO: "0",
  COMPLETED: "1",
};
const LIMIT = 999;

const MasterUnitPage = (props) => {
  const { class_id } = useParams();
  const tabDefault = localStorage.getItem("tabSelectedMasterunit");
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const [tabSelected, selectTab] = useState(tabDefault || TABS.TO_DO);
  const [dataImprove, setDataImprove] = useState({
    list_looking_back: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState();

  const deviceID = localStorage.getItem("device_id_commond");

  useEffect(() => {
    getListCurriculum(0);
  }, [tabSelected]);

  const getListCurriculum = (offset) => {
    setLoading(true);
    studentService
      .getListCurriculumMasterUnit(class_id, tabSelected, LIMIT, offset)
      .then((res) => {
        if (res) {
          setDataImprove(res);
        }
        setLoading(false);
      });
  };

  const handleVisibilityChange = () => {
    getListCurriculum(0);
  };

  useEffect(() => {
    window.addEventListener("pageshow", handleVisibilityChange);
    return () => {
      window.removeEventListener("pageshow", handleVisibilityChange);
    };
  }, []);

  const goToHomeWork = (data) => {
    // console.log(data, "===> 5");
    let typeUri = "";
    if (
      [
        "grammar",
        "listening",
        "pronunciation",
        "reading",
        "speaking",
        "writing",
        "vocabulary",
      ].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "lesson";
    } else if (["project"].includes(data?.exercise_type || data?.lesson_type)) {
      typeUri = "project";
    } else if (
      ["mini_test", "exam"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "exam";
    } else if (
      ["homework"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "homework";
    } else {
      typeUri = "lesson";
    }
    const url = `${
      configConstants.EXCERCISE_URL
    }index.php/example/lesson_demo?type=${typeUri}&id=${
      data?.lesson_id || data?.exercise_id
    }&deviceID=${deviceID}&token=${authentication?.jwt_token}`;
    // console.log(url, "===> 5");
    window.open(url);
  };

  const navigateToHistory = (item) => {
    if (parseInt(item.total_attempt) > 0) {
      let urlHistory =
        "/" +
        authentication.role +
        `/curriculum/` +
        class_id +
        `/skill/${"none"}/lesson/${item.type}/${
          item.id
        }/history?tab_selected=${tabSelected}`;
      history.push(urlHistory);
    }
  };

  // console.log(itemSelected);

  const handleCloseDoTest = () => {
    setItemSelected(null);
    getListCurriculum(0);
  };

  // Handle Go Back From Master Unit
  const handleGoBackMasterunit = () => {
    localStorage.removeItem("tabSelectedMasterunit");
    history.goBack();
  };

  return (
    <div className="sunE-right-container master-unit">
      <Header title={"Master Unit"} isBack clickBack={handleGoBackMasterunit} />
      <div style={{ width: "fit-content" }}>
        <div className="group-tab-content">
          <div
            className={
              "tab-item" + (tabSelected == TABS.TO_DO ? " active" : "")
            }
            onClick={() => {
              selectTab(TABS.TO_DO);
              localStorage.setItem("tabSelectedMasterunit", TABS.TO_DO);
            }}
          >
            <img
              src={`/assets/images/giaotrinh/icon_close${
                tabSelected == TABS.TO_DO ? "_active" : ""
              }.png`}
            />{" "}
            Chưa hoàn thành
          </div>
          <div
            className={
              "tab-item" + (tabSelected == TABS.COMPLETED ? " active" : "")
            }
            onClick={() => {
              selectTab(TABS.COMPLETED);
              localStorage.setItem("tabSelectedMasterunit", TABS.COMPLETED);
            }}
          >
            <img
              src={`/assets/images/giaotrinh/icon_check${
                tabSelected == TABS.COMPLETED ? "_active" : ""
              }.png`}
            />{" "}
            Đã hoàn thành
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className="listview-unit scrollbar-custom">
          {dataImprove.list_looking_back.map((item, index) => (
            <div className="item-data" key={index}>
              <div
                className="item-body origin-vertical pointer_cursor"
                onClick={() =>
                  //goToHomeWork(item)
                  {
                    if (item.lesson_type == "mini_test") {
                      setItemSelected(item);
                    } else {
                      return LessonBase._moveLesson(
                        LessonBase.Type.masterUnit,
                        authentication,
                        item
                      );
                    }
                  }
                }
              >
                <div className="horizontal-space-between">
                  {item.type === "improvement" && (
                    <span className="type-lesstion improvement">
                      Bài tập cải thiện
                    </span>
                  )}
                  {item.type === "user_looking_back" && (
                    <span className="type-lesstion user_looking_back">
                      Ôn luyện
                    </span>
                  )}
                  {item?.total_attempt > 0 &&
                    item.learning_result == "fail" && (
                      <span className="status-lesstion fail">
                        {item.learning_result}
                      </span>
                    )}
                  {item?.total_attempt > 0 &&
                    item.learning_result == "pass" && (
                      <span className="status-lesstion pass">
                        {item.learning_result}
                      </span>
                    )}
                </div>
                <div className="mg-left-10 mg-top-10 origin-vertical">
                  <span className="font-weight-bold">{item.topic}</span>
                  <span>{item.lesson_name}</span>
                  <span>
                    {item.looking_back_type === "grammar"
                      ? `Phần ngữ pháp`
                      : item.looking_back_type === "vocabulary"
                      ? "Phần từ vựng"
                      : `Unit ${
                          item.unit +
                          " > " +
                          item.lesson_type.charAt(0).toUpperCase() +
                          item.lesson_type.slice(1) +
                          " > " +
                          item.level.capitalize()
                        }`}
                  </span>
                </div>
              </div>
              <div className="item-footer">
                <div className="horizontal-space-between">
                  <span
                    style={{ cursor: "pointer" }}
                    className="color-confirm-success font-weight-bold"
                    onClick={() => navigateToHistory(item)}
                  >
                    Đã làm: {item.total_attempt} lần
                  </span>
                  <span className="score">
                    Điểm:{" "}
                    {tabSelected == TABS.COMPLETED ||
                    parseInt(item.total_attempt) > 0
                      ? parseFloat(parseFloat(item.score).toFixed(1))
                      : "__"}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {isEmpty(dataImprove.list_looking_back) &&
            (tabSelected == TABS.TO_DO ? (
              <div className="sunE-no-class text-center flex-1 mg-top-10">
                <img
                  src="/assets/images/giaotrinh/icon_empty_incomplete.png"
                  alt="img_no_class_big"
                />
                <div className="text-empty">
                  Danh sách bài trống <br /> Bạn chưa có bài Master unit nào cần
                  hoàn thành.
                </div>
              </div>
            ) : (
              <div className="sunE-no-class text-center flex-1 mg-top-10">
                <img
                  src="/assets/images/giaotrinh/icon_empty_complete.png"
                  alt="img_no_class_big"
                />
                <div className="text-empty">
                  Danh sách bài trống <br /> Bạn chưa hoàn thành bài Master unit
                  nào.
                </div>
              </div>
            ))}
          {itemSelected && (
            <DoTestExamModal
              setExamSelected={setItemSelected}
              isShow={itemSelected !== null}
              onClose={() => handleCloseDoTest()}
              classId={class_id}
              mockTestId={itemSelected?.looking_back_id}
              type={itemSelected.lesson_type}
              typeMasterUnit={itemSelected.type}
              masterUnitId={itemSelected.id}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MasterUnitPage;
