import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import "../Auth/InputSelect/index.scss";
import { PresentToAllSharp } from "@material-ui/icons";
import useOutsideClick from "../../_helpers/customHook/useOutsideClick";

const InputSelectNews = (props) => {
  let { placeholder } = props;
  const [isOpen, setIsOpen] = useState(false);
  const $selectRef = useRef(null);
  const isOpenCached = useRef(null);

  useEffect(() => {
    isOpenCached.current = isOpen;
  }, [isOpen]);

  useOutsideClick($selectRef, () => {
    if (isOpenCached.current) {
      setIsOpen(false);
      props?.setIsVisible && props?.setIsVisible(false);
    }
  });

  // Handle Checked box
  const handleCheckedBoxValue = () => {
    props.handleCheckedBox();
  };

  return (
    <div
      className={classnames(
        {
          [props.className]: props.className,
          focus: isOpen,
          warning_select: props.errorText || props?.compulWarning,
        },
        "input_radio_base_news input_radio_base "
      )}
      ref={$selectRef}
    >
      {props.errorText && props?.typeErrText != "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute error_select"
              : "error_text error_select"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
      <div
        className="input_select"
        onClick={() => {
          props.isWarning && props.setIsWarning(false);
          if (props.setErrorText) {
            props.setErrorText("");
            props?.setCompulWarning && props?.setCompulWarning("");
          }
          if (!props.disabledClick) {
            setIsOpen(!isOpen);
            props?.setIsVisible && props?.setIsVisible(!isOpen);
          } else {
            props.onClickDisable && props.onClickDisable();
          }
        }}
      >
        <div
          className={`input_select_main_wrapper  input_select_main_wrapper_news ${props.classNameProps}`}
          style={{
            marginBottom: props.mgBottom,
          }}
        >
          <div className="input_select_main">
            {props.renderLabelIcon ? (
              <div className="icon_label">{props.renderLabelIcon()}</div>
            ) : null}
            <input
              name={props.name}
              className={`no_select cursor_pointer one-line input_text ${props.colorInput}`}
              value={
                typeof props?.value == "object"
                  ? props?.value?.title || ""
                  : props?.value || ""
              }
              disabled={props.inputEditable ? false : true}
              placeholder={placeholder}
              onChange={(e) => {
                if (props.inputEditable) {
                  props.setValue({
                    value: e.target?.value,
                    title: e.target?.value,
                  });
                }
              }}
              style={props.inputEditable ? {} : { pointerEvents: "none" }}
            ></input>
            {props.typeSearch == "checkbox" && (
              <div
                className="flex-center pointer_cursor no_select"
                onClick={handleCheckedBoxValue}
              >
                <img
                  src={`/assets/images/icon/${
                    isOpen || props.isChecked || props?.value?.value
                      ? "ico_checkedbox"
                      : "ico_checkbox"
                  }.png`}
                  alt="Icon Checked Box"
                  className="contain_image img_action_input"
                />
              </div>
            )}
            <div className="icon  icon_dropdown cursor_pointer">
              {props.typeSearch != "checkbox" && (
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classnames({
                    rotate_180: isOpen,
                  })}
                  style={{ transition: "all 0.3s" }}
                >
                  <path d="M 0 0 L 10 0 L 5 8 Z" fill="#4d4d4d" />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          {
            active: isOpen,
          },
          `drop_down drop_down_news`
        )}
        style={{}}
      >
        <div className="drop_down_wrapper ">
          {props.options?.map((option) => {
            return (
              <div
                className="option_item no_select"
                key={option?.value + "_" + option.title}
                onClick={() => {
                  props.setValue(option);
                  setIsOpen(false);
                  props?.setIsVisible && props.setIsVisible(false);
                }}
              >
                <span
                  className={classnames(
                    {
                      active:
                        option.value === props.value?.value ||
                        option.value === props.value,
                    },
                    "title color-text"
                  )}
                >
                  {option.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {props.errorText && props?.typeErrText == "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute_new error_select"
              : "error_text error_select"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default InputSelectNews;
