import React from 'react';
import { alertActions } from '../../_actions';
import { useDispatch } from 'react-redux';

function PopUpSuccess(props) {
  if(props?.visible) {
    return (
      <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <p className="text-center">{props.message}</p>
              <div className="form-sunE-button">
                  <button className="btn-line-blue" onClick={props.onClick}>Đóng</button>
              </div>
          </div>
      </div>)
  }
  return null
}

export { PopUpSuccess };