import "./SelectCustom.style.scss";
import { selectCustomLogic } from "./SelectCustom.logic";
import classNames from "classnames";

const SelectCustom = (props) => {
  let { isVisible, handleOpenSelection, setWrapperRefFind } =
    selectCustomLogic(props);

  const renderDataSelect = (data, index) => (
    <div
      key={data?.id}
      onClick={(e) => props.handleClickOption(data, props.typeSelect)}
      className={`col-80 ${
        props?.dataSelect.length - 1 != index && " border_bottom_1_dark"
      } option_item_custom flex-center-column pointer_cursor `}
    >
      {data?.address || data?.title || data?.name}
    </div>
  );

  return (
    <div
      className="select_container"
      onClick={handleOpenSelection}
      ref={(e) => setWrapperRefFind(e)}
    >
      <div className="select_title flex-center fz-11 pointer_cursor">
        {props.titleSelected?.name ||
          props.titleSelected?.title ||
          props?.titleSelected?.address ||
          props?.defaultTitle}
        <div className="icon_drop_custom cursor_pointer">
          <svg
            width="23"
            height="13"
            viewBox="0 0 26 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames({
              rotate_180: isVisible,
            })}
            style={{ transition: "all 0.3s" }}
          >
            <path
              d="M13.2809 15.704L0.507935 2.93097L2.74695 0.692017L13.2611 11.206L23.7389 0.539978L25.9979 2.75897L13.2809 15.704Z"
              fill="#454545"
            />
          </svg>
        </div>
      </div>
      <div
        className="list_option_custom box-shadow flex-align flex-column "
        style={{
          display: isVisible ? "flex" : "none",
        }}
      >
        {props?.dataSelect?.map((data, index) => renderDataSelect(data, index))}
      </div>
    </div>
  );
};

export default SelectCustom;
