import "../FeePage/FeePage.style.scss";
import { tariffPageLogic } from "./TariffPage.logic";
import ButtonNews from "../../_components/Button/ButtonNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { HeaderNews } from "../../_components/Header";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import {
  convertTimePackage,
  validatePrice,
  hasDomainStore,
} from "../../_base/Validate";
import { HashLink } from "react-router-hash-link";
import "./TariffPage.style.scss";
import LazyLoad from "react-lazyload";
import { PopUpYesNo } from "../../_components/Popup";

const renderListPackageContent = (
  packageItems,
  item,
  index,
  handleChangeAccountNumber,
  handleActionAccount,
  handleNavigatePayment,
  accountNumber,
  currPriceSpecific,
  openPopUpAccount
) => {
  const packages = packageItems?.info?.split("\n");

  let isLastItem = item.startsWith("-") && !packages[index + 1].startsWith("-");

  const isPdElement = index == 0 || index == 1;

  return (
    <div key={index} className="pos_rel col-100">
      <div
        className={`pos_rel flex-all-start col-100 text-align-justify ${
          isPdElement && !packageItems.accounts_student ? " pdElement" : " "
        } ${item.startsWith("-") ? " noPdElement_side" : ""}`}
        style={{
          // marginBottom: item.startsWith("-") ? "0.5rem" : "1rem",
          paddingBottom: !item.startsWith("-")
            ? item.trim().endsWith(":")
              ? "0.3rem"
              : "0.6rem"
            : isLastItem
            ? "0.6rem"
            : "0.1rem",
          paddingTop: item.startsWith("-") ? "0.3rem" : "0.6rem",
          borderTop: !item.startsWith("-")
            ? index == 0
              ? "none"
              : "1px solid rgba(0 , 0, 0,  0.1)"
            : "none",
          paddingLeft: item.startsWith("-") && "1rem",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            marginLeft: !item.startsWith("-") && "0.85rem",
          }}
          className={`pos_rel fz-1-mobile item_detail_fee col-87-5 col-80-mobile ${
            !item.startsWith("-")
              ? "item_dot_lozenge_black"
              : " item_detail_fee_side"
          }`}
        >
          {item}
        </div>
        {!item.startsWith("-") && (
          <img
            src={`/assets/images/icon/${"ico_tick_detail"}.png`}
            alt="Icon Tick"
            style={{}}
            className={`${
              isPdElement && !packageItems.accounts_student
                ? "ico_tick_detail_1"
                : "ico_tick_detail"
            }`}
          />
        )}
      </div>

      {item.includes("Tài khoản Sunday Teacher") && (
        <div
          className={`pos_rel tariff_item_accounts ${
            packageItems.accounts_student
              ? " tariff_item_accounts_special"
              : " tariff_item_accounts_normal"
          } flex-all-start  col-100 text-align-justify`}
          style={{
            // marginBottom: item.startsWith("-") ? "0.5rem" : "1rem",
            paddingBottom: !item.startsWith("-")
              ? item.trim().endsWith(":")
                ? "0.3rem"
                : "0.6rem"
              : isLastItem
              ? "0.6rem"
              : "0.1rem",
            paddingTop: item.startsWith("-") ? "0.3rem" : "0.6rem",
            borderTop: !item.startsWith("-")
              ? index == 0
                ? "none"
                : "1px solid rgba(0 , 0, 0,  0.1)"
              : "none",
            paddingLeft: item.startsWith("-") && "1.5rem",
          }}
        >
          {packageItems.accounts_student && (
            <div className=" flex-1">
              <div
                style={{
                  fontSize: "1rem",
                  marginLeft: !item.startsWith("-") && "0.85rem",
                }}
                className={`pos_rel fz-1-mobile item_detail_fee col-87-5 col-80-mobile`}
              >
                <div className="flex-align">
                  <div
                    className={`flex-align input_change_account pos_rel  ${
                      !item.startsWith("-")
                        ? "item_dot_lozenge_black_top"
                        : " item_detail_fee_side"
                    }`}
                  >
                    <input
                      value={accountNumber}
                      type="text"
                      name="limitStudent"
                      onChange={(e) =>
                        handleChangeAccountNumber(e.target.value)
                      }
                      className="active font_news_bold fz-13-75 text-align-center"
                      min="10"
                    />

                    <div className="flex-column ">
                      <div
                        className="ico_action_account pointer_cursor flex-align-start mg-bottom-0-3 "
                        onClick={() => handleActionAccount("increase")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="17"
                          viewBox="0 0 24 17"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M0 1C0 0.447715 0.447715 0 1 0H18C21.3137 0 24 2.68629 24 6V16C24 16.5523 23.5523 17 23 17H1C0.447716 17 0 16.5523 0 16V1Z"
                            fill="white"
                          />
                          <path
                            d="M17.5078 11.4141L11.7956 4.99834"
                            stroke="white"
                            strokeWidth="2.93879"
                            strokeLinecap="round"
                          />
                          <path
                            d="M6.08594 11.4141L11.7982 4.99835"
                            stroke="white"
                            strokeWidth="2.93879"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>

                      <div
                        className="ico_action_account pointer_cursor flex-align-start"
                        onClick={() => handleActionAccount("decrease")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="17"
                          viewBox="0 0 24 17"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M24 12C24 14.7614 21.7614 17 19 17L1 17C0.447716 17 3.91405e-08 16.5523 8.74228e-08 16L1.39876e-06 0.999998C1.44705e-06 0.447714 0.447717 -2.05901e-06 1 -2.01072e-06L23 -8.74228e-08C23.5523 -3.91406e-08 24 0.447716 24 1L24 12Z"
                            fill="white"
                          />
                          <path
                            d="M6.49219 6L12.2044 12.4157"
                            stroke="white"
                            strokeWidth="2.93879"
                            strokeLinecap="round"
                          />
                          <path
                            d="M17.9141 6L12.2018 12.4157"
                            stroke="white"
                            strokeWidth="2.93879"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="fz-1 text-align-left text_desc_diamond">
                    Tài khoản Sunday English dành cho học sinh
                  </div>
                </div>
              </div>
            </div>
          )}
          {openPopUpAccount == "errorText" ? (
            packageItems.accounts_student && (
              <div
                className="fz-08-75 fz-0-7-mobile"
                style={{
                  color: "#e22028",
                  paddingBottom: "0.2rem",
                }}
              >
                Bạn cần mua tối thiểu 10 tài khoản học sinh.
              </div>
            )
          ) : packageItems.accounts_student ? (
            <div
              className={
                !packageItems.accounts_student && "display_none_mobile"
              }
              style={{
                height: "1.5rem",
              }}
            />
          ) : null}

          <div className="col-100 tariff_item_btn">
            <div className="flex-center-column mg-bottom-0-5  pd-top-0-mobile">
              {/* <div className=" text_through fz-1-mobile fz-12-5 pos_rel font_news_italic">
                {packageItems.origin_price == packageItems.cur_price
                  ? ""
                  : validatePrice(packageItems.origin_price)}
                {packageItems.origin_price != packageItems.cur_price ? (
                  <span className="original_price_item_unit  text_underline font_news_italic">
                    đ
                  </span>
                ) : (
                  <br className="display_none_mobile" />
                )}
              </div> */}

              <div
                className={`package_item_price package_item_price_tariff current_price_item   color-red font_news_bold fz-20-mobile fz-25 pos_rel ${
                  packageItems.accounts_student ? "" : ""
                } line_height_normal`}
              >
                {validatePrice(
                  packageItems.accounts_student
                    ? currPriceSpecific
                    : packageItems.cur_price
                )}
                <span className=" fz-15-mobile fz-18-75 color-red  current_price_item_unit text_underline font_news_bold">
                  đ
                </span>
              </div>
            </div>

            {!hasDomainStore() && (
              <ButtonNews
                width="15rem"
                height="3.5rem"
                border={"none"}
                backgroundColor={"#EB5757"}
                // boxShadow={"2px 2px 5px white, 0 0 2px black"}
                onClick={() => handleNavigatePayment(packageItems)}
                maxWidth={"55%"}
                maxHeight={"7vw"}
              >
                <div className="color-white fz-15 font_news_bold text_uppercase">
                  Mua ngay
                </div>
              </ButtonNews>
            )}
          </div>
          {!item.startsWith("-") && packageItems.accounts_student && (
            <img
              src={`/assets/images/icon/${"ico_tick_detail"}.png`}
              alt="Icon Tick"
              style={{}}
              className=" ico_tick_detail"
            />
          )}
        </div>
      )}
    </div>
  );
};

// Render List Packages
export const renderListPackagesTeacher = (
  item,
  handleChangeAccountNumber,
  handleActionAccount,
  handleNavigatePayment,
  accountNumber,
  currPriceSpecific,
  openPopUpAccount
) => {
  return (
    <div
      key={item.id}
      className="flex-center-column box-shadow package_item package_item_tariff border_radius_1 col-100"
    >
      <div
        className="flex-center-column package_item_header "
        style={{
          background:
            item?.package_type_id == 3
              ? `url(
                /assets/images/feeNews/bg_diamond.png
              )  no-repeat top center /cover`
              : item?.package_type_id == 2
              ? "linear-gradient(90deg, #D99F3F 0.01%, #EFC277 14.45%, #D29E4A 23.76%, #D29E4A 30.28%, #FFFF81 91.21%, #905C00 121.61%)"
              : "linear-gradient(90deg, #797979 -6.87%, #828282 -4.68%, #DBDBDB 12.81%, #FFF 20.47%, #D6D6D6 40.15%, #C2C2C2 51.09%, #CACACA 57.65%, #DFDFDF 68.58%, #FFF 80.61%, #F9F9F9 86.08%, #E9E9E9 92.64%, #CFCFCF 99.2%, #C2C2C2 102.48%)",
        }}
      >
        <div
          className={`package_item_title color-white fz-12-5-mobile fz-13-75 font_news_bold font_news_bold ${
            item?.package_type_id == 1 ? "color-text" : ""
          }`}
        >
          {item.package_name.toUpperCase()}{" "}
          {item.package_type_id == 3 && (
            <img
              src="/assets/images/icon/ico_diamond_white.png"
              alt="Icon White Star"
              className="contain_image ico_diamond_white"
            />
          )}
        </div>
      </div>
      <div
        className="flex-column package_item_content"
        style={{
          paddingLeft: item.package_type_id == 3 && "5%",
          paddingRight: item.package_type_id == 3 && "5% ",
        }}
      >
        {item?.info
          ?.split("\n")
          .map((info, index) =>
            renderListPackageContent(
              item,
              info,
              index,
              handleChangeAccountNumber,
              handleActionAccount,
              handleNavigatePayment,
              accountNumber,
              currPriceSpecific,
              openPopUpAccount
            )
          )}
      </div>
    </div>
  );
};

const TariffPage = () => {
  let {
    listPackagesTeacher,
    accountNumber,
    currPriceSpecific,
    openPopUpAccount,
    handleNavigatePaymentTeacher,
    handleChangeAccountNumber,
    handleActionAccount,
    setOpenPopUpAccount,
    handleResetAccount,
  } = tariffPageLogic();

  return (
    <div  id="list_packages" className="feePage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.TARIFF} />
      {/* <TitleNews title={NameTitleNewsItem.FEE} /> */}

      <LazyLoad className="slider">
        <img
          src="/assets/images/feeNews/slider_bg.png"
          alt="Tariff Teacher"
          className="slider_bg contain_image display_none_mobile"
        />
        <img
          src="/assets/images/feeNews/slider_bg_mobile.png"
          alt="Tariff Teacher"
          className="slider_bg contain_image hide display_block_mobile"
        />
      </LazyLoad>

      <div className="content flex-center flex-wrap">
        <LazyLoad className="content_bg flex-center-column col-100">
          <div
           
            className="list_packages flex-jus-evenly flex-wrap col-100"
          >
            {listPackagesTeacher.map((item) =>
              renderListPackagesTeacher(
                item,
                handleChangeAccountNumber,
                handleActionAccount,
                handleNavigatePaymentTeacher,
                accountNumber,
                currPriceSpecific,
                openPopUpAccount
              )
            )}
          </div>
        </LazyLoad>

        {/* <div className=" text_footer_content text-align-center ">
          <HashLink
            className="pointer_cursor display_none_mobile  color-primary-green text_underline fz-16 font_news_bold"
            to={`/${TypeHeaderNewsItem.FEE}/policy`}
          >
            Hình thức thanh toán - Chính sách đổi trả và hoàn tiền
          </HashLink>
          <span className="hide display_block_mobile">
            <HashLink
              className=" pointer_cursor  color-primary-green text_underline fz-11-5-mobile font_news_bold"
              to={`/${TypeHeaderNewsItem.FEE}/policy`}
            >
              Hình thức thanh toán
              <br />
              Chính sách đổi trả và hoàn tiền
            </HashLink>
          </span>
        </div> */}
      </div>

      <FooterNews typeFee={TypeHeaderNewsItem.TARIFF} />
      <ScrollFixed />

      {openPopUpAccount == "popup" && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => handleResetAccount()}
          labelYes="Đóng"
          message="Bạn cần mua tối thiểu 10 tài khoản học sinh."
        />
      )}
    </div>
  );
};

export default TariffPage;
