import "./ModalSuccessRegister.style.scss";
import "../ModalSuccessMsg/ModalSuccessMsg.style.scss";
import ButtonNews from "../../Button/ButtonNews";
import { history } from "../../../_helpers";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import { userConstants } from "../../../_constants";
import { LinkApp } from "../../../_constants/linkDownloadApp";

const ModalSuccessRegister = (props) => {
  let { isVisible, titleMsg, detailMsgTeacher, roleRegister } = props;

  return (
    <div className="flex-column modal_container modal_success_register hide">
      {/* <div className="flex-end">
        <div onClick={onClickClose} className="pointer_cursor">
          <img
            // loading="lazy"
            src="/assets/images/icon/ico_delete_full.png"
            alt="Icon Delete"
            className="contain_image img_delete_modal"
          />
        </div>
      </div> */}

      <div className="flex-center-column col-100-mobile">
        <img
          // loading="lazy"
          src="/assets/images/icon/ico_tick_success.png"
          alt="Icon Successful"
          className="contain_image icon_sucess_msg"
        />
        <div className="col-100-mobile font_news_bold text-align-center fz-16-mobile fz-18 pd-bottom-1 mg-top-1">
          {titleMsg}
        </div>

        <div className="col-100-mobile mg-bottom-0-5 col-90 text-align-center fz-1-mobile fz-11">
          {roleRegister == userConstants.ROLE_TEACHER
            ? `Vui lòng  đăng nhập tài khoản để sử dụng các
              tính năng của Sunday English. Nếu cần hỗ trợ,
              bạn vui lòng gọi điện tới hotline:`
            : `Vui lòng vào email để xác nhận và xem hướng dẫn
            đăng nhập tài khoản học Sunday English. Nếu cần hỗ trợ,
            bạn vui lòng gọi điện tới hotline:
            `}
        </div>

        <div className="font_news_bold fz-12 white_space_pre">
          024 6281 3888.
        </div>

        <div className="flex-align img_download_container flex-just-center display_none_mobile">
          <div
            className="img_qr_wrapper"
            onClick={() => window.open(LinkApp["student"].APP_STORE, "_blank")}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/images/authNews/qr_app_store.png"
              alt="Img App Store"
              className="contain_image img_qr_download"
            />
          </div>
          <div
            className="img_qr_wrapper"
            onClick={() => window.open(LinkApp["student"].GG_PLAY, "_blank")}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/images/authNews/qr_gg_play.png"
              alt="Img GG Play"
              className="contain_image img_qr_download"
            />
          </div>
        </div>

        <div
          className="flex-column footer_social_container hide display_flex_mobile col-100-mobile col-max-100-mobile "
          // style={{
          //   marginLeft: "0.5rem",
          // }}
        >
          <div className="flex-jus-between img_download_container_mobile">
            <div className="flex-align-end-column footer_app_download_container ">
              <img
                src="/assets/images/homeNews/icon/ico_appstore.png"
                alt="Icon App Store"
                className="contain_image img_app_download"
              />
              <img
                src="/assets/images/homeNews/icon/ico_ggplay.png"
                alt="Icon GG Play"
                className="contain_image img_app_download"
                style={{
                  marginBottom: "0.4rem",
                }}
              />
            </div>
          </div>

          <div className="flex-jus-between icon_social_container_mobile">
            <a href="#">
              <img
                src="/assets/images/homeNews/icon/ico_fb.png"
                alt="Icon Facebook"
                className="icon_social"
              />
            </a>
            <a href="#">
              <img
                src="/assets/images/homeNews/icon/ico_zalo.png"
                alt="Icon Zalo"
                className="icon_social"
              />
            </a>
            <a href="#">
              <img
                src="/assets/images/homeNews/icon/ico_ytb.png"
                alt="Icon Youtube"
                className="icon_social"
              />
            </a>
          </div>
        </div>

        <div className="flex-jus-between icon_social_container display_none_mobile">
          <a href="#">
            <img
              src="/assets/images/homeNews/icon/ico_fb.png"
              alt="Icon Facebook"
              className="icon_social"
            />
          </a>
          <a href="#">
            <img
              src="/assets/images/homeNews/icon/ico_zalo.png"
              alt="Icon Zalo"
              className="icon_social"
            />
          </a>
          <a href="#">
            <img
              src="/assets/images/homeNews/icon/ico_ytb.png"
              alt="Icon Youtube"
              className="icon_social"
            />
          </a>
        </div>

        <div className=" btn_auth_container flex-jus-evenly ">
          <ButtonNews
            width="15rem"
            height="3.5rem"
            onClick={() => history.push(`/${TypeHeaderNewsItem.LOGIN}`)}
            border={"1px solid #00cc83"}
            backgroundColor={"#00cc83"}
            boxShadow={"2px 2px 5px white, 0 0 1px black"}
            maxWidth={"40%"}
            maxHeight={"3.5rem"}
            // disabled={disabledBtnRegist}
          >
            <div className="fz-12 fz-11-mobile color-white font_news_bold">
              ĐĂNG NHẬP
            </div>
          </ButtonNews>

          <ButtonNews
            width="15rem"
            height="3.5rem"
            onClick={() => history.push("/")}
            border={"1px solid #00cc83"}
            backgroundColor={"transparent"}
            maxWidth={"40%"}
            maxHeight={"3.5rem"}
          >
            <div className="fz-12 fz-11-mobile  color-primary-green font_news_bold">
              TRANG CHỦ
            </div>
          </ButtonNews>
        </div>
      </div>
    </div>
  );
};

export default ModalSuccessRegister;
