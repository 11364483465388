import { HeaderNews } from "../../_components/Header";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import LazyLoad from "react-lazyload";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { accountActivationLogic } from "./AccountActivationNews.logic";
import { isEmpty } from "lodash";
import "./AccountActivationNews.style.scss";
import { Fragment } from "react";

const AccountActivationNews = (props) => {
  let {
    dataActivation,
    activationSelect,
    handleSelectActivation,
    handleNavigateLink,
  } = accountActivationLogic(props);

  // Render steps action
  const renderListSteps = (step, parent, typeAccount) => {
    return (
      <Fragment key={step.id}>
        <div
          key={step.id}
          className={`activation_step pos_rel flex-column pd-top-12-5-pc ${
            parent?.type == "computer" ? " col-100" : ""
          } ${
            step.id == 1 &&
            parent?.type == "mobile" &&
            !!parent?.typeActivation &&
            parent?.typeActivation == "package"
              ? " col-100"
              : ""
          }`}
        >
          <div className={!step?.title_0_link ? " text-align-justify " : ""}>
            <span className="color-primary-green line_height_normal step_text  font_news_bold">
              {`Bước ${step.id}: `}
              {step?.title_0 && (
                <span className="line_height_normal step_text ">
                  {step?.title_0}{" "}
                  {step?.title_0_link && (
                    <a
                      target="_blank"
                      // href={step?.title_0_link}
                      onClick={() =>
                        handleNavigateLink(
                          typeAccount || "student",
                          step?.title_0_link
                        )
                      }
                      className="line_height_normal step_text  color-primary-green text_underline_offset"
                    >
                      {step?.title_0_link}
                    </a>
                  )}
                </span>
              )}
            </span>
            {step?.title_1 && (
              <div
                className={`line_height_normal step_text  pd-top-0-5-pc pd-top-0-mobile ${
                  step?.title_mobile ? " display_none_mobile" : ""
                }`}
              >
                {step?.title_1}
              </div>
            )}
            {step?.title_2 && (
              <div
                className={`line_height_normal step_text ${
                  step?.title_mobile ? " display_none_mobile" : ""
                }`}
              >
                {step?.title_2}
              </div>
            )}
            {step?.title_mobile && (
              <div
                className={`line_height_normal step_text hide display_block_mobile`}
              >
                {step?.title_mobile}
              </div>
            )}
          </div>
          {!isEmpty(step?.data_images) && (
            <Fragment>
              <div className="display_flex display_none_mobile step-image__container pos_rel flex-center-column-mobile">
                {step?.data_images.map((image) => {
                  return (
                    <LazyLoad
                      once
                      className="step-image__mobile pd-top-1-pc pd-bottom-15"
                      key={image.id}
                    >
                      <img
                        src={`/assets/images/accountActivationNews/${image?.src}.png`}
                        alt="Image Step"
                        className="contain_image"
                      />
                    </LazyLoad>
                  );
                })}
              </div>
              <div className=" hide display_flex_mobile step-image__container pos_rel flex-center-column-mobile">
                {step?.data_images.map((image) => {
                  return (
                    <div
                      once
                      className="step-image__mobile pd-top-1-pc  pd-top-15 pd-bottom-15 pd-bottom-0-mobile"
                      key={image.id}
                    >
                      <img
                        src={`/assets/images/accountActivationNews/${image?.src}.png`}
                        alt="Image Step"
                        className="contain_image"
                      />
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
          {!!step?.arrow_absolute ? (
            <div className="display_none_mobile pos_abs step-arrow__small step-arrow__small--package step-arrow__abs flex-center">
              <img
                src="/assets/images/accountActivationNews/arrow.png"
                alt="Img Arrow"
                className="cover_image"
              />
            </div>
          ) : null}
          {!isEmpty(step?.data_images_pc) && (
            <div className="display_flex flex-column-mobile">
              {step?.data_images_pc.map((image, index) => {
                return (
                  <LazyLoad
                    key={image.id + index.toString()}
                    once
                    className="pd-top-2-5-pc pd-bottom-15 pd-bottom-0-mobile pd-top-1 flex-center col-100 step-image__pc"
                  >
                    <img
                      src={`/assets/images/accountActivationNews/${image?.src}.png`}
                      alt="Image Step"
                      className="contain_image"
                    />
                  </LazyLoad>
                );
              })}
            </div>
          )}

          {!isEmpty(step?.data_images_side) && (
            <div className="display_flex flex-column">
              {step?.data_images_side.map((image, index) => {
                return (
                  <div
                    className="display_flex flex-column"
                    key={image?.id + index.toString()}
                  >
                    <div className="pd-top-15-pc step_text font_news_bold line_height_normal  step-title__social">
                      {image?.type}
                    </div>
                    <LazyLoad
                      once
                      className="pd-top-2-pc pd-bottom-15 pd-bottom-0-mobile pd-top-1 flex-center col-100 step-image__pc"
                      key={image.id}
                    >
                      <img
                        src={`/assets/images/accountActivationNews/${image?.src}.png`}
                        alt="Image Step"
                        className={`contain_image ${
                          image?.src_small ? "img-step__side--big" : ""
                        }`}
                      />
                      {image?.src_small ? (
                        <img
                          src={`/assets/images/accountActivationNews/${image?.src_small}.png`}
                          alt="Image Step Small"
                          className="contain_image img-step__side--small"
                        />
                      ) : null}
                    </LazyLoad>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {!!step?.arrow ? (
          <div
            className={`display_none_mobile step-arrow step-arrow__${step?.arrow} flex-center`}
          >
            <img
              src="/assets/images/accountActivationNews/arrow.png"
              alt="Img Arrow"
              className="cover_image"
            />
          </div>
        ) : null}
      </Fragment>
    );
  };

  // Render Content Activation
  const renderContentActivation = (content, parent) => {
    return (
      <div key={content.id} className="activation_content col-100">
        {content?.typeAccount ? (
          <div className="flex-align activation_content_title">
            <img
              src="/assets/images/icon/ico_lozenge.png"
              alt="Icon Lazenge"
              className="contain_image"
            />
            <h2 className="font_news_bold">{`TẠO TÀI KHOẢN ${
              content.typeAccount == "student" ? "HỌC SINH" : "GIÁO VIÊN"
            }`}</h2>
          </div>
        ) : null}
        <div className="activation_list_steps col-100 flex-wrap flex-between flex-column-mobile">
          {!isEmpty(content.data) &&
            content.data?.map((step) =>
              renderListSteps(step, parent, content?.typeAccount)
            )}
        </div>
      </div>
    );
  };

  // Render Title Device
  const renderTitleDevice = (device) => {
    return (
      <div className="activation_device_title flex-center">
        <img
          src={`/assets/images/accountActivationNews/ico_${device}.png`}
          alt="Icon Device"
          className="contain_image"
        />
        <h2 className="color-white text_uppercase activation_device_text font_news_bold">
          {device == "mobile"
            ? "1. Với thiết bị điện thoại"
            : "2. Với thiết bị máy tính"}
        </h2>
      </div>
    );
  };

  // Render Data Activation
  const renderListActivation = (data) => {
    return (
      <div
        key={data.id + data?.title}
        className="flex-center-column activation_item"
      >
        <h2 className="text_uppercase display_none_mobile color-primary-green font_news_bold activation_title">
          {data.title}
        </h2>
        <div
          id={data.typeActivation}
          className="pos_rel hide display_flex_mobile flex-jus-between activation-header"
          onClick={() =>
            handleSelectActivation({
              title: data?.title,
              type: data?.typeActivation,
            })
          }
        >
          <div className="text_uppercase color-primary-green font_news_bold activation_title">
            {data.title}
          </div>
          <div className="flex-center">
            <img
              src={`/assets/images/icon/${
                data.typeActivation == activationSelect?.type
                  ? "ico_circle_up"
                  : "ico_circle_down"
              }.png`}
              alt="Icon Dropdown"
              className="contain_image img_dropdown"
            />
          </div>
        </div>
        {data.data &&
          data.data.map((item) => {
            return (
              <div
                key={item.id}
                className={`activation_device  pd-top-1-pc pd-bottom-15 flex-center-column col-100 ${
                  item.type == "computer" && item.typeActivation == "register"
                    ? " activation-computer__register"
                    : ""
                } ${
                  data.typeActivation == activationSelect?.type
                    ? " display_flex_mobile"
                    : " display_none_mobile"
                }`}
              >
                {renderTitleDevice(item.type)}
                {item.typeActivation == "package"
                  ? renderContentActivation(item, item)
                  : item?.data.map((content) =>
                      renderContentActivation(content, item)
                    )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="commondPage bg_white activationNewsPage">
      <HeaderNews type={TypeHeaderNewsItem.HOME} />
      <LazyLoad once className="slider">
        <img
          src="/assets/images/accountActivationNews/slide.png"
          alt="Slide FAQs"
          className="contain_image slider_bg col-100 display_none_mobile"
        />
        <img
          src="/assets/images/accountActivationNews/slide_mobile.png"
          alt="Slide FAQs"
          className="contain_image slider_bg col-100 hide display_block_mobile"
        />
      </LazyLoad>

      <div className="content flex-center-column">
        <div className="list_activation">
          {dataActivation.map((data) => renderListActivation(data))}
        </div>
      </div>

      <ScrollFixed />
      <FooterNews />
    </div>
  );
};

export default AccountActivationNews;
