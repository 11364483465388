import React, { useState } from 'react';
import { isEmpty } from 'lodash';

function PickDay(props) {
    let { time, handleChangeDate, name_time } = props;
    const [inputs, setInputs] = useState({
        day: !isEmpty(time) ? time.substr(0, 2) : new Date().getDate(),
        month: !isEmpty(time) ? time.substr(3, 2) : new Date().getMonth() + 1,
        year: !isEmpty(time) ? time.substr(6, 4) : new Date().getFullYear(),
    });

    function createElementsOption(start, end) {
        var elements = [];
        for (let i = start; i <= end; i++) {
            elements.push(<option key={i} >{i}</option>);
        }
        return elements;
    }

    let defaultValueDate = new Date().getDate();
    let defaultValueMonth = new Date().getMonth() + 1;
    let defaultValueYear = new Date().getFullYear();
    if (!isEmpty(time)) {
        defaultValueDate = time.substr(0, 2);
        defaultValueMonth = time.substr(3, 2)
        defaultValueYear = time.substr(6, 4)
    }

    function handleChange(e) {
        try{
            const { name, value } = e.target;
            let day = defaultValueDate;
            let month = defaultValueMonth;
            let year = defaultValueYear;
            if(_.isEqual(name, 'day')){
                day = value;
            }else if(_.isEqual(name, 'month')){
                month = value;
                if ((new Date(defaultValueYear, month, 0)).getDate() < (new Date(defaultValueYear, defaultValueMonth, 0)).getDate() && day > (new Date(defaultValueYear, month, 0)).getDate()){
                    day = (new Date(defaultValueYear, month, 0)).getDate()
                }
            }else if(_.isEqual(name, 'year')){
                year = value;
            }
            handleChangeDate({
                [name_time]: year + '-' + month + '-' + day,
            })
        }catch(e){
            console.log('handleChange', e)
        }
    }

    return (
        <div className="select-gr">
            <select onChange={handleChange} name={'day'} value={parseInt(defaultValueDate)}>
                {createElementsOption(1, (new Date(defaultValueYear, defaultValueMonth, 0)).getDate()) || 31}
            </select>
            <select onChange={handleChange} name={'month'} value={parseInt(defaultValueMonth)}>
                {createElementsOption(1, 12)}
            </select>
            <select onChange={handleChange} name={'year'} value={parseInt(defaultValueYear)}>
                {createElementsOption(2000, 2099)}
            </select>
        </div>
    );
}

export { PickDay };