import { useState } from "react";
import { useSelector } from "react-redux";
import { LinkApp } from "../../_constants/linkDownloadApp";
import { getMobileOperatingSystem } from "../../_base/Validate";

export const accountActivationLogic = (props) => {
  const authentication = useSelector((state) => state.authentication);

  // Data Activation
  const dataActivation = [
    {
      id: 1,
      title: "I. Hướng dẫn đăng ký tài khoản",
      typeActivation: "register",
      data: [
        {
          id: 1,
          type: "mobile",
          typeActivation: "register",
          data: [
            {
              id: 1,
              typeAccount: "student",
              data: [
                {
                  id: 1,
                  title_1: "Tải app Sunday English.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_1_1",
                    },
                    {
                      id: 2,
                      src: "mobile_student_acc_1_2",
                    },
                  ],
                  arrow: "big",
                },
                {
                  id: 2,
                  title_1: "Tại màn hình đăng ký",
                  title_2: " đăng nhập, chọn Đăng ký.",
                  title_mobile: "Tại màn hình đăng ký đăng nhập, chọn Đăng ký.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_2_1",
                    },
                  ],
                },
                {
                  id: 3,
                  title_1: "Nhập các thông tin cần thiết để tạo tài khoản.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_3_1",
                    },
                    {
                      id: 2,
                      src: "mobile_student_acc_3_2",
                    },
                    {
                      id: 3,
                      src: "mobile_student_acc_3_3",
                    },
                  ],
                  // arrow: "big",
                  // arrow_absolute: true,
                },
                {
                  id: 4,
                  title_1: "Hoàn tất đăng ký.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_4_1",
                    },
                    {
                      id: 2,
                      src: "mobile_student_acc_4_2",
                    },
                    {
                      id: 3,
                      src: "mobile_student_acc_4_3",
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              typeAccount: "teacher",
              data: [
                {
                  id: 1,
                  title_1: "Tải app Sunday Teacher.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_teacher_acc_1_1",
                    },
                    {
                      id: 2,
                      src: "mobile_teacher_acc_1_2",
                    },
                  ],
                  arrow: "big",
                },
                {
                  id: 2,
                  title_1: "Tại màn hình đăng ký",
                  title_2: " đăng nhập, chọn Đăng ký.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_2_1",
                    },
                  ],
                },
                {
                  id: 3,
                  title_1: "Nhập các thông tin cần thiết để tạo tài khoản.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_student_acc_3_1",
                    },
                    {
                      id: 2,
                      src: "mobile_teacher_acc_3_2",
                    },
                    {
                      id: 3,
                      src: "mobile_teacher_acc_3_3",
                    },
                  ],
                  // arrow: "big",
                  // arrow_absolute: true,
                },
                {
                  id: 4,
                  title_1: "Nhập mã xác nhận được gửi đến Zalo.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_teacher_acc_4_1",
                    },
                    {
                      id: 2,
                      src: "mobile_teacher_acc_4_2",
                    },
                  ],
                  arrow: "big",
                },
                {
                  id: 5,
                  title_1: "Hoàn tất đăng ký tài khoản.",
                  data_images: [
                    {
                      id: 1,
                      src: "mobile_teacher_acc_5_1",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          type: "computer",
          typeActivation: "register",
          data: [
            {
              id: 1,
              typeAccount: "student",
              data: [
                {
                  id: 1,
                  title_0: "Truy cập trang",
                  title_0_link: "https://sundayenglish.com/register",
                },
                {
                  id: 2,
                  title_0:
                    "Nhập email và mật khẩu thiết lập tài khoản. Lưu ý mật khẩu bao gồm từ 6 ký tự trở lên. Sau đó bấm Tiếp theo.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_2",
                    },
                  ],
                },
                {
                  id: 3,
                  title_0:
                    "Chọn loại tài khoản Học viên => Chọn khối học. Bấm Tiếp theo.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_3",
                    },
                  ],
                },
                {
                  id: 4,
                  title_0:
                    "Nhập các thông tin để tạo tài khoản như: Họ tên, Ngày sinh, Giới tính, Số điện thoại, Nơi học...",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_4",
                    },
                  ],
                },
                {
                  id: 5,
                  title_0: "Kích hoạt tài khoản qua link được gửi đến email.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_5",
                    },
                  ],
                },
                {
                  id: 6,
                  title_0: "Hoàn tất đăng ký tài khoản.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_6",
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              typeAccount: "teacher",
              data: [
                {
                  id: 1,
                  title_0: "Truy cập trang",
                  title_0_link: "https://sundayenglish.com/register",
                },
                {
                  id: 2,
                  title_0:
                    "Nhập email và mật khẩu thiết lập tài khoản. Lưu ý mật khẩu bao gồm từ 6 ký tự trở lên. Sau đó bấm Tiếp theo.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_student_acc_2",
                    },
                  ],
                },
                {
                  id: 3,
                  title_0: "Chọn loại tài khoản Giáo viên. Bấm Tiếp theo.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_teacher_acc_3",
                    },
                  ],
                },
                {
                  id: 4,
                  title_0:
                    "Nhập các thông tin để tạo tài khoản như: Họ tên, Ngày sinh, Giới tính, Số điện thoại, Nơi công tác...",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_teacher_acc_4",
                    },
                  ],
                },
                {
                  id: 5,
                  title_0:
                    "Kích hoạt tài khoản qua link được gửi đến email HOẶC nhập mã xác nhận được gửi đến Zalo.",
                  data_images_pc: [
                    {
                      id: 1,
                      src: "pc_teacher_acc_5_1",
                    },
                  ],
                  data_images_side: [
                    {
                      id: 1,
                      type: "Email",
                      src: "pc_teacher_acc_5_2",
                    },
                    {
                      id: 2,
                      type: "Zalo",
                      src: "pc_teacher_acc_5_3",
                      src_small: "pc_teacher_acc_5_4",
                    },
                  ],
                },
                {
                  id: 6,
                  title_0: "Hoàn tất đăng ký tài khoản.",
                  data_images_side: [
                    {
                      id: 1,
                      type: "Email",
                      src: "pc_teacher_acc_6_1",
                    },
                    {
                      id: 2,
                      type: "Zalo",
                      src: "pc_teacher_acc_6_2",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "II. Hướng dẫn kích hoạt gói",
      typeActivation: "package",
      data: [
        {
          id: 1,
          type: "mobile",
          typeActivation: "package",
          data: [
            {
              id: 1,
              title_0:
                "Đăng nhập tài khoản tại app Sunday English hoặc Sunday Teacher. ",
            },
            {
              id: 2,
              title_1: "Trong mục Thêm,",
              title_2: "chọn Kích hoạt tài khoản",
              title_mobile: "Trong mục Thêm, chọn Kích hoạt tài khoản.",
              data_images: [
                {
                  id: 1,
                  src: "mobile_student_package_2",
                },
              ],
              arrow: "small",
              // arrow_absolute: true,
            },
            {
              id: 3,
              title_1: "Nhập mã kích hoạt đã được",
              title_2: " gửi về email mua gói.",
              title_mobile: "Nhập mã kích hoạt đã được gửi về email mua gói.",
              data_images: [
                {
                  id: 1,
                  src: "mobile_student_package_3",
                },
              ],
              arrow: "small",
            },
            {
              id: 4,
              title_1: "Bấm Kích hoạt. Tài khoản sẽ được sử dụng",
              title_2: "toàn bộ nội dung và tính năng của gói.",
              title_mobile:
                "Bấm Kích hoạt. Tài khoản sẽ được sử dụng toàn bộ nội dung và tính năng của gói.",
              data_images: [
                {
                  id: 1,
                  src: "mobile_student_package_4",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          type: "computer",
          typeActivation: "package",
          data: [
            {
              id: 1,
              title_0: "Đăng nhập tài khoản trên trang",
              title_0_link: "https://sundayenglish.com/login",
            },
            {
              id: 2,
              title_0: "Trong mục Thêm, chọn Kích hoạt tài khoản.",
              data_images_pc: [
                {
                  id: 1,
                  src: "pc_student_package_2",
                },
              ],
            },
            {
              id: 3,
              title_0: "Nhập mã kích hoạt đã được gửi về email mua gói.",
              data_images_pc: [
                {
                  id: 1,
                  src: "pc_student_package_3",
                },
              ],
            },
            {
              id: 4,
              title_0:
                "Bấm Kích hoạt. Tài khoản sẽ được sử dụng toàn bộ nội dung và tính năng của gói.",
            },
          ],
        },
      ],
    },
  ];

  // Variable
  const [activationSelect, setActivationSelect] = useState({
    title: "",
    type: "",
  });

  // Handle Select Type Activation
  const handleSelectActivation = (newActivation) => {
    activationSelect?.type == newActivation?.type
      ? setActivationSelect({
          title: "",
          type: "",
        })
      : setActivationSelect(newActivation);

    if (activationSelect?.type != newActivation?.type) {
      setTimeout(() => {
        const element = document.getElementById(newActivation?.type?.trim());
        // console.log(element);
        element?.scrollIntoView({
          behavior: "instant",
          block: "start", // or 'center' or 'end'
          inline: "start", // or 'start' or 'end'
        });
      }, 50);
      setTimeout(() => {
        window.scrollBy(0, -80);
      }, 100);
    }
  };

  // Handle Navigate Link
  const handleNavigateLink = (type, link) => {
    const osMobile = getMobileOperatingSystem();
    if (osMobile == "Android") {
      window.open(LinkApp[`${type}`].GG_PLAY, "_blank");
    } else if (osMobile == "iOS") {
      window.open(LinkApp[`${type}`].APP_STORE, "_blank");
    } else {
      if (authentication.isLogin) {
        window.open(`/${authentication.role}`, "_blank");
      } else {
        window.open(link, "_blank");
      }
    }
  };

  return {
    dataActivation,
    activationSelect,
    handleSelectActivation,
    handleNavigateLink,
  };
};
