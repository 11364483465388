import { useState, useEffect } from "react";
import { isEmail } from "../../../_helpers/validateEmail";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../../_actions";
import { userConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import {
  validatePhoneNumber,
  validateEmail,
  validateNumber,
} from "../../../_base/Validate";
import { apiCaller } from "../../../_helpers";
import { configConstants } from "../../../_constants";

export const forgotPwLogic = () => {
  const $ = document.querySelector.bind(document);
  const _history = useHistory();
  const alert = useSelector((state) => state.alert);
  const _dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [disabledBtnSend, setDisabledBtnSend] = useState(true);

  const changeEmail = (value) => {
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (emailError) {
      if (validateEmail(value) || value == "") {
        setEmailError("");
      }
    }
    setEmail(value?.trim());
  };
  const handleSendRequired = () => {
    if (textBtnSend == "GỬI LẠI") {
      $(".msg_success")?.classList.add("hide");
    }
    apiCaller("/api_login/forget_password", "PUT", { email }, null, false)
      .then((res) => {
        // console.log(res.msg);
        setEmailError(res.msg);
        if (res.status) {
          setTextBtnSend("GỬI LẠI");
          $(".connect_social")?.classList.add("hide");
          $(".msg_success")?.classList.remove("hide");
          $(".image_success")?.classList.add("hide");
          $(".dot_typing")?.classList.remove("hide");
          setTimeout(() => {
            $(".image_success")?.classList.remove("hide");
            $(".dot_typing")?.classList.add("hide");
          }, 1000);
        }
      })
      .catch((e) => setEmailError(e));
  };

  const handleCancel = () => {
    _history.goBack();
  };

  const onBlurField = (type) => {
    switch (type) {
      case "email": {
        if (email && !emailError && !validateEmail(email)) {
          setEmailError("Định dạng email không đúng");
        } else if (validateEmail(email) || email == "") {
          setEmailError("");
        }

        return;
      }
      case "phone": {
        if (phone == "") {
          setPhoneWarning("");
        } else if (phone && phone?.length != 10 && phone?.length != 11) {
          setPhoneWarning("Số điện thoại hợp lệ gồm 10-11 số!");
          // console.log("123");
        } else if (phone && phone?.length && phone[0] !== "0") {
          setPhoneWarning("Định dạng số điện thoại không đúng");
        }
        return;
      }
      default:
    }
  };

  // Phone
  const [phone, setPhone] = useState();
  const [phoneWarning, setPhoneWarning] = useState("");

  const changePhone = (value) => {
    setPhone(validateNumber(value));
    if (phoneWarning) {
      if ([10, 11].includes(value?.length) || phone == "") {
        setPhoneWarning("");
      }
    }
  };

  useEffect(() => {
    if (
      // phone &&
      // [10, 11].includes(phone.length) &&
      email &&
      validateEmail(email)
    ) {
      setDisabledBtnSend(false);
    } else {
      setDisabledBtnSend(true);
    }
  }, [phone, email]);

  const [textBtnSend, setTextBtnSend] = useState("GỬI YÊU CẦU");

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return {
    email,
    emailError,
    disabledBtnSend,
    setEmailError,
    changeEmail,
    handleSendRequired,
    handleCancel,
    onBlurField,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    textBtnSend,
  };
};
