import { useState } from "react";

export const benefitStudentLogic = () => {
  // Data Content Benefit Student
  const [dataContent, setDataContent] = useState([
    {
      id: 1,
      content: "Học theo trình độ cá nhân, bám sát sách giáo khoa Bộ GD&ĐT",
    },
    {
      id: 2,
      content: "Nhớ từ nhanh, không cần học thuộc lòng",
    },
    {
      id: 3,
      content: "Luyện phát âm chuẩn bản xứ với công nghệ nhận diện giọng nói",
    },
    {
      id: 4,
      content: "Lấp lỗ hổng kiến thức với gia sư ảo thông thái",
    },
    {
      id: 5,
      content: "Ôn bài cũ, học trước bài mới mọi lúc mọi nơi",
    },

    {
      id: 6,
      content: "Thi thử",
      sideText: " MIỄN PHÍ",
      tailText: ", rèn luyện kỹ năng làm bài và nâng cao điểm số",
    },
    {
      id: 7,
      content: "Mở rộng kiến thức, nâng cấp trình độ",
    },
    {
      id: 8,
      content: "Chủ động lựa chọn thời gian học",
    },
  ]);

  // Handle Check Even
  const handleCheckEven = (number) => {
    return number % 2 == 0;
  };

  // Data Img Slide
  const dataImgSlideStudent = [
    {
      id: 1,
      url: "/assets/images/homeNews/content/banner_3.png",
    },
    {
      id: 2,
      url: "/assets/images/homeNews/content/banner_4.png",
    },
    {
      id: 3,
      url: "/assets/images/homeNews/content/banner_4.png",
    },
    {
      id: 4,
      url: "/assets/images/homeNews/content/banner_3.png",
    },
  ];

  return {
    dataContent,
    handleCheckEven,
    dataImgSlideStudent,
  };
};
