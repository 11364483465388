import { alertConstants } from "./../../_constants";
import { isEmpty } from "lodash";
import { userConstants } from "./../../_constants";

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: alertConstants.SUCCESS,
        screen: !isEmpty(action.alert.screen) ? action.alert.screen : "",
        message: action.alert.message,
        ...action.alert
      };
    case alertConstants.ERROR:
      return {
        type: alertConstants.ERROR,
        screen: !isEmpty(action.alert.screen) ? action.alert.screen : "",
        message: action.alert.message,
        ...action.alert
      };
    case alertConstants.CLEAR:
      return {};

    case userConstants.RESET_ALL_STATE:
      return {};
    default:
      return state;
  }
}
