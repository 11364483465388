import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { curriculumConstants } from '../../../_constants';

function ChangView(props) {
    const dispatch = useDispatch();
    const curriculums = useSelector(state => state.curriculums);

    const [view, setView] = useState(curriculums.view);
    let { student } = props;
    function changeView(view) {
        setView(view);
        dispatch({
            type: curriculumConstants.CHANGE_VIEW,
            view
        })
    }

    return (
        <div className="btn-bar" >
            <img src="/assets/images/giaotrinh/ico_bar.png" alt="ico_bar" className={view === 'map' ? 'show' : 'hide'} onClick={() => changeView('grid')} />
            { student ? 
                <img src="/assets/images/student/ico_bar_grid.png" alt="ico_bar" className={view === 'grid' ? 'show' : 'hide'} onClick={() => changeView('map')} />
                :
                <img src="/assets/images/giaotrinh/ico_bar_grid.png" alt="ico_bar" className={view === 'grid' ? 'show' : 'hide'} onClick={() => changeView('map')} />
            }
        </div>
    );
}

export { ChangView };