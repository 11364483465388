import React from 'react';
function AddSkillExercise() {
    return (
        <div className="sunE-right-container">
            <div className="sunE-container-box">
                <div className="flex-m">
                    <div className="flex-1 sunE-calendar">
                        <div className="calendar box-shadow">
                        </div>
                    </div>
                    <div className="flex-1 sunE-plan sunE-plan-list">
                        AddSkillExercise
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AddSkillExercise };