import { useEffect, useState } from "react";
import API from "../../_apis/APIConstants";
import APIBase from "../../_base/APIBase";
import { validateNumber } from "../../_base/Validate";
import { floatObjectToArray } from "../../_base/Validate";
import { configConstants } from "../../_constants";

export const tariffPageLogic = (props) => {
  const [listPackagesTeacher, setListPackagesTeacher] = useState([]);

  const [accountNumber, setAccountNumber] = useState();
  const [currPriceSpecific, setCurrPriceSpecific] = useState();

  const [openPopUpAccount, setOpenPopUpAccount] = useState("");

  // Fetch get list packages
  const getListPackages = async () => {
    try {
      let urlApi = `${API.baseURL}${API.get_packages_teacher}`;
      const result = await APIBase.apiBaseCaller("GET", urlApi);
      if (result?.status) {
        const dataPackageAllLife = result.data.package_list.filter(
          (item) => !item.duration
        );

        const dataPackage = result.data.package_list.filter(
          (item) => item.duration
        );
        dataPackage.sort((a, b) => {
          if (a.duration === b.duration) {
            // If duration is equal, compare based on package_type_id
            return a.package_type_id - b.package_type_id;
          } else {
            // If duration is not equal, compare based on duration
            return a.duration - b.duration;
          }
        });

        const dataAccountStudent = floatObjectToArray(
          result.data.package_list.filter((item) => item.accounts_student)
        );

        setAccountNumber(dataAccountStudent[0]?.accounts_student);
        setCurrPriceSpecific(dataAccountStudent[0]?.cur_price);

        setListPackagesTeacher([...dataPackage, ...dataPackageAllLife]);

        // const info
        // setListPackagesTeacher(
        //   result.data.package_list.sort((a, b) => {
        //     let prevDuration = a.duration;
        //     let nextDuration = b.duration;
        //     if (!prevDuration) {
        //       prevDuration = Number.MAX_VALUE;
        //     }
        //     return prevDuration - nextDuration;
        //   })
        // );

        // Scroll To View
        let hash = window.location.hash;
        if (hash) {
          var ids = hash.split("#");
          for (var i = 1; i < ids.length; i++) {
            var element = document.getElementById(ids[i]);
            if (element) {
              element.scrollIntoView({ behavior: "instant" });
            }
          }
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    getListPackages();
    // window.scrollTo({ top: 0 });
  }, []);

  // Handle Navigate to payment service
  const handleNavigatePaymentTeacher = (item) => {
    item.accounts_student
      ? accountNumber < 10
        ? setOpenPopUpAccount("popup")
        : window.open(
            `${configConstants.AUTH_URL}license/order?package=${
              item.id
            }&type=teacher&total_student=${accountNumber.toString()}`
          )
      : window.open(
          `${configConstants.AUTH_URL}license/order?package=${item.id}&type=teacher`
        );
  };

  // Scroll To View
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        if (element) {
          element.scrollIntoView();
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  // Data Policy Rule 4
  const [dataRule4, setDataRule4] = useState({
    nameRule: "CHÍNH SÁCH ĐỔI, TRẢ HÀNG VÀ HOÀN TIỀN",
    rule_id: 4,
    data: [
      {
        id: 1,
        content:
          "- Khi khách hàng đăng ký mua một khóa học tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua và sử dụng. Sunday English chịu trách nhiệm đảm bảo các cam kết dịch vụ đã nêu ra và sẽ làm hết khả năng để khắc phục trong những trường hợp bất khả kháng.",
      },
      {
        id: 2,
        content:
          "- Sunday English không có trách nhiệm hoàn trả học phí mà khách hàng đã đăng ký.",
      },
    ],
  });

  // Data policy Payment
  const dataPolicyPayment = {
    nameRule: "HƯỚNG DẪN THANH TOÁN",

    data1: {
      title:
        "Sau khi chọn gói học muốn mua tại mục “Học phí”, khách hàng tiến hành thanh toán bằng cách chọn một trong các hình thức thanh toán sau:",
      data: [
        {
          id: 1,
          title: "1. Thanh toán khi nhận hàng (COD) ",
          list_1: [
            {
              id: 1,
              content:
                "Khách hàng có thể đặt hàng qua hotline 024 6281 3888 hoặc qua website. Sau đó, nhân viên chăm sóc khách hàng của chúng tôi sẽ gọi điện xác nhận với quý khách về gói sản phẩm và phương thức vận chuyển. ",
            },
            {
              id: 2,
              content:
                "Khi nhận được nhận được hàng từ đơn vị vận chuyển, quý khách có trách nhiệm thanh toán đầy đủ toàn bộ giá trị đơn hàng cho nhân viên giao hàng. ",
            },
          ],
        },
        {
          id: 2,
          title: "2. Chuyển khoản qua tài khoản ngân hàng ",
          list_1: [
            {
              id: 1,
              content:
                "Quý khách vui lòng chuyển khoản theo cú pháp sau: <ĐK>[Họ và tên][Số điện thoại][Gói học cần mua]",
            },
            {
              id: 2,
              content: "Chủ tài khoản: Công ty Cổ phần Công nghệ Giáo dục GK",
            },
            {
              id: 3,
              content: "Số tài khoản:",
            },
            {
              id: 4,
              content: "Ngân hàng: ",
            },
          ],
        },
      ],
    },

    data2: {
      title: "Lưu ý: ",
      data: [
        {
          id: 1,
          content:
            "Sau khi thanh toán thành công, quý khách vui lòng chụp lại màn hình thanh toán và liên hệ tổng đài 024 6281 3888 để được xác nhận thanh toán. ",
        },
        {
          id: 1,
          content:
            "Bộ sản phẩm sẽ được chuyển đến địa chỉ nhận hàng trong thời gian sớm nhất, chậm nhất 5 ngày kể từ thời điểm hoàn tất giao dịch. ",
        },
      ],
    },
  };

  // Handle change price package item
  const handleChangePrice = (value) => {
    switch (true) {
      case value == 10:
        return "9899000";
      case value < 25 && value > 10:
        return (890000 * value)?.toString();
      case value == 25:
        return "19999000";
      case value > 25 && value <= 9999:
        return (890000 * value * 0.9)?.toString();
      case !value:
        // setAccountNumber(0);
        return "9899000";
        s;
      default:
        return "9899000";
    }
  };

  // Handle Change Account Number
  const handleChangeAccountNumber = (newValue) => {
    if (validateNumber(newValue) <= 9999) {
      if (openPopUpAccount == "errorText") {
        setOpenPopUpAccount("");
      }
      if (validateNumber(newValue) < 10) {
        setOpenPopUpAccount("errorText");
      }
      setAccountNumber(validateNumber(parseInt(newValue, 10).toString()));
      setCurrPriceSpecific(handleChangePrice(validateNumber(newValue)));
    }
  };

  // Handle Action account number
  const handleActionAccount = (type) => {
    switch (type) {
      case "increase":
        if (parseInt(accountNumber) + 1 <= 9999) {
          setAccountNumber(
            validateNumber((parseInt(accountNumber) + 1).toString())
          );

          setCurrPriceSpecific(
            handleChangePrice(
              validateNumber((parseInt(accountNumber) + 1).toString())
            )
          );

          if (parseInt(accountNumber) + 1 >= 10) {
            setOpenPopUpAccount("");
          }
        }
        return;
      case "decrease":
        if (parseInt(accountNumber) - 1 >= 10) {
          setAccountNumber(
            validateNumber((parseInt(accountNumber) - 1).toString())
          );
          setCurrPriceSpecific(
            handleChangePrice(
              validateNumber((parseInt(accountNumber) - 1).toString())
            )
          );
        }
        return;
      default:
        setAccountNumber(validateNumber(accountNumber));
    }
  };

  // Handle Resetr Account
  const handleResetAccount = () => {
    setOpenPopUpAccount("");
    setAccountNumber(25);
    setCurrPriceSpecific(handleChangePrice(25));
  };

  return {
    listPackagesTeacher,
    dataRule4,
    dataPolicyPayment,
    accountNumber,
    currPriceSpecific,
    openPopUpAccount,
    setOpenPopUpAccount,
    handleNavigatePaymentTeacher,
    handleChangeAccountNumber,
    handleActionAccount,
    handleResetAccount,
  };
};
