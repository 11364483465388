import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { teacherConstants, userConstants } from "./../../../_constants";
import { useParams } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import moment from "moment";
import _ from "lodash";
import { teacherService } from "../../../_services";
import { SelectAsDiv } from "../../../_components/Select";

function SendMessage() {
  const { id } = useParams();
  const [statusForm, setStatusForm] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [detailMessage, setDetailMessage] = useState({});
  const [dataContact, setDataContact] = useState({});
  const [activeIndex, setActiveIndex] = useState("0");

  const [inputs, setInputs] = useState({
    room_id: "",
    to_user_id: "",
    subject: "",
    content: "",
    reply_for_id: "",
    class_id: id,
    type_filter: "all",
  });
  const { content, type_filter } = inputs;
  const authentication = useSelector((state) => state.authentication);
  const classes = dataContact ? dataContact.data_contact : "";
  const baseUrl = dataContact ? dataContact.base_url : "";
  const [dataFilter, setdataFilter] = useState([]);
  let [stt, setStt] = useState(false);
  const [roomChat, setRoomChat] = useState("0");
  const [isLoading, setLoading] = useState(false);
  const [isFocusSearch, setFocusSearch] = useState(false)

  useEffect(() => {
    if (classes) {
      setdataFilter(classes);
    }
  }, [classes]);

  useEffect(() => {
    if (roomChat > 0) {
      teacherService.getDetailInbox(roomChat).then((data) => {
        setDetailMessage(data);
      });
    }
  }, [roomChat]);

  useEffect(() => {
    setLoading(true);
    teacherService.getDetailContact(id, authentication.role).then((data) => {
      setDataContact(data);
      setLoading(false);
    });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleChangeOption(type_filter) {
    setInputs({
      ...inputs,
      type_filter,
    });
    if (type_filter === "all") {
      if (_.isEmpty(textSearch)) {
        setdataFilter([...classes]);
      } else {
        setdataFilter([
          ..._.filter(
            classes,
            (data) =>
              data.fullname !== null &&
              replaceTextSearch(data.fullname).includes(
                replaceTextSearch(textSearch)
              )
          ),
        ]);
      }
    } else if (type_filter === "parent") {
      setdataFilter([
        ..._.filter(
          classes,
          (data) =>
            data.user_role === type_filter &&
            data.child_name !== null &&
            replaceTextSearch(data.child_name).includes(
              replaceTextSearch(textSearch)
            )
        ),
      ]);
    } else {
      setdataFilter([
        ..._.filter(
          classes,
          (data) =>
            data.user_role === type_filter &&
            data.fullname !== null &&
            replaceTextSearch(data.fullname).includes(
              replaceTextSearch(textSearch)
            )
        ),
      ]);
    }

    setStatusForm(false);
  }

  function replaceTextSearch(textSearch) {
    return textSearch
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  function handleInputChange(e) {
    setTextSearch(replaceTextSearch(e.target.value));
    setStt(true);
    if (!e.target.value) {
      if (type_filter === "all") {
        setdataFilter([...classes]);
      } else {
        setdataFilter([
          ..._.filter(classes, (data) => data.user_role === type_filter),
        ]);
      }
    } else {
      if (type_filter === "all") {
        setdataFilter([
          ..._.filter(
            classes,
            (data) =>
              data.fullname !== null &&
              replaceTextSearch(data.fullname).includes(
                replaceTextSearch(e.target.value)
              )
          ),
        ]);
      } else if (type_filter === "parent") {
        setdataFilter([
          ..._.filter(
            classes,
            (data) =>
              data.user_role === type_filter &&
              data.child_name !== null &&
              replaceTextSearch(data.child_name).includes(
                replaceTextSearch(e.target.value)
              )
          ),
        ]);
      } else {
        setdataFilter([
          ..._.filter(
            classes,
            (data) =>
              data.user_role === type_filter &&
              data.fullname !== null &&
              replaceTextSearch(data.fullname).includes(
                replaceTextSearch(e.target.value)
              )
          ),
        ]);
      }
    }
    setStatusForm(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateParam()) {
      teacherService.sendMessage(inputs).then(() => {
        teacherService.getDetailInbox(roomChat).then((data) => {
          setDetailMessage(data);
          executeScroll();
        });
      });
    }
    setInputs((inputs) => ({ ...inputs, content: "" }));
  }

  function handleClickPersonal(e, user_id, index) {
    e.preventDefault();
    setActiveIndex(index);
    setInputs((inputs) => ({ ...inputs, to_user_id: user_id }));
    setStatusForm(true);

    teacherService.getRoomChat(user_id).then((dataRoom) => {
      setRoomChat(dataRoom.room_id);
      teacherService.getDetailInbox(dataRoom.room_id).then((data) => {
        setDetailMessage(data);
        executeScroll();
      });
    });
  }

  function validateParam() {
    return content ? true : false;
  }

  function diffTime(startTimeString) {
    const start = moment(startTimeString).toDate().getTime();
    const end = moment().toDate().getTime();
    const timespan = end - start;
    const duration = moment(timespan);
    return millisToMinutesAndSeconds(duration);
  }
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    // var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  function handleKeyPress(event) {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      handleSubmit(event);
    }
  }

  const myRef = useRef(null);
  
  const executeScroll = () => myRef.current.scrollIntoView();
  
  return (
    <div className="sunE-container flex flex bg-main-img-teacher">
      <div className="sunE-right-container rel">
        <Header title="Tin nhắn mới" isBack />
        <div className="flex-m search-gr-custom">
          <div className="flex-1" />
          {authentication.role === userConstants.ROLE_TEACHER && (
            <SelectAsDiv
              // disabled={true}
              keySelected={inputs?.type_filter}
              keyName="name"
              textDefault={""}
              data={teacherConstants.TEXT_LIST_FILTER_TYPE_MESSAGE}
              onChangeSelect={(type) => handleChangeOption(type)}
              className="select-message"
              styleBox={{
                justifyContent: "center",
                paddingRight: '16px'
              }}
              styleItem={{
                justifyContent: 'center',
                textIdent: 'unset'
              }}
            />
          )}

          {authentication.role === userConstants.ROLE_TEACHER && (
            <div className="_line_box">
              <span className="_line"></span>
            </div>
          )}
          <div
            className={
              authentication.role !== userConstants.ROLE_TEACHER
                ? "rel student-search"
                : "rel"
            }
          >
            <input
              // name="content"
              style={{textAlign: (isFocusSearch || textSearch) ? "left" : "center"}}
              placeholder={isFocusSearch ? "" : "Tìm người nhận"}
              type="text"
              onChange={handleInputChange}
              className="search-custom"
              onFocus={() => setFocusSearch(true)}
              onBlur={() => setFocusSearch(false)}
            />
            <img
              src="/assets/images/tinnhan/ico_search.png"
              alt="ico_search"
              className="icon_search"
            />
          </div>
          <div className="flex-1" />
        </div>

        <div className="sunE-content tinnhan">
          <div className="flex-m">
            {!isEmpty(classes) ? (
              <div className="flex-1">
                <div className="box-shadow custom-list-student class_student_list_box">
                  <div className="list-student">
                    {/* {textSearch === "" &&
                      dataFilter?.length > 0 &&
                      dataFilter
                        .filter((it) => it.user_role === "student")
                        ?.map((data, i) => {
                          let className = "";
                          if (activeIndex === i) {
                            className = "msg-active";
                          }
                          return (
                            <div
                              className={"item-student flex-m " + className}
                              key={i}
                              onClick={(e) =>
                                handleClickPersonal(
                                  e,
                                  data.id || data.user_id,
                                  i
                                )
                              }
                            >
                              <span className="stt">{i + 1}</span>
                              <div className="item-student-img bd-orange">
                                <img
                                  src={
                                    !isEmpty(data.avatar)
                                      ? baseUrl + data.avatar
                                      : "/assets/images/teacher/student.png"
                                  }
                                  alt="student"
                                />
                              </div>
                              <div className="flex-1 item-student-name">
                                {type_filter === "parent" &&
                                  data.child_name && (
                                    <p className="one-line">
                                      {"Phụ huynh: " + data.child_name || ""}
                                    </p>
                                  )}
                                {type_filter !== "parent" && (
                                  <p className="one-line">
                                    {data.fullname || ""}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        })} */}
                    {dataFilter?.length > 0 &&
                      dataFilter
                        .filter((it) => (it.user_role === type_filter || type_filter === "all"))
                        .map((data, i) => {
                          let className = "";
                          if (activeIndex === i) {
                            className = "msg-active";
                          }
                          return (
                            <div
                              className={"item-student flex-m " + className}
                              key={i}
                              onClick={(e) =>
                                handleClickPersonal(
                                  e,
                                  data.id || data.user_id,
                                  i
                                )
                              }
                            >
                              <span className="stt">{i + 1}</span>
                              <div className="item-student-img bd-orange">
                                <img
                                  src={
                                    !isEmpty(data.avatar)
                                      ? baseUrl + data.avatar
                                      : "/assets/images/teacher/student.png"
                                  }
                                  alt="student"
                                />
                              </div>
                              <div className="flex-1 item-student-name" style={{display: 'flex', alignItems: 'center'}}>
                                <p className="one-line" style={{width: '100%'}}>
                                  {(data?.user_role === userConstants.ROLE_PARENT && !!data?.list_child_name?.length) ? (
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                                      <div>{data?.fullname || ''}</div>
                                      <div style={{fontSize: '13px', fontStyle: 'italic', opacity: 0.8}}>{`Phụ huynh: ` + data?.list_child_name?.join(', ') }</div>
                                    </div>
                                  ) : (
                                    <>
                                    {data?.fullname || ""}
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        })}

                    {!isLoading && (isEmpty(dataFilter) || dataFilter.length === 0) && (
                      <div className="flex-1 item-student-name">
                        <p>Không tìm thấy kết quả</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (!isLoading && (
              <div
                className="__text_empty_tutorial_guide"
                style={{ width: "100%" }}
              >
                Không có dữ liệu.
              </div>
            ))}
            {statusForm && (
              <div className="flex-1 mes-content">
                <div className="mex-box pad20 flex-column cus-hei-2">
                  <div className="scrollbar-custom mex-box-height-2 flex-1">
                    {!isEmpty(detailMessage) &&
                      detailMessage.data.map((data, i) => {
                        return (
                          <div
                            className={
                              "mex-info-box rel" +
                              (data.user_send_id === authentication.id
                                ? ""
                                : " isMe")
                            }
                            key={i}
                            id={i}
                          >
                            <span className="send-name one-line">
                              {data.user_send_id === authentication.id
                                ? ""
                                : data.fullname}
                            </span>
                            {data.user_send_id !== authentication.id && (
                              <div className="mes-avt">
                                <img
                                  src={data ? baseUrl + data.user_avatar : ""}
                                  alt="avatar"
                                  className="mes-avt-img"
                                />
                              </div>
                            )}
                            <p>{data.msg}</p>
                            {diffTime(data.send_time) > 59 && (
                              <span className="time-send">
                                {moment(data.send_time).format(
                                  "HH:mm, DD/MM/YYYY"
                                )}
                              </span>
                            )}
                            {diffTime(data.send_time) <= 59 && (
                              <span className="time-send">
                                {diffTime(data.send_time) <= 1
                                  ? "Vừa xong"
                                  : diffTime(data.send_time) + " phút trước"}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    <div ref={myRef}></div>
                  </div>
                  <form onSubmit={handleSubmit} noValidate={true}>
                    <div className="sunE-input-gr flex-m search-gr-p">
                      <div className="flex-1">
                        <textarea
                          name="content"
                          onChange={handleChange}
                          placeholder="Soạn nội dung..."
                          type="text"
                          value={content}
                          className="pr45 mes-box-send"
                          autoComplete="off"
                          onKeyPress={handleKeyPress}
                        ></textarea>
                      </div>
                      <div className="box-btn-send center-flex">
                        <button
                          className={
                            "send-mes" +
                            (!validateParam() ? " btn-disable" : "")
                          }
                        >
                          <img
                            src="/assets/images/tinnhan/ico_send_mes.png"
                            alt="send message"
                          />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { SendMessage };
