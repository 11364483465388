import { apiCaller, apiRequest } from "../_helpers";

export async function logoutAuth(deviceId) {
  try {
    let data = {
      method: "POST",
    };

    const res = await apiRequest("/api_user/logout", data);

    // console.log("logoutAuth", res);
  } catch (e) {}
}

export async function getLocationService() {
  try {
    let data = {
      method: "GET",
    };
    const res = await apiRequest("/web_advertise/api_lecture/list_address", data);
    return res;
  } catch (e) {}
}

export async function getListClassService() {
  try {
    const res = await apiRequest("/api_login/grade", "GET", {}, null, false);
    return res;
  } catch (e) {}
}

export async function deleteAccount() {
  try {
    let data = {
      method: "PUT",
    };
    const res = await apiRequest("/api_user/delete", data);
    return res;
  } catch (e) {}
}

// Send Msg
export function sendMsgContact(data) {
  return apiCaller(
    `/web_advertise/api_contact/send_contact`,
    "POST",
    data,
    null,
    true
  );
}

export const getProvinces = () => {
  return apiCaller(
    '/api_category/provinces?country_code=vn',
    "GET",
    {},
    null,
    true,
  );
}

export const getDistricts = (provinceAlias) => {
  return apiCaller(
    `/api_category/districts?province_alias=${provinceAlias}`,
    "GET",
    {},
    null,
    true,
  );
}