import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { PopUpYesNo } from "../../../_components/Popup";
import { teacherService } from "../../../_services";
import $ from "jquery";

function HomePageExamCard() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { id } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const classes = useSelector((state) => state.classes);
  const [isLoading, setLoading] = useState(false);
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false);
  const [idCardSelected, setIdCardSelected] = useState();
  const [isShowPopupAlert, setIsShowPopupAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getExamcardOffline(id));
  }, []);

  function componentAdd() {
    return (
      <Link
        to={"/" + authentication.role + "/class/view/" + id + "/exam_card/add"}
      >
        <div className="form-sunE-button btn-create-diemdanh rel mr-10">
          <img
            src="/assets/images/teacher/lichlamviec/ico_add_small.png"
            alt="ico_add"
          />
          <button className="btn-line-blue">Tạo phiếu điểm</button>
        </div>
      </Link>
    );
  }

  // console.log(classes.exam_card.list_exam_type);

  function getTypeExam(dataExam) {
    let typeResult = "";
    if (classes.exam_card) {
      classes.exam_card.list_exam_type.map((typeData) => {
        if (dataExam.type == typeData.type) {
          typeResult = typeData.name;
        }
      });
    }
    return typeResult;
  }

  // Handle Popup delete exam card
  const handleOpenPopUp = (id) => {
    setIsShowPopupDelete(true);
    // console.log(id);
    setIdCardSelected(id);
  };

  const handleDeletedCard = async () => {
    try {
      if (idCardSelected) {
        const res = await teacherService.deleteScoreBr(idCardSelected);
        console.log(res);
        if (res?.status) {
          setMessageAlert("Xóa phiểu điểm thành công!");
          setIsShowPopupDelete(false);
          setIsShowPopupAlert(true);
          dispatch(teacherActions.getExamcardOffline(id));
        } else {
          setMessageAlert(res?.data?.message);
        }
      }
    } catch (e) {
      setMessageAlert(e?.toString());
    }
  };

  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={"Quản lý phiếu điểm"}
        component={componentAdd()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id)
        }
      />
      <div className="sunE-container-box diemdanh offline flex-1">
        {!isLoading &&
          (isEmpty(classes.exam_card.data) ? (
            <div className="flex-m">
              <div className="diemdanh-no-item text-center">
                <img
                  src="/assets/images/teacher/diemdanh/bg_diemdanh_no_item.png"
                  alt="bg_diemdanh_no_item"
                />
                <p>
                  Bạn chưa có phiếu điểm nào,
                  <br />
                  Hãy bắt đầu tạo phiếu điểm lớp của bạn!
                </p>
              </div>
            </div>
          ) : (
            <div class="diemdanh-container">
              <h2 class="medium-title f-24">Danh sách phiếu Điểm kiểm tra</h2>
              <div className="phieudiem diemdanh-list scrollbar-custom">
                {classes.exam_card.data.map((data, i) => {
                  return (
                    <div key={i} style={{ position: "relative" }}>
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/exam_card/" +
                          data.card_id +
                          `?date=${moment(data.origin_date_test)}&examName=${data.exam_name}`
                        }
                        key={i}
                      >
                        <div class="diemdanh-item box-shadow flex-m">
                          <div className="phieudiem-box">
                            <div className="flex-m">
                              <div>
                                <img
                                  src="/assets/images/teacher/diemdanh/ico_date.png"
                                  alt="ico_date"
                                />
                              </div>
                              <p className="flex-1">
                                {moment(data.origin_date_test).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                            {/* <div className="flex-m">
                                                    <div>
                                                        <img src="/assets/images/teacher/diemdanh/ico_clock_orange.png" alt="ico_clock_orange" />
                                                    </div>
                                                    <p className="flex-1">{moment(data.origin_date_test).format('HH:mm')}</p>
                                                </div> */}
                            <div
                              className="flex-m"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div>
                                <img
                                  src="/assets/images/teacher/diemdanh/ico_luyenthiact.png"
                                  alt="ico_date"
                                  style={{
                                    width: "1.3rem",
                                    height: "auto",
                                  }}
                                />
                              </div>
                              <p className="flex-1">{getTypeExam(data)}</p>
                            </div>

                            <div className="flex-m">
                              <div>
                                <img
                                  src="/assets/images/teacher/diemdanh/ico_clock.png"
                                  alt="ico_clock"
                                />
                              </div>
                              <p className="flex-1">
                                {data?.semester == "1"
                                  ? "Học kỳ I"
                                  : "Học kỳ II"}
                              </p>
                            </div>
                          </div>
                          <div className="flex-1 phieudiem-info">
                            <p>{data.exam_name}</p>
                          </div>
                        </div>
                      </Link>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "2.5rem",
                          top: "2.5rem",
                          right: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenPopUp(data.card_id)}
                      >
                        <img
                          src="/assets/images/icon/ico_remove.png"
                          alt="ico_remove"
                          style={{
                            width: "1.5rem",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
      {isShowPopupDelete && (
        <PopUpYesNo
          onClickYes={() => handleDeletedCard()}
          onClickNo={() => setIsShowPopupDelete(false)}
          labelNo="Không"
          laelYes="Có"
          message={["Bạn có chắc chắn muốn xóa bảng điểm này?"]}
        />
      )}

      {isShowPopupAlert && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => setIsShowPopupAlert(false)}
          labelYes="Đóng"
          message={messageAlert}
        />
      )}
    </div>
  );
}

export { HomePageExamCard };
