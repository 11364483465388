import { useEffect, useState } from "react";
import { history } from "../../../../_helpers";
import { userConstants } from "../../../../_constants";
import { useDispatch, useSelector } from "react-redux";

export const placementTestPage = () => {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const [goToTest, setGoToTest] = useState(false);
  const [showTest, setShowTest] = useState(false);

  let infoTesting =
    authentication.grade_id == 2
      ? { quantityQuestion: 30, time: 25 }
      : { quantityQuestion: 35, time: 30 };

  const handleClickPassed = () => {
    dispatch({ type: userConstants.OFF_ROOTLESSNESS });
    dispatch({ type: userConstants.HIDE_SIDEBAR_ROOTLESSNESS });
    dispatch({type: userConstants.ON_CHOOSE_LIST_CURRICULUM});
    history.push(`/${userConstants.ROLE_STUDENT}/curriculum`);
  };

  const handleClickToStartTest = () => {
    setGoToTest(true);
    setTimeout(() => {
      setShowTest(true);
      dispatch({ type: userConstants.ON_PLACEMENT_TEST });
    }, 5000);
  };

  useEffect(() => {
    // Clear timeout khi component unmount
    return () => {
      const timeoutId = handleClickToStartTest
      clearTimeout(timeoutId);
    };
  }, []);

  const onClose = () => {
    dispatch({ type: userConstants.OFF_PLACEMENT_TEST });
    setGoToTest(false);
    setShowTest(false);
  };

  return {
    goToTest,
    infoTesting,
    handleClickPassed,
    handleClickToStartTest,
    showTest,
    onClose,
  };
};
