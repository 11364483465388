import './RootCheckBox.style.scss'

export default function RootCheckBox({label, checked, classNameContainer, onClick, ...other}) {
  return (
    <div className={"root-check-box " + classNameContainer} onClick={onClick}>
      <label>{label}</label>
      <div className={'check-box-div ' + (checked ? 'checked' : '')} {...other}>
        {checked && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M437.3 30L202.7 339.3L64 200.7l-64 64L213.3 478L512 94z"/></svg>}
      </div>
    </div>
  )
}