import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { configConstants, curriculumConstants } from "../../../_constants";
import { ContentSkill } from "../Curriculum";
import { Header } from "../../../_components/Admin/Header";
import LazyLoad from "react-lazyload";
import { isEmpty } from "lodash";
import { apiRequestLogout } from "../../../_helpers";
import API from "../../../_apis/APIConstants";
import { useRef } from "react";
import { PopUpYesNo } from "../../../_components/Popup";
import { Alert } from "../../../_components/Alert";

function ViewCurriculumPersonal() {
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const curriculums = useSelector((state) => state.curriculums);
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);

  const [typeCurriculum, setTypeCurriculum] = useState(type);
  const [listCuriculum, setListCurriculum] = useState([]);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");
  const dataServiceTeacher = useSelector(
    (state) => state.classes.dataServiceTeacher
  );
  const scrollCurriculumFavorite = useRef(null);
  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   if (scrollCurriculumFavorite && scrollCurriculumFavorite.current) {
  //     scrollCurriculumFavorite.current.addEventListener(
  //       "scroll",
  //       handleScrollFavorite
  //     );
  //     return () =>
  //       scrollCurriculumFavorite.current?.removeEventListener(
  //         "scroll",
  //         handleScrollFavorite
  //       );
  //   }
  // }, [scrollCurriculumFavorite, offset, isLoadMore]);

  const [isLoading, setLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const handleScrollFavorite = (e) => {
    if (
      scrollCurriculumFavorite &&
      scrollCurriculumFavorite.current?.scrollTop +
        scrollCurriculumFavorite.current?.clientHeight +
        5 >=
        scrollCurriculumFavorite.current?.scrollHeight &&
      !isLoading &&
      isLoadMore
    ) {
      fetchDataCurriculumFavorite(offset + configConstants.DEFAULT_LIMIT);
    }
    setScrollPosition(scrollCurriculumFavorite.current?.scrollTop);
  };

  const fetchDataCurriculumFavorite = (offset_Data) => {
    if ((!isLoading && offset != offset_Data) || offset_Data == 0) {
      setLoading(true);
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          offset_Data
        )
        .then((curriculum) => {
          setLoading(false);
          if (!hasDuplicates(curriculum?.data, listCuriculum)) {
            setListCurriculum((prev) => [...prev, ...curriculum?.data]);
            setOffset(offset_Data);
            setLoadMore(
              curriculum?.data?.length === configConstants.DEFAULT_LIMIT
            );
          }
          if (scrollPosition > 0 && !isLoading) {
            scrollCurriculumFavorite.current.scrollTop = scrollPosition;
          }
        });
    }
  };

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  useEffect(() => {
    changeType(typeCurriculum);
  }, []);

  function changeType(typeCurriculum) {
    setTypeCurriculum(typeCurriculum);
    setLoading(true);
    setListCurriculum([]);
    if (typeCurriculum === "personal") {
      // dispatch({
      //     type: curriculumConstants.GET_LIST_CURRICULUM,
      //     curriculums: []
      // })
      teacherActions.getCurriculumPersonal(typeCurriculum).then((res) => {
        setListCurriculum(res || []);
        setLoading(false);
      });
    } else {
      // dispatch({
      //     type: curriculumConstants.GET_LIST_CURRICULUM,
      //     curriculums: []
      // })
      if (scrollCurriculumFavorite?.current) {
        scrollCurriculumFavorite.current.scrollTop = 0;
      }
      setOffset(0);
      setLoadMore(true);

      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          0
        )
        .then((curriculum) => {
          setListCurriculum(curriculum?.data || []);
          setLoading(false);
        });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
    }
  }

  // Handle go to service
  const handleGotoService = async () => {
    if (
      authentication?.role == "teacher" &&
      (authentication?.package?.package_type?.toLowerCase() != "trial" ||
        authentication?.package?.remaining_day > 0)
    ) {
      await apiRequestLogout(`${API.logout_account_service}`);
      await apiRequestLogout(`${API.logout_account_exercise}`);
      if (
        !isEmpty(dataServiceTeacher.login_id) &&
        !isEmpty(dataServiceTeacher.teacher_redirect_url)
      ) {
        window.location.href = `${dataServiceTeacher.teacher_redirect_url}?signal=${dataServiceTeacher.login_id}`;
      } else {
        window.location.href = `${configConstants.BASE_URL}curriculum`;
      }
    } else {
      setIsLocked(true);
    }
  };

  // Add Component Create curriculum
  function addComponentCreate() {
    return (
      <div
        className='form-sunE-button btn-create-taomoi rel'
        onClick={handleGotoService}
      >
        <img
          src='/assets/images/teacher/lichlamviec/ico_add.png'
          alt='ico_add'
        />
        <button className='btn-line-blue'>Giáo trình mới</button>
      </div>
    );
  }

  // Go to Upgrade Account
  const gotoUpgradePremiumAccount = () => {
    history.push("/" + authentication.role + "/more/license");
    setIsLocked(false);
  };

  return (
    <div className='sunE-right-container'>
      <Header
        title={"Giáo trình Cá nhân"}
        component={addComponentCreate()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/curriculum")
        }
      />
      <div className='sunE-container-box giaotrinh canhan pad'>
        <div className='gtcn-menu-gr'>
          <span
            className={typeCurriculum === "personal" ? " active" : ""}
            onClick={() => changeType("personal")}
          >
            {" "}
            Cá nhân
          </span>
          <span
            className={typeCurriculum === "favorite" ? " active" : ""}
            onClick={() => changeType("favorite")}
          >
            Yêu thích
          </span>
        </div>
        {typeCurriculum !== "favorite" ? (
          <div className='uk-container'>
            <div className='uk-grid'>
              {listCuriculum.length > 0 &&
                listCuriculum.map((data, i) => {
                  return (
                    <div className=' uk-width-1-2' key={i}>
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/curriculum/view/detail/personal/" +
                          data.id +
                          (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
                        }
                        key={i}
                      >
                        <div className='box-giaotrinh-gr rel box-shadow'>
                          <div className='thumb3'>
                            <img
                              src='/assets/images/giaotrinh/ico_gt.png'
                              alt='ico_gt_sun'
                              style={{
                                width: '40px',
                                height: '40px'
                              }}
                            />
                          </div>
                          <div className='box-select-info'>
                            <p>{data.name || data.unit_name}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              {listCuriculum.length === 0 && !isLoading && (
                <div className='__text_empty_curiclum_personal'>
                  Bạn chưa có giáo trình cá nhân nào. Hãy ấn{" "}
                  <strong className='__semibold'>"Giáo trình mới"</strong> để
                  tạo giáo trình cho mình.
                </div>
              )}
            </div>
          </div>
        ) : (
          <ContentSkill
            screen='CURRICULUM_PERSONAL_FAVORITE'
            data={listCuriculum}
            btn={false}
            fullWish={true}
            textEmpty={!isLoading ? "Bạn chưa có bài tập yêu thích nào." : ""}
            typeCurriculum={typeCurriculum}
            fromViewCurriculumPersonal
            scrollCurriculumFavorite={scrollCurriculumFavorite}
            handleScrollFavorite={handleScrollFavorite}
          />
        )}
      </div>

      {isLocked ? (
        <PopUpYesNo
          width={"360px"}
          onClickYes={gotoUpgradePremiumAccount}
          onClickNo={() => setIsLocked(false)}
          message={
            "Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!"
          }
          labelNo={"Đóng"}
          labelYes={"Nâng cấp"}
        />
      ) : null}
      {alert.message && 
        <Alert alert={alert} />
      }
    </div>
  );
}

export { ViewCurriculumPersonal };
