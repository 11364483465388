import { profileUserLogic } from "./ProfileUser.logic";
import "./ProfileUser.style.scss";
import { HeaderNews } from "../../_components/Header";
import ButtonNews from "../../_components/Button/ButtonNews";
import FooterAuth from "../../_components/FooterNews/FooterAuth";
import InputText from "../../_components/Auth/InputText";
import InputSelect from "../../_components/Auth/InputSelect";
import InputDate from "../../_components/Auth/InputDate";
import GradeSelection from "../../_components/GradeSelection/GradeSelection";
import { userConstants } from "../../_constants";
import { useSelector, useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../_constants/headerNews";
import TitleNews from "../../_components/Header/HeaderNews/TitleNews";
import { convertTimePackage, validatePrice } from "../../_base/Validate";
import { Alert } from "../../_components/Alert";
import { history } from "../../_helpers";
import { teacherActions } from "../../_actions";
import { validateTime } from "../../_base/Validate";

const ProfileUser = () => {
  let {
    dataProfile,
    inputs,
    genderList,
    handleChangeFile,
    openSelectFile,
    validateParam,
    handleChangeInput,
    provinceList,
    districtList,
    dataGrades,
    handleClickGrade,
    dataTypeSchool,
    handleClickTypeSchool,
    changeBirthday,
    setInputs,
    handleChangeSelect,
    schoolList,
    dataPaymentHistory,
    emailError,
    setEmailError,
    phoneWarning,
    setPhoneWarning,
    onBlurField,
    handleUpdateProfile,
  } = profileUserLogic();

  const authentication = useSelector((state) => state.authentication);
  const baseUrl = useSelector((state) => state.profile.base_url);
  const alert = useSelector((state) => state.alert);

  const _dispatch = useDispatch();

  const renderGrade = (id) => {
    switch (id) {
      case "2":
        return "6";
      case "4":
        return "7";
      case "3":
        return "8";
      case "6":
        return "9";
      default:
        return "";
    }
  };

  const renderTypeSchool = (school_type) => {
    switch (school_type) {
      case userConstants.SCHOOL_TYPE_FREE:
        return "Tự do";
      case userConstants.SCHOOL_TYPE_PUBLIC:
        return "Công lập";
      case userConstants.SCHOOL_TYPE_PRIVATE:
        return "Dân lập";
      default:
        return "";
    }
  };

  const renderAuthIcon = (type) => {
    return (
      <div
        className={`flex-center ${
          type == "district" && " hide display_flex_mobile"
        }`}
      >
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  const renderPaymentHistory = (item) => {
    return (
      <tr key={item.id} className="text-align-center table_row_item">
        <td className="fz-09-mobile">
          {item.name}{" "}
          <span className="fz-08-mobile font-weight-500">
            {item.duration ? convertTimePackage(item?.duration) : "(vĩnh viễn)"}
          </span>
        </td>
        <td className="fz-09-mobile ">{validateTime(item?.date_buy)}</td>
        <td className="fz-09-mobile break_word_all pos_rel">
          <span className="fz-09-mobile break_word_all pos_rel">
            {validatePrice(item.cur_price)}
            <div className="ultil_price_payment"></div>
          </span>
        </td>
        <td className="fz-09-mobile">
          {item.status == 1 ? "Đã kích hoạt" : "Chưa kích hoạt"}
        </td>
        <td className="fz-09-mobile ">
          {item.date_active ? validateTime(item.date_active) : "-"}
        </td>
        <td className="fz-09-mobile break_word_all">{item.email_active}</td>
      </tr>
    );
  };

  const renderBoxPayment = (item) => {
    return (
      <div key={item?.id} className="box_payment_item flex-column-mobile">
        <div className="font_news_bold fz-11-mobile mg-bottom-1">
          {item?.name}{" "}
          <span className="fz-09-mobile font-weight-500">
            {item.duration
              ? convertTimePackage(item?.duration)
              : " (vĩnh viễn)"}
          </span>
        </div>
        <div className="flex-jus-between pos_rel mg-bottom-1">
          <div className="col-50">
            <div className="pos_rel color-red font_news_bold  font_news_bold fz-15-mobile">
              {validatePrice(item.cur_price)}
              <div className="ultil_price_right"></div>
            </div>
          </div>

          <div className="flex-center item_left_divider">
            <img
              src="/assets/images/icon/ico_date_buy.png"
              alt="Icon Date Buy"
              className="contain_image icon_box_payment mg-right-0-5"
            />
            <div className="fz-08-mobile">{validateTime(item?.date_buy)}</div>
          </div>
        </div>
        <div className="flex-jus-between pos_rel  mg-bottom-1">
          <div className="flex-align col-fixed-50">
            <span className="mg-right-0-5 fz-08-mobile">
              {item.status == 1 ? "Đã kích hoạt" : "Chưa kích hoạt"}
            </span>
            <img
              src={`/assets/images/icon/${
                item.status == 1 ? "ico_box_tick" : "ico_box"
              }.png`}
              alt="Icon Box Tick"
              className="contain_image icon_box_tick"
            />
          </div>

          <div className="flex-center item_left_divider">
            <img
              src="/assets/images/icon/ico_date_active.png"
              alt="Icon Date Active"
              className="contain_image icon_box_payment mg-right-0-5"
            />
            <div className="fz-08-mobile">
              {item?.date_active
                ? validateTime(item?.date_active)
                : "_________"}
            </div>
          </div>
        </div>

        <div className="flex-align">
          <span className="mg-right-0-5 fz-08-mobile">
            Tài khoản kích hoạt:
          </span>
          <span className="color-primary-green fz-08-mobile font_news_bold break_word_all">
            {item.email_active}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="profilePage commondPage registerNew">
      <HeaderNews type="profile" />
      {/* <TitleNews title={NameTitleNewsItem.PROFILE} /> */}

      <div
        style={{
          background: `url(
              /assets/images/authNews/bg_login.png
            ) no-repeat top center /cover`,
        }}
      >
        <div className="profile_title_container display_none_mobile">
          <div className="profile_title ">
            <div className="flex-align">
              <img
                src="/assets/images/icon/ico_profile.png"
                alt="Icon Profile"
                className="contain_image icon_profile"
              />
              <span className="mg-left-15 font_news_bold fz-20 color-white">
                Hồ sơ
              </span>
            </div>

            <div>
              <ButtonNews
                disabled={validateParam() ? false : true}
                width="10rem"
                height="2.8rem"
                onClick={(e) => handleUpdateProfile(e)}
                border={`1px solid ${validateParam() ? "#00cc83" : "#fff"}`}
                backgroundColor={"#fff"}
                maxWidth={"15vw"}
                maxHeight={"7vw"}
              >
                <img
                  // loading="lazy"
                  src={`/assets/images/icon/${
                    validateParam() ? "ico_save" : "ico_save_white"
                  }.png`}
                  alt="Icon Save"
                  className="contain_image icon_save_profile mg-right-1"
                />
                <div
                  className={`btn_text fz-1 font_news_bold ${
                    validateParam() ? " color-primary-green " : " color-white"
                  } `}
                >
                  LƯU LẠI
                </div>
              </ButtonNews>
            </div>
          </div>
        </div>

        <div className="content_profile">
          <div className="flex-align profile_edit_container col-100">
            <div className="flex-center-column col-40  avt_container">
              <img
                // loading="lazy"
                id="preview-avatar"
                src={dataProfile?.avatar}
                alt="Avatar User"
                className="cover_image img_avt_profile"
              />

              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={handleChangeFile}
                id="avatar"
                className="hide"
              />

              <div
                className="flex-align mg-top-1 pointer_cursor"
                onClick={openSelectFile}
              >
                <img
                  src="/assets/images/hoso/ico_edit_avt.png"
                  alt="Icon Edit"
                  className="contain_image icon_edit_avt mg-right-0-5"
                />
                <span className="font_news_bold fz-09-mobile  text-align-center">
                  Ảnh đại diện
                </span>
              </div>
            </div>

            <div className="flex-column form_input form_input_mobile col-60">
              <InputText
                className="mg-bottom-1 mg-bottom-1-mobile"
                value={inputs?.fullname}
                onChange={handleChangeInput}
                type="text"
                name="fullname"
                placeholder="Họ và tên"
                required={true}
                renderLabelIcon={() => renderAuthIcon("name")}
                onFocus={() =>
                  setInputs({
                    ...inputs,
                    fullname: inputs?.fullname?.trim(),
                  })
                }
              ></InputText>

              <InputText
                readOnly={true}
                className="mg-bottom-1 mg-bottom-1-mobile"
                value={inputs?.email}
                onChange={handleChangeInput}
                type="text"
                name="email"
                placeholder="Địa chỉ Email xác thực tài khoản"
                required={true}
                renderLabelIcon={() => renderAuthIcon("email")}
              ></InputText>

              <InputText
                className="mg-bottom-1 mg-bottom-1-mobile"
                value={inputs?.phone}
                onChange={handleChangeInput}
                type="text"
                name="phone"
                placeholder="Số điện thoại"
                required={true}
                renderLabelIcon={() => renderAuthIcon("phone")}
                errorText={phoneWarning}
                setErrorText={setPhoneWarning}
                onBlur={() => {
                  onBlurField("phone");
                }}
                onFocus={() =>
                  setInputs({
                    ...inputs,
                    phone: inputs?.phone?.trim(),
                  })
                }
                // errorAbsolute={true}
              ></InputText>

              {authentication.role == userConstants.ROLE_STUDENT && (
                <InputText
                  className="mg-bottom-1 mg-bottom-1-mobile"
                  value={inputs?.email_parent}
                  onChange={handleChangeInput}
                  type="text"
                  name="email_parent"
                  placeholder="Địa chỉ Email của bố/ mẹ"
                  required={true}
                  renderLabelIcon={() => renderAuthIcon("email")}
                  errorText={emailError}
                  onBlur={() => {
                    onBlurField("email");
                  }}
                  setErrorText={setEmailError}
                ></InputText>
              )}

              <div className="flex-align col-100 flex-jus-between birthday_gender_container">
                <InputDate
                  className="field_birthday col-100 mg-bottom-15 mg-bottom-1-mobile"
                  value={inputs?.birthday}
                  setValue={changeBirthday}
                  name="birthday"
                  renderLabelIcon={() => renderAuthIcon("birthday")}
                  // errorText={birthdayWarning}
                  // setErrorText={setBirthdayWarning}
                  // errorAbsolute={true}
                ></InputDate>

                <InputSelect
                  colorInput="color-text"
                  fillColor="#4D4D4D"
                  classNameProps=" mg-bottom-1-mobile"
                  className="field_item field_gender col-fix-35"
                  value={inputs?.gender == "male" ? "Nam" : "Nữ"}
                  name="gender"
                  setValue={(option) => handleChangeSelect(option, "gender")}
                  options={genderList.map((item) => {
                    return {
                      value: item.value,
                      title: item.title,
                    };
                  })}
                  placeholder="Giới tính"
                  renderLabelIcon={() => renderAuthIcon("gender")}
                  mgBottom={"1.5rem"}
                ></InputSelect>
              </div>

              <div className="flex-align col-100 flex-jus-between inputs_address_mobile">
                <InputSelect
                  colorInput="color-text"
                  fillColor="#4D4D4D"
                  classNameProps=" mg-bottom-1-mobile"
                  className="field_item field_province col-60"
                  value={inputs?.province}
                  name="province"
                  setValue={(option) => handleChangeSelect(option, "province")}
                  options={provinceList.map((item) => {
                    return {
                      value: item.province_alias,
                      title: item.province,
                    };
                  })}
                  placeholder="Chọn Tỉnh/ Thành phố"
                  renderLabelIcon={() => renderAuthIcon("city")}
                  // errorText={errProvince}
                  // setErrorText={setErrProvince}
                  mgBottom={"1.5rem"}
                ></InputSelect>

                <InputSelect
                  colorInput="color-text"
                  fillColor="#4D4D4D"
                  className="field_item field_district col-fix-35"
                  value={inputs?.district}
                  classNameProps=" mg-bottom-1-mobile"
                  name="district"
                  setValue={(option) => handleChangeSelect(option, "district")}
                  options={districtList?.map((item) => {
                    return {
                      value: item.district_alias,
                      title: item.district,
                    };
                  })}
                  placeholder="Quận/ Huyện"
                  renderLabelIcon={() => renderAuthIcon("district")}
                  disabledClick={!inputs?.province}
                  // onClickDisable={() => {
                  //   setErrProvince("Vui lòng chọn tỉnh / thành phố");
                  // }}
                  // errorText={errDistrict}
                  // setErrorText={setErrDistrict}
                  mgBottom={"1.5rem"}
                ></InputSelect>
              </div>

              {authentication.role != userConstants.ROLE_PARENT && (
                <InputSelect
                  colorInput="color-text"
                  fillColor="#4D4D4D"
                  className="field_item field_school"
                  value={inputs?.school}
                  classNameProps=" mg-bottom-1-mobile"
                  name="school"
                  setValue={(option) => handleChangeSelect(option, "school")}
                  options={schoolList.map((item) => {
                    return {
                      value: item.school_name,
                      title: item.school_name,
                    };
                  })}
                  placeholder="Trường"
                  renderLabelIcon={() => renderAuthIcon("school")}
                  // errorText={errProvince}
                  // setErrorText={setErrProvince}
                  mgBottom={"1.5rem"}
                ></InputSelect>
              )}

              {(authentication.role == userConstants.ROLE_STUDENT ||
                authentication.role == userConstants.ROLE_PARENT) && (
                <div className="flex-align grade_container col-80">
                  <div className="flex-align title_grade">
                    <img
                      src="/assets/images/authNews/ico_grade.png"
                      alt="Icon Grade"
                      className="contain_image img_label_input"
                    />
                    <span className="fz-11 fz-08-mobile name_input_grade">
                      Khối lớp của con
                    </span>
                  </div>
                  <GradeSelection
                    dataGrades={dataGrades}
                    gradeSelected={renderGrade(inputs?.grade_id)}
                    handleClickGrade={handleClickGrade}
                  />
                </div>
              )}

              {authentication.role == userConstants.ROLE_TEACHER && (
                <div className="flex-align grade_container col-80">
                  <div className="flex-align title_grade">
                    <img
                      src="/assets/images/authNews/ico_grade.png"
                      alt="Icon Grade"
                      className="contain_image img_label_input"
                    />
                    <span className="fz-11 fz-08-mobile name_input_grade">
                      Bạn là giáo viên
                    </span>
                  </div>
                  <GradeSelection
                    dataGrades={dataTypeSchool}
                    gradeSelected={renderTypeSchool(inputs?.school_type)}
                    handleClickGrade={handleClickTypeSchool}
                    type="teacher"
                  />
                </div>
              )}
            </div>

            <div className="btn_save_container hide display_flex_mobile">
              <ButtonNews
                disabled={validateParam() ? false : true}
                width="33vw"
                height="3.3rem"
                onClick={(e) => handleUpdateProfile(e)}
                border={`1px solid ${validateParam() ? "#00cc83" : "#fff"}`}
                backgroundColor={"#fff"}
              >
                <img
                  // loading="lazy"
                  src={`/assets/images/icon/${
                    validateParam() ? "ico_save" : "ico_save_white"
                  }.png`}
                  alt="Icon Save"
                  className="contain_image icon_save_profile mg-right-1"
                />
                <div
                  className={`btn_text fz-09-mobile fz-11 font_news_bold ${
                    validateParam() ? " color-primary-green " : " color-white"
                  } `}
                >
                  LƯU LẠI
                </div>
              </ButtonNews>
            </div>
          </div>

          {alert.message &&
            alert.screen === userConstants.SCREEN_UPDATE_PROFILE && (
              <Alert
                alert={alert}
                onComplete={() => {
                  history.push("/profile");
                  _dispatch(teacherActions.getProfile(authentication.id));
                }}
              />
            )}

          <div className="payment_history">
            <div className="fz-20 fz-14-mobile font_news_bold">Lịch sử mua</div>

            <div className="col-100">
              <div className="col-100 table_payment_history scrollbar-custom">
                <table className="display_none_mobile col-100">
                  <thead>
                    <tr className="table_row_header">
                      <th className="fz-09-mobile col-23">Tên khóa học</th>
                      <th className="fz-09-mobile col-13">Ngày mua</th>
                      <th className="fz-09-mobile col-13 ">Giá tiền</th>
                      <th className="fz-09-mobile col-13">Trạng thái</th>
                      <th className="fz-09-mobile col-13">Ngày kích hoạt</th>
                      <th className="fz-09-mobile col-23">
                        Tài khoản kích hoạt
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table_body_payment">
                    {dataPaymentHistory?.map((item) =>
                      renderPaymentHistory(item)
                    )}
                  </tbody>
                </table>

                <div className="hide display_flex_mobile box_payment_container flex-column">
                  {dataPaymentHistory?.map((item) => renderBoxPayment(item))}
                </div>
              </div>
              <div className="flex-end mg-top-1 flex-center-mobile">
                <HashLink
                  className="flex-end"
                  to={`/${TypeHeaderNewsItem.FEE}`}
                >
                  <img
                    src="/assets/images/icon/ico_add_circle.png"
                    alt="Icon Add"
                    className="contain_image mg-right-0-5"
                  />
                  <span className="color-primary-green fz-11">
                    Thêm gói học khác
                  </span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_profile">
          <FooterAuth />
        </div>
      </div>
    </div>
  );
};

export default ProfileUser;
