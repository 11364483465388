// Function Animation Scroll
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

export function smoothScroll(container, change, duration) {
  const startTime = performance.now();
  const initialScrollLeft = container.scrollLeft;

  function step(currentTime) {
    const elapsedTime = currentTime - startTime;
    if (elapsedTime < duration) {
      container.scrollLeft = easeInOutQuad(
        elapsedTime,
        initialScrollLeft,
        change,
        duration
      );
      requestAnimationFrame(step);
    } else {
      container.scrollLeft = initialScrollLeft + change;
    }
  }

  requestAnimationFrame(step);
}
