import React, { useEffect, useState, useRef } from "react";
import { Header } from "../../_components/Admin/Header";
import { SideBarAssessment } from "../../_components/Admin/Student";
import { studentService } from "../../_services";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import { configConstants } from "../../_constants";

function HomePageRankAssessment() {
  const [rankAssessment, setRankAssessment] = useState({});
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [listRankAssessment, setListRankAssessment] = useState([]);
  const [isLoadMore, setLoadMore] = useState(true);
  const userRankRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    studentService
      .getRankAssessment(configConstants.DEFAULT_LIMIT, 0)
      .then((data) => {
        setRankAssessment(data);
        setListRankAssessment(data.data.top_users);
      });
  }, []);

  useEffect(() => {
    if (userRankRef.current) {
      userRankRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (userRankRef.current) {
        userRankRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [userRankRef]);

  const fetchDataRankingUsers = (offsetFetch) => {
    if (offset != offsetFetch && !isLoading) {
      setLoading(true);
      studentService
        .getRankAssessment(configConstants.DEFAULT_LIMIT, offsetFetch)
        .then((data) => {
          setLoading(false);
          if (!hasDuplicates(listRankAssessment, data.data.top_users)) {
            // setRankAssessment(data);
            setListRankAssessment((prev) => [...prev, ...data.data.top_users]);
            setLoadMore(
              data.data.top_users?.length == configConstants.DEFAULT_LIMIT
            );
            setOffset(offsetFetch);
            if (scrollPosition > 0 && !isLoading) {
              userRankRef.current.scrollTop = scrollPosition;
            }
          }
        });
    }
  };

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const handleScroll = (e) => {
    if (
      userRankRef &&
      userRankRef.current?.scrollTop + userRankRef.current?.clientHeight + 5 >=
        userRankRef.current?.scrollHeight &&
      isLoadMore &&
      !isLoading
    ) {
      fetchDataRankingUsers(offset + configConstants.DEFAULT_LIMIT);
    }
    setScrollPosition(userRankRef.current?.scrollTop);
  };

  return (
    <div className="sunE-right-container flex-column">
      <Header title={"Thành tích"} />
      <LazyLoad className="flex-1 flex-m sunE-content thanhtich-container">
        <SideBarAssessment />
        <div className="flex-1 thanhtich-box-content">
          {rankAssessment && rankAssessment.data && (
            <div className="bxh-info-student">
              {/*<h2 className="sub-title-18">Thành tích cá nhân</h2>*/}
              <div className="bxh-info-student-box flex-m">
                <div className="img-avatar-k">
                  <img src={authentication.avatar} alt="ico_avt" />
                </div>
                <div className="flex-1">
                  <h3 className="name one-line">{authentication.fullname}</h3>
                  <div className="gr-r flex-m">
                    <p className="flex-1">Thứ Hạng:</p>
                    <div className="flex-2">
                      <span>
                        {rankAssessment.data?.current_rank?.user_rank ??
                          "Chưa có"}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="gr-r flex-m">
                    <p className="flex-1">Huân chương:</p>
                    <div className="flex-2 flex-m">
                      <span className="mr-5">
                        {rankAssessment.data?.current_rank?.total_medal ?? 0}
                      </span>
                      <div className="flex-1">
                        <img
                          src="/../assets/images/student/thanhtich/ico_hcs.png"
                          alt="ico_hc"
                          className="ico_hc"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <h2 className="sub-title-18">Bảng xếp hạng chung</h2>
          <div
            className="student_bxh_list scrollbar-custom"
            onScroll={(e) => handleScroll(e)}
            ref={userRankRef}
          >
            {rankAssessment && rankAssessment.data && (
              <div className="thanhtich-bxh-box">
                <div className="box-top-k box-shadow flex-m">
                  <div className="pad-t-30">
                    <div className="top-k-item s">
                      <span className="top-k-score">
                        {rankAssessment.data.top_users[1].total_medal}
                      </span>
                      <div className="avt-top-k rel">
                        <img
                          src="/../assets/images/student/thanhtich/ico_s.png"
                          alt="ico_s"
                          className="ico_s"
                        />
                        <img
                          src={
                            rankAssessment.base_url +
                            rankAssessment.data.top_users[1].avatar
                          }
                          className="ico_avt"
                          alt="ico_avt"
                        />
                        <p className="__top_number __top_3 justify-content-center __top_assess_rank">
                          2
                        </p>
                      </div>
                      <p>{rankAssessment.data.top_users[1].fullname}</p>
                    </div>
                  </div>
                  <div className="top-k-item v">
                    <span className="top-k-score">
                      {rankAssessment.data.top_users[0].total_medal}
                    </span>
                    <div className="avt-top-k rel">
                      <img
                        src="/../assets/images/student/thanhtich/ico_v.png"
                        alt="ico_v"
                        className="ico_v"
                      />
                      <img
                        src={
                          rankAssessment.base_url +
                          rankAssessment.data.top_users[0].avatar
                        }
                        className="ico_avt"
                        alt="ico_avt"
                      />
                      <p className="__top_number __top_1 justify-content-center __top_assess_rank">
                        1
                      </p>
                    </div>
                    <p>{rankAssessment.data.top_users[0].fullname}</p>
                  </div>
                  <div className="pad-t-30">
                    <div className="top-k-item b">
                      <span className="top-k-score">
                        {rankAssessment.data.top_users[2].total_medal}
                      </span>
                      <div className="avt-top-k rel">
                        <img
                          src="/../assets/images/student/thanhtich/ico_b.png"
                          alt="ico_b"
                          className="ico_b"
                        />
                        <img
                          src={
                            rankAssessment.base_url +
                            rankAssessment.data.top_users[2].avatar
                          }
                          className="ico_avt"
                          alt="ico_avt"
                        />
                        <p className="__top_number __top_2 justify-content-center __top_assess_rank">
                          3
                        </p>
                      </div>
                      <p>{rankAssessment.data.top_users[2].fullname}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isEmpty(listRankAssessment) &&
              listRankAssessment.map((data, i) => {
                return (
                  i > 2 && (
                    <div className="item-student flex-m box-shadow-3" key={i}>
                      <span className="stt">{i + 1}</span>
                      <div className="item-student-img">
                        <img
                          src={rankAssessment.base_url + data.avatar}
                          alt=""
                        />
                      </div>
                      <div className="flex-1 item-student-name">
                        <p>{data.fullname}</p>
                      </div>
                      <div className="gcs-gr flex-m">
                        <div className="center-flex">
                          <img
                            src="/../assets/images/student/thanhtich/ico_hcs.png"
                            alt="ico_hc"
                            className="ico_hc"
                          />
                        </div>
                        <div className="flex-1 center-flex">
                          <span className="hcs">{data.total_medal}</span>
                        </div>
                      </div>
                      <div
                        className={
                          data.rank_change_status === "up"
                            ? "lv-up center-flex"
                            : "lv-down center-flex"
                        }
                      >
                        {data.rank_change_status === "up" && (
                          <div className="mr-10">
                            <img
                              src="/../assets/images/thanhtich/up.png"
                              alt="up"
                            />
                          </div>
                        )}
                        {data.rank_change_status === "down" && (
                          <div className="mr-10">
                            <img
                              src="/../assets/images/thanhtich/down.png"
                              alt="down"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </LazyLoad>
    </div>
  );
}

export { HomePageRankAssessment };
