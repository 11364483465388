import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { alertActions, teacherActions } from "../../../_actions";
import { teacherConstants } from "./../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";
import { PopUpAddStudent } from "../../../_components/Popup";
import { useHistory } from "react-router-dom";
import PopupGiveTurnHomework from "../../../_components/Popup/PopupGiveTurnHomework";
import { apiCaller } from "../../../_helpers";

function AddStudent() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const Popup = useSelector((state) => state.Popup);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    user_code: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [listTurnHomework, setListTurnHomework] = useState([]);
  const [listIdStudentAdded, setListIdStudentAdded] = useState([])
  const [isOpenGiveTurnHomework, setIsOpenGiveTurnHomework] = useState(false)
  const { user_code } = inputs;

  const getDataTurnHomework = async () => {
    try {
      const res = await apiCaller('/api_exercise_report/exercise_turn_class?class_id='+id, 'get')
      if(res?.status) {
        setListTurnHomework(res?.data || []);
      }
    } catch (err) {
      console.log(err)
    }
  }

  function handleChange(e) {
    if (alert.type === "ALERT_ERROR") {
      dispatch(alertActions.clear());
    }

    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      dispatch(
        teacherActions.checkStudent({
          class_id: id,
          user_code: user_code.trim(),
        })
      );
    }
  }

  function validateParam() {
    return user_code?.trim() !== "" ? true : false;
  }

  const handleEventEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  const authentication = useSelector((state) => state.authentication);

  useEffect(() => {
    getDataTurnHomework()
  }, [])

  return (
    <div className="sunE-right-container add_student_container">
      <Header
        title={"Thêm học sinh"}
        isBack
        clickBack={() =>
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/student"
          )
        }
      />
      <div className="sunE-content rel">
        <img
          className="background-add-student"
          src="/assets/images/bg_add_student.png"
          alt=""
        />
        <div className="add_code_student_custom text-center add_code_student-bottom-60">
          <h2 className="bold sunE-title-medium bottom-10" style={{padding: '0 32px 10px'}}>
            Nhập email hoặc số điện thoại học sinh muốn thêm
          </h2>
          <div
            className={
              "sunE-input-border-blue-gr rel horizontal-center" +
              ((submitted && !user_code && inputs.user_code !== "") ||
              (alert.type === "ALERT_ERROR" &&
                alert.message &&
                alert.screen === teacherConstants.SCREEN_ADD_STUDENT)
                ? " err"
                : "")
            }
          >
            <img
              src="/assets/images/ico_user_border.png"
              className="ico_input"
              alt="ico_user_border"
            />
            <input
              type="text"
              name="user_code"
              placeholder="Nhập email hoặc số điện thoại học sinh"
              onChange={handleChange}
              onKeyUp={(e) => handleEventEnter(e)}
              autoFocus
            />
            <img
              src="/assets/images/ico_danger.png"
              className="ico_err"
              alt="ico_danger"
            />
          </div>
          {alert.message &&
          alert.screen === teacherConstants.SCREEN_ADD_STUDENT ? (
            <Alert alert={alert} notShowComplete={true} isHideIcon={true} />
          ) : (
            <div className="empty-err-custom" />
          )}
          <div className="form-sunE-button">
            <button
              className={
                "btn-check-custom btn-line-blue" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Kiểm tra
            </button>
          </div>
        </div>
      </div>
      <Alert
        alert={alert}
        onComplete={() => {
          if(!listTurnHomework.length){
            history.push(
              "/" + authentication.role + "/class/view/" + id + "/student"
            )
          }
        }}
        notShowError={true}
      />
      {Popup.showFormAddStudent && <PopUpAddStudent isHasTurnHomework={!!listTurnHomework.length} width={400} isAddClass={true} onSetListIdStudentAdded={setListIdStudentAdded} onSetIsOpenGiveTurnHomework={setIsOpenGiveTurnHomework}/>}
      <PopupGiveTurnHomework 
        open={isOpenGiveTurnHomework && !!listTurnHomework.length} 
        listData={listTurnHomework} 
        classId={id} 
        listStudent={listIdStudentAdded} 
        onClose={() => {
          setIsOpenGiveTurnHomework(false)
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/student"
          )
        }}
        onComplete={() => {
          setIsOpenGiveTurnHomework(false)
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/student"
          )
        }}
      />
    </div>
  );
}

export { AddStudent };
