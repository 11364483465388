import React, { useEffect, useState } from "react";
import InputText from "../../../../_components/Auth/InputText";
import "./index.scss";
import { isEmail } from "../../../../_helpers/validateEmail";
import { apiCaller } from "../../../../_helpers";
import { stepAuthConstants } from "../../../../_constants/auth";

const UpdateEmailFb = (props) => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [cacheEmailPass, setCacheEmailPass] = useState([]);
    const [cacheEmailFail, setCacheEmailFail] = useState([]);

    useEffect(() => {
        setEmail(props.data.email);
    }, [props.data]);

    async function handleSubmit(e) {
        // setSubmitted(true);
        // if (validateParam()) {
        //     dispatch(userActions.udpateInformation(inputs));
        // }
        e.preventDefault();
        if (isEmail(email)) {
            if (cacheEmailPass.includes(email)) {
                nextStep();
            } else if (cacheEmailFail.includes(email)) {
                setEmailError("Bạn đã đăng ký tài khoản với email này trước đó. Hãy kích hoạt email và sử dụng!");
            } else {
                await apiCaller("/api_register/check_email", "POST", {
                    email,
                })
                    .then((res) => {
                        if (res.status) {
                            setCacheEmailPass([...cacheEmailPass, email]);
                            nextStep();
                        }
                    })
                    .catch((e) => {
                        setCacheEmailFail([...cacheEmailFail, email]);
                        setEmailError(e);
                    });
            }
            return;
        }
        checkEmail();
    }

    const nextStep = () => {
        props.setData({
            email,
        });
        props.setStep(stepAuthConstants.STEP_ACCOUNT_TYPE);
    };

    const checkEmail = () => {
        if (!isEmail(email)) {
            setEmailError("Định dạng email không đúng");
        }
    };

    const changeEmail = (value) => {
        if (emailError) {
            if (isEmail(value)) {
                setEmailError("");
            }
        }
        setEmail(value);
    };

    const onBlurField = (type) => {
        switch (type) {
            case "email": {
                if (email && !emailError) {
                    checkEmail();
                }
                return;
            }
            default: {
                return;
            }
        }
    };

    function validateParam() {
        return isEmail(email);
    }

    const renderEmailIcon = () => {
        return (
            <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M30.2412 11.4491L25.2341 7.79102V6.91003C25.2157 6.04501 24.8553 5.22259 24.2317 4.6228C23.6081 4.02302 22.7721 3.69481 21.907 3.71008H19.647L15.604 0.756104C15.4999 0.680115 15.3745 0.63916 15.2456 0.63916C15.1167 0.63916 14.9913 0.680115 14.8872 0.756104L10.844 3.71008H8.58521C7.71995 3.69454 6.88357 4.02262 6.25977 4.62244C5.63596 5.22225 5.27547 6.04483 5.25708 6.91003V7.79102L0.25 11.4491C0.173 11.5054 0.110312 11.5789 0.0668945 11.6638C0.0234769 11.7487 0.000539448 11.8426 0 11.938C0.00129897 11.9716 0.00551907 12.0051 0.012207 12.0381C0.00662848 12.0655 0.00241492 12.0932 0 12.1211V27.255C0.0812238 27.478 0.092936 27.7204 0.0332031 27.9501C0.0739633 28.0702 0.151258 28.1745 0.254395 28.2484C0.357531 28.3223 0.481275 28.3621 0.608154 28.3621H29.8811C30.008 28.3617 30.1318 28.3217 30.2349 28.2477C30.338 28.1736 30.4152 28.0693 30.4561 27.9491C30.3962 27.7199 30.4074 27.4778 30.488 27.255V12.1211C30.4861 12.0936 30.4825 12.0661 30.4771 12.0391C30.4841 12.0035 30.4877 11.9673 30.488 11.931C30.4865 11.8371 30.4635 11.7448 30.4207 11.6613C30.3778 11.5777 30.3166 11.5052 30.2412 11.4491ZM25.2341 9.297L28.8691 11.953L25.364 14.6531L25.2351 14.7461L25.2341 9.297ZM15.2461 1.99707L17.5852 3.70703H12.905L15.2461 1.99707ZM6.47412 6.9071C6.49214 6.3643 6.72452 5.85073 7.12036 5.47888C7.5162 5.10703 8.04331 4.90719 8.58618 4.9231H21.9082C22.4509 4.90746 22.9777 5.10743 23.3733 5.47925C23.7689 5.85107 24.001 6.36448 24.019 6.9071V15.625L19.2332 19.1011C19.1506 19.118 19.0726 19.152 19.0042 19.201L15.2842 22.011L11.3892 19.1941L6.4751 15.6261L6.47412 6.9071ZM5.25806 9.29504V14.629L1.63501 11.942L5.25806 9.29504ZM1.21899 13.313L9.99805 19.687L1.21899 26.061V13.313ZM2.39014 27.144L11.0452 20.4441L14.9351 23.2581C15.0405 23.3343 15.1677 23.3747 15.2979 23.3734C15.428 23.3722 15.5542 23.3293 15.6582 23.2511L19.3672 20.451L28.0901 27.145L2.39014 27.144ZM29.2742 26.0601L20.4951 19.686L29.2742 13.312V26.0601Z"
                    fill="#00B9B7"
                />
            </svg>
        );
    };

    function logout() {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        if (window.FB) {
            window.FB.api("/me/permissions", "delete", null, () => window.FB.logout());
            window.location.href = "/";
        }
    }

    return (
        <div className="sunE-form-container login-form-container register_create_account register_step login_fb_update_email">
            <div className="sunE-custom-form login-form-container_content">
                <h2 className="title_register title text-center">ĐĂNG KÝ TÀI KHOẢN</h2>
                <h3 className="step_register_name text-center">1. Thêm địa chỉ email</h3>
                <p className="step_desc text-center">Hãy nhập địa chỉ email hợp lệ của bạn để có thể kích hoạt và khôi phục tài khoản trong tương lai.</p>
                <form className="sunE-login-app" onSubmit={handleSubmit} noValidate={true}>
                    <>
                        <InputText
                            value={email}
                            setValue={changeEmail}
                            type="text"
                            name="email"
                            placeholder="Nhập email xác thực tài khoản"
                            required={true}
                            renderLabelIcon={renderEmailIcon}
                            errorText={emailError}
                            setErrorText={setEmailError}
                            onBlur={() => {
                                onBlurField("email");
                            }}
                        ></InputText>
                    </>
                    <div className="button_submit">
                        <button className={"btn-line-blue" + (!validateParam() ? " btn-disable" : "")}>Tiếp theo</button>
                    </div>
                    <div className="login_text text-center">
                        <p
                            onClick={() => {
                                logout();
                            }}
                        >
                            <a>Quay lại</a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateEmailFb;
