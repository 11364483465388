import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { HeaderCloud } from '../../_components/Header';
import { useSelector, useDispatch } from 'react-redux';
import { userConstants } from '../../_constants';
import { Alert } from '../../_components/Alert';
import { userActions, studentActions } from '../../_actions';
import { LinkBack } from '../../_components/Link';
import InputForm from '../../_components/Input';
import { useHistory } from 'react-router-dom';
import { SelectAsDiv } from '../../_components/Select';
import { SelectDate } from '../../_components/Calendar';

function AddInformationPage() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const dataRegister = useSelector(state => state.register);
    const grades = useSelector(state => state.grades);
    const history = useHistory();


    useEffect(() => {
        dispatch(studentActions.getAllGrade());
    }, []);

    const [inputs, setInputs] = useState({
        fullname: '',
        class_number: '',
        birthday: new Date(),
        school: '',
        grade_id: '',
        phone: '',
        gender: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const { fullname, class_number, birthday, grade_id, school, phone, gender } = inputs;

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));

    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (validateParam()) {
            dispatch(userActions.register(_.pickBy({ ...inputs, ...dataRegister, birthday: birthday.toISOString().slice(0, 10), class: class_number })));
        }
    }


    function validateParam() {
        if (fullname && birthday && gender) {
            if ((dataRegister.role === userConstants.ROLE_STUDENT && (!inputs.class_number || !school)) || (dataRegister.role === userConstants.ROLE_TEACHER && !school) || ((dataRegister.role === userConstants.ROLE_TEACHER || dataRegister.role === userConstants.ROLE_PARENT) && !phone)) {
                return false;
            }
            return true;
        }
        return false;
    }


    function changeGender(gender) {
        setInputs({
            ...inputs,
            gender: gender
        });
    }



    return (
        <div className="bg-register rel">
            <HeaderCloud />
            <div className="sunE-form-container login-form-container">
                <div className="sunE-custom-form max430">

                    <h2 className="title text-center profile-title">{dataRegister.role === userConstants.ROLE_STUDENT ? 'Vui lòng điền đầy đủ thông tin dưới đây để chúng tôi có thể giúp bạn học tiếng anh theo cách tốt' : 'Vui lòng điền đầy đủ thông tin dưới đây để chúng tôi có thể hỗ trợ bạn tốt nhất' }</h2>

                    {alert.message && alert.screen === userConstants.SCREEN_COMPLETE_DATA_REGISTER && <Alert alert={alert} onComplete={() => history.push('/login')} />}
                    <form className="sunE-login-app" onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                        <InputForm option={'image'} class={submitted && !fullname ? ' err' : ''} classImage="ico_top_3" imageLink={'assets/images/icon/icon_user_2.png'} alt={'ico_input'} placeholder={'Họ và Tên'} onChange={handleChange} type={'text'} name={'fullname'} value={fullname} />
                        <div className="flex-m">
                            <div className={"form-sunE-input rel flex-1 " + (submitted && !birthday ? ' err' : '')}>
                                <img src="assets/images/icon/ico_birthday.png" alt="birthday" className="ico_input birthday" />
                                <SelectDate dateFormat="dd/MM/yyyy" name="birthday" selected={birthday} onChange={birthday => setInputs({ ...inputs, birthday })} />
                            </div>
                            <div className={"flex-m gr-gender " + (submitted && inputs.gender === '' ? 'err' : '')}>
                                <div className={"sunE-male-female male flex-1" + (inputs.gender === 'male' ? ' active' : '')} onClick={() => changeGender('male')}>
                                    <img src="assets/images/icon/ico_male.png" alt={"ico_male"} className="ico_male_active" />
                                    <img src="assets/images/icon/ico_male_2.png" alt={"ico_male_2"} className="ico_male" />
                                </div>
                                <div className={"sunE-male-female female flex-1" + (inputs.gender === 'female' ? ' active' : '')} onClick={() => changeGender('female')}>
                                    <img src="assets/images/icon/ico_female.png" alt={"ico_female"} className="ico_female" />
                                    <img src="assets/images/icon/ico_female_2.png" alt={"ico_female_2"} className="ico_female_active" />
                                </div>
                            </div>
                        </div>
                        {dataRegister.role === userConstants.ROLE_STUDENT &&
                            <Fragment>
                                <InputForm option={'image'} class={submitted && !school ? ' err' : ''} imageLink={'assets/images/icon/ico_school.png'} classImage="ico_top_3" alt={'ico_school'} placeholder={'Trường'} onChange={handleChange} type={'text'} name={'school'} value={school} />
                                <div className={"form-sunE-input ico_left_custom rel custom-select-no-bg" + (submitted && !grade_id ? ' err' : '')}>
                                    <SelectAsDiv  keySelected={grade_id} textDefault={'Khối'} data={grades} onChangeSelect={(grade_id) => setInputs({ ...inputs, grade_id })} ico_url={'/assets/images/icon/ico_khoi.png'} />
                                </div>
                                <InputForm option={'image'} class={submitted && !inputs.class_number ? ' err' : ''} imageLink={'assets/images/icon/ico_student.png'} alt={'ico_student'} placeholder={'Lớp'} onChange={handleChange} type={'text'} name={'class_number'} value={class_number} />
                            </Fragment>
                        }
                        {dataRegister.role === userConstants.ROLE_TEACHER &&
                            <Fragment>
                                <InputForm option={'image'} class={submitted && !school ? ' err' : ''} imageLink={'assets/images/icon/ico_school.png'} classImage="ico_top_3" alt={'school'} placeholder={'Nơi công tác'} onChange={handleChange} type={'text'} name={'school'} value={school} />
                            </Fragment>
                        }
                        {(dataRegister.role === userConstants.ROLE_PARENT || dataRegister.role === userConstants.ROLE_TEACHER) &&
                            <Fragment>
                                <InputForm option={'image'} class={submitted && !phone ? ' err' : ''} imageLink={'assets/images/icon/ico_phone.png'} classImage="ico_phone" alt={'ico_phone'} placeholder={'Số điện thoại'} onChange={handleChange} type={'text'} name={'phone'} value={phone} />
                            </Fragment>
                        }
                        <div className="form-sunE-button">
                            <button className={'btn-line-blue' + (!validateParam() ? ' btn-disable' : '')}>Hoàn tất</button>
                        </div>
                        <div className="sunE-bottom-form text-center btn-back">
                            <LinkBack />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export { AddInformationPage };