import { useParams } from "react-router-dom";
import { Header } from "../../../../_components/Admin/Header";
import { apiCaller, history } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import './DetailAssignedHomeWorkExercise.style.scss'

export default function DetailAssignedHomeWorkExercise() {
  const authentication = useSelector((state) => state.authentication);
  const { id, turn_id, lesson_id, unit_id } = useParams();
  const heading = new URLSearchParams(window.location.search).get("heading");
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const [listData, setListData] = useState([])
  const [totalStudent, setTotalStudent] = useState(0);
  const [isHasStudentDone, setIsHasStudentDone] = useState(false);
  const [isHideRatio, setIsHideRatio] = useState(false);
  const [isShowScore, setIsShowScore] = useState(false);
  const [isShowPercent, setIsShowPercent] = useState(false);
  const [isShowByWords, setIsShowByWords] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const getData = async () => {
    const url = '/api_exercise_report/turn_lesson_report_details' + '?turn_id=' + turn_id + '&lesson_id=' + lesson_id + '&unit_id=' + unit_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        const infoQuestion = res?.data_question?.[0]?.list_option?.[0] || res?.data_question?.list_option?.[0]
        if (infoQuestion?.type == 'speaking' && infoQuestion?.question_type == 2 ||
          infoQuestion?.type == 'speaking' && infoQuestion?.question_type == 3 ||
          infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 1 ||
          // infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 3 ||
          infoQuestion?.type == 'writing' && infoQuestion?.question_type == 7 ||
          infoQuestion?.type == 'project'
        ) {
          setIsHideRatio(true);
        }
        if (infoQuestion?.type == 'writing' && infoQuestion?.question_type == 7 ||
          infoQuestion?.type == 'speaking' && infoQuestion?.question_type == 3
        ) {
          setIsShowScore(true)
        }
        if (infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 1) {
          setIsShowPercent(true)
        }
        if (infoQuestion?.type == 'pronunciation' && infoQuestion?.question_type == 3) {
          const listOption = res?.data_question?.list_option || res?.data_question?.[0]?.list_option;
          const listWords = listOption?.map(item => item?.match_option_text?.[0])
          const listTotalStudentRight = Array(listOption?.length).fill(0);
          const data = res?.data?.map(item => {
            const userChoice = transformUserChoice(res?.data_question, item?.user_choice)
            userChoice?.map((choice, index) => {
              if(choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0)) {
                listTotalStudentRight[index] += 1
              }
            })
            return {
              ...item,
              userChoice
            }
          })
          setIsShowByWords(true)
          setTotalQuestion(listOption?.length || 0)
          setIsHasStudentDone(listTotalStudentRight.some(item => item > 0))
          setListData([{listNumberQuestion: listWords}, {listTotalStudentRight}, ...data])
        } else {
          const listNumberQuestion = Array(res?.total_question).fill(0).map((_, index) => index + 1);
          const listTotalStudentRight = Array(res?.total_question).fill(0);
          res?.data?.map(item => {
              item?.lesson_data?.map((lesson, index) => {
                if(lesson?.do_right) {
                  listTotalStudentRight[index] += 1
                }
              })
          })
          setTotalQuestion(res?.total_question)
          setIsHasStudentDone(listTotalStudentRight.some(item => item > 0))
          setListData([{listNumberQuestion}, {listTotalStudentRight}, ...res?.data])
        }
        setTotalStudent(res?.data?.filter(item => !!item?.user_choice?.length)?.length)
      }
    } catch (error) { 
    }
  };

  const transformUserChoice = (data_question, listUserChoice) => {
    const userChoice = listUserChoice?.map(item => {
      let user_choice = '[]';
      // const isGetDetailUserTurn = (item?.exercise_type == 'pronunciation' && item?.question_type == 8)
      if (
        data_question?.length === 1 || !!data_question?.question_id
        //  && !isGetDetailUserTurn
      ) {
        let finalUserChoice = [];
        if(item?.detail_user_turn_style_2?.find(item => !!item?.data?.length)) {
          item?.detail_user_turn_style_2?.map(choice => {
            if(choice?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = [];
            }
            finalUserChoice = choice?.data;
          })
        } else {
          item?.detail_user_turn_style_2?.map(item => {
            if(item?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = []
            }
            if (finalUserChoice.find(ans => JSON.stringify(ans) == JSON.stringify(item))) {
              return;
            }
            finalUserChoice.push(item)
          })
        }
        user_choice = finalUserChoice
      } else {
        let finalUserChoice = [];
          item?.detail_user_turn?.map(choice => {
            if(choice?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = [];
            }
            finalUserChoice.push(choice);
          })
        user_choice = finalUserChoice
      }
      return {
        user_choice,
      }
    })
    return userChoice?.[0]?.user_choice?.flat();
  }

  const renderItem = ({item, index}) => {
    const isDone = index > 1 && (isShowByWords ? !!item?.userChoice?.length : !!item?.lesson_data?.length);
    const countNumberRight = index > 1 ? isShowByWords ? item?.userChoice?.filter(choice => choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0))?.length : item?.lesson_data?.filter(lesson => lesson?.do_right)?.length : 0

    return (
      <div className="turn_lesson_report_detail-item">
        <div 
          className="turn_lesson_report_detail-name" 
          style={{
            color: index === 1 ? '#00BEB4' : '#4D4D4D',
            fontWeight: index === 1 ? 'bold' : 'normal',
          }}
        >
          {index === 0 ? '' : index === 1 ? (isHideRatio ? '' : 'Tỷ lệ làm đúng') : item?.name}
        </div>
        <div className="turn_lesson_report_detail-box" style={{borderWidth: index > 1 && !isHideRatio ? 1 : 0, height: index === 1 && isHideRatio ? 'unset' : 28, width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60}}>
          <span className="font-weight-bold">{index === 0 ? isShowByWords ? 'Từ' : 'Câu' : (index === 1 || isHideRatio) ? '' : isDone ? `${countNumberRight}/${totalQuestion}` : '___'}</span>
        </div>
        {index === 0 && item?.listNumberQuestion?.map(numberQuestion => (
          <div
            key={numberQuestion}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1, width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60}}
          >
            <span style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
              textAlign: 'center'
            }}>{numberQuestion}</span>
          </div>
        ))}
        {index === 1 && !isHideRatio && item?.listTotalStudentRight?.map((numberRight, iNumRight) => (
          <div
            key={iNumRight}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1, borderColor: '#00BEB4', width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60}}
          >
            <span className="font-weight-bold" style={{color: '#00BEB4'}}>{isHasStudentDone ? `${numberRight}/${totalStudent}` : '___'}</span>
          </div>
        ))}
        {index > 1 && !isShowByWords && 
          <>
            {!!item?.lesson_data?.length && !isShowPercent && !isShowScore ? item?.lesson_data?.map(lesson => (
          <div
            key={lesson?.id}
            className="turn_lesson_report_detail-box"
          >
            <img 
              className="turn_lesson_report_detail-box" 
              src={lesson?.do_right ? '/assets/images/icon/icon_tick_rectangle.png' : '/assets/images/icon/icon_swrong_rectangle.png'} 
              style={{objectFit: 'cover'}}/>
          </div>
        )) : (
          <>
            {!!item?.user_choice?.length ? item?.user_choice?.map(choice => (
              <div
              key={choice?.question_id}
              className="turn_lesson_report_detail-box" 
              style={{borderWidth: 1}}
            >
              <span className="font-weight-bold" style={{ fontSize: item?.status == 0 ? '12px' : '16px'}}>{isShowScore ? item?.status == 0 ? "Chờ chấm" : (Math.round((choice?.question_score * 10 || 0)) / 10) : ((Math.round((choice?.question_score * 100 || 0) * 10) / 10) + '%' )}</span>
            </div>
            )) : (
              Array(totalQuestion).fill(0).map((_, i) => (
                <div
                  key={i}
                  className="turn_lesson_report_detail-box" 
                  style={{borderWidth: 1}}
                >
                  <span className="font-weight-bold">___</span>
                </div>
              ))
            )}
          </>
        )}
          </>
        }
        {index > 1 && isShowByWords && item?.userChoice?.map((choice, iChoice) => (
          <div
            key={iChoice}
            className="turn_lesson_report_detail-box"
            style={{width: 80, minWidth: 80}}
          >
            <img 
              className="turn_lesson_report_detail-box" 
              src={choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0) ? '/assets/images/icon/icon_tick_rectangle.png' : '/assets/images/icon/icon_swrong_rectangle.png'} 
              style={{objectFit: 'cover', width: 80, minWidth: 80}}/>
          </div>
        ))}
      </div>
    )
  }

  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={heading}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + "/exercise/detail-assigned/" + turn_id + '?startTime=' + startTime + '&endTime=' + endTime + '&indexTab=1' )
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="turn_lesson_report_detail-container scrollbar-custom">
          <div className="turn_lesson_report_detail-list">
            {listData?.map((item, index) => (
              <Fragment key={index}>
                {renderItem({item, index})}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}