import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function ChartDoughnut(props) {
    let { report_data, position, display_legend } = props;
    if (display_legend === undefined) {
        display_legend = true;  
    }
    const options = {
        legend: {
            display: display_legend,
            position: position,
            labels: {
                fontColor: '#000',
                boxWidth: 20,
                padding: 10,
            },
            onClick: (e) => e.stopPropagation()
        },
        responsive: true,
        maintainAspectRatio: true,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
    };

    return (
        <Doughnut data={report_data} options={options} width={250} height={100} />
    );
}

export { ChartDoughnut };