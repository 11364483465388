import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "./../../../_components/Admin/Header";
import { useParams, Link, useHistory } from "react-router-dom";
import moment from "moment";
import { isNull } from "lodash";
import { scheduleConstants } from "../../../_constants";

function ViewReportStudent() {
  const { id, student_id } = useParams();
  const { location } = useHistory();
  const defaultCurrentWeek = useMemo(() => {
    return location.search
      ? location.search.replace("?week=", "")
      : moment().format("YYYY-MM-DD");
  }, [location.search]);

  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const reports = useSelector((state) => state.classes.reports);
  const [currentWeek, setCurrentWeek] = useState(
    moment(defaultCurrentWeek).format("YYYY-MM-DD")
  );
  useEffect(() => {
    dispatch(
      teacherActions.getReportByStudentIdByDateTime(
        id,
        student_id,
        moment(currentWeek).startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss"),
        moment(currentWeek).endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss")
      )
    );
    // dispatch(
    //   teacherActions.getReportClass(
    //     id,
    //     moment(currentWeek).startOf("isoWeek").format("YYYY-MM-DD"),
    //     moment(currentWeek).endOf("isoWeek").format("YYYY-MM-DD")
    //   )
    // );
  }, []);

  function changeWeek(value) {
    let week = moment(currentWeek, "YYYY-MM-DD HH:mm:ss").add(value, "week");

    let to_date = moment(week).endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss");
    if (
      moment(week).startOf("isoWeek").month() !==
      moment(week).endOf("isoWeek").month()
    ) {
      // Lấy ngày cuối cùng của tháng của ngày đầu tuần
      to_date = moment(week)
        .endOf("isoWeek")
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    dispatch(
      teacherActions.getReportClass(
        id,
        moment(week).startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss"),
        moment(week).endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss")
      )
    );
    dispatch(
      teacherActions.getReportByStudentIdByDateTime(
        id,
        student_id,
        moment(week).startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss"),
        moment(week).endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss")
        // to_date
      )
    );
    setCurrentWeek(week);
  }

  let week = moment(currentWeek, "YYYY-MM-DD").add("week", 1);
  let isDisableNextWeek = moment() < moment(week).startOf("isoWeek");

  // Handle Select Date
  const handleChangeDateSelected = () => {
    dispatch({
      type: scheduleConstants.SET_SELECT_DATE,
      time: moment().format("YYYY-MM-DD"),
    });
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time: moment().format("YYYY-MM-DD"),
    });
  };

  return (
    <div className="sunE-right-container">
      <Header title={"Chi tiết học sinh"} isBack />
      {reports.students && (
        <div className="flex-m sunE-content bcht flex-1">
          <div className="student-info">
            <div className="avt-i">
              <img
                src={
                  reports.students.base_url +
                  reports.students.student_data.avatar
                }
                alt="student"
              />
            </div>
            <h2 className="name bold">
              {reports.students.student_data.fullname}
            </h2>
            <div className="email-gr center-flex max-w-hidden">
              <div className="flex-m">
                <div className="center-flex">
                  <img
                    src="/assets/images/chambai/ico_email.png"
                    alt="ico_email"
                  />
                </div>
                <p className="flex-1 two-line">
                  {reports.students.student_data.email}
                </p>
              </div>
            </div>
            <div className="center-flex">
              <div className="check-date-time-week flex-m">
                <div className="center-flex">
                  <img
                    src="/assets/images/teacher/ico_left_ca.png"
                    alt="ico_left_ca"
                    onClick={() => changeWeek(-1)}
                  />
                </div>
                <div className="flex-1 text-center">
                  <p>
                    {moment(currentWeek)
                      .startOf("isoWeek")
                      .format("DD/MM/YYYY")}
                    {" >> "}
                    {moment(currentWeek).endOf("isoWeek").format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="center-flex">
                  {!isDisableNextWeek && (
                    <img
                      src="/assets/images/teacher/ico_right_ca.png"
                      alt="ico_right_ca"
                      onClick={() => changeWeek(1)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="box-bcht box-shadow">
              <div className="flex-m box-bcht-top">
                <div className="flex-1">
                  <h2>Báo cáo học tập</h2>
                </div>
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/class/view/" +
                    id +
                    "/report/" +
                    student_id +
                    "/schedule"
                  }
                  onClick={() => handleChangeDateSelected()}
                >
                  <div className="flex-1">
                    <span className="btn-history-bcht">Nhật ký học tập</span>
                  </div>
                </Link>
              </div>
              <div className="bcht-details-box">
                <div className="flex-m mb-5">
                  <div className="flex-1 title-info">
                    <p>Số bài giáo viên giao đã hoàn thành</p>
                  </div>
                  <div className="score-info">
                    <p>
                      {reports.students.teacher_giving_homework}/
                      {reports.students.total_homework}
                    </p>
                  </div>
                </div>
                <div className="flex-m mb-5">
                  <div className="flex-1 title-info">
                    <p>Số bài đã quá hạn</p>
                  </div>
                  <div className="score-info">
                    <p>{reports.students.deadline}</p>
                  </div>
                </div>
                <div className="flex-m mb-5">
                  <div className="flex-1 title-info">
                    <p>Số bài giáo trình hoàn thành trong tuần</p>
                  </div>
                  <div className="score-info">
                    <p>{reports.students.lesson_done}</p>
                  </div>
                </div>
                <div className="flex-m mb-5">
                  <div className="flex-1 title-info">
                    <p>Số bài hoàn thành trong Master Unit</p>
                  </div>
                  <div className="score-info">
                    <p>{reports.students.number_done_in_master_unit}</p>
                  </div>
                </div>
                <div className="flex-m mb-5">
                  <div className="flex-1 title-info">
                    <p>Số từ đã học trong giáo trình </p>
                  </div>
                  <div className="score-info">
                    <p>
                      {reports.students &&
                      !isNull(reports.students.total_vocab_learned)
                        ? reports.students.total_vocab_learned
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="flex-m mb-5">
                  <div className="title-info dtb-title center-flex">
                    <p>
                      Điểm
                      <br />
                      trung bình
                    </p>
                  </div>
                  <div className="flex-1 flex-column">
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Bài tập Giáo viên giao</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students?.user_assessment_log
                            ?.exercise_score &&
                          reports.students?.user_assessment_log != 0
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    ?.exercise_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Grammar</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.grammar_score
                          ) &&
                          reports.students.user_assessment_log?.grammar_score ==
                            `${reports.students.user_assessment_log.grammar_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    ?.grammar_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Reading</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.reading_score
                          ) &&
                          reports.students.user_assessment_log.reading_score ==
                            `${reports.students.user_assessment_log.reading_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    .reading_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Listening</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.listening_score
                          ) &&
                          reports.students.user_assessment_log
                            .listening_score ==
                            `${reports.students.user_assessment_log.listening_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    ?.listening_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Speaking</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.speaking_score
                          ) &&
                          reports.students.user_assessment_log.speaking_score ==
                            `${reports.students.user_assessment_log.speaking_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    ?.speaking_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Pronunciation</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log
                              .pronunciation_score
                          ) &&
                          reports.students.user_assessment_log
                            .pronunciation_score ==
                            `${reports.students.user_assessment_log.pronunciation_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    ?.pronunciation_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Kỹ năng Writing</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.writing_score
                          ) &&
                          reports.students.user_assessment_log.writing_score ==
                            `${reports.students.user_assessment_log.writing_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    .writing_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                    <div className="flex-m mb-5">
                      <div className="flex-1 title-info">
                        <p>Điểm thi</p>
                      </div>
                      <div className="score-info">
                        <p>
                          {reports.students.user_assessment_log &&
                          !isNull(
                            reports.students.user_assessment_log.test_score
                          ) &&
                          reports.students.user_assessment_log.test_score ==
                            `${reports.students.user_assessment_log.test_score}`
                            ? parseFloat(
                                parseFloat(
                                  reports.students.user_assessment_log
                                    .test_score
                                ).toFixed(1)
                              )
                            : "..."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { ViewReportStudent };
