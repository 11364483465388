import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import { ViewCurriculumSunEnglish, ViewCurriculumPersonal } from './';

function ViewCurriculum() {
    const { type } = useParams();
    let componentDetailCurriculum = (type === 'sunday') ? <ViewCurriculumSunEnglish /> : <ViewCurriculumPersonal />;
    return (
        <Fragment>
            { componentDetailCurriculum}
        </Fragment>
    );
}

export { ViewCurriculum };