export const customBarLogic = (props) => {
  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".bar__progress");
    const barStart = bar?.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = 100 / barWidth;

    let draggedPercentage = Math.round(timePerPixel * clickPositionInBar);

    const snappedPercentage = Math.round(draggedPercentage / 10) * 10;

    // Ensure the snapped value is within the 0-100 range
    const finalPercentage = Math.max(0, Math.min(100, snappedPercentage));

    return draggedPercentage;
  }

  const handleTimeDrag = (e) => {
    if (props.currUnit != props.totalUnit) {
      props.setMovePercent(calcClickedTime(e));
      const updateTimeOnMove = (eMove) => {
        props.setMovePercent(calcClickedTime(eMove));
      };
      document.addEventListener("mousemove", updateTimeOnMove);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", updateTimeOnMove);
      });
    }
  };

  return { handleTimeDrag };
};
