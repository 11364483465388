import React, { Fragment, useState, useEffect } from "react";
import { SideBar } from "../../../_components/Admin/Schedule/SideBar";
import { Header } from "../../../_components/Admin/Header";
import { useDispatch, useSelector } from "react-redux";
import { scheduleActions } from "./../../../_actions";
import {
  PopUpEditScheduleYear,
  PopUpYesNo,
} from "./../../../_components/Popup";
import { scheduleConstants, popupConstants } from "./../../../_constants";
import Parser from "html-react-parser";
import { Alert } from "./../../../_components/Alert";
import { useHistory } from "react-router-dom";

function ScheduleYearPage() {
  const history = useHistory();

  const schedules = useSelector((state) => state.schedules.year);
  const schedulesMonth = useSelector(
    (state) => state.schedules.dataAddScheduleYear
  );
  const authentication = useSelector((state) => state.authentication);
  const Popup = useSelector((state) => state.Popup);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  let [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Variable state for delete schedule year
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false);
  const [idCardSelected, setIdCardSelected] = useState();
  const [isShowPopupAlert, setIsShowPopupAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  function nextYear() {
    setCurrentYear(currentYear + 1);
    dispatch(scheduleActions.getScheduleYear(currentYear + 1));
  }
  function preYear() {
    setCurrentYear(currentYear - 1);
    dispatch(scheduleActions.getScheduleYear(currentYear - 1));
  }
  useEffect(() => {
    dispatch(scheduleActions.getScheduleYear(currentYear));
  }, []);

  function openFormUpdateScheduleYear(data, month) {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFormAddScheduleYear: true,
      },
    });
    let dataAddScheduleYear = {
      year: currentYear,
      month,
    };
    if (!data[month]) {
      dataAddScheduleYear.action = "add";
      dataAddScheduleYear.content = "";
    } else {
      dataAddScheduleYear.action = "edit";
      dataAddScheduleYear.id = data[month].id;
      dataAddScheduleYear.content = data[month].content;
    }
    dispatch({
      type: scheduleConstants.ADD_DATA_SCHEDULE_YEAR,
      dataAddScheduleYear,
    });
  }

  function addComponentYear() {
    return (
      <Fragment>
        <div className="form-sunE-button btn-select-year rel">
          <img
            alt="ico_left"
            src="/assets/images/icon/ico_left_white_small.png"
            onClick={() => preYear()}
            className="ico_left"
          />
          <button className="btn-line-blue">{currentYear}</button>
          <img
            alt="ico_right"
            src="/assets/images/icon/ico_right_white_small.png"
            onClick={() => nextYear()}
            className="ico_right"
          />
        </div>
      </Fragment>
    );
  }

  //Delete schedule year
  // Function for delete roll up
  const handleOpenPopUp = (id) => {
    setIsShowPopupDelete(true);
    // console.log(id);
    setIdCardSelected(id);
  };

  const handleDeletedSchedule = async (data, month) => {
    // try {
    //   if (idCardSelected) {
    //     const res = await teacherService.deleteRollUp(idCardSelected);
    //     console.log(res);
    //     if (res?.status) {
    //       setMessageAlert("Xóa phiểu điểm thành công!");
    //       setIsShowPopupDelete(false);
    //       setIsShowPopupAlert(true);
    //     } else {
    //       setMessageAlert(res?.data?.message);
    //     }
    //   }
    // } catch (e) {
    //   setMessageAlert(e.toString());
    // }
    let dataAddScheduleYear = {
      year: currentYear,
      month,
    };

    dataAddScheduleYear.id = data[month].id;
    dataAddScheduleYear.content = "";

    dispatch(scheduleActions.deleteScheduleYear(dataAddScheduleYear));

    setIsShowPopupDelete(false);
  };

  const handleAcceptAlert = () => {
    setIsShowPopupAlert(false);
    dispatch(scheduleActions.getScheduleYear(currentYear));
  };

  return (
    <div className="sunE-right-container">
      {Popup.showFormAddScheduleYear && <PopUpEditScheduleYear width={340} />}
      <Header
        title={"Lịch làm việc năm"}
        component={addComponentYear()}
        isBack
        clickBack={() => history.push("/" + authentication.role + "/more")}
      />
      <div className="flex-m sunE-content lichngay-teacher">
        <SideBar />
        {alert.message &&
          alert.screen === scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR && (
            <Alert alert={alert} notShowError={true} />
          )}
        <div className="flex-1 sunE-content-box lichnam">
          <div className="list-lichnam">
            <div className="title-list-lichnam flex-m">
              <div className="w-120">
                <h2>Tháng</h2>
              </div>
              <div className="flex-1">
                <h2>Kế hoạch</h2>
              </div>
            </div>
            <div className="list-lichnam-box scrollbar-custom">
              {Object.keys(schedules).map(function (key) {
                console.log(schedules)
                return (
                  <div className="list-lichnam-item flex-m" key={key}>
                    <div className="w-120">
                      <p>{key}</p>
                    </div>
                    <div className="flex-1 rel">
                      {schedules[key] &&
                        Parser(
                          schedules[key].content.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        )}
                      <span
                        className="btn-capnhat"
                        onClick={() =>
                          openFormUpdateScheduleYear(schedules, key)
                        }
                      >
                        Cập nhật
                      </span>

                      <div
                        style={{
                          position: "absolute",
                          bottom: "0.3rem",
                          right: "0.8rem",
                          cursor: !!schedules[key] ? "pointer" : 'default',
                        }}
                        onClick={() => {
                          !!schedules[key] && handleOpenPopUp(key);
                        }}
                      >
                        <img
                          src={!!schedules[key] ? "/assets/images/icon/ico_remove.png" : '/assets/images/icon/ico_remove_gray.png'}
                          alt="ico_remove"
                          style={{
                            width: "1.3rem",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {isShowPopupDelete && (
        <PopUpYesNo
          onClickYes={() => handleDeletedSchedule(schedules, idCardSelected)}
          onClickNo={() => setIsShowPopupDelete(false)}
          labelNo="Không"
          laelYes="Có"
          message={[
            `Bạn có chắc muốn xóa lịch làm việc tháng ${idCardSelected} này?`,
          ]}
        />
      )}

      {isShowPopupAlert && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => handleAcceptAlert()}
          labelYes="Đồng ý"
          message={messageAlert}
        />
      )}
    </div>
  );
}

export { ScheduleYearPage };
