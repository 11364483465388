import { useState, useEffect } from "react";

export const selectCustomLogic = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOpenSelection = () => {
    setIsVisible(!isVisible);
  };

  let wrapperRef = null;

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  function clickOutside(event) {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setIsVisible(false);
    }
  }

  const setWrapperRefFind = (node) => {
    if (!wrapperRef) {
      wrapperRef = node;
    }
  };

  return {
    wrapperRef,
    isVisible,
    handleOpenSelection,
    setWrapperRefFind,
  };
};
