export const policy = {
  term: {
    id: 'term',
    name: "ĐIỀU KHOẢN SỬ DỤNG",
    data: [
      {
        id: 1,
        title: "1. Chấp nhận các điều khoản thỏa thuận",
        contents: [
          {
            id: 1,
            content:
              "Chào mừng các bạn đến với Sunday English - Giải pháp nâng cao năng lực Tiếng Anh học sinh THCS. Chúng tôi cung cấp dịch vụ (như được mô tả dưới đây) cho bạn, dựa trên các Điều khoản Sử dụng Dịch vụ sau đây, chúng tôi toàn quyền thay đổi nội dung các điều khoản theo quyết định của mình mà không cần báo trước. Khi sử dụng, có nghĩa rằng bạn đã chấp nhận và đồng ý tuân thủ theo thoả thuận.",
          },
          {
            id: 2,
            content:
              "Tất cả các chỉ dẫn hoặc quy định theo từng trang riêng biệt cũng là những phần không thể tách rời với Điều khoản dịch vụ này.",
          }
        ]
      },
      {
        id: 2,
        title: "2. Mô tả dịch vụ",
        contents: [
          {
            id: 0,
            content:
              "Dịch vụ Sunday English cung cấp cho bạn bao gồm, nhưng không giới hạn, những dịch vụ hiện có sau đây:",
          },
          {
            id: 1,
            content:
              "- Ứng dụng Sunday English - Nền tảng học tiếng Anh trực tuyến thông minh, cung cấp cho bạn các khóa học tiếng Anh online uy tín và chất lượng.",
          },
          {
            id: 2,
            content:
              "- Ứng dụng Sunday Teacher - Giải pháp ưu việt giúp thầy cô giảng dạy tiếng Anh hiệu quả.",
          },
          {
            id: 3,
            content:
              "- Ứng dụng Sunday Parent - Ứng dụng tích hợp công nghệ AI giúp phụ huynh dễ dàng kèm cặp con học giỏi tiếng Anh.",
          },
          {
            id: 4,
            content: "- Website: <a href=\"https://sundayenglish.com\">sundayenglish.com</a>",
          },
        ],
      },
      {
        id: 3,
        title: "3. Trách nhiệm của bạn trong việc đăng ký",
        contents: [
          {
            id: 0,
            content:
              "Khi xem xét việc bạn sử dụng Dịch vụ, bạn cam kết rằng mình có đủ tuổi luật định để xác lập một hợp đồng có tính ràng buộc và không phải là người bị hạn chế nhận các dịch vụ theo pháp luật hoặc bởi việc thực thi các phán quyết khác. Bạn cũng đồng ý rằng: ",
          },
          {
            id: 1,
            content:
              "(a) Cung cấp các thông tin mới nhất, đầy đủ, trung thực và chính xác về bản thân bạn theo hướng dẫn trong mẫu đăng ký sử dụng Dịch vụ; ",
          },
          {
            id: 2,
            content:
              "(b) Duy trì và cập nhật kịp thời Dữ liệu đăng ký để bảo đảm rằng dữ liệu này là mới nhất, đầy đủ, trung thực và chính xác. Nếu bạn cung cấp bất kỳ thông tin nào không phải là thông tin mới nhất, không đầy đủ, không trung thực hoặc không chính xác, hoặc nếu Sunday English có căn cứ hợp lý để nghi ngờ rằng thông tin đó không phải là thông tin mới nhất, không đầy đủ, không trung thực hoặc không chính xác, Sunday English có quyền đình chỉ hoặc chấm dứt tài khoản của bạn và từ chối bất kỳ và toàn bộ việc sử dụng Dịch vụ (hoặc bất kỳ phần nào của Dịch vụ) tại thời điểm hiện tại hoặc sau này.",
          },
          {
            id: 3,
            content:
              "Sunday English quan tâm tới sự an toàn và sự riêng tư của tất cả những người sử dụng dịch vụ của mình, đặc biệt là trẻ em. Tuy nhiên, xin nhớ rằng Dịch vụ này được thiết kế để thu hút đông đảo người sử dụng. Vì vậy, nếu bạn là cha mẹ hoặc người giám hộ hợp pháp, bạn có trách nhiệm xác định xem bất kỳ các Dịch vụ và/hoặc Nội dung nào có thích hợp cho con của bạn hay không. Tương tự, nếu bạn là trẻ em, xin hãy hỏi ý kiến cha mẹ hoặc người giám hộ hợp pháp của bạn để xem liệu Dịch vụ và/hoặc Nội dung có phù hợp với bạn hay không. ",    
          },
          {
            id: 4,
            content:
              "Mặc dù có quy định như đã nêu ở trên, chúng tôi có thể cho phép bạn sử dụng một số phần của Dịch vụ mà bạn không cần phải đăng ký sử dụng. Trong trường hợp đó, danh tính của bạn sẽ được dựa trên các phương tiện nhận dạng khác mà chúng tôi cho là thích hợp. Trong các trường hợp thích hợp, việc nhận dạng có thể được tiến hành dựa trên dữ liệu nhận dạng máy điện thoại di động hoặc việc đăng ký thuê bao sử dụng dịch vụ liên lạc của bạn do người điều hành mạng của bạn cung cấp. Bạn đồng ý rằng thông tin đó có thể được thu thập và tiết lộ cho chúng tôi và có thể được sử dụng theo quy định của bản Điều khoản dịch vụ này.",
          }
        ],
      },
      {
        id: 4,
        title: "4. Tài khoản thành viên, mật khẩu và sự bảo mật",
        contents: [
          {
            id: 1,
            content:
              "Bạn có trách nhiệm giữ kín mật khẩu và tài khoản, và hoàn toàn chịu trách nhiệm đối với tất cả các hoạt động diễn ra thông qua việc sử dụng mật khẩu hoặc tài khoản của bạn. Bạn đồng ý thông báo ngay cho Sunday English về bất kỳ trường hợp nào sử dụng trái phép mật khẩu hoặc tài khoản của bạn hoặc bất kỳ trường hợp nào khác vi phạm sự bảo mật. Sunday English không thể và sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào phát sinh do bạn không tuân thủ quy định tại mục này.",
          },
          {
            id: 2,
            content:
              "Mỗi tài khoản được đăng nhập tối đa trên 2 thiết bị, danh sách thiết bị có thể được thay đổi ở phần quản lý thiết bị. Người dùng chỉ được sử dụng 1 tài khoản trên 1 thiết bị tại 1 thời điểm.",
          }
        ]
      },
      {
        id: 5,
        title: "5. Nội dung của bạn",
        contents: [
          {
            id: 1,
            titleDot: "Tổng quan",
            content:
              "Hiện tại, hoặc trong tương lai, chúng tôi có thể cho phép gửi và đăng hoặc liên kết đến tác phẩm của tác giả, tác phẩm sáng tác, đồ họa, hình ảnh, video, âm thanh, văn bản và các tương tác (“Nội dung”) do bạn gửi, bao gồm nhưng không giới hạn mục đích tương tác với các tính năng hiện có của ứng dụng hoặc đăng tải, lưu trữ và tạo mới nội dung để phục vụ cho việc dạy và học trên nền tảng ứng dụng (gọi chung là “Nội dung của bạn”). Bạn chịu trách nhiệm pháp lý về Nội dung của bạn được gửi tới Dịch vụ. Chúng tôi không có nghĩa vụ kiểm duyệt toàn bộ Nội dung của bạn nhưng chúng tôi có thể sử dụng các hệ thống tự động phân tích Nội dung của bạn để giúp phát hiện hành vi vi phạm và lạm dụng, chẳng hạn như thư rác, phần mềm độc hại và nội dung bất hợp pháp.",
          },
          {
            id: 2,
            titleDot: "Trao quyền",
            content:
              "Chúng tôi có thể sử dụng Nội dung của bạn theo nhiều cách khác nhau, bao gồm hiển thị công khai, định dạng lại, sử dụng công nghệ để cải thiện công nghệ, tạo các tác phẩm phái sinh từ nó, phân phối và cho phép bên khác quyền sử dụng tương tự trên trang web và nền tảng truyền thông của họ. Để làm rõ, bạn giữ lại tất cả các quyền sở hữu của mình đối với Nội Dung của bạn. Tuy nhiên, bằng việc gửi Nội dung của bạn đến Dịch vụ, bạn thông qua đây cấp cho chúng tôi quyền toàn cầu, không độc quyền, miễn phí bản quyền, có thể cấp quyền thứ cấp và có thể chuyển giao để sử dụng, sao chép, truyền đạt, tạo lập các tác phẩm phái sinh, trình bày, và thực hiện Nội dung của bạn liên quan đến Dịch vụ và hoạt động kinh doanh của chúng tôi (và của các bên kế thừa và các công ty liên kết của chúng tôi), bao gồm nhưng không giới hạn cho việc quảng bá và truyền đạt lại một phần hoặc toàn bộ Dịch vụ (và các tác phẩm phái sinh của Dịch vụ) trong bất kỳ định dạng truyền thông đại chúng nào và thông qua bất kỳ các kênh truyền thông đại chúng nào. Bạn cũng thông qua đây cấp cho từng người dùng Dịch vụ quyền không độc quyền để truy cập Nội dung của bạn thông qua Dịch vụ, và để sử dụng, sao chép, truyền đạt, trình bày và thực hiện Nội dung của bạn theo như được cho phép thông qua chức năng của Dịch vụ và theo các Điều khoản này.",
          },
          {
            id: 3,
            titleDot: "Đại diện và bảo đảm về nội dung của bạn",
            contents: [
              {
                id: 0,
                content: "Bạn đại diện và đảm bảo rằng: ",
              },
              {
                id: 1,
                content:
                  "(a) Bạn sở hữu Nội dung của bạn hoặc bạn có quyền sử dụng và cấp cho chúng tôi quyền và giấy phép như được cung cấp trong các Điều khoản này;",
              },
              {
                id: 2,
                content:
                  "(b) Việc chia sẻ Nội dung của bạn trên hoặc thông qua Dịch vụ không vi phạm quyền riêng tư, quyền công khai, bản quyền, quyền hợp đồng hoặc bất kỳ quyền nào khác của bất kỳ người nào.",
              },
            ],
          },
          {
            id: 4,
            titleDot: "Điều khoản cấm về Nội Dung chia sẻ",
            contents: [
              {
                id: 0,
                content:
                  "Khi gửi Nội dung hoặc sử dụng Dịch vụ, bạn cam kết sẽ không gửi tài liệu:",
              },
              {
                id: 1,
                content:
                  "a) Gửi hoặc chuyển các thông tin đe doạ, lạm dụng, bôi nhọ, nói xấu, khiêu dâm, phi thẩm mỹ, xúc phạm hoặc bất kỳ loại thông tin không đứng đắn nào, bao gồm nhưng không hạn chế việc truyền bá tin tức góp phần hay khuyến khích hành vi phạm tội, gây ra trách nhiệm pháp lý dân sự hoặc vi phạm pháp luật địa phương, liên bang, quốc gia hoặc quốc tế;",
              },
              {
                id: 2,
                content:
                  "b) Chống Nhà nước Cộng hoà xã hội chủ nghĩa Việt Nam, phá hoại khối đoàn kết toàn dân, tuyên truyền, xuyên tạc, kích động hoặc cung cấp thông chống phá Nhà nước Việt Nam; ",
              },
              {
                id: 3,
                content:
                  "c) Kích động bạo lực, tuyên truyền chiến tranh xâm lược, gây hận thù giữa các dân tộc và nhân dân các nước, kích động dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong mỹ tục của dân tộc; ",
              },

              {
                id: 4,
                content:
                  "d) Tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác đã được pháp luật quy định; ",
              },

              {
                id: 5,
                content:
                  "e) Xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh dự, nhân phẩm, uy tín của công dân;",
              },
              {
                id: 6,
                content:
                  "f) Quảng cáo, tuyên truyền hàng hoá, dịch vụ thuộc danh mục cấm đã được pháp luật quy định; ",
              },
              {
                id: 7,
                content: "g) Đề cập đến các vấn đề chính trị và tôn giáo; ",
              },
              {
                id: 8,
                content:
                  "h) Sử dụng các từ ngữ vô văn hóa vi phạm truyền thống đạo đức của Việt Nam; ",
              },
              {
                id: 9,
                content:
                  "i) Hạn chế hoặc ngăn cản người sử dụng khác sử dụng và hưởng các tính năng tương tác; ",
              },
              {
                id: 10,
                content:
                  "j) Gửi hay chuyển các thông tin, phần mềm, hoặc các tài liệu khác bất kỳ, vi phạm hoặc xâm phạm các quyền của những người khác, trong đó bao gồm cả tài liệu xâm phạm đến quyền riêng tư hoặc công khai, hoặc tài liệu được bảo vệ bản quyền, tên thương mại hoặc quyền sở hữu khác, hoặc các sản phẩm phái sinh mà không được sự cho phép của người chủ sở hữu hoặc người có quyền hợp pháp;",
              },
            ],
          },
          {
            id: 5,
            titleDot: "Quyền xử lý Nội dung",
            content:
              "Chúng tôi có quyền theo dõi, chỉnh sửa và loại bỏ nội dung. Chúng tôi không có nghĩa vụ giám sát việc bạn sử dụng Dịch vụ hoặc xem xét, kiểm duyệt bất kỳ Nội dung của bạn nhưng chúng tôi có quyền giám sát, xóa, chỉnh sửa và chặn Nội dung hoặc tài khoản chứa Nội dung mang tính chất vi phạm và lạm dụng, nhằm đảm bảo sự tuân thủ của bạn với các Điều khoản này; hoặc tuân thủ pháp luật hiện hành; lệnh hoặc yêu cầu của tòa án, cơ quan thẩm quyền khác. Bất kỳ lúc nào và không cần thông báo trước, chúng tôi sẽ bảo lưu quyền xóa hoặc vô hiệu hóa quyền truy cập vào bất kỳ Nội dung nào mà chúng tôi xem xét và nhận thấy có hành vi vi phạm các Điều khoản này hoặc có hại cho Dịch vụ.",
          },
        ],
      },
      {
        id: 6,
        title: "6. Quyền sở hữu của Sunday English đối với Nội dung Dịch vụ",
        contents: [
          {
            id: 1,
            content:
              "Tất cả nội dung có sẵn thông qua Dịch vụ, bao gồm thiết kế, văn bản, đồ họa, hình ảnh, thông tin, phần mềm, âm thanh và các tệp khác cũng như sự lựa chọn và sắp xếp của chúng tôi (“Nội dung Dịch vụ”), là tài sản độc quyền của Sunday English hoặc người cấp phép của Sunday English. Không có Nội dung dịch vụ nào có thể được sửa đổi, sao chép, phân phối, đóng khung, sao chép, tái xuất bản, tải xuống, hiển thị, đăng, truyền hoặc bán dưới bất kỳ hình thức nào hoặc bằng bất kỳ phương tiện nào, toàn bộ hoặc một phần, ngoài những điều được cho phép rõ ràng trong các Điều khoản sử dụng này. Bạn không được sử dụng bất kỳ phương pháp khai thác dữ liệu, rô bốt, công nghệ AI hoặc thu thập hoặc trích xuất dữ liệu tương tự nào để lấy Nội dung dịch vụ.",
          }
        ]
      },
      {
        id: 7,
        title: "7. Quy định về Hạn chế",
        contents: [
          {
            id: 0,
            content: "Bạn đồng ý không:"
          },
          {
            id: 1,
            content:
              "(a) Can thiệp, làm hỏng, làm suy yếu hoặc vô hiệu hóa hoạt động của Dịch vụ, bằng bất kỳ phương tiện nào (kể cả thông qua phương tiện tự động hoặc cách khác), bao gồm tải lên hoặc phổ biến virus, worm, phần mềm gián điệp, phần mềm quảng cáo hoặc mã độc hại khác; ",
          },
          {
            id: 2,
            content:
              "(b) Đưa ra các đề nghị, quảng cáo, đề xuất hoặc gửi thư rác hoặc thư rác không mong muốn cho Người dùng khác hoặc sử dụng Dịch vụ vì mục đích thương mại; ",
          },
          {
            id: 3,
            content:
              "(c) Sử dụng bất kỳ “rô bốt”, “spider”, “thiết bị đọc ngoại tuyến” hoặc các phương tiện tự động khác để truy cập Dịch vụ cho bất kỳ mục đích nào mà không có sự đồng ý rõ ràng của chúng tôi hoặc bỏ qua các tiêu đề loại trừ “rô bốt” hoặc các biện pháp tương tự; ",
          },
          {
            id: 4,
            content:
              "(d) Xóa, phá vỡ, vô hiệu hóa, làm hỏng hoặc can thiệp vào các tính năng liên quan đến bảo mật của Dịch vụ, các tính năng ngăn chặn hoặc hạn chế việc sử dụng hoặc sao chép bất kỳ phần nào của Dịch vụ hoặc các tính năng thực thi Hạn chế dịch vụ; ",
          },
          {
            id: 5,
            content:
              "(e) Cố gắng truy cập trái phép vào Dịch vụ, các tài khoản Người dùng khác, hệ thống máy tính hoặc mạng được kết nối với Dịch vụ thông qua hoạt động truy cập trái phép, khai thác mật khẩu hoặc bất kỳ phương tiện nào khác; ",
          },
          {
            id: 6,
            content:
              "(f) Liên kết worm tới Dịch vụ và bạn đồng ý rằng bạn sẽ nhanh chóng xóa mọi liên kết mà Công ty thấy có thể bị phản đối theo quyết định riêng của mình; ",
          },
          {
            id: 7,
            content:
              "(g) Gửi email hàng loạt, khảo sát hoặc thông điệp hàng loạt khác, dù là thương mại hay phi thương mại; ",
          },
          {
            id: 8,
            content:
              "(h) Gạ gẫm lấy thông tin cá nhân từ trẻ em, hoặc gửi hoặc truyền tải nội dung khiêu dâm; ",
          },
          {
            id: 9,
            content:
              "(i) Định dạng lại hoặc tái cấu trúc bất kỳ phần nào của Dịch vụ; ",
          },
          {
            id: 10,
            content:
              "(k) Thực hiện bất kỳ hành động nào để áp đặt, hoặc mang tính áp đặt, theo quyết định riêng của chúng tôi, một lượng tải lớn không hợp lý hoặc không cân xứng trên cơ sở hạ tầng công nghệ của chúng tôi hoặc tạo ra nhu cầu lưu lượng quá mức của Dịch vụ; ",
          },
          {
            id: 11,
            content:
              "(l) Mạo danh một người khác hoặc đại diện cho chính mình như là liên kết với chúng tôi, nhân viên của chúng tôi hoặc các chuyên gia trong ngành khác; ",
          },
          {
            id: 12,
            content:
              "(m) Thu thập mật khẩu hoặc thông tin tài khoản khác của Người dùng;  ",
          },
          {
            id: 13,
            content:
              "(n) Thu thập Tên người dùng, địa chỉ hoặc địa chỉ email cho bất kỳ mục đích nào.",
          },
          {
            id: 14,
            content:
              "(o) Cho tặng, sang nhượng, chuyển giao, cho thuê mượn những tài khoản đã kích hoạt.",
          },
        ],
      },
      {
        id: 8,
        title: "8. Sửa đổi đối với Dịch vụ",
        contents: [
          {
            id: 1,
            content:
              "Sunday English giữ quyền, tại bất kỳ thời điểm nào và tại từng thời điểm, sửa đổi hoặc ngừng tạm thời hoặc vĩnh viễn, Dịch vụ (hoặc bất kỳ phần nào của Dịch vụ) cho dù có hoặc không có thông báo, vì bất kỳ lý do nào, cho dù áp dụng chung đối với tất cả mọi người hoặc chỉ giới hạn đối với bạn. Bạn đồng ý rằng Sunday English không có nghĩa vụ dưới bất kỳ hình thức nào đối với bạn hoặc bất kỳ bên thứ ba nào về việc điều chỉnh, tạm ngừng hoặc ngừng cung cấp Dịch vụ.",          
          }
        ]
      },
      {
        id: 9,
        title: "9. Chấm dứt",
        contents: [
          {
            id: 1,
            content:
              "Bạn đồng ý rằng Sunday English có thể, tùy theo các trường hợp cụ thể và không cần thông báo trước, chấm dứt ngay lập tức việc sử dụng dịch vụ và truy cập vào tài khoản Sunday English đã được cấp cho bạn, bao gồm nhưng không giới hạn bởi các trường hợp sau:",
            contents: [
              {
                id: 1,
                content:
                  "(a) Bạn có hành vi truy cập vào các hệ thống Sunday English với mục đích phá hoại sự hoạt động của hệ thống; truy xuất và thu thập dữ liệu trái phép; hoặc các hành vi sai phạm khác như được đề cập ở mục I.7 mà chúng tôi cho rằng chúng ảnh hưởng đến lợi ích của hệ thống và của cả cộng đồng người học khác.",
              },
              {
                id: 2,
                content:
                  "(b) Theo yêu cầu của các cơ quan pháp luật có thẩm quyền.",
              },
              {
                id: 3,
                content: "(c) Theo yêu cầu của bạn.",
              },
            ]
          },
          {
            id: 2,
            content: "Việc chấm dứt sử dụng tài khoản Sunday English của bạn bao gồm:",
            contents: [
              {
                id: 1,
                content:
                  "(a) Xóa bỏ việc truy cập vào tất cả các Nội dung trong phạm vi ứng dụng và dịch vụ Sunday English.",
              },
              {
                id: 2,
                content:
                  "(b) Xóa bỏ mật khẩu và tất cả các thông tin, tập tin liên quan và các nội dung gắn kết hoặc nằm trong tài khoản của bạn (hoặc bất cứ phần nào của tài khoản).",
              },
              {
                id: 3,
                content:
                  "(c) Ngăn cản việc tiếp tục sử dụng ứng dụng và dịch vụ Sunday English.",
              },
            ]
          }
        ],
      },
    ],
  },
  security: {
    id: 'security',
    name: "CHÍNH SÁCH BẢO VỆ VÀ XỬ LÝ DỮ LIỆU CÁ NHÂN",
    contents: [
      {
        id: 1,
        content: "Khách hàng đồng ý áp dụng, phối hợp và cam kết tuân thủ Điều khoản và điều kiện chung về bảo vệ và xử lý dữ liệu cá nhân của Sunday English thuộc <b>Công ty Cổ phần Công nghệ Giáo dục GK (GK Corp).</b>",
      }
    ],
    data: [
      {
        id: 1,
        title: "1. Những quy định chung",
        contents: [
          {
            id: 1,
            contents: [
              {
                id: 1,
                content:
                  "A. Điều khoản và điều kiện chung về bảo vệ dữ liệu cá nhân (gọi chung là “Điều khoản và điều kiện chung về BVDLCN”), là một phần không thể tách rời của các thỏa thuận, điều khoản và điều kiện chi phối mối quan hệ giữa Khách hàng với Sunday English.",
              },
              {
                id: 2,
                content:
                  "B. Sunday English đề cao và tôn trọng quyền riêng tư, bảo mật và an toàn thông tin cá nhân. Đồng thời, Sunday English luôn nỗ lực bảo vệ thông tin cá nhân, quyền riêng tư của Khách hàng và tuân thủ quy định pháp luật Việt Nam thông qua những biện pháp bảo vệ dữ liệu cá nhân đáp ứng và phù hợp với các tiêu chuẩn quốc tế.",
              },
              {
                id: 3,
                content:
                  "C. Sunday English chỉ thu thập, xử lý và lưu trữ dữ liệu cá nhân của Khách hàng phù hợp với quy định của pháp luật và trong phạm vi (các) thỏa thuận giữa Sunday English và Khách hàng.",
              },
              {
                id: 4,
                content:
                  "D. Bằng việc cung cấp dữ liệu cá nhân của một bên thứ ba (bao gồm nhưng không giới hạn bởi: thông tin của người phụ thuộc, người có liên quan theo quy định pháp luật, vợ/chồng, con cái và/hoặc cha mẹ và/hoặc người giám hộ, bạn bè, người tham chiếu, bên thụ hưởng, người được ủy quyền, đối tác, người liên hệ trong các trường hợp khẩn cấp hoặc cá nhân khác của Khách hàng) cho Sunday English, Khách hàng cam đoan, bảo đảm và chịu trách nhiệm rằng Khách hàng đã có được sự đồng ý hợp pháp của bên thứ ba đó cho việc xử lý và thông tin về việc Sunday English là chủ thể xử lý các thông tin cá nhân cho các mục đích được nêu tại Điều khoản và điều kiện chung về BVDLCN.",
              },
              {
                id: 5,
                content:
                  "E. Điều khoản và điều kiện chung về BVDLCN sẽ được ưu tiên áp dụng trong trường hợp có bất kỳ xung đột hoặc mâu thuẫn nào với các thỏa thuận, điều khoản và điều kiện chi phối mối quan hệ của Khách hàng với Sunday English, cho dù được ký kết trước, vào ngày hoặc sau ngày Khách hàng chấp thuận Điều khoản và điều kiện chung về BVDLCN này.",
              },
            ]
          },
          {
            id: 2,
            content:
              "Tất cả các quyền và nghĩa vụ của Sunday English và Khách hàng tại Điều khoản và điều kiện chung về BVDLCN này sẽ không thay thế, chấm dứt hoặc thay đổi, nhưng sẽ là cộng dồn vào các quyền mà Sunday English và Khách hàng đang có ở bất kỳ văn bản nào và không một điều khoản nào trong Điều khoản và điều kiện chung về BVDLCN này hàm ý hạn chế hoặc xóa bỏ bất kỳ quyền nào trong số các quyền của Sunday English.",
          }
        ],
      },
      {
        id: 2,
        title: "2. Các hoạt động xử lý dữ liệu cá nhân",
        contents: [
          {
            id: 1,
            title: "2.1. Thu thập dữ liệu cá nhân",
            contents: [
              {
                id: 1,
                content:
                  "- Để Sunday English có thể cung cấp các sản phẩm, dịch vụ cho Khách hàng và/hoặc xử lý các yêu cầu của Khách hàng, Sunday English có thể cần phải và/hoặc được yêu cầu phải thu thập dữ liệu cá nhân có liên quan đến Khách hàng và các cá nhân có liên quan của Khách hàng. ",
              },
              {
                id: 2,
                content:
                  "- Sunday English có thể thu thập trực tiếp hoặc gián tiếp những dữ liệu cá nhân này từ Khách hàng khi Khách hàng yêu cầu, hoặc trong quá trình Sunday English cung cấp bất kỳ sản phẩm, dịch vụ nào cho Khách hàng.",
              },
              {
                id: 3,
                content:
                  "Việc thu thập dữ liệu chủ yếu trên Sunday English bao gồm: email, điện thoại, tên đăng nhập, mật khẩu đăng nhập, địa chỉ khách hàng (thành viên). Đây là các thông tin mà Sunday English cần thành viên cung cấp bắt buộc khi đăng ký sử dụng dịch vụ và để Sunday English liên hệ xác nhận khi khách hàng đăng ký sử dụng dịch vụ trên website nhằm đảm bảo quyền lợi cho cho người tiêu dùng. Các thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử của mình. Ngoài ra, thành viên có trách nhiệm thông báo kịp thời cho Sunday English về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.",
              },
            ],
          },
          {
            id: 2,
            title: "2.2. Phạm vi sử dụng thông tin",
            contents: [
              {
                id: 1,
                title:
                  "A. Sunday English có thể xử lý dữ liệu cá nhân cho một hoặc nhiều mục đích sau đây:",
                contents: [
                  {
                    id: 1,
                    contentDot:
                      "Xác minh tính chính xác, đầy đủ của các thông tin được Khách hàng cung cấp; xác định hoặc xác thực danh tính của Khách hàng và thực hiện quy trình xác thực khách hàng;",
                  },
                  {
                    id: 2,
                    contentDot:
                      "Xử lý việc đăng ký của Khách hàng đối với bất kỳ sản phẩm và dịch vụ nào do Sunday English đề xuất hoặc cung cấp;",
                  },
                  {
                    id: 3,
                    contentDot:
                      "Liên hệ với Khách hàng nhằm trao đổi thông tin, giao các hóa đơn, các sao kê, các báo cáo hoặc các tài liệu khác có liên quan;",
                  },
                  {
                    id: 4,
                    contentDot:
                      "Thông báo cho Khách hàng các thông tin về quyền lợi, thay đổi các tính năng của sản phẩm, dịch vụ;",
                  },
                  {
                    id: 5,
                    contentDot:
                      "Quản lý và đánh giá các hoạt động kinh doanh bao gồm thiết kế, cải tiến và nâng cao chất lượng các các sản phẩm, dịch vụ của Sunday English hoặc thực hiện các hoạt động truyền thông tiếp thị;",
                  },
                  {
                    id: 6,
                    contentDot:
                      "Lập các báo cáo tài chính, báo cáo hoạt động hoặc các loại báo cáo liên quan khác theo quy định pháp luật;",
                  },
                  {
                    id: 7,
                    contentDot:
                      "Thực hiện nghiên cứu thị trường, khảo sát và phân tích dữ liệu liên quan đến bất kỳ các sản phẩm, dịch vụ nào do Sunday English cung cấp (dù được thực hiện bởi Sunday English hay một bên thứ ba khác mà Sunday English hợp tác) mà có thể liên quan đến Khách hàng;",
                  },
                  {
                    id: 8,
                    contentDot:
                      "Bảo vệ lợi ích hợp pháp của Sunday English và tuân thủ các quy định pháp luật liên quan;",
                  },
                  {
                    id: 9,
                    contentDot:
                      "Ngăn chặn hoặc giảm thiểu mối đe dọa đối với tính mạng, sức khỏe của người khác và lợi ích công cộng;",
                  },
                  {
                    id: 10,
                    contentDot:
                      "Để đáp ứng, tuân thủ các chính sách nội bộ của Sunday English, các thủ tục và bất kỳ quy tắc, quy định, hướng dẫn, chỉ thị hoặc yêu cầu được ban hành bởi Cơ quan nhà nước có thẩm quyền theo quy định pháp luật;",
                  },
                  {
                    id: 11,
                    contentDot:
                      "Cho bất kỳ mục đích nào khác được yêu cầu hoặc cho phép bởi bất kỳ luật, quy định, hướng dẫn và/hoặc các Cơ quan nhà nước có thẩm quyền;",
                  },
                  {
                    id: 12,
                    contentDot:
                      "Để thực hiện các hoạt động khác có liên quan đến việc cung cấp, vận hành, xử lý và quản lý của Sunday English đối với các sản phẩm, dịch vụ cho Khách hàng;",
                  },
                  {
                    id: 13,
                    contentDot:
                      "Để phục vụ các mục đích khác có liên quan đến hoạt động kinh doanh của Sunday English mà Sunday English cho là phù hợp tại từng thời điểm; ",
                  },
                  {
                    id: 14,
                    contentDot:
                      "Các mục đích hợp lý khác có liên quan đến những mục đích được nêu trên.",
                  },
                ],
              },
              {
                id: 2,
                title: "B. Việc chuyển giao và tiết lộ dữ liệu cá nhân:",
                contents: [
                  {
                    id: 1,
                    contentDot:
                      "Các cơ quan nhà nước có thẩm quyền tại Việt Nam hoặc bất kỳ cá nhân, cơ quan có thẩm quyền hoặc cơ quan quản lý hoặc bên thứ ba mà Sunday English được phép hoặc bắt buộc phải tiết lộ theo quy định pháp luật của bất kỳ quốc gia, hoặc theo bất kỳ hợp đồng/thỏa thuận hoặc cam kết nào khác giữa bên thứ ba và Sunday English;",
                  },
                  {
                    id: 2,
                    contentDot:
                      "Các bên thứ ba mà Khách hàng đồng ý hoặc Sunday English có cơ sở pháp lý để chia sẻ dữ liệu cá nhân của Khách hàng.",
                  },
                  {
                    id: 3,
                    contentDot:
                      "Mặt khác, Sunday English sẽ xem dữ liệu cá nhân của Khách hàng là riêng tư và bí mật. Ngoài các bên đã nêu ở trên, Sunday English sẽ không tiết lộ dữ liệu của Khách hàng cho bất kỳ bên nào khác, trừ các trường hợp:",
                    contents: [
                      {
                        id: 1,
                        content: "a. Khi có sự đồng ý của Khách hàng;",
                      },
                      {
                        id: 2,
                        content:
                          "b. Khi Sunday English được yêu cầu hoặc được phép tiết lộ theo quy định pháp luật; hoặc theo quyết định của cơ quan nhà nước có thẩm quyền;",
                      },
                      {
                        id: 3,
                        content:
                          "c. Khi Sunday English chuyển giao quyền và nghĩa vụ theo (các) thỏa thuận giữa Khách hàng và Sunday English.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        title: "3. Thời gian lưu trữ thông tin",
        contents: [
          { 
            id: 1,
            content:
            "Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ theo yêu cầu hoặc cho phép bởi các quy định pháp luật hiện hành hoặc tự thành viên đăng nhập và thực hiện hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của Sunday English trong khoảng thời gian cần thiết để hoàn thành các mục đích theo Điều khoản và điều kiện chung về BVDLCN.",
          }
        ]
      },
      {
        id: 4,
        title: "4. Biện pháp Bảo mật dữ liệu cá nhân",
        contents: [
          {
            id: 1,
            content:
              "- Sunday English xem các dữ liệu cá nhân của Khách hàng như là tài sản quan trọng nhất của Sunday English và Sunday English đảm bảo tính bảo mật, an toàn, tuân thủ pháp luật, hạn chế các hậu quả, thiệt hại không mong muốn có khả năng xảy ra (bao gồm nhưng không giới hạn: rò rỉ dữ liệu hoặc xử lý dữ liệu không phù hợp gây tổn hại đến quyền và lợi ích hợp pháp của Khách hàng). Trách nhiệm bảo mật dữ liệu cá nhân của Khách hàng là yêu cầu bắt buộc Sunday English đặt ra cho toàn thể nhân viên.            ",
          },
          {
            id: 2,
            content:
              "- Sunday English thực hiện trách nhiệm bảo vệ dữ liệu cá nhân theo quy định của pháp luật hiện hành với các phương pháp bảo mật tốt nhất theo tiêu chuẩn quốc tế và thường xuyên xem xét và cập nhật các biện pháp quản lý và kỹ thuật khi xử lý dữ liệu cá nhân của Khách hàng (nếu có).",
          },
        ],
      },
      {
        id: 5,
        title: "5. Chính sách bảo mật thông tin thanh toán",
        contents: [
          {
            id: 1,
            content:
              "Sunday English luôn coi trọng việc bảo mật thông tin và sử dụng các biện pháp tốt nhất để bảo vệ thông tin cá nhân của Khách hàng (người dùng dịch vụ) trong quá trình thanh toán. Khách hàng không được sử dụng bất kỳ chương trình, công cụ hay hình thức nào để can thiệp vào hệ thống làm thay đổi cấu trúc dữ liệu.",
          },
          {
            id: 2,
            content:
              "Cá nhân hay tổ chức có hành vi can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống sẽ bị tước bỏ mọi quyền lợi cũng như bị truy tố trước pháp luật nếu cần thiết.",
          },
          {
            id: 3,
            content: 'Tất cả thông tin giao dịch đều được bảo mật trừ trường hợp phải thực hiện theo yêu cầu của cơ quan Nhà nước có thẩm quyền.'
          },
          {
            id: 4,
            title: '5.1. Mục đích áp dụng',
            contents: [
              {
                id: 1,
                content: '- Hệ thống thanh toán thẻ trên website: <a href=\"https://sundayenglish.com\">https://sundayenglish.com/</a> (sau đây gọi là Sunday English) được cung cấp bởi đối tác cổng thanh toán OnePay đã được cấp phép hoạt động hợp pháp tại Việt Nam (“Đối Tác Cổng Thanh Toán”). Theo đó, các tiêu chuẩn bảo mật thanh toán thẻ của Sunday English đảm bảo tuân thủ theo các tiêu chuẩn bảo mật của Đối Tác Cổng Thanh Toán.'
              },
              {
                id: 2,
                content: '- Ngoài ra, Sunday English còn có các tiêu chuẩn bảo mật giao dịch thanh toán riêng, đảm bảo an toàn các thông tin thanh toán của khách hàng.'
              }
            ]
          },
          {
            id: 5,
            title: '5.2. Quy định cụ thể',
            contents: [
              {
                id: 1,
                content: '- Chính sách giao dịch thanh toán bằng thẻ nội địa và thẻ quốc tế đảm bảo tuân thủ các tiêu chuẩn bảo mật của các Đối Tác Cổng Thanh Toán gồm:',
                contents: [
                  {
                    id: 1,
                    content: '+ Thông tin tài chính của khách hàng được bảo vệ bằng giao thức SSL (Secure Sockets Layer). Giao thức SSL sẽ mã hóa thông tin khách hàng cung cấp trong suốt quá trình giao dịch.'
                  },
                  {
                    id: 2,
                    content: '+ Hệ thống thanh toán đáp ứng chuẩn bảo mật thông tin của PCI Security Standards Council.'
                  },
                  {
                    id: 3,
                    content: '+ Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính ngân hàng theo quy định của Ngân hàng Nhà nước Việt Nam.'
                  }
                ]
              },
              {
                id: 2,
                content: '- Chính sách bảo mật giao dịch trong thanh toán của Sunday English áp dụng với khách hàng: Sunday English cung cấp tiện ích lưu giữ thông tin thẻ để sử dụng cho các lần thanh toán sau trên Sunday English với nguyên tắc Sunday English chỉ lưu khóa thông tin thẻ thanh toán đã được các Đối Tác Cổng Thanh Toán mã hóa. Vì vậy:',
                contents: [
                  {
                    id: 1,
                    content: '+ Khách hàng lựa chọn sử dụng tiện ích: trong trường hợp phát sinh vấn đề liên quan đến việc bảo mật thông tin thẻ thanh toán, khách hàng liên hệ với Đối Tác Cổng Thanh Toán để được giải quyết.'
                  },
                  {
                    id: 2,
                    content: '+ Khách hàng không lựa chọn sử dụng tiện ích: thông tin thẻ thanh toán của khách hàng không lưu trên hệ thống của Sunday English mà được lưu giữ và bảo mật theo tiêu chuẩn quốc tế PCI DSS trên Đối Tác Cổng Thanh Toán.'
                  },
                  {
                    id: 3,
                    content: '+ Khách hàng truy cập vào website thông qua giao thức HTTPS - HyperText Transfer Protocol Secure (giao thức truyền tải siêu văn bản bảo mật).'
                  }
                ]
              }
            ]
          }
        ],
      },
      {
        id: 6,
        title: "6. Sửa đổi",
        contents: [
          {
            id: 1,
            content:
              "- Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu Sunday English thực hiện việc này.",
          },
          {
            id: 2,
            content:
              "- Sunday English có thể sửa đổi, cập nhật hoặc điều chỉnh các điều khoản của Điều khoản và điều kiện chung về BVDLCN này tùy từng thời điểm. Thông báo về bất kỳ sự sửa đổi, cập nhật hoặc điều chỉnh nào sẽ được cập nhật, đăng tải trên trang điện tử của Sunday English: <a href=\"https://sundayenglish.com\">sundayenglish.com</a> và/ hoặc thông báo đến Khách hàng thông qua các phương tiện liên lạc khác mà Sunday English cho là phù hợp. ",
          },
        ],
      },
      {
        id: 7,
        title: "7. Cơ chế tiếp nhận và giải quyết khiếu nại của Khách hàng",
        contents: [
          {
            id: 1,
            content:
              "Chúng tôi có trách nhiệm tiếp nhận khiếu nại và hỗ trợ Khách hàng liên quan đến giao dịch tại hệ thống Sunday English. Khi phát sinh tranh chấp, chúng tôi đề cao giải pháp thương lượng, hòa giải giữa các bên nhằm duy trì sự tin cậy của Khách hàng vào chất lượng dịch vụ của chúng tôi và thực hiện theo các bước sau:",
          },
          {
            id: 2,
            content:
              "Bước 1: Khách Hàng gửi khiếu nại về dịch vụ của hệ thống Sunday English qua email: <a href=\"mailto:support@sundayenglish.com\">support@sundayenglish.com</a>, Hoặc Khách hàng có thể phản ánh trực tiếp qua Hotline: 024 6281 3888",
          },
          {
            id: 3,
            content: 'Bước 2: Bộ phận Chăm Sóc Khách Hàng của chúng tôi sẽ tiếp nhận các khiếu nại của Khách Hàng, tùy theo tính chất và mức độ của khiếu nại thì chúng tôi sẽ có những biện pháp cụ thể hỗ trợ Khách hàng để giải quyết tranh chấp đó. Bộ phận này chủ động giải quyết nhanh chóng và trả lời ngay kết quả giải quyết các khiếu nại trên cơ sở các Chính sách mà chúng tôi đã công bố trong thời hạn 7 ngày làm việc.'
          },
          {
            id: 4,
            content: 'Bước 3: Trong trường hợp nằm ngoài khả năng và thẩm quyền của chúng tôi thì chúng tôi sẽ yêu cầu Khách hàng đưa vụ việc này ra cơ quan nhà nước có thẩm quyền giải quyết theo đúng quy định của pháp luật.'
          },
          {
            id: 5,
            content: 'Chúng tôi luôn tôn trọng và nghiêm túc thực hiện các quy định của pháp luật về bảo vệ quyền lợi của Khách hàng (người dùng dịch vụ).'
          },
          {
            id: 6,
            content: 'Nếu thông qua hình thức thỏa thuận mà vẫn không thể giải quyết được mâu thuẫn phát sinh từ giao dịch giữa 02 (hai) bên, thì một trong 02 (hai) bên Khách hàng hay Sunday English sẽ có quyền nhờ đến cơ quan pháp luật có thẩm quyền can thiệp nhằm đảm bảo lợi ích hợp pháp của các bên.'
          }
        ],
      },
    ]
  },
  license: {
    id: 'license',
    name: 'CHÍNH SÁCH VỀ BẢN QUYỀN & SỞ HỮU TRÍ TUỆ',
    data: [
      {
        id: 1,
        title:
          "1. Quy định về việc sử dụng nội dung trên hệ thống website và ứng dụng của dịch vụ Sunday English",
        contents: [
          {
            id: 1,
            content:
              "- Sunday English cho phép bạn xem và sử dụng thông tin của website cho mục đích học tập cá nhân, không phải cho mục đích thương mại, vì thế bạn cần phải giữ lại tất cả tuyên bố bản quyền và quyền sở hữu khác trong thông tin gốc trên tất cả bản sao của những thông tin này.",
          },
          {
            id: 2,
            content:
              "- Không được sửa thông tin của website dưới bất cứ hình thức nào, hoặc sao chép hoặc công khai trình bày, thực hiện, tuyên truyền, hoặc dùng cho mục đích thương mại. Nghiêm cấm sử dụng thông tin website cho bất cứ mục đích nào ở website khác hoặc môi trường máy tính mạng dưới bất cứ hình thức nào. Thông tin trên website đều có bản quyền, sử dụng thông tin website trái phép sẽ vi phạm luật bản quyền, luật thương hiệu và các luật khác. Thông tin, giới thiệu chương trình, ý tưởng, tài liệu, dịch vụ, và tất cả phần mềm tải xuống của website đều là sản phẩm có bản quyền của <b>Công ty Cổ phần Công nghệ Giáo dục GK</b>, nghiêm cấm sao chép hoặc chuyển tiếp phần mềm.",
          },
          {
            id: 3,
            content:
              "- Chúng tôi có toàn quyền khoá tài khoản và cấm truy cập vĩnh viễn đối với những Thành viên không tôn trọng hay có hành vi xâm phạm Bản quyền và Quyền sở hữu trí tuệ theo quy định của pháp luật nước CHXHCN Việt Nam.",
          },
          {
            id: 4,
            content:
              "- Nếu Bạn cho rằng nội dung mà Bạn sở hữu bị sử dụng trái phép trên Trang Web, có dấu hiệu vi phạm Bản quyền hay Quyền sở hữu Trí tuệ, xin vui lòng báo ngay cho Chúng tôi với các thông tin sau để kịp thời xử lý:",
            contents: [
              {
                id: 1,
                content: "+ Mô tả nội dung Bạn sở hữu bị vi phạm Bản quyền.",
              },
              {
                id: 2,
                content:
                  "+ Mô tả vị trí, nội dung, cách thức mà nội dung của Bạn bị vi phạm Bản quyền trên Trang Web của chúng tôi, để Chúng tôi có thể xác định đúng chi tiết nội dung để xử lý.",
              },
              {
                id: 3,
                content: "+ Thông tin liên hệ, địa chỉ, số điện thoại, email.",
              },
              {
                id: 4,
                content:
                  "+ Lời xác thực bởi chính Bạn, rằng những thông tin bạn cung cấp là chính xác và yêu cầu xử lý đối với nội dung vi phạm Bản quyền.",
              },
              {
                id: 5,
                content:
                  "+ Bạn có thể liên hệ với chúng tôi về các vấn đề bản quyền theo email …",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        title: "2. Liên kết website",
        contents: [
          {
            id: 1,
            content:
              "Sau khi được Công ty Cổ phần Công nghệ Giáo dục GK chấp thuận, quý khách có thể liên kết với website, nhưng không được sao chép nội dung trên wesbite của Sunday English. Không được dùng nội dung trên website của Sunday English để xây dựng trình duyệt và môi trường mạng. Không được ám chỉ Sunday English công nhận website này hoặc sản phẩm dịch vụ của nó. Không được xuyên tạc quan hệ giữa website này với Sunday English. Không được sử dụng logo liên quan đến Sunday English khi chưa được sự cho phép của Công ty Cổ phần Công nghệ Giáo dục GK.",
          }
        ]
      },
    ]
  },
  payment: {
    id: 'payment',
    name: 'CHÍNH SÁCH THANH TOÁN',
    data: [
      {
        id: 1,
        title: "1. Chính sách Vận chuyển và Giao nhận",
        contents: [
          {
            id: 1,
            content:
              "Hiện nay, Sunday English hiện chỉ hỗ trợ hình thức giao mã kích hoạt gói học thông qua email được gửi về địa chỉ email do khách hàng đăng ký khi tiến hành thanh toán trực tuyến.",
          },
          {
            id: 2,
            content: '- Đối với hình thức thanh toán trực tuyến: Khi khách hàng thanh toán qua cổng thanh toán trực tuyến của Sunday English thành công, khách hàng sẽ nhận được email thông báo mã kích hoạt gói học và xác nhận thanh toán thành công.'
          },
          {
            id: 3,
            content: '- Đối với hình thức thanh toán trong ứng dụng bằng thẻ tín dụng đăng ký với Apple/ Google (In-app purchase): Với hình thức giao nhận này, khách hàng thực hiện được qua app Sunday English và Sunday Teacher. Khách hàng sẽ nhận được email thông báo mã kích hoạt gói học và xác nhận thanh toán thành công.'
          },
          {
            id: 4,
            content: 'Khách hàng được quyền yêu cầu xuất hóa đơn điện tử cho mỗi đơn hàng mua gói học thành công bằng cách gửi yêu cầu đến địa chỉ email: <a href=\"mailto:support@sundayenglish.com\">support@sundayenglish.com</a>.'
          }
        ],
      },
      {
        id: 2,
        title: "2. Chính sách Kiểm hàng",
        contents: [
          {
            id: 1,
            content:
              "Hiện nay, Sunday English hiện chỉ hỗ trợ hình thức giao mã kích hoạt gói học thông qua email trực tuyến. Sau khi khách hàng nhận và nhập mã kích hoạt gói học, nếu có yêu cầu hoặc thắc mắc liên quan đến việc sử dụng ứng dụng, khách hàng có thể liên hệ tổng đài chăm sóc khách hàng 024 6281 3888 để được hỗ trợ từ 8h00-18h00 các ngày trong tuần.",
          }
        ]
      },
      {
        id: 3,
        title: "3. Chính sách Đổi hàng",
        contents: [
          {
            id: 1,
            content:
              "Khách hàng được chuyển đổi sang các gói học có giá trị lớn hơn gói học đã mua theo giá niêm yết trên website tại thời điểm xác nhận yêu cầu chuyển đổi. Mỗi tài khoản chỉ được hỗ trợ chuyển đổi 1 lần.",
          },
          {
            id: 2,
            content: "Phương thức thực hiện",
            contents: [
              {
                id: 1,
                content:
                  "- Khi có nhu cầu chuyển đổi sang gói học có giá trị cao hơn gói học đã mua, quý khách sẽ được nhân viên chăm sóc khách hàng hỗ trợ chuyển đổi ngay tại thời điểm nhận yêu cầu chuyển đổi.",
              },
              {
                id: 2,
                content:
                  "- Khi chuyển đổi sang gói học có giá trị lớn hơn gói học đã mua, quý khách vui lòng thanh toán phí chênh lệch.",
              },
            ],
          },
          {
            id: 3,
            content:
              "<b style='color:#eb5757;'>LƯU Ý:</b> Trong thời gian 24 giờ kể từ khi Sunday English xác nhận yêu cầu chuyển đổi sang gói học có giá trị cao hơn từ quý khách (Không tính thứ 7, Chủ Nhật và các ngày nghỉ lễ), nếu quý khách không thực hiện thanh toán chênh lệch, yêu cầu chuyển đổi của quý khách sẽ tự động bị <b>HỦY</b>",
          },
        ],
      },
      {
        id: 4,
        title: "4. Chính sách Trả hàng và Hoàn tiền",
        contents: [
          {
            id: 1,
            content:
              "Khi khách hàng đăng ký mua một khóa học tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua và sử dụng. Sunday English chịu trách nhiệm đảm bảo các cam kết dịch vụ đã nêu ra và sẽ làm hết khả năng để khắc phục trong những trường hợp bất khả kháng.",
          },
          {
            id: 2,
            content:
              "Sunday English không có trách nhiệm hoàn trả học phí mà khách hàng đã đăng ký.",
          }
        ]
      },
    ]
  }
}