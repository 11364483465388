import React, { useEffect } from "react";
import { ScheduleTodayItem } from "./ScheduleTodayItem";
import { scheduleConstants } from "../../../../_constants";
import { PopUpRadio } from "./../../../Popup";
import { useDispatch } from "react-redux";

function ScheduleToday(props) {
  const { selectedDate, type } = props;
  const dispatch = useDispatch();
  const schedules = props.schedules;
  let { student, page } = props;
  if (page === undefined) {
    page = "schedule";
  }
  useEffect(() => {
    dispatch({
      type: scheduleConstants.REMOVE_DETAIL_SCHEDULE_DAY,
    });
  }, []);
  
  return (
    <div
      className={
        "flex-1 sunE-plan sunE-plan-list top-12-custom sunE-plan-list-home responsive_plan_list_screen_small" +
        (student ? " khht-cn" : "")
      }
    >
      <PopUpRadio selectedDate={selectedDate} student />
      {schedules.map((schedule, i) => {
        if (type === "personal") {
          return (
            schedule.type === "personal" && (
              <ScheduleTodayItem page={page} schedule={schedule} key={i} />
            )
          );
        } else {
          return (
            <ScheduleTodayItem
              page={page}
              schedule={schedule}
              selectedDate={selectedDate}
              key={i}
            />
          );
        }
      })}
    </div>
  );
}

export { ScheduleToday };
