import ButtonNews from "../Button/ButtonNews";
import "./Slogan.style.scss";
import { sloganLogic } from "./Slogan.logic";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { useSelector } from "react-redux";
import { hasDomainStore } from "../../_base/Validate";
import LazyLoad from "react-lazyload";

const Slogan = (props) => {
  let { handleNavigate, handleNavigateRegister } = sloganLogic();
  const authentication = useSelector((state) => state.authentication);

  return (
    <div className="slogan_container">
      <div className="slogan_content">
        <LazyLoad once className="col-100">
          {/* <img
            className="slogan_img display_none_mobile"
            src={`/assets/images/homeNews/content/${
              props.type == "teacher"
                ? "slogan_teacher"
                : hasDomainStore()
                ? "slogan_1_infor"
                : "slogan_1"
            }.png`}
            alt="Slogan Image"
          /> */}
          {/* <img
            loading="lazy"
            className="slogan_img hide display_block_mobile contain_image"
            src={`/assets/images/homeNews/content/${
              props.type == "teacher"
                ? "slogan_teacher_mobile"
                : "slogan_1_mobile"
            }.png
          `}
            alt="Slogan Image"
          /> */}
          {/* {props.type != "teacher" && !hasDomainStore() && (
            <div className="btn_buy_container">
              <ButtonNews
                width="20rem"
                height="4.68rem"
                onClick={() =>
                  handleNavigate(`/${TypeHeaderNewsItem.FEE}/#list_packages`)
                }
                border={"1px solid #fff"}
                backgroundColor={"#EB5757"}
                boxShadow={"1px 1px 5px white, 0 0 1px white"}
                maxWidth={"19.5vw"}
                maxHeight={"5.5vw"}
              >
                <div className="text_btn_buy fz-1-mobile btn_text font_news_bold color-white">
                  MUA NGAY
                </div>
              </ButtonNews>
            </div>
          )} */}
        </LazyLoad>
      </div>
      {/* <div className="flex-jus-evenly btn_container">
        <ButtonNews
          width="25rem"
          height={"3.5rem"}
          onClick={() => handleNavigate("/advisement")}
          border={"1px solid #00cc83"}
          backgroundColor={"#00cc83"}
        >
          <div className="fz-07-5-mobile fz-16 btn_text font_news_bold">
            NHẬN TƯ VẤN MIỄN PHÍ
          </div>
        </ButtonNews>
      </div> */}
    </div>
  );
};

export default Slogan;
