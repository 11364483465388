import React, { Fragment, useEffect, useRef } from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

const months = [
  "Tháng 1 / ",
  "Tháng 2 / ",
  "Tháng 3 / ",
  "Tháng 4 / ",
  "Tháng 5 / ",
  "Tháng 6 / ",
  "Tháng 7 / ",
  "Tháng 8 / ",
  "Tháng 9 / ",
  "Tháng 10 / ",
  "Tháng 11 / ",
  "Tháng 12 / ",
];
const days = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

registerLocale("es", {
  ...es,
  options: { ...es.options, weekStartsOn: 1 },
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
});

function SelectDate(props) {
  const { id, name, selected, onChange, dateFormat, disableMouseFocus, minDate } = props;
  const showTimeSelect = props.showTimeSelect || false;
  const showTimeSelectOnly = props.showTimeSelectOnly || false;
  const dateRef = useRef(null);

  useEffect(() => {
    let isFirefox = window.navigator.userAgent.indexOf("Firefox") != -1;
    if (dateRef.current && disableMouseFocus && !isFirefox) {
      dateRef.current?.input?.setAttribute("disabled", true);
    }
  }, [dateRef, disableMouseFocus]);

  return (
    <Fragment>
      <DatePicker
        minDate={minDate ? new Date() : {}}
        onChangeRaw={(e) => e.preventDefault()}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        // timeFormat="HH:mm"
        locale="es"
        id={id}
        timeIntervals={props?.timeIntervals || 15}
        dateFormat={dateFormat}
        name={name}
        selected={selected}
        placeholderText="DD/MM/YYYY"
        onChange={(time) => onChange(time)}
        ref={dateRef}
      />
    </Fragment>
  );
}

export { SelectDate };
