import React from "react";
import Slider from "react-slick";
import { ChartDoughnut } from "./../../Chart";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img
        src='/assets/images/icon/ico_right_white.png'
        alt='ico_right_white'
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src='/assets/images/icon/ico_left_white.png' alt='ico_left_white' />
    </div>
  );
}

function SliderClass(props) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  let { classes, role } = props;

  const renderBodyItemClass = (data) => {
    if (parseInt(data.total_student) === 0) {
      return (
        <div className='__text_empty_data_home_page __semibold'>
          Lớp chưa có học sinh,
          <div className=' __semibold'>vui lòng thêm học sinh vào lớp.</div>
        </div>
      );
    } else {
      return (
        <div className='slider-class-see-report'>
          <Link
            to={"/" + role + "/class/view/" + data?.class_id + "/report"}
            className='text_underline _semibold'
          >
            Xem báo cáo học tập
          </Link>
        </div>
      );
    }
    //  else if (data.report_data) {
    //   let total_overview_score =
    //     data.report_data.overview_score.A +
    //     data.report_data.overview_score.B +
    //     data.report_data.overview_score.C +
    //     data.report_data.overview_score.D;
    //   let report_data = {
    //     datasets: [
    //       {
    //         data: [
    //           data.report_data.overview_score.A,
    //           data.report_data.overview_score.B,
    //           data.report_data.overview_score.C,
    //           data.report_data.overview_score.D,
    //         ],
    //         backgroundColor: ["#00AEEF", "#00CC7E", "#FBB040", "#BE1E2D"],
    //       },
    //     ],
    //     labels: [
    //       "Giỏi: " +
    //         data.report_data.overview_score.A +
    //         " (" +
    //         Number.parseFloat(
    //           (data.report_data.overview_score.A / total_overview_score) * 100
    //         ).toFixed(1) +
    //         "%)",
    //       "Khá: " +
    //         data.report_data.overview_score.B +
    //         " (" +
    //         Number.parseFloat(
    //           (data.report_data.overview_score.B / total_overview_score) * 100
    //         ).toFixed(1) +
    //         "%)",
    //       "Trung Bình: " +
    //         data.report_data.overview_score.C +
    //         " (" +
    //         Number.parseFloat(
    //           (data.report_data.overview_score.C / total_overview_score) * 100
    //         ).toFixed(1) +
    //         "%)",
    //       "Dưới trung bình: " +
    //         data.report_data.overview_score.D +
    //         " (" +
    //         Number.parseFloat(
    //           (data.report_data.overview_score.D / total_overview_score) * 100
    //         ).toFixed(1) +
    //         "%)",
    //     ],
    //   };
    //   if (total_overview_score === 0) {
    //     return (
    //       <div className='__text_empty_data_home_page __semibold'>
    //         Báo cáo học tập của lớp đang được cập nhật.
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div className='chart-content rel responsive_chart_screen_small'>
    //         <ChartDoughnut report_data={report_data} position='right' />
    //         <div className='flex-m dtb-sum-slider'>
    //           <div className='w space_null_responsive_home_page_teacher'></div>
    //           <p className='flex-1 font_average_score'>
    //             Điểm Trung bình:{" "}
    //             <span className='calendar-text-tb'>
    //               {parseFloat(
    //                 Number.parseFloat(data.report_data.avg).toFixed(1)
    //               )}
    //             </span>{" "}
    //           </p>
    //         </div>
    //       </div>
    //     );
    //   }
    // }
  };

  return (
    <div className='slider-custom-2'>
      <ul className='uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m rel'>
        <Slider {...settings}>
          {classes.data.map((data, i) => {
            return (
              <li key={i}>
                {/* <Link
                  to={"/" + role + "/class/view/" + data.class_id + "/report"}
                > */}
                <div className='box-shadow box-white-content text-center slider-item-content flex-m'>
                  <LazyLoad
                    once
                    className='img-thumb responsive_avatar_class_screen_small'
                  >
                    <img
                      className='img-cover responsive_avatar_class_screen_small_img'
                      src={classes.base_url + "/" + data.class_avatar}
                      alt='detail2'
                    />
                  </LazyLoad>
                  <div className='flex-1 slider-item-desc'>
                    <div className='item-top-title-main responsive_class_name_screen_small'>
                      <p className='text-light two-line'>{data.class_name}</p>
                    </div>
                    <div className=' __semibold text-align-left top-16'>
                      {`Sĩ số: ${data?.total_student}`}
                    </div>
                    <LazyLoad
                      once
                      className='__body_class __body_class_add_responsive_screen_small'
                    >
                      {renderBodyItemClass(data)}
                    </LazyLoad>
                  </div>
                </div>
                {/* </Link> */}
              </li>
            );
          })}
        </Slider>
      </ul>
    </div>
  );
}

export { SliderClass };
