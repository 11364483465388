import { history } from "../../_helpers";

export const scrollFixedLogic = () => {
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const handleNavigate = (href) => {
    history.push(href)
    window.scrollTo({ top: 0, behavior: "instant" });
  }

  return {
    handleScrollTop,
    handleNavigate
  };
};
