import "./FormTrial.style.scss";
import "../AdvisementForm.style.scss";
import InputText from "../../Auth/InputText";
import ButtonNews from "../../Button/ButtonNews";
import InputSelectNews from "../../Input/InputSelect";
import ModalSuccessMsg from "../../Modal/ModalSuccessMsg/ModalSuccessMsg";
import { formTrialLogic } from "./FormTrial.logic";
import { advisementLogic } from "../AdvisementForm.logic";
import InputSelect from "../../Auth/InputSelect";
import { history } from "../../../_helpers";
import { sendMsgContact } from "../../../_services/user";
import "../../../_components/Modal/ModalSuccessMsg/ModalSuccessMsg.style.scss";

const FormTrial = (props) => {
  // Variable advisement Logic for value and validate
  let {
    timeAdvisement,
    gradeSelected,
    nameUser,
    setNameUser,
    changeNameUser,
    nameUserError,
    setNameUserError,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    onBlurField,
    handleCheckedBoxTrial,
    isVisbileTrial,
    isVisiblePolicy,
    handleCheckedBoxPolicy,
    email,
    emailError,
    setEmailError,
    changeEmail,
    handleChangeNumberAccount,
    numberStudentAccount,
    isAgreePolicy,
    errorAdviseTrial,
    setErrorAdviseTrial,
    compulError,
    setIsAgreePolicy,
  } = advisementLogic(props);

  // Variable form trial Logic for value and validate
  let {
    provinceList,
    provinceSelected,
    changeProvince,
    errProvince,
    setErrProvince,
    districtList,
    districtSelected,
    errDistrict,
    setErrDistrict,
    changeDistrict,
    schoolList,
    schoolSelected,
    changeSchool,
    errSchool,
    setErrSchool,
    dataPolicyTrial,
    isSuccessTrial,
    setIsSuccessTrial,
  } = formTrialLogic(props);

  //   Render Icon Input Select
  const renderInputIcon = (type) => {
    return (
      <div className={`flex-center`}>
        <img
          src={`/assets/images/homeNews/icon/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  //   Register Trial Teacher
  const handleRegistTrialTeacher = async (e) => {
    e?.preventDefault();

    if (
      nameUser &&
      !nameUserError &&
      email &&
      !emailError &&
      phone &&
      !phoneWarning &&
      provinceSelected?.value &&
      !errProvince &&
      districtSelected?.value &&
      !errDistrict &&
      schoolSelected?.value &&
      !errSchool
    ) {
      if (!isVisiblePolicy) {
        setIsAgreePolicy(false);
      } else {
        try {
          const data = {
            user_name: nameUser,
            phone,
            school: schoolSelected.value,
            source: "trial",
            role: props.type,
            email,
            type_contact: "free_trial",
            source: "trial",
            number_teacher: 1,
            number_student: numberStudentAccount,
          };

          const result = await sendMsgContact(data);
          if (result.status) {
            setIsSuccessTrial(true);
          }
        } catch (e) {
          // console.log(e);
        }
      }
    } else {
      if (!nameUser) {
        setNameUserError("Vui lòng nhập thông tin.");
      } else if (!phone) {
        setPhoneWarning("Vui lòng nhập thông tin.");
      } else if (!email) {
        setEmailError("Vui lòng nhập thông tin.");
      } else if (!provinceSelected?.value) {
        setErrProvince("Vui lòng chọn tỉnh / thành phố.");
      } else if (!districtSelected?.value) {
        setErrDistrict("Vui lòng chọn quận/ huyện.");
      } else if (!schoolSelected?.value) {
        setErrSchool("Vui lòng chọn thông tin trường.");
      } else if (!isVisbileTrial) {
        setErrorAdviseTrial("Vui lòng chọn thông tin.");
      }
    }
  };

  //   Render Data Policy Trial
  const renderListPolicyTrial = (policy) => {
    return (
      <div key={policy.id} className="policy-item">
        <h4 className="font_news_semi_bold line_height_normal policy-item-title">
          {policy.title}
        </h4>

        <div className="policy-item-content_list">
          {policy.content_list.map((content) => (
            <div
              className="policy-item-content line_height_normal"
              key={content.id}
            >
              {content?.content}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render Success Modal
  const SuccessTrialInfo = () => {
    return (
      <div className="flex-center-column info-trial-success pos_rel">
        <img
          src="/assets/images/icon/ico_tick_success.png"
          alt="Icon Successful"
          className="contain_image icon_sucess_msg"
        />
        <div className="font_news_bold text-align-center modal_msg fz-15-mobile fz-18-75 mg-bottom-1 pd-bottom-1 mg-top-15 msg-title">
          ĐĂNG KÝ THÀNH CÔNG
        </div>

        <div className="mg-bottom-0-5 col-80 col-100-mobile text-align-center fz-1-mobile fz-13-75 msg-detail">
          Sunday English sẽ liên hệ lại bạn trong thời gian sớm nhất. Trân trọng
          cảm ơn!
        </div>

        <div className="color-red fz-1-mobile fz-12-5 text-align-center msg-contact">
          Hotline hỗ trợ:{" "}
          <a
            href="tel: 02462813888"
            className="white_space_pre color-red text-align-center font_news_bold fz-12-mobile fz-15 font_news_bold"
          >
            024 6281 3888
          </a>
        </div>

        <div
          onClick={() => {
            history.push("/teacher_news");
          }}
          className="form_trial_close"
        >
          <img
            src="/assets/images/icon/ico_cancel_schedule.png"
            alt="Icon Delete"
            className="contain_image"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="modal_not_scroll  modal_form_trial uk-flex-top uk-modal uk-flex uk-open commondPages col-100 flex-align flex-column">
      <div className="pos_rel advisement_form_teacher form_trial pos_rel flex-center-column">
        <div className="col-100 content_advisement_container  pos_rel ">
          {isSuccessTrial ? (
            <SuccessTrialInfo />
          ) : (
            <div className="content_advisement col-100 scrollbar-custom-orange">
              <div className="header_advisement flex-center-column">
                <img
                  src="/assets/images/homeNews/content/teacher/title_advisement_teacher_trial.png"
                  alt="Titlte Advisement"
                  className="contain_image title_header_teacher text-align-center display_none_mobile"
                />

                <div className="flex-center-column hide display_flex_mobile">
                  <img
                    src="/assets/images/homeNews/content/title_header_1_mobile.png"
                    alt="Titlte Advisement"
                    className="contain_image title_header_teacher_1_mobile text-align-center"
                  />
                  <img
                    src="/assets/images/homeNews/content/teacher/title_header_trial_mobile.png"
                    alt="Titlte Advisement"
                    className="contain_image title_header_teacher_2_mobile text-align-center"
                  />
                </div>
              </div>

              <form
                className="form_input_advisement"
                noValidate={true}
                onSubmit={handleRegistTrialTeacher}
              >
                <div className="flex-jus-start-between form_input_wrapper">
                  <div className="input_container_left">
                    <InputText
                      value={nameUser}
                      setValue={changeNameUser}
                      type="text"
                      name="name"
                      placeholder={"Họ và tên thầy cô"}
                      required={true}
                      // renderLabelIcon={() => renderAuthIcon("name")}
                      errorText={nameUserError}
                      setErrorText={setNameUserError}
                      onFocus={() => setNameUser(nameUser?.trim())}
                      onBlur={() => {
                        onBlurField("name");
                      }}
                      errorAbsolute={true}
                      typeErrText={"underAbsolute"}
                    />
                    <InputText
                      value={phone}
                      setValue={changePhone}
                      type="text"
                      name="phone"
                      placeholder="Số điện thoại"
                      required={true}
                      // renderLabelIcon={() => renderAuthIcon("phone")}
                      errorText={phoneWarning}
                      setErrorText={setPhoneWarning}
                      onBlur={() => {
                        onBlurField("phone");
                      }}
                      errorAbsolute={true}
                      typeErrText={"underAbsolute"}
                    />
                    <InputText
                      value={email}
                      setValue={changeEmail}
                      type="text"
                      name="email"
                      placeholder="Email"
                      required={true}
                      // renderLabelIcon={() => renderAuthIcon("phone")}
                      errorText={emailError}
                      setErrorText={setEmailError}
                      onBlur={() => {
                        onBlurField("email");
                      }}
                      errorAbsolute={true}
                      typeErrText={"underAbsolute"}
                    />

                    {/* <InputSelectNews
                    value={timeAdvisement}
                    setValue={changeTimeAdvisement}
                    type="text"
                    name="timeAdvisement"
                    placeholder="Thời gian nhận tư vấn"
                    required={true}
                    errorText={timeAdvisementError}
                    setErrorText={setTimeAdvisementError}
                    options={dataTimeAdvisement.map((item) => {
                      return {
                        value: item.value,
                        title: item.title,
                      };
                    })}
                    errorAbsolute={true}
                    handleCheckedBox={handleCheckedBoxTime}
                    isChecked={isVisibleTime}
                    onBlur={() => {
                      onBlurField("timeAdvise");
                    }}
                    typeSearch="checkbox"
                    setCompulWarning={setCompulError}
                    setIsVisible={setIsVisibleTime}
                    typeErrText={"underAbsolute"}
                  /> */}
                  </div>

                  <div className="input_container_right pos_rel">
                    <div className="text-address font_news_semi_bold">
                      Nơi công tác
                    </div>
                    <InputSelect
                      colorInput="color-text"
                      fillColor="#4D4D4D"
                      className="field_item field_province "
                      value={provinceSelected}
                      setValue={changeProvince}
                      options={provinceList.map((item) => {
                        return {
                          value: item.province_alias,
                          title: item.province,
                        };
                      })}
                      placeholder="Chọn Tỉnh/ Thành phố"
                      renderLabelIcon={() => renderInputIcon("location")}
                      errorText={errProvince}
                      setErrorText={setErrProvince}
                      mgBottom={"1.5rem"}
                      typeErrText={"underAbsolute"}
                      errorAbsolute
                    ></InputSelect>
                    <InputSelect
                      colorInput="color-text"
                      fillColor="#4D4D4D"
                      className="field_item field_district"
                      value={districtSelected}
                      setValue={changeDistrict}
                      options={districtList.map((item) => {
                        return {
                          value: item.district_alias,
                          title: item.district,
                        };
                      })}
                      placeholder="Quận/ Huyện"
                      renderLabelIcon={() => renderInputIcon("location")}
                      disabledClick={!provinceSelected.value}
                      onClickDisable={() => {
                        setErrProvince("Vui lòng chọn tỉnh / thành phố.");
                      }}
                      errorText={errDistrict}
                      setErrorText={setErrDistrict}
                      mgBottom={"1.5rem"}
                      typeErrText={"underAbsolute"}
                      errorAbsolute
                    ></InputSelect>
                    <InputSelect
                      colorInput="color-text"
                      fillColor="#4D4D4D"
                      className="field_item field_school"
                      value={schoolSelected}
                      setValue={changeSchool}
                      options={schoolList.map((item) => {
                        return {
                          value: item.school_name,
                          title: item.school_name,
                          id: item.id,
                        };
                      })}
                      placeholder="Trường"
                      renderLabelIcon={() => renderInputIcon("school")}
                      disabledClick={!districtSelected.value}
                      onClickDisable={() => {
                        setErrDistrict("Chọn quận/ huyện.");
                        if (!provinceSelected.value) {
                          setErrProvince("Vui lòng chọn tỉnh/ thành phố.");
                        }
                      }}
                      errorText={errSchool}
                      setErrorText={setErrSchool}
                      typeErrText={"underAbsolute"}
                      errorAbsolute
                    ></InputSelect>
                  </div>
                </div>

                <div className="pos_rel input_trial_container">
                  <InputText
                    typeSearch="checkbox"
                    // value={phone}
                    // setValue={changePhone}
                    type="text"
                    name="asistant"
                    placeholder="Trải nghiệm sản phẩm"
                    required={true}
                    errorText={errorAdviseTrial}
                    setErrorText={setErrorAdviseTrial}
                    // renderLabelIcon={() => renderAuthIcon("phone")}
                    errorAbsolute={true}
                    handleCheckedBox={() => handleCheckedBoxTrial()}
                    isChecked={true}
                    readOnly={true}
                    compulWarning={compulError}
                    typeErrText={"underAbsolute"}
                  />

                  <div className="flex-jus-between number_account_assistant">
                    <div className="flex-center-column">
                      <div className="title_account_assistant">
                        Tài khoản giáo viên
                      </div>
                      <div className="box_number_account text-align-center font_news_bold">
                        01
                      </div>
                    </div>
                    <div className="flex-center-column">
                      <div className="title_account_assistant">
                        Tài khoản học sinh
                      </div>
                      <div className="flex-center box_number_account text-align-center font_news_bold">
                        <div
                          className="pointer_cursor"
                          onClick={() => handleChangeNumberAccount("increase")}
                          style={{
                            width: "1.3rem",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="13"
                            viewBox="0 0 16 12"
                            fill="none"
                          >
                            <path
                              d="M8.125 6.88454e-07L16 12L0.25 12L8.125 6.88454e-07Z"
                              fill="#4D4D4D"
                            />
                          </svg>
                        </div>
                        <div className="disabled_user_select number_account_text font_news_bold  text-align-center">
                          {numberStudentAccount}
                        </div>
                        <div
                          className="pointer_cursor"
                          onClick={() => handleChangeNumberAccount("decrease")}
                          style={{
                            width: "1.3rem",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="13"
                            viewBox="0 0 16 12"
                            fill="none"
                          >
                            <path
                              d="M7.875 12L0 0H15.75L7.875 12Z"
                              fill="#4D4D4D"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Policy Trial */}

                <div className="policy-trial-container">
                  <h3 className="font_news_bold policy-trial-title">
                    {dataPolicyTrial?.title}
                  </h3>

                  <div className="policy-trial-content">
                    {dataPolicyTrial?.data?.map((policy) =>
                      renderListPolicyTrial(policy)
                    )}
                  </div>
                </div>

                <div className="pos_rel">
                  <div className="flex-align text_policy_assistant_account">
                    <div
                      className="disabled_user_select flex-center pointer_cursor ico_checkbox_policy"
                      onClick={handleCheckedBoxPolicy}
                    >
                      <img
                        src={`/assets/images/icon/${
                          isVisiblePolicy ? "ico_checkedbox" : "ico_checkbox"
                        }.png`}
                        alt="Ico Checkbox"
                        className="contain_image"
                      />
                    </div>
                    <span>
                      Tôi đã đọc và đồng ý với Chính sách Trải nghiệm sản phẩm
                      được nêu trên
                    </span>
                  </div>

                  {!isAgreePolicy && (
                    <div
                      className="fz-08-75 fz-07-5-mobile mg-top-0-5"
                      style={{
                        color: "#e22028",
                      }}
                    >
                      Vui lòng tích chọn đồng ý với Chính sách Trải nghiệm sản
                      phẩm.
                    </div>
                  )}
                </div>

                <div className="flex-center btn_container">
                  <ButtonNews
                    width="18rem"
                    height="3.65rem"
                    onClick={(e) => handleRegistTrialTeacher(e)}
                    border={"1px solid #fff"}
                    backgroundColor={"#EB5757"}
                    boxShadow={"1px 1px 5px white, 0 0 1px white"}
                    maxWidth={"19.5vw"}
                    maxHeight={"5.5vw"}
                  >
                    <div className="text_btn fz-1-mobile btn_text font_news_bold color-white">
                      ĐĂNG KÝ
                    </div>
                  </ButtonNews>
                </div>
              </form>
              <div
                onClick={() => {
                  history.push("/teacher_news");
                }}
                className="form_trial_close"
              >
                <img
                  src="/assets/images/icon/ico_cancel_schedule.png"
                  alt="Icon Delete"
                  className="contain_image"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormTrial;
