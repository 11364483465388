import "./AdvisementForm.style.scss";
import { advisementLogic } from "./AdvisementForm.logic";
import InputText from "../Auth/InputText";
import ButtonNews from "../Button/ButtonNews";
import InputSelectNews from "../Input/InputSelect";
import { userConstants } from "../../_constants";
import ModalSuccessMsg from "../Modal/ModalSuccessMsg/ModalSuccessMsg";
import LazyLoad from "react-lazyload";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";

const AdvisementFormTeacher = (props) => {
  let {
    dataTimeAdvisement,
    timeAdvisement,
    gradeSelected,
    dataGrades,
    changeGradeSelected,
    changeTimeAdvisement,
    nameUser,
    setNameUser,
    changeNameUser,
    nameUserError,
    setNameUserError,
    daisuCode,
    setDaisuCode,
    changeDaisu,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    onBlurField,
    addressTeach,
    setAddressTeach,
    changeAddressTeach,
    handleRegistAdvisement,
    timeAdvisementError,
    setTimeAdvisementError,
    addressTeachError,
    setAddressTeachError,
    gradeError,
    setGradeError,
    isSuccessRegist,
    handleCloseModal,
    handleCheckedBoxTime,
    isVisibleTime,
    handleCheckedBoxTrial,
    isVisbileTrial,
    isVisiblePolicy,
    handleCheckedBoxPolicy,
    handleRegistAdvisementTeacher,
    email,
    emailError,
    setEmailError,
    changeEmail,
    handleChangeNumberAccount,
    numberStudentAccount,
    isAgreePolicy,
    errorAdviseTrial,
    setErrorAdviseTrial,
    compulError,
    setCompulError,
    setIsVisibleTime,
  } = advisementLogic(props);

  return (
    <div className=" advisement_form_teacher pos_rel flex-center-column">
      {/* Img Banner */}

      <div className="col-100 content_advisement_container">
        <div className="content_advisement">
          <div className="header_advisement flex-center-column">
            <img
              src="/assets/images/homeNews/content/teacher/title_advisement_teacher.png"
              alt="Titlte Advisement"
              className="contain_image title_header_teacher text-align-center display_none_mobile"
            />

            <div className="flex-center-column hide display_flex_mobile">
              <img
                src="/assets/images/homeNews/content/teacher/title_advisement_teacher_1_mobile.png"
                alt="Titlte Advisement"
                className="contain_image title_header_teacher_1_mobile text-align-center"
              />
              <img
                src="/assets/images/homeNews/content/teacher/title_advisement_teacher_2_mobile.png"
                alt="Titlte Advisement"
                className="contain_image title_header_teacher_2_mobile text-align-center"
              />
            </div>

            <div className="detail_header text-align-center">
              {props.type == userConstants.ROLE_TEACHER
                ? "Thầy cô hãy để lại thông tin chính xác để được hỗ trợ sớm nhất có thể nhé!"
                : "Ba mẹ hãy để lại thông tin chính xác để được hỗ trợ sớm nhất có thể nhé!"}
            </div>
          </div>
          <form
            className="form_input_advisement"
            noValidate={true}
            onSubmit={handleRegistAdvisementTeacher}
          >
            <div className="flex-jus-start-between form_input_wrapper">
              <div className="input_container_left">
                <InputText
                  value={nameUser}
                  setValue={changeNameUser}
                  type="text"
                  name="name"
                  placeholder={props.placeholder}
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("name")}
                  errorText={nameUserError}
                  setErrorText={setNameUserError}
                  onFocus={() => setNameUser(nameUser?.trim())}
                  onBlur={() => {
                    onBlurField("name");
                  }}
                  errorAbsolute={true}
                />
                <InputText
                  value={phone}
                  setValue={changePhone}
                  type="text"
                  name="phone"
                  placeholder="Số điện thoại"
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("phone")}
                  errorText={phoneWarning}
                  setErrorText={setPhoneWarning}
                  onBlur={() => {
                    onBlurField("phone");
                  }}
                  errorAbsolute={true}
                />
                <InputText
                  value={email}
                  setValue={changeEmail}
                  type="text"
                  name="email"
                  placeholder="Email"
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("phone")}
                  errorText={emailError}
                  setErrorText={setEmailError}
                  onBlur={() => {
                    onBlurField("email");
                  }}
                  errorAbsolute={true}
                />

                {/* <InputSelectNews
                  value={timeAdvisement}
                  setValue={changeTimeAdvisement}
                  type="text"
                  name="phone"
                  placeholder="Thời gian nhận tư vấn"
                  required={true}
                  errorText={timeAdvisementError}
                  setErrorText={setTimeAdvisementError}
                  options={dataTimeAdvisement.map((item) => {
                    return {
                      value: item.value,
                      title: item.title,
                    };
                  })}
                  errorAbsolute={true}
                /> */}

                <div className="display_none_mobile font_style_italic color-black text_note_assistant">
                  * Chỉ áp dụng cho Giáo viên. Miễn phí trải nghiệm trong thời
                  gian 1 tháng.
                </div>
              </div>

              <div className="input_container_right">
                <InputText
                  value={addressTeach}
                  setValue={changeAddressTeach}
                  type="text"
                  name="address"
                  placeholder="Nơi công tác"
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("phone")}
                  errorText={addressTeachError}
                  setErrorText={setAddressTeachError}
                  onFocus={() => setAddressTeach(addressTeach?.trim())}
                  onBlur={() => {
                    onBlurField("address");
                  }}
                  errorAbsolute={true}
                />
                <InputText
                  value={daisuCode}
                  setValue={changeDaisu}
                  type="text"
                  name="daisu"
                  placeholder="Mã đại sứ"
                  required={true}
                />
                <InputText
                  typeSearch="checkbox"
                  // value={phone}
                  // setValue={changePhone}
                  type="text"
                  name="asistant"
                  placeholder="Trải nghiệm sản phẩm"
                  required={true}
                  errorText={errorAdviseTrial}
                  setErrorText={setErrorAdviseTrial}
                  // renderLabelIcon={() => renderAuthIcon("phone")}
                  errorAbsolute={true}
                  handleCheckedBox={() => handleCheckedBoxTrial()}
                  isChecked={isVisbileTrial}
                  readOnly={true}
                  compulWarning={compulError}
                />

                <div className="hide display_block_mobile font_style_italic color-black text_note_assistant">
                  * Chỉ áp dụng cho Giáo viên. Miễn phí trải nghiệm trong thời
                  gian 1 tháng.
                </div>
                {isVisbileTrial && (
                  <div className="flex-jus-between number_account_assistant">
                    <div className="flex-center-column">
                      <div className="title_account_assistant">
                        Tài khoản giáo viên
                      </div>
                      <div className="box_number_account text-align-center font_news_bold">
                        01
                      </div>
                    </div>
                    <div className="flex-center-column">
                      <div className="title_account_assistant">
                        Tài khoản học sinh
                      </div>
                      <div className="flex-center box_number_account text-align-center font_news_bold">
                        <div
                          className="pointer_cursor"
                          onClick={() => handleChangeNumberAccount("increase")}
                          style={{
                            width: "1.3rem",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="13"
                            viewBox="0 0 16 12"
                            fill="none"
                          >
                            <path
                              d="M8.125 6.88454e-07L16 12L0.25 12L8.125 6.88454e-07Z"
                              fill="#4D4D4D"
                            />
                          </svg>
                        </div>
                        <div className="disabled_user_select number_account_text font_news_bold  text-align-center">
                          {numberStudentAccount}
                        </div>
                        <div
                          className="pointer_cursor"
                          onClick={() => handleChangeNumberAccount("decrease")}
                          style={{
                            width: "1.3rem",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="13"
                            viewBox="0 0 16 12"
                            fill="none"
                          >
                            <path
                              d="M7.875 12L0 0H15.75L7.875 12Z"
                              fill="#4D4D4D"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isVisbileTrial && (
              <div className="flex-center text_policy_assistant_account">
                <div
                  className="disabled_user_select flex-center pointer_cursor ico_checkbox_policy"
                  onClick={handleCheckedBoxPolicy}
                >
                  <img
                    src={`/assets/images/icon/${
                      isVisiblePolicy ? "ico_checkedbox" : "ico_checkbox"
                    }.png`}
                    alt="Ico Checkbox"
                    className="contain_image"
                  />
                </div>
                <span>
                  Tôi đồng ý với{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `/${TypeHeaderNewsItem.POLICY}/#rule1`,
                        "_blank"
                      )
                    }
                    style={{
                      lineHeight: "normal",
                    }}
                    className="text_underline_offset pointer_cursor"
                  >
                    điều khoản, chính sách và dịch vụ
                  </span>{" "}
                  của Sunday Teacher
                </span>
              </div>
            )}

            {isVisbileTrial && !isAgreePolicy && (
              <div
                className="fz-1 fz-08-mobile text-align-center mg-top-0-5"
                style={{
                  color: "#e22028",
                }}
              >
                Vui lòng tích chọn đồng ý điều khoản chính sách và dịch vụ
              </div>
            )}

            <div className="flex-center btn_container">
              <ButtonNews
                width="18rem"
                height="4rem"
                onClick={(e) => handleRegistAdvisementTeacher(e)}
                border={"1px solid #fff"}
                backgroundColor={"#EB5757"}
                boxShadow={"1px 1px 5px white, 0 0 1px white"}
                maxWidth={"19.5vw"}
                maxHeight={"5.5vw"}
              >
                <div className="text_btn fz-1-mobile btn_text font_news_bold color-white">
                  ĐĂNG KÝ
                </div>
              </ButtonNews>
            </div>
          </form>
        </div>

        {isSuccessRegist && (
          <div className="pos_abs modal_success_abs_container">
            <div className=" modal_success_abs">
              <ModalSuccessMsg
                isVisible={isSuccessRegist}
                onClickClose={handleCloseModal}
                titleMsg="ĐĂNG KÝ THÀNH CÔNG!"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvisementFormTeacher;
