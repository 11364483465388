const CheckBoxSquare = (props) => {
  const { label, status, onChange } = props;

  const renderBox = () => {
    switch (status) {
      case true:
        return (
          <img
            src="/assets/images/luyenthi/ico-checkboxanh.png"
            alt="Img CheckedBox"
            className="img_checkBox"
          />
        );
      case false:
        return (
          <img
            src="/assets/images/luyenthi/ico-uncheckedBox.png"
            alt="Img UnCheckedBox"
            className="img_checkBox"
          />
        );
      default:
        return (
          <img
            src="/assets/images/luyenthi/ico-uncheckedBox.png"
            alt="Img UnCheckedBox"
            className="img_checkBox"
          />
        );
    }
  };

  return (
    <div className="item-checkbox" onClick={onChange}>
      {renderBox()}
      <label
        style={{ cursor: "pointer" }}
        dangerouslySetInnerHTML={{ __html: label }}
      ></label>
    </div>
  );
};

export default CheckBoxSquare;
