import "./GradeSelection.style.scss";

const GradeSelection = (props) => {
  let { dataGrades, gradeSelected, handleClickGrade, type } = props;

  const renderNumberGrade = (item) => {
    return (
      <div className={`item_grade_container `} key={item.id}>
        <div
          onClick={() => handleClickGrade(item)}
          className={`pointer_cursor flex-center item_grade  ${
            type == "teacher"
              ? "font_news item_grade_teacher"
              : " font_news_bold"
          }`}
          style={{
            backgroundColor:
              (gradeSelected?.value || gradeSelected) == item.value
                ? "#00CC83"
                : "#FFF",
            color:
              (gradeSelected?.value || gradeSelected) == item.value
                ? "#FFF"
                : "#C4C4C4",
            fontSize: type == "teacher" && "1.125rem",
            width: type == "teacher" && "7vw",
          }}
        >
          {item.value}
        </div>
      </div>
    );
  };

  return (
    <div className="flex-align list_grades">
      {dataGrades?.map((item) => renderNumberGrade(item))}
    </div>
  );
};

export default GradeSelection;
