import React, {useState, useEffect} from 'react';
import { isJsonString } from '../../../_helpers/utils';

const ResultAnswerTheQuestion = (props) => {
    const {dataQuestion, answer, dataAns, infoQuestion, skill, question_type} = props;
    const [userChose,setUserChose] = useState([]);

    const unShowHint = (skill == 'reading' && question_type == 11)

    useEffect(() => {
      setUserChose(JSON.parse(answer))
    }, [dataQuestion, answer, dataAns]);

    const _renderResult = (item, index, iQues) => {
        const chose = userChose[iQues];
        const choice = (isJsonString(chose?.user_choice) ? JSON.parse(chose?.user_choice) : chose?.user_choice);
        const user_choice = typeof choice === 'string' ? choice?.replace(/[\n\t\u00a0]+/g, " ")?.trim() : Array.isArray(choice) ? choice?.join(' ') : choice;

        return (
            <>
              
                <div style={{paddingLeft: 8}}>

                    {
                      !!user_choice ? 
                      <p style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 6
                      }}>
                          {item?.hint && !unShowHint ? <p>{item?.hint + ' '}</p> : null}
                          <p style={{color: chose?.score==1 ? '#62B440' : '#C22D39'}}>{user_choice}</p>
                      </p>
                      : null
                    }
                    {
                      !chose?.score &&
                      <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 6
                      }}>
                          <img
                            style={{ height: 24, marginLeft: 16 }}
                            src="/assets/images/ico_hover_answer.png"
                          />
                          <div>
                              {item?.hint && !unShowHint ? <span>{item?.hint + ' '}</span> : null}
                              <span 
                                style={{
                                  color: '#62B440',
                                }}
                              >
                                {item?.match_option_text[0]||item?.option_text}
                              </span>
                          </div>
                      </div>
                    }
                </div>
            </>
        );
    };

    const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

    return (
        <div>
            {paragraph ? <p style={{
                    paddingBottom: 16
                }}>
                    {paragraph?.trim()}
                </p> : null
            }
            <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
              {
                  dataQuestion?.map((ques, iQues) => {
                    const explainQuestion = ques?.list_option[0]?.question_explain?.content_question_text
                                            || ques?.list_option[0]?.option_explain || "";
                    return (
                      <div>
                        <p>{iQues + 1 + '. ' + ques?.list_option?.[0]?.question_content?.trim()}</p>
                        {ques?.list_option.map((item, index) => {
                          return (
                            <div key={index}>
                              {
                                _renderResult(item, index, iQues)
                              }
                            </div>
                          )
                        })}
                        {!!explainQuestion && <div style={{marginTop: 8}}>
                            <p style={{fontWeight: 600}}>Giải thích:</p>
                            <p>{explainQuestion}</p>
                        </div>
                        }
                      </div>
                  )
              })}
            </div>
        </div>
    );
};

export default ResultAnswerTheQuestion;
