import { useSelector } from "react-redux"
import { Header } from "../../_components/Admin/Header"
import { Alert, AlertSuccess } from "../../_components/Alert"
import { PopUpSuccess } from "../../_components/Popup/PopUpSusscess"
import { configConstants, userConstants } from "../../_constants"
import { history } from "../../_helpers"
import { manageLinkingParentLogic } from "./manageLinkingParent.logic"
import './manageLinkingParent.style.scss'
import { linkingParentConstants } from "../../_constants/linkingParent"

export default function ManageLinkingParent(props) {
  const {
    listMyParent,
    typeLinking,
    listInvitationReceive,
    listInvitationSend,
    isLoadingMyParent,
    isLoadingInvitation,
    message,
    setMessage,
    handleRemoveParent,
    handleAcceptParent
  } = manageLinkingParentLogic(props)
  const alert = useSelector(state => state.alert);

  const renderLinkingBox = (type, listData, isLoading) => {
    let title = '';
    let emptyText = ''
    switch (type) {
      case typeLinking.MY_PARENT: {
        title = 'Danh sách phụ huynh',
        emptyText = 'Bạn chưa liên kết với tài khoản phụ huynh nào.'
        break;
      }
      case typeLinking.INVITATION_RECEIVE: {
        title = 'Lời mời liên kết';
        emptyText = 'Bạn chưa có lời mời liên kết từ tài khoản phụ huynh nào.'
        break;
      }
      case typeLinking.INVITATION_SEND: {
        title = 'Liên kết đã gửi'
        emptyText = 'Bạn chưa gửi lời mời liên kết tới tài khoản phụ huynh nào.'
      }
      default: break;
    }
    return (
      <>
        {(!isLoading) ? <div className="linking-box">
          <div className="linking-box-content">
            <p className="linking-box-title">{title}</p>
            <ul className="linking-list-parent">
              {listData?.map(item => (
                <li className="linking-item-parent" key={item?.id}>
                  <div className="linking-parent-info">
                    <div className="linking-parent-image-box">
                      <img className="linking-parent-image" src={item?.avatar ? configConstants.BASE_URL + item.avatar : '/assets/images/hoso/default_avt.png'} alt={item?.fullname}/>
                    </div>
                    <div className="linking-parent-info-text">
                      <p className="linking-parent-name">{`Phụ huynh ${item?.fullname}`}</p>
                      <p className="linking-parent-email">{item?.email}</p>
                    </div>
                  </div>
                  {type === typeLinking.MY_PARENT && (
                    <div className="linking-parent-action-container">
                      <span className="linking-parent-action-btn" onClick={() => handleRemoveParent(item?.id, 1)}>
                        <img className="linking-parent-action-img" src="/assets/images/icon/ico_trash.png" alt="delete-btn"/>
                      </span>
                    </div>
                  )}
                  {type === typeLinking.INVITATION_RECEIVE && (
                    <div className="linking-parent-action-container">
                      <span className="linking-parent-action-btn" onClick={() => handleAcceptParent(item?.id)}>
                        <img className="linking-parent-action-img" src="/assets/images/icon/ico_tick_green.png" alt="delete-btn"/>
                      </span>
                      <span className="linking-parent-action-btn" onClick={() => handleRemoveParent(item?.id, 2)}>
                        <img className="linking-parent-action-img" src="/assets/images/icon/ico_close_red.png" alt="delete-btn"/>
                      </span>
                    </div>
                  )}
                  {type === typeLinking.INVITATION_SEND && (
                    <div className="linking-parent-action-container">
                      <span className="linking-parent-action-btn" onClick={() => handleRemoveParent(item?.id, 0)}>
                        <img className="linking-parent-action-img" src="/assets/images/icon/ico_close_red.png" alt="delete-btn"/>
                      </span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            {type !== typeLinking.INVITATION_SEND && !!listData?.length && <div className="linking-parent-divider" />}
          </div>
          {!listData?.length && <p className="linking-parent-empty-text">{emptyText}</p>}
        </div> : null}
      </>
    )
  }
  
  return (
    <div className="sunE-container flex">
      <div className="sunE-right-container flex-column">
        <Header
          title={"Liên kết"}
          isBack
          clickBack={() =>
            history.push("/" + userConstants.ROLE_STUDENT + "/more/profile")
          }
        />
        <div className='sunE-content sunE-class-content'>
          <div className="linking-container scrollbar-custom">
           {renderLinkingBox(typeLinking.MY_PARENT, listMyParent, isLoadingMyParent)}
           {renderLinkingBox(typeLinking.INVITATION_RECEIVE, listInvitationReceive, isLoadingInvitation)}
           {renderLinkingBox(typeLinking.INVITATION_SEND, listInvitationSend, isLoadingInvitation)}
          </div>
        </div>
        <div className="add-linking-btn-container">
          <button className={"btn-line-blue btn-p35"} onClick={() => history.push("/" + userConstants.ROLE_STUDENT + "/more/link-account")}>
            Tạo liên kết
          </button>
        </div>
        <PopUpSuccess visible={!!message} message={message} onClick={() => setMessage('')}/>
        {alert.message && alert.screen === linkingParentConstants.SCREEN_MANAGE_LINKING && <Alert alert={alert} />}

      </div>
    </div>
  )
}