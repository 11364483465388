import React, { useState, useEffect } from "react";
import { Header } from "../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { useSelector } from "react-redux";

function ContactPage() {
  const authentication = useSelector((state) => state.authentication);
  const cachedDeviceId = localStorage.getItem("device_id_" + authentication.id);
  const [dataContact, setDataContact] = useState([]);

  useEffect(() => {
    teacherService.getSettingDetail(cachedDeviceId).then((data) => {
      setDataContact(data.data_contact);
    });
  }, []);
  // console.log(dataContact);

  return (
    <div className="sunE-right-container">
      <Header title="Liên hệ" isBack />
      <div className="sunE-container-box setting support flex-1">
        <div className="box-shadow box-gr-sp">
          <div className="flex-m box-gr-sp-i">
            <div className="mi">
              <img
                loading="lazy"
                src="/assets/images/setting/ico_group.png"
                className="ico_err"
                alt="ico_group"
              />
            </div>
            <div className="flex-1">
              <p>{dataContact?.company}</p>
            </div>
          </div>
          <div className="flex-m box-gr-sp-i">
            <div className="mi">
              <img
                loading="lazy"
                src="/assets/images/setting/ico_phone.png"
                className="ico_err"
                alt="ico_phone"
              />
            </div>
            <div className="flex-1">
              <p>{dataContact?.company_phone}</p>
            </div>
          </div>
          <div className="flex-m box-gr-sp-i">
            <div className="mi">
              <img
                loading="lazy"
                src="/assets/images/setting/ico_email.png"
                className="ico_err"
                alt="ico_email"
              />
            </div>
            <div className="flex-1">
              <p>{dataContact?.company_mail}</p>
            </div>
          </div>
          <div className="flex-m box-gr-sp-i">
            <div className="mi">
              <img
                loading="lazy"
                src="/assets/images/setting/ico_map.png"
                className="ico_err"
                alt="ico_map"
              />
            </div>
            <div className="flex-1">
              <p>{dataContact?.company_location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ContactPage };
