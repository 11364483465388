import React, { useEffect, useState,useCallback } from "react";
import { studentService } from "../../_services";
import { useSelector } from "react-redux";
import { Header } from "../../_components/Admin/Header";
import _ from "lodash";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { check } from "prettier";
import styled from "styled-components";
import { CricleProcess } from "../../_components/Process";

const WrapArrow = styled.div`
  background: linear-gradient(to right, #00e1a0, #00b9b7);
  border-radius: 50%;
  width: 20px;
  height: 20px;

  &:focus,
  &:hover {
    background: linear-gradient(to right, #00e1a0, #00b9b7);
  }
  &.slick-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const WrapItem = styled.div`
  width: 100%;
  padding-right: 20px;
`;

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return <WrapArrow onClick={onClick} className={className} />;
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return <WrapArrow onClick={onClick} className={className} />;
}

function HomePageVocabulary() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currId, setCurrId] = useState(0);
  const authentication = useSelector((state) => state.authentication);
  const [curriculum, setCurriculum] = useState({
    base_url: "",
    data: {list_unit: []},
  });
  const [listClass, setListClass] = useState([]);
  const [listVocab, setListVocab] = useState({
    data: {list_unit: []}
  });
  // const listVocab = curriculum ? curriculum.data.list_unit : "";
  let [dataFilterDefault, setdataFilterDefault] = useState("");
  // let [dataFilter, setdataFilter] = useState(listVocab);
  // let getCurrId = dataFilterDefault ? dataFilterDefault.data.curriculum_id : "";
  const [getClassId, setGetClassId] = useState(0);
  
  useEffect(async() => {
      await studentService.getMyClassesV2().then(async(data) => {
      setListClass(data.data)
      setGetClassId(data.data[0].id)
      await studentService.getVocabularyByClass(data.data[0].id).then((data_vocab) => {
         setCurriculum(data_vocab);
         setListVocab(data_vocab)
         setCurrId(data_vocab.data.curriculum_id)
      });
    });
  }, []);

  useEffect(() => {
    setdataFilterDefault(listVocab);
  }, [listVocab]);

  // useEffect(() => {
  //   setdataFilterDefault(dataFilter);
  // }, [currId]);

  const fetchVocabularyByClass = useCallback(async(class_id) => {
       await studentService.getVocabularyByClass(class_id).then((data_vocab) => {
        setCurriculum(data_vocab);
        setListVocab(data_vocab)
     });
  },[currId])


  function handleClickCurriculum(e, index, class_id) {
    setGetClassId(class_id)
    e.preventDefault();
    setActiveIndex(index);
    setCurrId(class_id);
    fetchVocabularyByClass(class_id)
    // setdataFilter([
    //   ..._.filter(listVocab, (data) => data.curriculum_id === curriculumId),
    // ]);
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2.5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="sunE-right-container">
      <Header title="Từ Vựng" isBack />
      <div className="flex-1 sunE-content tuvung-container">
        <div className="sunE-container-box tuvung">
          <div className="tuvung-slider-custom">
            <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
              <Slider {...settings}>
                {listClass &&
                  listClass.map((data, i) => {
                    let className = "";
                    if (activeIndex === i) {
                      className = "active";
                    }
                    return (
                      <WrapItem key={i}>
                        <div
                          className={
                            "tv-giaotrinh-item center-flex box-shadow " +
                            className
                          }
                          key={i}
                          style={{height: 'auto'}}
                          onClick={(e) =>
                            handleClickCurriculum(e, i, data.id)              
                          }
                        >
                          <div className="flex-m" style={{padding: 10, width: '100%'}}>
                            <div className="img" style={{flex: 1}}>
                              <img
                                style={{objectFit: 'cover'}}
                                src={curriculum.base_url + data.class_avatar}
                                alt=""
                              />
                            </div>
                            <div className="center-flex" style={{marginLeft: 10, flexDirection: "column", flex: 1, alignItems: 'flex-start'}}>
                              <div className="two-line" style={{color: '#00BBB6', fontWeight: 'bold'}}>{data.class_name}</div>
                              <div className="two-line">{data.curriculum_name}</div>
                            </div>
                            {/* <div className="flex-1 center-flex">
                              <p className="one-line">{data.curriculum_name}</p>
                            </div> */}
                          </div>
                        </div>
                      </WrapItem>
                    );
                  })}
              </Slider>
            </ul>
          </div>

          {/* OLD DESIGN */}
          {/* <div className="ov-auto scrollbar-custom">
            <div
              className="tv-giaotrinh-list flex-m center-flex"
              style={{ width: 1048 }}
            >
              {curriculum &&
                curriculum.list_unit.map((data, i) => {
                  let className = "";
                  if (activeIndex === i) {
                    className = "active";
                  }
                  return (
                    <div
                      className={
                        "tv-giaotrinh-item center-flex box-shadow " + className
                      }
                      key={i}
                      onClick={(e) =>
                        handleClickCurriculum(e, i, data.curriculum_id)
                      }
                    >
                      <div className=" flex-m">
                        <div className="img">
                          <img
                            src={curriculum.base_url + data.curriculum_avatar}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 center-flex">
                          <p>{data.curriculum_name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div> */}

          <div className="sunE-giaotrinh-list scrollbar-custom pad-center-large">
            {dataFilterDefault &&
              dataFilterDefault.data.list_unit.map((data, i) => {
                return (
                  <Link
                    // to={
                    //   "/" +
                    //   authentication.role +
                    //   "/assessment/curriculum/" +
                    //   currId +
                    //   "/unit/" +
                    //   data.unit_id?class_id=getClassId
                    to={
                      `/${authentication.role}/assessment/curriculum/${currId}/unit/${data.unit_id}?class_id=${getClassId}`
                    }
                    className={data.is_block && "disable-link"}
                    key={i}
                  >
                    <div className="box-giaotrinh-gr rel box-shadow">
                      <div className="thumb">
                        <div className="percent __custom_percent center-flex rel">
                          {!data.is_block && (
                            CricleProcess(
                              data.process, 'large'
                            )
                          )}
                          {data.is_block && (
                            <img
                              src="../../../assets/images/process/ico_pro_lock.png"
                              alt="process"
                            />
                          )}
                          {/* <span>{data.process}</span> */}
                        </div>
                      </div>
                      <div className="box-tv-info flex-1">
                        <h2>{i+1}. {data.unit_name}</h2>
                        <p className="text-right">
                          Số từ học được:{" "}
                          <span className="bold">
                            {data.number_word_completed}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomePageVocabulary };
