import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CalendaSchedule } from "../../_components/Calendar";
import { teacherActions, scheduleActions } from "../../_actions";
import { SliderClass, EmptyClassSlider } from "../../_components/Admin/Teacher";
import {
  EmptyScheduleToday,
  ScheduleToday,
} from "../../_components/Admin/More/Schedule";
import { isEmpty } from "lodash";
import { parse } from "query-string";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { scheduleConstants } from "../../_constants";

function HomePageTeacher() {
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useSelector((state) => state.classes);
  const schedules = useSelector((state) => state.schedules);
  const authentication = useSelector((state) => state.authentication);
  const [isLoadingClasses, setLoadingClasses] = useState(false);
  const [isLoadingSchedules, setLoadingSchedules] = useState(false);
  // useEffect(() => {
  //   let isLoading = !$(".loading").hasClass("hide");
  //   setLoadingClasses(isLoading);
  // }, [classes]);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoadingSchedules(isLoading);
  }, [schedules]);

  const pageParams = useMemo(() => {
    return history?.location?.search ? parse(history.location.search) : null;
  }, [history.location.search]);

  const [selectedDate, setSelectedDate] = useState(
    !!schedules.dateSelectedCalendar
      ? schedules.dateSelectedCalendar
      : new Date()
  );
  useEffect(() => {
    setLoadingSchedules(true);
    setLoadingClasses(true);
    dispatch(teacherActions.getListClass(1, false, false));

    if (!!schedules.dateSelectedCalendar) {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.dateSelectedCalendar
        )
      );
    } else if (pageParams?.date) {
      dispatch(
        scheduleActions.getScheduleToday(authentication.id, pageParams?.date)
      );
    } else {
      dispatch(scheduleActions.getScheduleToday(authentication.id));
    }
  }, []);

  function changeDateCalendaSchedule(date) {
    // console.log(date);
    setSelectedDate(
      date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
    );
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time:
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day,
    });
    dispatch(
      scheduleActions.getScheduleToday(
        authentication.id,
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
      )
    );
  }

  useEffect(() => {
    let isLoading = !$(".loading-side").hasClass("hide");
    setLoadingClasses(isLoading);
  }, [classes]);

  let componentClass = isEmpty(classes.data) ? (
    <EmptyClassSlider />
  ) : (
    <SliderClass role={authentication.role} classes={classes} />
  );
  let componentScheduleToday = isEmpty(schedules.today) ? (
    <EmptyScheduleToday isButtonTKH selectedDate={selectedDate} />
  ) : (
    <ScheduleToday
      page='homepage'
      schedules={schedules.today}
      selectedDate={selectedDate}
    />
  );

  return (
    <div className='sunE-right-container flex-column scrollbar-custom'>
      <div className='sunE-container-box flex-column flex-1 homepage-teacher p_responsive_home_page_teacher'>
        <div className='flex-m'>
          <div className='flex-1 sunE-calendar height_calendar_responsive'>
            <div className='calendar box-shadow'>
              <CalendaSchedule
                changeDateCalendaSchedule={(date) =>
                  changeDateCalendaSchedule(date)
                }
                showAll
                homePageTeacherCalendaSchedule={true}
              />
            </div>
          </div>
          {componentScheduleToday}
        </div>
        <div className='sunE-box-slider flex-1 mb_mt_5px'>
          {!isLoadingClasses ? (
            componentClass
          ) : (
            <div
              style={{ height: "100%" }}
              className='col-100 flex-center flex-1'
            >
              <div className='loader'></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { HomePageTeacher };
