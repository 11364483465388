import "./CoursesPage.style.scss";
import { HeaderNews } from "../../../_components/Header";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../../_constants/headerNews";
import FooterNews from "../../../_components/FooterNews/FooterNews";
import { coursesPageLogic } from "./CoursesPage.logic";
import ButtonNews from "../../../_components/Button/ButtonNews";
import { HashLink } from "react-router-hash-link";
import ScrollFixed from "../../../_components/ScrollFixed/ScrollFixed";
import TitleNews from "../../../_components/Header/HeaderNews/TitleNews";
import { useSelector } from "react-redux";
import {
  renderCourseItem,
  renderListDetailCourseMobile,
} from "../../../_base/DetailListNews";
import { handleCheckEven } from "../../../_base/Validate";
import { hasDomainStore } from "../../../_base/Validate";
import LazyLoad from "react-lazyload";

const CoursesPage = () => {
  const authentication = useSelector((state) => state.authentication);

  let { dataContentCourses } = coursesPageLogic();

  const renderListCoursesMobile = (data) => {
    return (
      <div
        key={data.id}
        id={data.type + "_mb"}
        className="flex-center-column col-100 pos_rel"
        style={{
          background:
            data.id == 1 &&
            `url(
          /assets/images/coursesNews/bg_content_1.png
        ) no-repeat top center /cover`,
          // paddingTop: "50%",
          backgroundAttachment: "scroll",
          backgroundColor: data.id == 2 && "#fffcea",
        }}
      >
        <div className="flex-center col-100 pd-bottom-1">
          <div
            className="col-100 flex-center img_border_courses zIndex1"
            style={{
              background: `url(
            /assets/images/coursesNews/border_${data.id}.png
          ) no-repeat top center /contain`,
            }}
          >
            <div className="font_news_bold color-white fz-1-mobile">
              {data.name}
            </div>
          </div>
        </div>

        <div
          className={`list_boxes_mobile zIndex1 flex-center-column ${
            data.id == 1 ? " list_boxes_1" : " list_boxes_2"
          }`}
        >
          {data.data.map((item) => renderListDetailCourseMobile(item, data.id))}
        </div>

        {data.id == 2 && (
          <div className="img_bg_side hide display_block_mobile">
            <LazyLoad className="pos_abs img_bg_1">
              <img
                src="/assets/images/homeNews/content/icon/ico_half_circle.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="pos_abs img_bg_2">
              <img
                src="/assets/images/homeNews/content/icon/ico_half_circle_yellow.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="pos_abs img_bg_3">
              <img
                src="/assets/images/homeNews/content/parent/ico_left_mobile.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="pos_abs img_bg_4">
              <img
                src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="pos_abs img_bg_5">
              <img
                src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="coursesPage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.COURSES} />
      {/* <TitleNews title={NameTitleNewsItem.COURSES} /> */}
      <div className="content">
        <div
          className="content_1 flex-column pos_rel display_none_mobile pos_rel content_course_list"
          style={{
            background: `url(
              /assets/images/coursesNews/bg_content_1.png
            ) no-repeat top center /cover`,
            backgroundAttachment: "scroll",
          }}
        >
          <div className="flex-jus-start-between">
            {dataContentCourses?.map((data) => renderCourseItem(data))}
          </div>
          <div className="flex-align-end ">
            <LazyLoad once className="pos_abs img_courses_tree_container">
              <img
                src="/assets/images/coursesNews/img_courses_tree.png"
                alt="Tree Courses"
                className="contain_image"
              />
            </LazyLoad>
          </div>
        </div>

        <div className="content_1_mobile pos_rel hide display_flex_mobile flex-center-column">
          {dataContentCourses?.map((data) => renderListCoursesMobile(data))}
        </div>

        <div className="content_2 pos_rel col-100 pos_rel_mobile ">
          <div className="flex-center text-align-center display_none_mobile fz-15 text_uppercase font_news_bold pd-bottom-2">
            Thành quả sau khi tham gia 2 khóa học Sunday English:
          </div>

          <LazyLoad
            once
            className="col-100 img_table_convert_container flex-center pos_rel"
          >
            <img
              src="/assets/images/coursesNews/table_convert.png"
              alt="Img Table"
              className="contain_image display_none_mobile"
            />
            <img
              src="/assets/images/coursesNews/table_convert_mobile.png"
              alt="Img Table"
              className="contain_image hide display_block_mobile "
            />
          </LazyLoad>

          <div className="col-100">
            <LazyLoad className="icon_left_container display_none_mobile">
              <img
                src="/assets/images/homeNews/content/icon/ico_text_left.png"
                alt="Icon Text Left"
                className="contain_image icon_text_left"
              />
            </LazyLoad>
            <LazyLoad className="icon_right_container display_none_mobile">
              <img
                src="/assets/images/homeNews/content/icon/ico_text_right.png"
                alt="Icon Text Right"
                className="contain_image icon_text_right"
              />
            </LazyLoad>
          </div>

          {/* MOBILE */}

          <div className="img_bg_table_side hide display_block_mobile">
            <LazyLoad once className="pos_abs img_bg_table_1">
              <img
                src="/assets/images/homeNews/content/icon/ico_half_circle.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="pos_abs img_bg_table_2">
              <img
                src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="pos_abs img_bg_table_3">
              <img
                src="/assets/images/homeNews/content/parent/ico_left_mobile.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="pos_abs img_bg_table_4">
              <img
                src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                alt="Img Bg"
                className="contain_image"
              />
            </LazyLoad>
          </div>
        </div>

        {!hasDomainStore() && (
          <div className="color-red fz-12-5-mobile flex-center fz-20 text-align-center font_news_bold pd-bottom-3 bg-white">
            <HashLink
              scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
              // to={
              //   authentication.isLogin
              //     ? `/${authentication.role}/curriculum`
              //     : "/register"
              // }
              to={TypeHeaderNewsItem.FEE}
              className="color-red text_underline text_register font_news_bold "
            >
              <ButtonNews
                width="15rem"
                height="3.5rem"
                onClick={() => handleNavigate(TypeHeaderNewsItem.FEE)}
                border={"1px solid #fff"}
                backgroundColor={"#EB5757"}
                boxShadow={"1px 1px 5px white, 0 0 1px black"}
                // maxWidth={"20vw"}
                // maxHeight={"7vw"}
              >
                <div className=" fz-15 btn_text font_news_bold color-white">
                  MUA NGAY
                </div>
              </ButtonNews>
            </HashLink>
          </div>
        )}
      </div>

      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default CoursesPage;
