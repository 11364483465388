import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { teacherActions, studentActions, alertActions } from './../../_actions';
import { useParams } from "react-router-dom";
import { configConstants, popupConstants, teacherConstants } from './../../_constants';
import { userConstants } from '../../_constants';
import moment from 'moment'

import './style.css';
import { apiCaller, history } from '../../_helpers';

function PopUpAddStudent(props) {
    let { width, isHasTurnHomework } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const student = useSelector(state => state.classes.addStudent);
    const authentication = useSelector(state => state.authentication);
    function handleSubmit() {
        if(authentication.role === userConstants.ROLE_TEACHER){
            const data = { class_id: id, username: student.user_data.user_code }
            // dispatch(teacherActions.addStudent({ class_id: id, username: student.user_data.user_code }));
            apiCaller("/api_class/add_student", "POST", data, null, false).then(
                () => {
                  dispatch(
                    alertActions.success({
                      message: `${teacherConstants.ADD_STUDENT_COMPLETE}${isHasTurnHomework ? '.\nBạn muốn giao các bài tập đã từng giao cho học sinh mới không?' : ''}`,
                      screen: teacherConstants.SCREEN_ADD_STUDENT,
                      hasBtnClosePopup: isHasTurnHomework,
                      textComplete: isHasTurnHomework ? 'Đồng ý' : 'Đóng',
                      isAutoClose: !isHasTurnHomework,
                      onClosePopup: () => {
                        history.push(
                            "/" + authentication.role + "/class/view/" + id + "/student"
                          )
                      },
                      onCompletePopup: () => {
                        props?.onSetListIdStudentAdded && props.onSetListIdStudentAdded([student?.user_data?.id]);
                        props?.onSetIsOpenGiveTurnHomework && props.onSetIsOpenGiveTurnHomework(true);
                      }
                    })
                  );
                  dispatch({
                    type: popupConstants.CLEAR_ALL_POPUP,
                  });
                },
                (error) => {
                  dispatch(
                    alertActions.error({
                      message: error.toString(),
                      screen: teacherConstants.SCREEN_ADD_STUDENT,
                    })
                  );
                }
              );
        }else{
            dispatch(studentActions.addParent({username: student.user_data.user_code }));
        }
    }
    function close() {
        dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
        });
    }
    
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                <p className="text-center popup-title bold">{student.avatar}</p>
                <div className="avt-pop">
                    <img src={student?.user_data?.avatar ? configConstants.BASE_URL + student?.user_data?.avatar : '/assets/images/hoso/default_avt.png'} alt={student?.user_data?.fullname} />
                </div>
                <h2 className="text-center popup-title bold title-student-pop cutom-mg">{student?.user_data?.full_name || student?.user_data?.fullname}</h2>
                {/* {props?.isAddClass ? 
                <p style={{padding: '16px', textAlign: 'center'}}>
                    {student?.user_data?.email &&`Email: ${student.user_data.email}`}
                </p>  */}
                <div className='info-container'>
                    {student?.user_data?.email && <div className='info-row-box'>
                        <span className='label-info-left font-weight-bold'>Email:{" "}</span>
                        <span>{student.user_data.email}</span>
                    </div>}
                    {student?.user_data?.phone && <div className='info-row-box'>
                        <span className='label-info-left font-weight-bold'>Số điện thoại:{" "}</span>
                        <span>{student.user_data.phone}</span>
                    </div>}
                    {!props?.isAddClass && student?.user_data?.birthday && <div className='info-row-box'>
                        <span className='label-info-left font-weight-bold'>Ngày sinh:{" "}</span>
                        <span>{moment(new Date(student?.user_data?.birthday)).format('DD/MM/YYYY')}</span>
                    </div>}
                </div>
               
                <div className="flex-m btn-popup-gr ">
                    <button className="btn-default mr-10 width-100-percent" onClick={close}>Huỷ</button>
                    <button className="btn-bg-blue btn-default width-100-percent" onClick={handleSubmit}>{props?.isAddClass ? "Thêm vào lớp" : "Liên kết"}</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpAddStudent };