import { useState, useEffect } from "react";

let timeoutId = null;
function useAudioPlayer() {
  const [duration, setDuration] = useState(0);
  const [curTime, setCurTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState(0);

  let audio = document.getElementById("audio");
  useEffect(() => {
    audio = document.getElementById("audio");
    if (parseInt(curTime) == parseInt(duration)) {
      audio.currentTime = 0;
      setCurTime(0);
    }
    if (!playing) {
      audio.pause();
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    } else {
      audio.play();
      audio.addEventListener("loadeddata", setAudioData);
      audio.addEventListener("timeupdate", setAudioTime);
    }
  }, [playing]);

  useEffect(() => {
    if (parseInt(curTime) == parseInt(duration + 3)) {
      setPlaying(false);
    }
  }, [clickedTime, curTime]);

  // state setters wrappers
  const setAudioData = () => {
    setDuration(audio.duration);
    setCurTime(audio.currentTime);
  };

  const setAudioTime = () => {
    if (parseInt(curTime) == parseInt(duration)) {
      setCurTime(0);
    } else {
      setCurTime(audio.currentTime);
    }
  };

  useEffect(() => {
    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  });

  useEffect(() => {
    if (playing) {
      let time =
        parseInt(duration) + 1 === parseInt(curTime)
          ? duration
          : duration - curTime;
      const audio = document.getElementById("audio");
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        audio.pause();
        setPlaying(false);
      }, time * 1000);
    } else {
      // setCurTime(0)
    }
  }, [playing, curTime]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setCurTime,
  };
}

export default useAudioPlayer;
