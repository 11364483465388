import { useState, useEffect } from "react";

export const skillsAssignLogic = (props) => {
  let { dataSkills, setDataSkills } = props;

  const [dataSkillsSide, setDataSkillsSide] = useState(
    dataSkills.lesson_list_propose
  );

  const dataLevels = ["easy", "medium", "hard"];

  // Handle Checked
  // Handle Checked Box
  const handleChecked = (e, data) => {
    const newDataAssign = dataSkillsSide;
    if (e.target.checked) {
      newDataAssign?.forEach((item) => {
        if (item.skill == data.skill) {
          item.sum = 1;
        }
      });

      setDataSkillsSide(newDataAssign);
      setDataSkills((prev) => ({
        ...prev,
        lesson_list_propose: newDataAssign,
      }));
    } else {
      newDataAssign?.forEach((item) => {
        if (item.skill == data.skill) {
          item.sum = 0;
        }
      });

      setDataSkillsSide(newDataAssign);
      setDataSkills((prev) => ({
        ...prev,
        lesson_list_propose: newDataAssign,
      }));
    }
  };

  // Handle Change Level
  const handleChangeLevel = (data, type) => {
    const newDataAssign = dataSkillsSide;
    switch (type) {
      case "prev":
        newDataAssign?.forEach((item) => {
          if (item.skill == data.skill) {
            const indexLevel = dataLevels.indexOf(data.level);
            if (indexLevel - 1 >= 0) {
              item.level = dataLevels[indexLevel - 1];
            }
          }
        });

        setDataSkills((prev) => ({
          ...prev,
          lesson_list_propose: newDataAssign,
        }));
        setDataSkillsSide(newDataAssign);
        break;
      case "next":
        newDataAssign?.forEach((item) => {
          if (item.skill == data.skill) {
            const indexLevel = dataLevels.indexOf(data.level);
            if (indexLevel + 1 < dataLevels.length) {
              item.level = dataLevels[indexLevel + 1];
            }
          }
        });
        setDataSkills((prev) => ({
          ...prev,
          lesson_list_propose: newDataAssign,
        }));
        setDataSkillsSide(newDataAssign);
        break;
      default:
    }
  };

  // Handle Change Count Exercise
  const handleChangeCountExercise = (data, type) => {
    const newDataAssign = dataSkillsSide;
    switch (type) {
      case "prev":
        newDataAssign?.forEach((item) => {
          if (item.skill == data.skill) {
            item.sum -= 1;
          }
        });

        setDataSkills((prev) => ({
          ...prev,
          lesson_list_propose: newDataAssign,
        }));
        setDataSkillsSide(newDataAssign);
        break;
      case "next":
        newDataAssign?.forEach((item) => {
          if (item.skill == data.skill) {
            if (item.skill == data.skill) {
              item.sum += 1;
            }
          }
        });

        setDataSkills((prev) => ({
          ...prev,
          lesson_list_propose: newDataAssign,
        }));
        setDataSkillsSide(newDataAssign);
        break;
      default:
    }
  };

  return {
    dataSkillsSide,
    handleChangeLevel,
    handleChangeCountExercise,
    handleChecked,
  };
};
