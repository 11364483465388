import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, studentActions } from "../../../_actions";
import { studentConstants } from "./../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";
import { PopUpAddStudent } from "../../../_components/Popup";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

function LinkAccountPage() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const Popup = useSelector((state) => state.Popup);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    user_code: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const { user_code } = inputs;

  function handleChange(e) {
    dispatch(alertActions.clear());
    setSubmitted(false);
    if (error) {
      setError(false);
    }
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }
  function handleSubmit(e) {
    e.preventDefault();
    setError(true);
    setSubmitted(true);
    if (validateParam()) {
      dispatch(studentActions.checkParent(inputs));
    }
  }

  useEffect(() => {
    if (alert?.type === "ALERT_SUCCESS") {
      setInputs((inputs) => ({ ...inputs, class_code: "" }));
    }
  }, [alert?.type]);

  function validateParam() {
    return user_code?.trim() !== "" && !alert.message ? true : false;
  }

  const authentication = useSelector((state) => state.authentication);
  // console.log(alert);
  return (
    <div className="sunE-right-container add_student_container">
      <Header
        title={"Liên kết phụ huynh"}
        isBack
        clickBack={() =>
          history.goBack()
        }
      />
      <div className="sunE-content rel add_student">
        <img
          className="background-add-student"
          src="/assets/images/img-bg-parent.png"
        />
        <div
          className="add_code_student-gr text-center __posistion_custom"
          style={{ bottom: 20, transform: "translate(-50%, 0%)" }}
        >
          <h2 className="sunE-title-medium" style={{display: 'inline-block'}}>
            Vui lòng nhập {" "}
            <span className="bold" style={{fontSize: '22px'}}>
              email hoặc số điện thoại của phụ huynh
            </span>
            {/* <h2 className="sunE-title-medium" style={{padding: 0}}> */}
              {' '}để gửi lời mời liên kết
            {/* </h2> */}
          </h2>
          <div
            className={
              "sunE-input-border-blue-gr rel" +
              ((submitted && !user_code) ||
              (alert.type === "ALERT_ERROR" &&
                alert.message &&
                error &&
                alert.screen === studentConstants.SCREEN_ADD_PARENT)
                ? " err"
                : "")
            }
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "35rem",
              margin: "0 0 10px",
            }}
          >
            <img
              src="/assets/images/ico_user_border.png"
              className="ico_input"
              alt="ico_user_border"
            />
            <input
              type="text"
              name="user_code"
              placeholder="Nhập email hoặc số điện thoại phụ huynh"
              onChange={handleChange}
              className="fz-1 text-align-center"
              onKeyDown={(e) => {
                if(e?.code === "Enter" || e?.code === 'NumpadEnter') {
                  validateParam() && handleSubmit(e)
                }
              }}
            />
            <img
              src="/assets/images/ico_danger.png"
              className="ico_err"
              alt="ico_danger"
            />
          </div>
          {alert.message &&
            error &&
            alert.screen === studentConstants.SCREEN_ADD_PARENT && (
              <Alert alert={alert} notShowComplete={true} isHideIcon={true} />
            )}
          <div className="form-sunE-button">
            <button
              className={
                "btn-line-blue btn-p35" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Kiểm tra
            </button>
          </div>
        </div>
      </div>
      <Alert
        alert={alert}
        onComplete={() =>
          history.push("/" + authentication.role + "/more/manage-linking-parent")
        }
        notShowError={true}
      />
      {Popup.showFormAddStudent && <PopUpAddStudent width={'400px'}/>}
    </div>
  );
}

export { LinkAccountPage };
