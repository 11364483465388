import "./ButtonNews.style.scss";

export const ButtonNews = ({
  onClick,
  border,
  children,
  width,
  height,
  backgroundColor,
  boxShadow,
  maxWidth,
  maxHeight,
  disabled,
  styleProps,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      className={`btn_custom ${disabled && " btn_custom_disabled"} ${className}`}
      onClick={onClick}
      style={{
        border: disabled ? "none" : border || "none",
        width: width,
        height: height,
        backgroundColor: disabled ? "#C4C4C4" : backgroundColor || "white",
        boxShadow: boxShadow,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        cursor: disabled ? "not-allowed" : "pointer",
        ...styleProps,
      }}
    >
      {children}
    </button>
  );
};

export default ButtonNews;
