import React, { useEffect, useState } from "react";
import moment from "moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { alertActions, scheduleActions } from "./../../_actions";
import { Alert } from "./../../_components/Alert";
import { useSelector, useDispatch } from "react-redux";
import { userConstants, scheduleConstants } from "./../../_constants";
import { SelectDate } from "./../../_components/Calendar";
import { SelectAsDiv } from "./../../_components/Select";
import "./style.css";

function PopUpAddTimeTable(props) {
  const dispatch = useDispatch();
  let {
    onClickCancel,
    titleButtonDone,
    titleButtonCancel,
    width,
    dataEditTimeTable,
  } = props;
  const alert = useSelector((state) => state.alert);

  const authentication = useSelector((state) => state.authentication);

  const [alertState, setAlert] = useState({});
  const isRemind =
    dataEditTimeTable.action === "add"
      ? dataEditTimeTable.checkRemind
      : parseInt(dataEditTimeTable.remind) === 1;
  const [checkRemind, setcheckRemind] = useState(isRemind);
  const [inputs, setInputs] = useState({
    ...dataEditTimeTable,
    // fix example for library datepicker can working
    start_time: new Date("2021/01/01 " + dataEditTimeTable.start_time),
    end_time: new Date("2021/01/01 " + dataEditTimeTable.end_time),
  });

  useEffect(() => {
    dispatch(alertActions.error({}));
  }, []);

  useEffect(() => {
    setAlert(alert);
  }, [alert]);

  function handleChange(e) {
    const { name } = e.target;
    if (name === "remind_time") {
      let val = e.target.value === "" ? "" : e.target.value.replace(/\D/, "");
      if (val > 60 && val < 70) {
        val = 60;
        setInputs((inputs) => ({ ...inputs, [name]: val }));
      } else if (val <= 60) {
        setInputs((inputs) => ({ ...inputs, [name]: val }));
      }
    } else {
      let value = e.target.value;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
    dispatch(alertActions.error({}));
  }

  function handleBlurInputText(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value.trim() }));
  }

  function handleOnBlur() {
    if (!inputs.remind_time) {
      setInputs((inputs) => ({ ...inputs, ["remind_time"]: 0 }));
    }
    if (Number(inputs.remind_time) > 60) {
      setInputs((inputs) => ({ ...inputs, ["remind_time"]: 60 }));
    }
  }
  const [submitted, setSubmitted] = useState(false);

  function handleSubmit(e) {
    if (inputs.class_name.length > 30) {
      dispatch(
        alertActions.error({
          message:
            (authentication.role === userConstants.ROLE_TEACHER
              ? "Tên lớp học"
              : "Tên môn học") + " không được quá 30 ký tự.",
          screen: scheduleConstants.SCREEN_ADD_TIME_TABLE,
        })
      );
    } else {
      // console.log(inputs.class_name.length)
      e.preventDefault();
      setSubmitted(true);
      if (validateParam()) {
        if (!checkRemind) {
          // inputs.remind_time = null;
          inputs.remind = 0;
          inputs.is_remind = 0;
          setInputs({
            ...inputs,
            remind: 0,
            // 'remind_time': null,
          });
        } else {
          inputs.remind = 1;
          inputs.is_remind = 1;
          if (!inputs.remind_time || inputs.remind_time <= 0) {
            // return false;
          }
        }
        if (dataEditTimeTable.action === "add") {
          dispatch(
            scheduleActions.addTimeTable({
              ...inputs,
              remind_time: inputs.remind_time || 10,
              start_time: moment(inputs.start_time).format("HH:mm"),
              end_time: moment(inputs.end_time).format("HH:mm"),
            })
          );
        } else {
          dispatch(
            scheduleActions.editTimeTable({
              ...inputs,
              remind_time: inputs.remind_time || 10,
              start_time: moment(inputs.start_time).format("HH:mm"),
              end_time: moment(inputs.end_time).format("HH:mm"),
            })
          );
        }
      }
    }
  }

  function validateParam() {
    if (checkRemind) {
      if (!inputs.remind_time || inputs.remind_time <= 0) {
        // return false;
      }
    }
    return inputs.class_name.trim() && inputs.start_time < inputs.end_time
      ? true
      : false;
  }

  // console.log(inputs);
  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
        style={{ width: width }}
      >
        <div className="popup-body">
          {alertState.message &&
            alertState.screen === scheduleConstants.SCREEN_ADD_TIME_TABLE && (
              <Alert alert={alertState} notShowComplete={true} />
            )}
          <div className="input-gr select-custom-bg text-center">
            <SelectAsDiv
              keySelected={inputs.day_name}
              textDefault={"Chọn ngày"}
              data={userConstants.TEXT_LIST_DAY}
              onChangeSelect={(day_name) => {
                setAlert({});
                setInputs({ ...inputs, day_name });
              }}
              styleBox={{
                display: 'flex',
                justifyContent: 'center',
              }}
              styleItem={{
                justifyContent: 'center',
                textIndent: 'unset'
              }}
            />
          </div>
          <div style={{marginBottom: '15px'}}>
            <span className="title-span">Thời gian</span>
            <div className="time-start-end-gr flex-m">
              <div className="flex-1 start active">
                <p>Bắt đầu</p>
                <SelectDate
                  // disableMouseFocus={true}
                  onChangeRaw={(e) => e.preventDefault()}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  timeIntervals={5}
                  name="start_time"
                  selected={inputs.start_time}
                  onChange={(start_time) => {
                    setAlert({});
                    setInputs({ ...inputs, start_time });
                  }}
                />
              </div>
              <div className="flex-1 end">
                <p>Kết thúc</p>
                <SelectDate
                  // disableMouseFocus={true}
                  onChangeRaw={(e) => e.preventDefault()}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  timeIntervals={5}
                  name="end_time"
                  selected={inputs.end_time}
                  onChange={(end_time) => {
                    setAlert({});
                    setInputs({ ...inputs, end_time });
                  }}
                />
              </div>
            </div>
            {inputs.start_time >= inputs.end_time && <span className="text-error-input">Giờ kết thúc phải lớn hơn giờ bắt đầu</span>}
          </div>
          <span className="title-span">
            {authentication.role === userConstants.ROLE_TEACHER
              ? "Lớp học"
              : "Môn học"}
          </span>
          <div
            className={
              "input-gr" + (submitted && !inputs.class_name ? " err" : "")
            }
          >
            <input
              type="text"
              name="class_name"
              className="box-shadow-la"
              onChange={handleChange}
              onBlur={handleBlurInputText}
              value={inputs.class_name}
              placeholder={
                authentication.role === userConstants.ROLE_TEACHER
                  ? "Nhập lớp học"
                  : "Nhập môn học"
              }
            />
          </div>
          <span className="title-span">
            Đặt lịch nhắc
            {/* {checkRemind && <span className="__text_remind_max">(Nhắc trước tối đa 60 phút)</span>} */}
          </span>
          <div className={"nput-gr datlichnhac"}>
            <div className="datlichnhac-input-gr">
              <span className="wh20 chk-custom-gr">
                <input
                  type="checkbox"
                  name="datlichnhac"
                  className="dln-check"
                  onChange={() => {
                    setcheckRemind(!checkRemind);
                  }}
                  checked={checkRemind}
                  id="datlichnhac"
                />
                <label htmlFor="datlichnhac"></label>
              </span>
              <span className="left">Nhắc trước</span>
              <input
                type="text"
                className="dln-mins  box-shadow-la"
                name="remind_time"
                onChange={handleChange}
                value={inputs.remind_time}
                onBlur={handleOnBlur}
              />
              <span className="right">phút</span>
            </div>
          </div>
          <div className="__text_remind_max text-center">
            Thời gian nhắc trước tối đa 60 phút
          </div>
        </div>
        <div className="flex-m btn-popup-gr">
          <button className="btn-default wd-c mr-10" onClick={onClickCancel}>
            {titleButtonCancel}
          </button>
          <button
            className={
              "btn-bg-blue btn-default wd-c" +
              (!validateParam() ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            {titleButtonDone}
          </button>
        </div>
      </div>
    </div>
  );
}

export { PopUpAddTimeTable };
