import { useEffect } from "react";

export const introPageLogic = (props) => {
  // Data Missions
  const dataMissions = [
    {
      id: 1,
      keyImg: "ico_mission_1",
      content:
        "Tiếng Anh là ngôn ngữ giúp chúng ta kết nối với thế giới, khám phá những điều mới mẻ và thể hiện bản thân.",
    },
    {
      id: 2,
      keyImg: "ico_mission_2",
      content:
        "Chúng tôi là những người yêu tiếng Anh và muốn lan toả tình yêu này tới bạn. Vì vậy, chúng tôi đã tạo ra phần mềm DẠY và HỌC tiếng Anh Sunday English, để giúp các Thầy cô và các bạn Học sinh trên mọi miền đất nước DẠY và HỌC tiếng Anh một cách hiệu quả, thú vị và thành công.",
    },
    {
      id: 3,
      keyImg: "ico_mission_3",
      content:
        "Chúng tôi luôn nỗ lực để mang đến cho bạn những phương pháp giảng dạy tiên tiến và sáng tạo, nhằm kích thích sự hứng thú và năng lực học tập, mang lại cho bạn những trải nghiệm học tập tuyệt vời nhất.",
    },
    {
      id: 4,
      keyImg: "ico_mission_4",
      content:
        "Chúng tôi tin rằng, với niềm đam mê và nỗ lực không ngừng, chúng tôi sẽ giúp các Thầy cô và các bạn học sinh chinh phục tiếng Anh và tự tin tỏa sáng trong cuộc sống.",
    },
  ];

  // Data Table Scholarly
  const dataScholarly = [
    {
      id: 1,
      titleSub: "Học tập",
      titleEng: "Studying",
      listContent: [
        {
          id: 1,
          content:
            "Học sinh khám phá kiến thức thông qua các dạng bài học tương tác và các video giảng bài hấp dẫn của giáo viên.",
        },
      ],
      colorSpecific: "#C84242",
    },
    {
      id: 2,
      titleSub: "Thực hành",
      titleEng: "Exercising",
      listContent: [
        {
          id: 1,
          content:
            "Giáo trình điện tử được thiết kế với một môi trường học tập, giao tiếp, tương tác hứng khởi, theo 1 quá trình lặp lại liên tục, tạo ra thói quen luyện tập thường xuyên, khoa học, giúp học sinh nắm vững kiến thức và nhớ bài lâu hơn.",
        },
        {
          id: 2,
          content:
            "Phát triển toàn diện các kỹ năng nhờ việc ứng dụng kiến thức được học vào các bài luyện tập, thực hành theo chủ đề.",
        },
      ],

      colorSpecific: "#F29500",
    },
    {
      id: 3,
      titleSub: "Phản hồi",
      titleEng: "Coaching",
      listContent: [
        {
          id: 1,
          content:
            "Hệ thống phần mềm thông minh có chức năng như một Gia sư ảo, gợi ý làm bài, chữa bài và đưa ra phản hồi, giúp học sinh nhớ lâu, hiểu sâu.",
        },
      ],
      colorSpecific: "#FDCD04",
    },
    {
      id: 4,
      titleSub: "Đánh giá",
      titleEng: "Assessment",
      listContent: [
        {
          id: 1,
          content:
            "Đánh giá thường xuyên và định kỳ với hệ thống 28 bài kiểm tra được phân bổ khoa học, giúp ôn luyện kiến thức và đánh giá đúng năng lực học sinh nhằm:",
          listSideContent: [
            {
              id: 1,
              content:
                "- Biết chính xác học lực và sự tiến bộ của học sinh theo từng kỹ năng",
            },
            {
              id: 2,
              content:
                "- Cá nhân hóa lộ trình đào tạo giúp học sinh học đúng với học lực của mình",
            },
            {
              id: 3,
              content: "- Tạo động lực học tập cho học sinh",
            },
          ],
        },
      ],

      colorSpecific: "#00E2A0",
    },
    {
      id: 5,
      titleSub: "Nhắc nhở",
      titleEng: "Repetition",
      listContent: [
        {
          id: 1,
          content:
            "Công cụ nhắc nhớ với thuật toán tiên tiến, xác định chính xác điểm quên của học sinh giúp ôn luyện và củng cố kiến thức đúng thời điểm, đúng trọng tâm; hình thành trí nhớ dài hạn, tạo phản xạ tự nhiên khi giao tiếp, giúp học sinh giao tiếp tiếng Anh hiệu quả, tư duy trực tiếp bằng tiếng Anh và đạt điểm cao trong các kỳ thi.",
        },
      ],
      colorSpecific: "#24BBE0",
    },
  ];

  // Data Result Schoolary

  const dataResultScholarly = [
    {
      id: 1,
      content: "Yêu thích học tiếng Anh",
    },
    {
      id: 2,
      content: "Học đúng với trình độ của mình",
    },
    {
      id: 3,
      content: "Nhớ lâu, hiểu sâu, không cần học thuộc lòng",
    },
    {
      id: 4,
      content: "Phát triển toàn diện 4 kỹ năng Nghe – Nói – Đọc – Viết",
    },
    {
      id: 5,
      content:
        "Hình thành phản xạ ngôn ngữ tự nhiên, tư duy trực tiếp bằng tiếng Anh",
    },
    {
      id: 6,
      content: "Giao tiếp tiếng Anh hiệu quả và đạt điểm cao trong các kỳ thi",
    },
  ];

  // Data intro
  const dataIntro = {
    cell_1: [
      {
        id: 1,
        content:
          "Sunday English cung cấp một nền tảng công nghệ tiên tiến, giúp các trường Quốc tế, Tư thục, Công lập, các Trung tâm đào tạo ngoại ngữ và các Thầy cô có thể tự tạo ra một hoặc nhiều giáo trình cho trường, lớp chất lượng cao, ngoại khoá, chính khoá theo các mục tiêu đào tạo riêng biệt.",
        keyImg: "img_cell_1_1",
      },
      {
        id: 2,
        content:
          "Sunday English là công cụ giảng dạy đắc lực của Thầy cô và Phụ huynh, giúp giao bài, quản lý thời gian, mức độ chuyên cần và kết quả học tập của học sinh nhằm mang lại kết quả đào tạo cao nhất.",
        keyImg: "img_cell_1_2",
      },
      {
        id: 3,
        content:
          "Phụ huynh không biết Tiếng Anh hoặc không có thời gian vẫn có thể kèm cặp, giao bài cho con, quản lý kết quả học tập của con, giúp con học giỏi Tiếng Anh nhờ sự hỗ trợ của công nghệ trí tuệ nhân tạo AI.",
        keyImg: "img_cell_1_3",
      },
    ],
    cell_2: [
      {
        id: 1,
        content:
          "Sunday English là ứng dụng phần mềm DẠY và HỌC Tiếng Anh hàng đầu dành cho học sinh THCS, được thiết kế theo tiêu chuẩn quốc tế, bám sát chương trình SGK Bộ GD&ĐT.",
        keyImg: "img_cell_2_1",
      },
      {
        id: 2,
        content:
          "Sunday English bao gồm 8 bộ giáo trình học liệu Tiếng Anh cho các khối 6, 7, 8, 9, phát triển toàn diện 4 kỹ năng Nghe – Nói - Đọc - Viết.",
        keyImg: "img_cell_2_2",
      },
      {
        id: 3,
        content:
          "Sunday English là công cụ thực hành Tiếng Anh hiệu quả, bổ trợ cho phương pháp học truyền thống trên lớp. Giúp học sinh tiến bộ nhanh, hiểu bài sâu hơn, nhớ Từ vựng và Ngữ pháp lâu hơn với sự hỗ trợ của AI và các phương pháp học thuật tiên tiến.",
        keyImg: "img_cell_2_3",
      },
      {
        id: 4,
        content:
          "Thầy cô tiết kiệm được nhiều thời gian soạn bài, chấm bài bằng việc sử dụng kho học liệu Sunday English với hàng ngàn bài tập kèm lời giải chi tiết được phân loại theo học lực của học sinh, bao gồm trên 50 dạng bài theo các chủ đề trong SGK.",
        keyImg: "img_cell_2_4",
      },
    ],
  };

  // Data Intro Mombile
  const dataIntroMobile = [
    {
      id: 1,
      content:
        "Sunday English là ứng dụng phần mềm DẠY và HỌC Tiếng Anh hàng đầu dành cho học sinh THCS, được thiết kế theo tiêu chuẩn quốc tế, bám sát chương trình SGK Bộ GD&ĐT.",
      keyImg: "img_cell_2_1",
    },
    {
      id: 2,
      content:
        "Sunday English bao gồm 8 bộ giáo trình học liệu Tiếng Anh cho các khối 6, 7, 8, 9, phát triển toàn diện 4 kỹ năng Nghe – Nói - Đọc - Viết.",
      keyImg: "img_cell_2_2",
    },
    {
      id: 3,
      content:
        "Sunday English cung cấp một nền tảng công nghệ tiên tiến, giúp các trường Quốc tế, Tư thục, Công lập, các Trung tâm đào tạo ngoại ngữ và các Thầy cô có thể tự tạo ra một hoặc nhiều giáo trình cho trường, lớp chất lượng cao, ngoại khoá, chính khoá theo các mục tiêu đào tạo riêng biệt.",
      keyImg: "img_cell_1_1",
    },
    {
      id: 4,
      content:
        "Sunday English là công cụ thực hành Tiếng Anh hiệu quả, bổ trợ cho phương pháp học truyền thống trên lớp. Giúp học sinh tiến bộ nhanh, hiểu bài sâu hơn, nhớ Từ vựng và Ngữ pháp lâu hơn với sự hỗ trợ của AI và các phương pháp học thuật tiên tiến.",
      keyImg: "img_cell_2_3",
    },
    {
      id: 5,
      content:
        "Sunday English là công cụ giảng dạy đắc lực của Thầy cô và Phụ huynh, giúp giao bài, quản lý thời gian, mức độ chuyên cần và kết quả học tập của học sinh nhằm mang lại kết quả đào tạo cao nhất.",
      keyImg: "img_cell_1_2",
    },
    {
      id: 6,
      content:
        "Thầy cô tiết kiệm được nhiều thời gian soạn bài, chấm bài bằng việc sử dụng kho học liệu Sunday English với hàng ngàn bài tập kèm lời giải chi tiết được phân loại theo học lực của học sinh, bao gồm trên 50 dạng bài theo các chủ đề trong SGK.",
      keyImg: "img_cell_2_4",
    },
    {
      id: 7,
      content:
        "Phụ huynh không biết Tiếng Anh hoặc không có thời gian vẫn có thể kèm cặp, giao bài cho con, quản lý kết quả học tập của con, giúp con học giỏi Tiếng Anh nhờ sự hỗ trợ của công nghệ trí tuệ nhân tạo AI.",
      keyImg: "img_cell_1_3",
    },
  ];

  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);

  // Scroll To View
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  return {
    dataMissions,
    dataScholarly,
    dataResultScholarly,
    dataIntro,
    dataIntroMobile,
  };
};
