import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { teacherActions } from "../../../../../_actions";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isEmpty, findIndex } from "lodash";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { teacherConstants } from "../../../../../_constants";

export const assignSelectionLogic = (props) => {
  const history = useHistory();
  const _dispatch = useDispatch();
  const { id } = useParams();
  const authentication = useSelector((state) => state.authentication);

  const [typeSelected, setTypeSelected] = useState(
    JSON.parse(localStorage.getItem("type_assign")) ?? ""
  );

  // Data Selection
  const dataSelection = [
    {
      id: 1,
      title: "Giao bài theo năng lực",
      content:
        "Chỉ cần chọn thời lượng bài tập, Sunday English sẽ tự đề xuất những bài tập phù hợp nhất với năng lực từng học sinh của bạn ",
      type: TypeAssignExercise.CAPACITY,
    },
    {
      id: 2,
      title: "Giao bài theo yêu cầu",
      content:
        "Chọn kỹ năng, độ khó và số lượng bài tập cần giao và Sunday English sẽ tự động đề xuất bài tập thích hợp cho học sinh của bạn",
      type: TypeAssignExercise.DEMAND,
    },
    {
      id: 3,
      title: "Giao bài tự do",
      content:
        "Tự chọn những bài tập mong muốn giao cho học sinh trong các giáo trình của Sunday English và giáo trình cá nhân",
      type: TypeAssignExercise.FREEDOM,
    },
  ];

  // Handle Navigate Back
  const navigateToSourcePage = () => {
    _dispatch({
      type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
    });
    history.push(
      "/" + authentication.role + "/class/view/" + id + "/exercise/student"
    );
    _dispatch({
      type: teacherConstants.GET_DATA_SETTING_ASSIGN,
      setting: [],
    });
    localStorage.removeItem("type_assign");
  };

  // Handle Submit Continue
  const handleSubmit = () => {
    if (typeSelected) {
      localStorage.setItem("type_assign", JSON.stringify(typeSelected));
      switch (typeSelected) {
        case TypeAssignExercise.FREEDOM:
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/curriculum?assign=assign_freedom&page=classmanager" +
              `&classId=${id}`
          );
          break;
        case TypeAssignExercise.DEMAND:
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              TypeAssignExercise.DEMAND
          );
          break;
        case TypeAssignExercise.CAPACITY:
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              TypeAssignExercise.CAPACITY +
              "?page=capacity"
          );
          break;
        default:
      }
    }
  };

  // Handle Checked
  const handleChecked = (data) => {
    setTypeSelected(data?.type);
  };

  return {
    dataSelection,
    navigateToSourcePage,
    handleSubmit,
    typeSelected,
    handleChecked,
  };
};
