import "./SelectQuestion.style.scss";
import { renderDataQuestion1 } from "../../../_base/DetailListNews";
import { selectQuestionLogic } from "./SelectQuestion.logic";

const SelectQuestion = (props) => {
  let {
    title,
    title_2,
    data,
    titleSelected,
    typeQuestion,
    handleSelectQuestion,
  } = props;

  // let { isSelected, handleSelectQuestion } = selectQuestionLogic(props);

  const isSelected = titleSelected == title;

  return (
    <div
      id={title}
      key={title}
      className="select-question-container border_1_light"
    >
      <div
        className="flex-jus-between pointer_cursor question_header pos_rel"
        onClick={() => handleSelectQuestion(title)}
      >
        <div className="font_news_semi_bold question_name line_height_normal">
          {title}{" "}
          {!!title_2 ? (
            <div className="font_news_semi_bold question_name line_height_normal">
              {title_2}
            </div>
          ) : null}
        </div>
        <div className="flex-center">
          <img
            src={`/assets/images/icon/${
              isSelected ? "ico_circle_up" : "ico_circle_down"
            }.png`}
            alt="Icon Dropdown"
            className="contain_image img_dropdown"
          />
        </div>

        {isSelected ? <div className="question_divider_header"></div> : null}
      </div>

      {isSelected ? renderDataQuestion1(data) : null}
    </div>
  );
};

export default SelectQuestion;
