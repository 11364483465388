import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "../../_components/Admin/Header";
import { studentService } from "../../_services";
import { isEmpty } from "lodash";
import moment from 'moment'

const TYPE_MANUAL_MARK = ["project", "writing 7", "speaking 3"];

const HistoryCurriculumStudent = (props) => {
  const { skill, unit_id, id, lesson_id} = useParams();
  const tabSelected = new URLSearchParams(window.location.search).get(
    "tab_selected"
  );
  const fromScreen = new URLSearchParams(window.location.search).get(
    "from_screen"
  );
  const teacher_id = new URLSearchParams(window.location.search).get(
    "teacher_id"
  );
  const authentication = useSelector((state) => state.authentication);
  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const page = params?.get("page");
  const tittle = params?.get("tittle");
  const topicId = params?.get("topicId");
  const curriculumId = params?.get("curriculumId");

  const [dataHistory, setDataHistory] = useState({
    data_log: [],
  });
  const [dataHistoryHomePage, setDataHistoryHomePage] = useState({});

  useEffect(() => {
    if (unit_id !== "none") {
      studentService
        .getListCurriculumHistory(lesson_id, skill, id)
        .then((res) => {
          if (res) {
            setDataHistory(res);
            studentService
              .getDetailHomeWorkExercise(
                unit_id,
                "exercise",
                res.lesson_data.lesson_type
              )
              .then((data) => {
                if (data) {
                  setDataHistoryHomePage(data);
                }
              });
          }
        });
    } else {
      studentService
        .getListCurriculumHistoryMasterUnit(lesson_id, skill)
        .then((res) => {
          if (res) {
            setDataHistory(res);
          }
        });
    }
  }, []);

  const navigateToDetail = (data) => {
    TYPE_MANUAL_MARK.includes(
      `${dataHistory.lesson_data?.lesson_type} ${
        !!dataHistory.lesson_data?.question_typ
          ? dataHistory.lesson_data?.question_type
          : ""
      }`?.trim()
    )
      ? history.push(
          "/" +
            authentication.role +
            `/curriculum/${id}/skill/${unit_id}/lesson/${skill}/${lesson_id}/${data.library}/${data.user_exercise_id}/${dataHistory.lesson_data.lesson_type}`
        )
      : history.push(
          "/" +
            authentication.role +
            `/curriculum/${id}/skill/${unit_id}/lesson/${skill}/${lesson_id}/${data.library}/${data.user_exercise_id}/${data.exercise_type}`
        );
  };

  const goBack = () => {
    if (fromScreen === "page_more") {
      history.push(
        "/" +
          authentication.role +
          `/more/class/view/${id}/exersice/${teacher_id}`
      );
    } else if (fromScreen === "home_page") {
      history.push("/" + authentication.role);
    } else if (fromScreen === "home_work_teacher_page") {
      history.push(
        "/" + authentication.role + `/homework_by_teacher/${teacher_id}`
      );
    } else {
      if (unit_id === "none") {
        // history.push(
        //   "/" +
        //     authentication.role +
        //     `/curriculum/${id}/master_unit?tab_selected=${tabSelected}`
        // );

        history.go(-1);
      } else if (page == "homepage") {
        history.go(-1);
      } else {
        if(tittle)
          history.push(
            "/" +
              authentication.role +
              `/curriculum/${id}/skill/${unit_id}/lesson/${tittle}?subType=test_preparation&topicId=${topicId}&curriculumId=${curriculumId}`
          );
        else
        history.push(
          "/" +
            authentication.role +
            `/curriculum/${id}/skill/${unit_id}/lesson/${skill}`
        );
      }
    }
  };

  const checkTypeManualMark = (item) => {
    return (
      TYPE_MANUAL_MARK.includes(
        `${item?.exercise_type} ${
          !!item?.question_type ? item?.question_type : ""
        }`?.trim()
      ) ||
      TYPE_MANUAL_MARK.includes(
        `${dataHistory.lesson_data?.lesson_type} ${
          !!dataHistory.lesson_data?.question_type
            ? dataHistory.lesson_data?.question_type
            : ""
        }`?.trim()
      ) ||
      TYPE_MANUAL_MARK.includes(
        `${item?.lesson_type} ${
          !!item?.question_type ? item?.question_type : ""
        }`?.trim()
      )
    );
  };

  return (
    <div className="sunE-right-container">
      <Header title={"Lịch sử làm bài"} isBack clickBack={goBack} />
      <div className="history-curriculum-student-page scrollbar-custom">
        <div className="view-lession-detail origin-vertical">
          <span className="font-weight-bold color-confirm-success">
            {dataHistory.lesson_data?.topic}
          </span>
          <span className="font-weight-bold">
            {dataHistory.lesson_data?.name}
          </span>
        </div>
        <div
          className={`listview-history ${
            page == "homepage" && !isEmpty(dataHistoryHomePage)
              ? "listview_history_homepage"
              : ""
          }`}
        >
          {page == "homepage" && !isEmpty(dataHistoryHomePage) ? (
            <div className="box-shadow box-project-score-gr bg-white">
              <div className="flex-m project-score-gr">
                <div className="project-score mr-10 text-center">
                  <h2>Điểm</h2>
                  <input
                    disabled={true}
                    type="text"
                    step="0.1"
                    onWheel={(e) => e.target.blur()}
                    name="score"
                    max={10}
                    min={0}
                    value={
                      dataHistoryHomePage?.old_result?.final_score &&
                      parseFloat(
                        parseFloat(
                          dataHistoryHomePage?.old_result?.final_score
                        ).toFixed(1)
                      )
                    }
                  />
                </div>

                <div className="flex-1 ml-10">
                  <h2 className="pl-20">Nhận xét</h2>
                  <textarea
                    disabled={true}
                    defaultValue={
                      (dataHistoryHomePage?.old_result?.final_score &&
                        dataHistoryHomePage?.old_result?.comment) ||
                      ""
                    }
                    name="comment"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              {dataHistory.data_log.map((item, index) => (
                <div
                  className="item-history-student horizontal-space-between"
                  key={index}
                >
                  <div className="flex-1 info-left origin-vertical">
                    <span className="font-weight-bold">{moment(item?.start_time).format("DD/MM/YYYY HH:mm:ss")}</span>
                    <span>
                      {`Thời gian làm: ${parseInt(item.duration / 60) === 0 ? ' ' : parseInt(item.duration / 60) + ' phút '}${item.duration % 60} giây`}
                    </span>
                    {checkTypeManualMark(item) ? (
                      parseInt(item.status) == 1 ? (
                        <span className="font-weight-bold color-confirm-success-2 mg-top-10">
                          Đã chấm
                        </span>
                      ) : (
                        <span className="font-weight-bold color-confirm-danger mg-top-10">
                          Chờ chấm
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="info-right origin-vertical"
                    style={{ alignItems: "center" }}
                  >
                    {item.status == 1 || item.score > 0 ? (
                      <span className="view-score">
                        {parseFloat(parseFloat(item.score).toFixed(1))}
                      </span>
                    ) : null}
                    {checkTypeManualMark(item)
                      ? parseInt(item.status) === 1 &&
                        item.user_exercise_id && (
                          <a
                            className="btn link-to-url mg-top-10 font-weight-bold"
                            onClick={() => navigateToDetail(item)}
                          >
                            Xem bài chữa
                          </a>
                        )
                      : ""}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryCurriculumStudent;
